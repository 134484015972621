import { styled } from "styled-components";
import { mediaQuery } from "../../../../../../breakPoint";
import { useGetFinnanceVerification } from "../../../../../../hooks/useFinanceVerification";
import { Button } from "@mui/material";
import { SelfEmployedDetails } from "./selfEmployed/inndex";

export function EmploymentDetails() {
  const { status, employment_status, employment_details } = useGetFinnanceVerification();

  const employmentDetails = employment_details?.__typename === "IsEmployed" && employment_details;

  return (
    <>
      <ContactInfoDiv>
        <Content>
          <H2 status={status}>{status}</H2>
          <DetailsContainer>
            <DETAILS>
              <H3>EMPLOYMENT STATUS</H3>
              <p>{employment_status?.replace(/_/g, ' ')}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          {employment_status === "EMPLOYED" && (
            <>
              <DetailsContainer>
                <DETAILS>
                  <H3>employer name</H3>
                  <p>{employmentDetails && employmentDetails?.employer_name}</p>
                </DETAILS>
                <Line></Line>
              </DetailsContainer>
              <DetailsContainer>
                <DETAILS>
                  <H3>job description</H3>
                  <p>
                    {employmentDetails && employmentDetails?.job_description}
                  </p>
                </DETAILS>
                <Line></Line>
              </DetailsContainer>
              <DetailsContainer>
                <DETAILS>
                  <H3>work address</H3>
                  <p>{employmentDetails && employmentDetails?.work_address}</p>
                </DETAILS>
                <Line></Line>
              </DetailsContainer>
              <DetailsContainer>
                <DETAILS>
                  <H3>Salary range</H3>
                  <p>{employmentDetails && employmentDetails?.salary}</p>
                </DETAILS>
                <Line></Line>
              </DetailsContainer>
              <DetailsContainer>
                <DETAILS>
                  <H3>work ID</H3>
                  <Button variant="text" size="small" href={employmentDetails? employmentDetails?.work_id?.front : ""} download>
                    ID Card (Front)
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    href={ employmentDetails? employmentDetails?.work_id?.back as string : ""}
                    download
                  >
                    ID Card (Back)
                  </Button>
                </DETAILS>
                <Line></Line>
              </DetailsContainer>
              <DetailsContainer>
                <DETAILS>
                  <H3>bank statement (1 year)</H3>
                  <Button
                    href={
                      employmentDetails
                        ? employmentDetails?.bank_statement?.src
                        : ""
                    }
                    variant="text"
                    size="small"
                    download
                  >
                    Bank Statement
                  </Button>
                </DETAILS>
                <Line></Line>
              </DetailsContainer>
            </>
          )}
          {employment_status === "SELF_EMPLOYED" && (
            <>
              <SelfEmployedDetails/>
            </>
          )}
        </Content>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  margin-top: 2.375em;
  margin-bottom: 3.5em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;

const Content = styled.div``;

const DetailsContainer = styled.div``;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1em;
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #2c2c2c;
    text-transform: capitalize;
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;

export const H2 = styled.h2<{ status: string | any }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  color: ${({ status }) =>
    status === "APPROVED"
      ? "#33B95D;"
      : status === "PENDING"
        ? "#DFAE00;"
        : status === "REJECTED"
          ? "#CE2B2B;"
          : "#00317a"};
`;
