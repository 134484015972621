import { styled } from "styled-components";
// import { SaveButtonComponent } from "../../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
// import { Money } from "../../../../../utils/svg";
import CostAndEquityTable, { Row } from "./table";
// import { DoughnutChart } from "./chart";
import { DownloadBtnComponent } from "../../../../utils/buttons/downloadBtn";
// import { DatePickComponent } from "../../../../../datePicker";
import { mediaQuery } from "../../../../breakPoint";
import { PaymentPlan } from "./paymentPlan";
import { useGetCosting } from "../../../../hooks/costing/usegetCosting";
import SimpleBackdrop from "../../../../utils/backdrop";
import { InlineWidget } from "react-calendly";


export function Costing() {
  const rows: Row[] = [
    {
      id: 1,
      option: "Building plan",
      amount: 2459000.0,
    },
    {
      id: 2,
      option: 15343455.0,
      amount: 2459000.0,
    },
    {
      id: 3,
      option: "Building permit",
      amount: 4569000.0,
    },
  ];
  // const price = 2500000;
  // const monthlyPay = 1250000.0;

  const { 
    // permit_cost, 
    // substructure_cost, 
    // superstructure_cost, 
    // land_verification_cost, 
    duration, 
    loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen, loading, paymentTerms } = useGetCosting();

  // const total = permit_cost + substructure_cost + superstructure_cost + land_verification_cost

  const isPaymentTermsPending = paymentTerms === "PENDING";
  const isPaymentTermsApproved = paymentTerms === "APPROVED";

  return (
    <>
      {loading ? (<SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />) : (
        <FinanceContainer>
          <Finance>
            <FinanceContent>
              <FinanceHeader>
                <H1>Costing</H1>
                {/* <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P> */}
              </FinanceHeader>
              {/* <Amount>
                <H2>
                  <Sup>NGN</Sup>
                  {total?.toLocaleString()}
                  <Sup>.00</Sup>
                </H2>
              </Amount> */}
              {/* <MonthlyPaymentDiv>
              <P2>Monthly repayment (After initial deposit)</P2>
              <CostDiv>
                <Money />
                <AmountPerMonth>
                  NGN {monthlyPay.toLocaleString()}.00/ month
                </AmountPerMonth>
              </CostDiv>
            </MonthlyPaymentDiv> */}
              <DetailsHeader>
                <Details>Details</Details>
                <DownloadBtnComponent variant="outlined" />
              </DetailsHeader>
              <Table>
                <CostAndEquityTable rows={rows} />
              </Table>
              {/* <ChartContainer>
              <DoughnutChart />
            </ChartContainer> */}
              {/* <DatePickComponent /> */}
            </FinanceContent>
            <Duration>Duration: {duration} months</Duration>
            {isPaymentTermsApproved ?
             (<PaymentPlan />) :
            isPaymentTermsPending &&
              (<div className="App">
                <InlineWidget  url="https://calendly.com/appointments-cja/appointment" />
              </div>)}
            {/* <SaveButtonComponent className="btn" name="Accept and proceed" /> */}
          </Finance>
          <ChartConponentDiv>
            <ChartIconComponent />
          </ChartConponentDiv>
        </FinanceContainer>)}
    </>
  );
}



export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 3em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    margin-top: 0;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;

export const Finance = styled.div`
  width: 100%;
  margin-inline: auto;
  ${mediaQuery.tablet} {
    width: 100%;
    margin: auto;
  }
  .btn {
    padding: 1em !important;
  }
`;

export const FinanceContent = styled.div`
  width: 100%;
`;

export const FinanceHeader = styled.div``;

export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 20px;
  width: 330px;
  height: 53px;
  color: #797474;
  ${mediaQuery.mobile} {
    font-size: 0.875rem;
    line-height: 19px;
    width: 100%;
  }
`;

// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;

// const Amount = styled.div`
//   ${mediaQuery.tablet} {
//     text-align: center;
//   }
// `;
// const H2 = styled.h1`
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 54px;
//   line-height: 65px;
//   color: #000000;
//   margin-bottom: 0.5em;
//   margin-top: 0.2em;
//   ${mediaQuery.tablet} {
//     font-size: 2.5rem;
//   }
// `;

// const Sup = styled.sup`
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 22px;
//   color: #000000;
//   ${mediaQuery.tablet} {
//     font-size: 0.9rem;
//   }
// `;
// const MonthlyPaymentDiv = styled.div``;

// const P2 = styled.p`
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 20px;
//   color: #000000;
// `;

// const CostDiv = styled.div`
//   margin-top: 0.3em;
//   display: flex;
//   align-items: center;
//   padding: 0.7em 0.6em 0.7em 1.5em;
//   gap: 0.764375em;
//   background: #e5f3ff;
//   border-radius: 32px;
//   svg {
//     fill: #0084fe;
//   }
// `;

// const AmountPerMonth = styled.p`
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 19px;
//   color: #00317a;
// `;

const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5em;
  ${mediaQuery.tablet} {
    flex-wrap: wrap-reverse;
    gap: 0.5em;
  }
`;
const Details = styled.h4`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0084fe;
`;

const Table = styled.div`
  margin-top: 1em;
`;

// const ChartContainer = styled.div`
//   margin-top: 3em;
//   margin-bottom: 2em;
// `;

const Duration = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 2.5em;
  margin-top: 1.2em;
`;
