import { styled } from "styled-components";
import { Phase1 } from "./phase1";
import { Phase2 } from "./phase2";
import { Outlet } from "react-router-dom";
import { IntializeConstructionHamburgerIcon } from "../../utils/svg";
import { Fragment, KeyboardEvent, MouseEvent, useState } from "react";
import { Drawer } from "@mui/material";
import { mediaQuery } from "../../breakPoint";
type Props = {
  navAppearance?: boolean;
};
type AnchorNav = "left";

export function NavigationProject(props: Props) {
  // const match = window.location.href;
  // DRAWER OR MOBILE VIEW
  const [showMobileDrawer, setShowDrawer] = useState({ left: false });
  const toggleDrawer =
    (anchor: AnchorNav, open: boolean) =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setShowDrawer({ ...showMobileDrawer, [anchor]: open });
    };

  const list = (anchor: AnchorNav) => (
    <MobilePhaseContainer
      role="navigation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="phase"
    >
      <Phase1 />
      <Phase2 />
    </MobilePhaseContainer>
  );
 
  return (
    <>
      <Main>
        <ProjectDiv>
          <ProjectContainer>
            <ProjecHeader>
              <H3>Project</H3>
            </ProjecHeader>
            <Phase>
              <Phase1 />
            </Phase>
            <Phase>
              <Phase2 />
            </Phase>
          </ProjectContainer>
        </ProjectDiv>
        {/* desktop/mobile */}
        <OutleatMain className="mobile_outlet">
          <Outlet />
        </OutleatMain>
      </Main>
      <MainContainer>
        {(["left"] as const).map((anchor) => (
          <Fragment key={anchor}>
            <MobileNav>
              <MobileNavToggle>
                <div onClick={toggleDrawer(anchor, true)} className="icon">
                  <IntializeConstructionHamburgerIcon />
                </div>
                <Text>
                  <p className="name">Build Now </p>/
                  <p className="route">Employment verification </p>
                </Text>
              </MobileNavToggle>
            </MobileNav>
            <Drawer
              anchor={anchor}
              open={showMobileDrawer[anchor]}
              onClose={toggleDrawer(anchor, false)}
              sx={{ height: "fitContent" }}
            >
              {list(anchor)}
            </Drawer>
          </Fragment>
        ))}
        {/* mobile  */}
        {/* <OutleatMain className="mobile_outlet" >
          <Outlet />
        </OutleatMain> */}
      </MainContainer>
    </>
  );
}

const Main = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  .mobile_outlet {
    ${mediaQuery.tablet} {
      width: 100%;
    }
  }
`;
export const ProjectDiv = styled.div`
  height: 90vh;
  padding: 2em;
  position: fixed;
  left: 22em;
  border-right: 0.5px solid #7d7d7d;
  border-radius: 5px;
  ${mediaQuery.tablet} {
    display: none;
  }
`;

export const ProjectContainer = styled.div`
  width: 100%;
`;

export const ProjecHeader = styled.div``;

export const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 29px;
  color: #000000;
`;

const OutleatMain = styled.div`
  margin-left: 40em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin: 5em auto 5em;
    margin-inline: auto;
  }
`;
export const Phase = styled.div``;

// mobile
const MobileNav = styled.div`
  display: none;
  ${mediaQuery.tablet} {
    display: block;
  }
`;
const MainContainer = styled.div`
  display: none;
  ${mediaQuery.tablet} {
    display: block;
  }
  position: fixed;
  top: 6.35em;
  width: 100%;
  background: rgba(6, 123, 232, 0.2);
  border-bottom: 0.5px solid #a4a4a4;
  height: 50px;
  padding: 0.8em;
  margin: auto;
  z-index: 2;
  ${mediaQuery.mobile} {
    top: 4.1em;
  }
  .mobile_outlet {
    display: none;
    ${mediaQuery.tablet} {
      display: flex;
    }
  }
`;

const MobileNavToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 1.416875em;
  margin: auto;
  padding-left: 2em;
  ${mediaQuery.mobile} {
    padding-left: 1em;
  }
  .icon {
    cursor: pointer;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2em;
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .route {
    text-decoration: underline;
  }
`;

const MobilePhaseContainer = styled.div`
  padding: 1em;
  z-index: -1;
  overflow: hidden;
`;
