import { RefObject, useCallback, useRef, useState } from "react";
import { styled } from "styled-components";
import { ImageUploadIcon } from "../../../utils/svg";
import { UploadImageProps, imageUplaodBackProps } from "../../../types";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { useNotificationToast } from "../../../hooks/useToast";

export function FileUpload(props: UploadImageProps) {

  const {errorToast} = useNotificationToast()
  // const fileInputRef = useRef<HTMLInputElement>(null);

  // const handleClickToUploadImage = () => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }
  // };

  const [selectedFilePATH, setThumbnailPath] = useState<string | null>("")

  const onDrop = useCallback((acceptedFiles: any, rejectedFiles: any) => {
    if (rejectedFiles.length > 0) {
      errorToast("File too large. Please upload a file that is less than 1.5MB.");
      return;
    }

    props?.src(acceptedFiles)

    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const dataUrl = reader.result as string;
        setThumbnailPath(dataUrl);
      };

      reader.readAsDataURL(file);
    }

  }, [props,errorToast])


  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    maxSize: 1500000, 
    onDrop,
    onDropRejected: (rejectedFiles) => onDrop([], rejectedFiles),
  })

  

  const deleteFunction = () =>{
    props.src("")
    setThumbnailPath("")
  }


  return (
    <>
      <ImageContainer>
        <ImageUpload>
          <ImageHeader>{props?.label}</ImageHeader>
          <ImageBorder>
            {selectedFilePATH ? (
              <Items >
                <img src={selectedFilePATH} alt="" className="img" />
              </Items>
            ) : (
              <Items   {...getRootProps()}>
                <ImageUploadIcon  className = "id"/>
                <input  {...getInputProps({required: true})} />
                {/* <Input
                  ref={fileInputRef as RefObject<HTMLInputElement>}
                  type="file"
                  id="file-upload"
                  name="file"
                  onChange={props.imageChange}
                /> */}
                <Text>
                  <H3>{props.name}</H3>
                </Text>
              </Items>
            )} 
          </ImageBorder>
          { selectedFilePATH && (
            <div
              className="delete"
              style={{ cursor: "pointer" }}
              onClick={deleteFunction}
            >
              <DeleteIcon />
              Delete Image
            </div>
          )}
        </ImageUpload>
        {props.error &&(
           <ValidationLabel >
           {props.error}
         </ValidationLabel>
        )}
      </ImageContainer>
    </>
  );
}

export function FileUploadBack(props: imageUplaodBackProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickToUploadImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <ImageContainer>
        <ImageUpload>
          <ImageHeader>{props.label}</ImageHeader>
          <ImageBorder>
            {props.srcBack !== null || props.srcBack ? (
              <Items onClick={handleClickToUploadImage}>
                <img src={props.srcBack} alt="" className="img" />
              </Items>
            ) : (
              <Items onClick={handleClickToUploadImage}>
                <ImageUploadIcon />
                <Input
                  ref={fileInputRef as RefObject<HTMLInputElement>}
                  type="file"
                  id="file-upload"
                  name="file"
                  onChange={props.handleImageChangeBack}
                />
                <Text>
                  <H3>{props.name}</H3>
                </Text>
              </Items>
            )}
          </ImageBorder>
          {props.src && (
            <div
              className="delete"
              style={{ cursor: "pointer" }}
              onClick={props.DeleteUploadedImageBack}
            >
              <DeleteIcon />
              Delete Uploaded Image
            </div>
          )}
        </ImageUpload>
        {props.error &&(
           <ValidationLabel >
           {props.error}
         </ValidationLabel>
        )}
      </ImageContainer>
    </>
  );
}

export const ImageContainer = styled.div`
  /* margin-top: 2.375em; */
  margin-bottom: 2.375em;
  width: 100%;
`;

export const ImageUpload = styled.div`
  margin-top: 2.375em;
  .id{
    margin-bottom: 1em;
  }
  .delete {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: .9rem;
    margin-top: .6em;
  }
`;

export const ImageHeader = styled.h3`
  margin-bottom: 1.2em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 19px;

  color: #000000;
`;

export const ImageBorder = styled.div`
  background: #ffffff;
  border: 1.5px dashed #aaaaaa;
  border-radius: 32px;
  width: 100%;
  height: 111px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Items = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  .img {
    height: 100px;
    width: 100%;
    border-radius: 32px;
  }
`;

export const Input = styled.input`
  display: none;
`;

export const Text = styled.div``;

export const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #353535;
`;

export const ValidationLabel = styled.label`
  margin-left: .6em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: rgba(206, 43, 43, 1);
  font-size: .9rem;
  margin-top: .5em;
  `