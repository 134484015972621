import { styled } from "styled-components";
import { FileUpload } from "../../../../../components/imageUploads/fileUpload";
import { SaveButtonComponent } from "../../../../../utils/buttons/saveButton";
// import { ChartIconComponent } from "../../../../../utils/ChartBtn";
import { mediaQuery } from "../../../../../breakPoint";
import {
  useGetSimpleBuildingSpecification,
  useSimplePlanUploadBuildingDocumentStates,
} from "../../../../../hooks/buildSpecificationState/simple/useBuildingDocument";
import { FormEvent } from "react";
import apiFetchClient from "../../../../../apiProvider/axiosInstance";
import { useNotificationToast } from "../../../../../hooks/useToast";
import AlertDialogSlide from "../../../../../utils/dialog";
import { costEquityLink } from "../../../../../links";
import { useNavigate } from "react-router-dom";
import { GetSimplePlanDetails } from "./simplePlanDetails";
import SimpleBackdrop from "../../../../../utils/backdrop";

export function UploadBuildingDocument() {
  // CHECK FOR STATUS
  const { status, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen } =useGetSimpleBuildingSpecification();

  const hasStatus = status === "PENDING" || status === "APPROVED" || status === "REJECTED";
  const hasNoStatus = status === "NONE" || !status;

  const { errorToast } = useNotificationToast();

  const navigate = useNavigate();

  const {
    structuralDrawing,
    setStructuralDrawing,
    mechanicalDrawing,
    setMechanicalDrawing,
    architecturalDrawing,
    setArchitecturalDrawing,
    buildingPermit,
    setBuildingPermit,
    electricalDrawing,
    setElectricalDrawing,
    surveyPlan,
    setSurveyPlan,

    // loading
    uploadBuildingDocumentLoadingState,
    setUploadBuildingDocumentLoadingState,

    // data
    uploadBuildingDocumentData,
    setUploadBuildingDocumentData,

    openDialog,
    setOpenDialog,
  } = useSimplePlanUploadBuildingDocumentStates();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      setUploadBuildingDocumentLoadingState(true);
      const formData = new FormData();

      structuralDrawing.forEach((file) => formData.append("structural_drawing", file));
      mechanicalDrawing.forEach((file) => formData.append("mechanical_drawing", file));
      architecturalDrawing.forEach((file) => formData.append("architectural_drawing", file));
      electricalDrawing.forEach((file) => formData.append("electrical_drawing", file));
      buildingPermit.forEach((file) => formData.append("building_permit", file));
      surveyPlan.forEach((file) => formData.append("survey_plan", file));

      const response = await apiFetchClient.post("/construction/build-now/building-specification", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadBuildingDocumentLoadingState(false);
      setUploadBuildingDocumentData(response?.data?.message);
      setOpenDialog(true);
    } catch (error: any) {
      setUploadBuildingDocumentLoadingState(false);

      const errorMessage = error?.response?.data?.message;

      const err = Array.isArray(errorMessage);

      err ? errorToast(errorMessage[0]) : errorToast(errorMessage || "An error occurred");
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
    navigate(costEquityLink as string, { replace: true });
  };

  return (
    <>
      {/* success message */}
      <AlertDialogSlide
        next="Next"
        handleClose={closeDialog}
        open={openDialog}
        details={uploadBuildingDocumentData || "Successful!"}
      />
      {/* loading */}
      {loadingBackDrop ? (
        <SimpleBackdrop
          setOpen={setLoadingBackDrop}
          loadingBackDrop={loadingBackDrop}
          handleClose={handleBackDropClose}
          handleOpen={handleBackDropOpen}
        />) :
        hasNoStatus ? (
          <FinanceContainer>
            <Finance onSubmit={handleSubmit}>
              <FinanceContent>
                <FinanceHeader>
                  <H1>UPLOAD BUILDING DOCUMENTS</H1>
                  <P>
                  Upload your preferred building plan documents. Your documents would be reviewed and costing would be generated for your transaction in 5 working days
                  </P>
                </FinanceHeader>
                <ImageUpLoadDiv>
                  <FileUpload name="Structural Drawing" label="Structural Drawing" src={setStructuralDrawing} />
                  <FileUpload label="Mechanical Drawing" name="Upload your Mechanical Drawing" src={setMechanicalDrawing} />
                  <FileUpload
                    label="Architectural Drawing"
                    name="Upload your Architectural Drawing"
                    src={setArchitecturalDrawing}
                  />
                  <FileUpload label="Building Permit or Approval" name="Upload your building permit" src={setBuildingPermit} />
                  <FileUpload label="Electrical Drawing" name="Upload your Electrical Drawing" src={setElectricalDrawing} />
                  <FileUpload label="Survey Plan" name="Upload your Survey Plan" src={setSurveyPlan} />
                </ImageUpLoadDiv>
              </FinanceContent>
              {/* <Link to="/initialize-construction/cost-equity" > */}
              <SaveButtonComponent
                loading={uploadBuildingDocumentLoadingState}
                type="submit"
                className="btn"
                name="Save and continue"
              />
              {/* </Link> */}
            </Finance>
            {/* <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv> */}
          </FinanceContainer>
        ) : (
          hasStatus && (
            <FinanceContainer>
              <GetSimplePlanDetails />
            </FinanceContainer>
          )
        )}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 4em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  margin-left: 2em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    margin: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.form`
  .btn {
    ${mediaQuery.tablet} {
      width: 100%;
      padding: 1em !important;
    }
  }
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
`;

export const FinanceContent = styled.div``;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 1em;
  ${mediaQuery.tablet} {
    margin-bottom: 0.8em;
  }
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
    font-size: 1.1275rem;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;

// const SelectInputDiv = styled.div``;
// const ChartConponentDiv = styled.div`
//   position: fixed;
//   right: 6em;
//   ${mediaQuery.tablet} {
//     right: 2em;
//     bottom: 1.408125em;
//   }
//   ${mediaQuery.mobile} {
//     right: 1em;
//     bottom: 1em;
//   }
// `;

const ImageUpLoadDiv = styled.div``;
