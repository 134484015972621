import { LoadingButton } from "@mui/lab";
// import { Button } from "@mui/material";
import { FormEvent } from "react";
import { styled } from "styled-components";

type AuthBtnProps = {
  name: string;
  onClick?:(event: FormEvent) => Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  className? : string;
};

type NavButtonProps = {
  name: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  className? : string;
};
export function AuthBtn ({ name,loading, disabled,onClick,className }:AuthBtnProps) {
  return <Buttons className={className} disabled = {disabled} onClick={onClick} loading = {loading} type="submit" variant="contained">{name}</Buttons>;
};

export function NavButton ({ name,loading, disabled,onClick,className }:NavButtonProps) {
  return <Buttons className={className} disabled = {disabled} onClick={onClick} loading = {loading} variant="contained">{name}</Buttons>;
};

export const Buttons = styled(LoadingButton)`
  && {
    margin-top: 1.3125em;
    width: 100%;
    padding: 0.875em 1.125em;
    border-radius: 8px;
    background-color: #6F5DE7;
    &:hover{
      background-color: #6F5DE7;
    }
  }
`;
