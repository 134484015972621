import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { MuiOtpInput, MuiOtpInputProps } from "mui-one-time-password-input";
import { styled } from "styled-components";
import { ConbuildLogo, MailBox } from "../../../utils/svg";
import { AuthBtn } from "../../../utils/buttons/authBtn";
import bg from "../../../assets/otpbg.png";
import { useNavigate } from "react-router-dom";
import { forgotPassword, login, password } from "../../../links";
import { OTP, RESEND_OTP } from "../../../api/auth";
import { useApolloClient, useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useNotificationToast } from "../../../hooks/useToast";
// import bgR from "../../../assets/bgright.png";

export function OtpInputComponent() {
  const { errorToast, successToast } = useNotificationToast();

  const refUrl = useSelector((state: RootState) => state?.otpRefReducer.value);

  const navigate = useNavigate();

  const [value, setValue] = useState<string>("");

  const [VerifyOTP, { loading }] = useMutation(OTP);


  const handleChange = (event: ChangeEvent<HTMLInputElement> | string) => {
    const newValue = typeof event === 'string' ? event : event.target.value; setValue(newValue);

  };

  



  const handleComplete = async (finalValue: string) => {
    try {
      const token = localStorage.getItem("conbuild_otp_token");
      
      const { data } = await VerifyOTP({ variables: { payload: { token: finalValue !== "" ? finalValue : value, }, },context: {headers: {Authorization: `Bearer ${token}`,} }});

      if (data?.verifyOTP === true) {
        if (refUrl === "/create-password") navigate(password);
        if (refUrl === "/forgot-password") navigate(forgotPassword);
        if (refUrl === "") navigate(login);
      }

      successToast( data?.message || "Successful!")

    }

    catch (error) {
      const err: any = error
      // console.error("Error creating account:", error);
      errorToast(err?.message || "An error occurred")
    
    }

  };


  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    try {

      const { data } = await VerifyOTP({
        variables: {
          payload: {
            token: value,
          },
        },
      });
      if (data?.verifyOTP === true) {
        navigate(password)
      }
       successToast( data?.message || "Successful!")

    }
     catch (error) {
      const err: any = error
      // console.error("Error creating account:", error);
      errorToast(err?.message)
      // console.error("Error creating account:", error);
    }
  }



  const [remainingTime, setRemainingTime] = useState<number>(60);
  const [resendDisabled, setResendDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (remainingTime > 0) {
      const timerId = setTimeout(() => { setRemainingTime((prevRemainingTime) => prevRemainingTime - 1); }, 1000);

      return () => clearTimeout(timerId);
    }

    else {
      setResendDisabled(false)
    }

  }, [remainingTime, setResendDisabled]);

  useEffect(() => timer(remainingTime), [remainingTime]);  // Call the timer function on component mount



  const timer = (remaining: number) => {
    // let m: number | string = Math.floor(remaining / 60);
    // let s: number | string = remaining % 60;

    // m = m < 10 ? "0" + m : m;
    // s = s < 10 ? "0" + s : s;

    remaining -= 1;

    if (remaining >= 0) {
      setTimeout(function () {
        setRemainingTime(remaining);
      }, 1000);
    }

    else {
      setResendDisabled(false); // Enable the resend button after timeout
    }

  };



  const  apolloClient = useApolloClient()// resend otp query 
  const [resendOtpLoading , setResendOtpLoading] = useState<boolean>(false)


  // resend otp 
  const handleResendOTP = async () => {
    const token = localStorage.getItem("conbuild_otp_token");

    // Perform the logic to resend OTP
    try{
      setResendOtpLoading(true)
      const {data} = await apolloClient.query({query: RESEND_OTP,context: {
        headers: { Authorization: `Bearer ${token}` },
      },})

      if (data?.resendOTP === true) {
        successToast("OTP sent successfully");
      }

      setResendOtpLoading(false)
    }
    catch(error){
      setResendOtpLoading(false)

      const err = error as  {message: string};
      errorToast(err?.message || "An error occurred");
    }

    setRemainingTime(120); // Reset the timer to 120 seconds
    setResendDisabled(true); // Disable the resend button

  };


  return (
    <>
      {/* {error && (<Alert severity="error"><AlertTitle>Error</AlertTitle>{error?.message}</Alert>)} */}
      <CREATEACCOUNT>
        <CreateAccountMain>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <CreateAccountInputForm>
            <CreateAccountContent>
              <ConbuildLogo />
              <MailBoxIcon>
                <MailBox />
              </MailBoxIcon>
              <HeaderText>
                <Register>Verify Email</Register>
                <P>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </P>
              </HeaderText>
              <InputContainers>
                <MuiOtpInputStyled
                  value={value}
                  onChange={handleChange}
                  onComplete={handleComplete}
                  length={6}
                  validateChar={(character: string, index: number) => true}
                />
              </InputContainers>
              <p className="remaining">Remaining Time: {remainingTime}</p>
              <BUTTON onClick={handleResendOTP} disabled={resendDisabled} variant="text" size="small" >Resend Otp</BUTTON>

              <AuthBtn onClick={handleSubmit} loading={loading || resendOtpLoading} name="Verify Email" />
            </CreateAccountContent>
          </CreateAccountInputForm>
          <BackgroundImage src={bg} />
          {/* <BackgroundImageRight src={bgR} /> */}
        </CreateAccountMain>
      </CREATEACCOUNT>
    </>
  );
};

export const CREATEACCOUNT = styled.div`
  @media (min-width: 1020px) {
    background: #c6c6c6;
    height: 100%;
    min-height: 100vh;
    position: relative;
  }
`;

export const CreateAccountMain = styled.div`
  padding: 1em;
`;

export const BackgroundCircle = styled.div``;

export const HeaderText = styled.div`
  /* width: 96px; */
  max-width: 80%;
  margin-top: 1.836em;
`;

export const MailBoxIcon = styled.div`
  margin-top: 1.8em;
`;
export const Register = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #00317a;
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  color: #797474;
  margin-top: 1em;
`;

export const CreateAccountInputForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2em;
  /* width: 90%; */
`;

export const CreateAccountContent = styled.div`
  width: 100%;
  padding: 1.5em;
  @media (min-width: 600px) {
    width: 530px;
    margin-top: 8.5em;
  }
  @media (min-width: 900px) {
    width: 500px;
    margin-top: 6em;
  }
  @media (min-width: 1020px) {
    width: 500px;
    margin-top: 3em;
    background: #fff;
    border-radius: 3px;
    padding: 2em;
    z-index: 1;
  }

  .remaining{
    margin-top: 2.18em;
    font-size: .92rem;
  }
`;

export const InputContainers = styled.div`
  margin: auto;
`;

export const BackgroundImage = styled.img`
  display: none;
  @media (min-width: 1020px) {
    width: 90%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const BackgroundImageRight = styled.img`
  display: none;
  @media (min-width: 1020px) {
    width: 45%;
    display: block;
    position: absolute;
    right: 0;
    bottom: 4em;
  }
`;


const MuiOtpInputStyled = styled(MuiOtpInput).attrs((props: MuiOtpInputProps) => ({
  ...props
}))`
  max-width: 650px;
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 2.1em;
`;

const BUTTON = styled(LoadingButton)`
  margin-top: 1em;
`;

// ROUTER LINK
// const LINK = styled(Link)`
//   text-decoration: none;
// `;



