import { styled } from "styled-components";
import { DropDownIcon } from "../../utils/svg";
import { mediaQuery } from "../../breakPoint";
type NavigationComponentProps = {
  name: string;
  status?: string
};
export function NavigationComponent(props: NavigationComponentProps) {
  return (
    <>
      <NavigationBar>
        <NameText>{props.name}</NameText>
        <div className="status">
          <span>{props.status}</span>
        </div>
        <DropDownIcon className="drop_down" />
      </NavigationBar>
    </>
  );
}

const NavigationBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  border-bottom: 0.5px solid #919191;
  padding: 0.8em;
  margin-bottom: 2em;
 ${mediaQuery.tablet} {
    width: 100%;
  }
  @media (max-width: 450px) {
    width: 100%;

  }
  .drop_down {
    transform: rotate(-90deg);
  }

  .status{
    /* padding-left: 4em; */
    span{
      color: #33b95d;
    }
  }
`;

const NameText = styled.p`
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #838383;
`;
