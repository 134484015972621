import { styled } from "styled-components";
import { DropDownIcon } from "../../../../../utils/svg";
import { useMemo } from "react";
import { CustomAlert } from "../../../../../utils/customPlanAlert";
import { ChartIconComponent } from "../../../../../utils/ChartBtn";
import { mediaQuery } from "../../../../../breakPoint";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { useNavigate } from "react-router-dom";
import { useUserKyc } from "../../../../../hooks/useUserKyc";
import { useDateMoment } from "../../../../../hooks/useMomentDate";
import { useNotificationToast } from "../../../../../hooks/useToast";
import SimpleBackdrop from "../../../../../utils/backdrop";
interface OtpPhoneProps {
  status?: string;
}
export function PhoneVerificationStatus(props: OtpPhoneProps) {

  const navigate = useNavigate();

  const { errorToast } = useNotificationToast()

  const {
    phone,
    error,
    phone_status,
    createdon,
    loadingBackDrop,
    setLoadingBackDrop,
    handleBackDropClose,
    handleBackDropOpen
  } = useUserKyc();

  const { convertDate, formattedDate } = useDateMoment()

  const isPhoneNUmberOtpPost = useSelector((state: RootState) => state?.phoneOtpSubmitSuccess)

  const status = isPhoneNUmberOtpPost?.phone_status || phone_status;

  const date = createdon || isPhoneNUmberOtpPost?.createdon;   // convert date 

  useMemo(() => convertDate({ dateString: date }), [convertDate, date,])

  error && errorToast(error?.message ?? "An Error Occured");

  // phone number from response posted by user 
  const userPostReponsePhoneNumber = isPhoneNUmberOtpPost?.phone

  return (
    <>
      {/* loading */}
      <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />
      <ContactInfoDiv>
        <Content>
          <DropDownIcon
            className="drop_down"
            onClick={() => navigate("/kyc-verifcation")}
          />
          <AlertContainer>
            <CustomAlert
              className={
                status === "PENDING" ?
                  "pending_status" :
                  status === "REJECTED" ?
                    "rejected_status" :
                    status === "APPROVED" ?
                      "verified" : "pending_status"
              }
              status={status}
              date={formattedDate ?? "loading Date"}
            />
          </AlertContainer>
          <Header>
            <H2>Phone verification</H2>
            {/* <EditIcon
              className="edit"
              onClick={() => setDisabled(!disabledd)}
            /> */}
          </Header>
          <Paragragh>
          Your Phone number has been verified, you would be contacted on this number when we need to reach
          </Paragragh>
          <DetailsContainer>
            <DETAILS>
              <H3>PHONE</H3>
              <p className="value" >
                {userPostReponsePhoneNumber || phone}
              </p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
        </Content>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  margin-top: 2em;
  margin-left: 22em;
  width: 100%;
  padding: 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    margin: 4.5em auto 2em;
  }
  ${mediaQuery.mobile}{
    margin: 1em auto
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;

const Content = styled.div`
  margin: auto;
  width: 80%;
  padding: 0em 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    width: 90%;
  }
  .drop_down {
    transform: rotate(90deg);
    cursor: pointer;
  }
`;

const Header = styled.div`
  margin-top: 2.5em;
  /* width: 432px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8125em;
  .edit {
    cursor: pointer;
  }
`;
const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;

  color: #00317a;
`;
const Paragragh = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  width: 432px;
  color: #797474;
 ${mediaQuery.mobile}{
    width: 100%;
    font-size: .9rem;
  }
`;

const DetailsContainer = styled.div`

`;

const AlertContainer = styled.div`
  margin-top: 3.375em;
  .verified {
    padding: 0.875em 1.1875em;
    background: rgba(51, 185, 93, 0.1);
    border: 1px solid #33b95d;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #33b95d;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      color: #33b95d;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;

      color: #33b95d;
    }
  }

  .pending_status {
    padding: 0.875em 1.1875em;
    background: rgba(223, 174, 0, 0.1);
    border: 1px solid #dfae00;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #dfae00;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: #dfae00;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #dfae00;
    }
  }
  .rejected_status {
    padding: 0.875em 1.1875em;
    background: rgba(206, 43, 43, 0.13);
    border: 1px solid #ce2b2b;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #ce2b2b;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: #ce2b2b;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #ce2b2b;
    }
  }
`;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* gap: 1em; */
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
    text-align: right;
    
  }
  .value{
    color: #797474;
    font-size: 16px;
    font-family: Inter;
    font-weight: 300;
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;


const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
 ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;