import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


  export interface Row {
    id: number;
    option: string | number;
    amount: number | string;
    status: number | string;
    date: number | string;
    remaining: number | string;
  }
  
  interface TableProps {
    rows: Row[]
  }


  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#00317A",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  export default function HistoryTable(props:TableProps) {
   
  
    return (
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Ref</StyledTableCell>
            <StyledTableCell align="right">Amount</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
            <StyledTableCell align="right">Date</StyledTableCell>
            <StyledTableCell align="right">Amount Remaining</StyledTableCell>
          </TableRow>
        </TableHead>
          <TableBodyStyled>
            {props.rows.map((row) => (
              <TableRowStyled key={row.id}>
                <TableCell  >{row?.option}</TableCell>
                <TableCell>{(row?.amount).toLocaleString()}.00</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.remaining?.toLocaleString()}</TableCell>
              </TableRowStyled>
            ))}
          </TableBodyStyled>
        </Table>
      </TableContainer>
    );
  }
  
  
  // const TableHeadStyle = styled(TableHead)`
  // background: #eaeaea;
    
  //   .table_head_cell {
  //     font-family: "Inter";
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 1rem;
  //     width: 10%;
  //     line-height: 19px;
  //     color: #000000;
  //   }
  // `;
  
  const TableBodyStyled = styled(TableBody)`
  
  `
  
  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
    background-color: #eaeaea;
  }
  color: #d63333;
  
  `
  