import { gql } from "@apollo/client";

export const USER_KYC = gql`
  query UserKyc {
    userKyc {
      phone_status
      document_status
      residential_status
      phone
      address
      document_image {
        front
        back
      }
      document_type
      country {
        code
        name
      }
      createdon
    } 
  }
`;
