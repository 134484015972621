import { ChartIcon } from "../svg";
import { styled } from "styled-components";

export function ChartIconComponent() {
  return (
    <>
      <CONTAINER>
        <ChartContentDiv>
          <ChartIcon />
        </ChartContentDiv>
      </CONTAINER>
    </>
  );
};

const CONTAINER = styled.div`
  margin-top: 1.1em;
  width: 100%;
  
`;

const ChartContentDiv = styled.div`
  cursor: pointer;
  background: #33b95d;
  border-radius: 30000px;
  padding: 1.2em;
  box-shadow: 0px 15px 20px rgba(51, 185, 93, 0.5);
  /* position: fixed; */
  z-index: 1;
  
  /* right: 6em; */
`;
