// imageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StatusState {
    value: boolean;
    phone: string;
}

const initialState: StatusState = {
    value: false,
    phone: "",
};

const phoneSubmitSuccess = createSlice({
    name: 'status',
    initialState,
    reducers: {
        setPhoneSubmit: (state, action: PayloadAction<boolean >) => {
        state.value = action.payload;
        },
        setPhoneValue: (state, action: PayloadAction<string>) => {
        state.phone = action.payload;
        },
    },
});

export const { setPhoneSubmit,setPhoneValue } = phoneSubmitSuccess.actions;

export default phoneSubmitSuccess.reducer;


