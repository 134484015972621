import error from "../../assets/network.gif";
import { styled } from "styled-components";

export function NetworkError() {

    // const handleRefresh = () => {
    //     if (navigator.onLine) {
    //       window.location.reload();
    //     } else {
    //       alert("Network connection is still offline. Please check your internet connection.");
    //     }
    //   };
    
    

  return (
    <>
      <div className="not_found_page">
        <Container className="page_not_found_content">
          <img src={error} alt="error 404" />
          <div className="btn_h2">
            <h2 className="not_found_p">Something went wrong !</h2>
            <p>we're having issues loading this page</p>
            {/* <button onClick={handleRefresh}>Refresh</button> */}
            <span> Please check your internet connection.</span>
          </div>
        </Container>
      </div>
    </>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6em;
  position: relative;
  img {
    padding: 0;
  }
  .btn_h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: .7em;
    p{
        margin-top: .5em;
    }
    span{
        margin-top: 1em;
        color: #0084fe;
    }
  }
  button {
    border: none;
    outline: none;
    border-radius: 6px;
    color: #fff;
    background: #0084fe;
    font-family: "inter";
    padding: 1em;
    cursor: pointer;
    margin-top: 2em;
  }
`;
