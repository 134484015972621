import { Avatar } from "@mui/material";
import { styled } from "styled-components";
// import avatar from "../../assets/avater.png";
import { CallIcon, MessageTypeIcon } from "../../utils/svg";
import { ContactProps } from "../../types";
import { mediaQuery } from "../../breakPoint";

export function ContactPerson(props: ContactProps) {
  return (
    <>
      <Main>
        <Head>{props.header}</Head>
        <AvatarDetails>
          <AvatarText>
            <Avatarr  />
            <Text>
              <Name>{props.name}</Name>
              <Email>{props.email}</Email>
            </Text>
          </AvatarText>
          <ICONS>
            <IconContainer>
              <CallIcon />
            </IconContainer>
            <IconContainer>
              <MessageTypeIcon />
            </IconContainer>
          </ICONS>
        </AvatarDetails>
      </Main>
    </>
  );
}

const Main = styled.div`
  ${mediaQuery.mobile} {
    margin-right: 4.6em;
  }
`;

const Head = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1275em;
  line-height: 22px;
  color: #00317a;
  ${mediaQuery.tablet} {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 18px;

    color: #00317a;
  }
`;
const AvatarDetails = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.8em;
  flex-wrap: wrap;
  ${mediaQuery.mobile} {
    display: block;
  }
`;
const Avatarr = styled(Avatar)``;

const AvatarText = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5em;
  margin-bottom: 2em;
  width: 100%;
`;
const Text = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #00317a;

  ${mediaQuery.mobile} {
    width: 100%;
    font-size: 0.9rem;
  }
`;
const Name = styled.p``;
const Email = styled.p``;
const ICONS = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const IconContainer = styled.div`
  padding: 0.8em;
  display: flex;
  justify-content: center;
  background: rgba(0, 132, 254, 0.1);
  border-radius: 8px;
`;
