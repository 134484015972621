import { useQuery } from "@apollo/client";
import { USER_KYC } from "../api/query/kyc/residentialVerification";
import { useNotificationToast } from "./useToast";
import { useMemo, useState } from "react";

type UserKyc = {
    phone_status: string;
    phone: string
    residential_status: string;
    address: string
    country: {
        code: string;
        name: string;
    }
    createdon: string;
    document_status: string;
    document_image: {
        front: string;
        back: string;
    }
    document_type: string;
}
export function useUserKyc() {
    const { data, loading, error, refetch } = useQuery(USER_KYC);

    const { successToast } = useNotificationToast();

    const userKycData: UserKyc = data?.userKyc;

    // backdrop loadinng funnction 
    const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

    const handleBackDropClose = () => setLoadingBackDrop(false);

    const handleBackDropOpen = () => setLoadingBackDrop(true);

    useMemo(() => {

        (loading) && handleBackDropOpen();

        (!loading) && handleBackDropClose();

    }, [loading]);

    return { ...userKycData, loading, successToast, error, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen, refetch }
}