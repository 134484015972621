import { styled } from "styled-components";
import { SaveButtonComponent } from "../../../../../utils/buttons/saveButton";
// import { ChartIconComponent } from "../../../../../../utils/ChartBtn";
import {
  GoodMarkIcon,
  InstallmentIcon,
  MortgageIconSvg,
  OutRightPaymentIcon,
} from "../../../../../utils/svg";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
//   exclusivePlanLink,
    // installment,
//   ownership,
//   premuimPlanLink,
//   simplePlanLink,
} from "../../../../../links";
import { mediaQuery } from "../../../../../breakPoint";
import { useMutation } from "@apollo/client";
import { MUTATE_PAYMENT_PLAN } from "../../../../../api/mutatation/phase2Plan/payment";
import { useNotificationToast } from "../../../../../hooks/useToast";
import { useGetOutRightInstallmentPayment } from "../../../../../hooks/payment/usePayment";


enum ServiceType {
  outrightPayment = "OUTRIGHT",
  installmentPayment = "INSTALLMENTAL",
  mortgage = "MORTGAGE",
}


export function PaymentPlan() {

  const { plan} = useGetOutRightInstallmentPayment() // payment api 
  
  // plan selected conditions 
  const outrightpayment = plan === "OUTRIGHT"

  const Installmental = plan === "INSTALLMENTAL"

  const Mortgage = plan === "MORTGAGE"

  // conditions to disale the radio 
  const enableOnlyOutrightPayment = !Installmental && !Mortgage && outrightpayment

  const enableOnlyInstallmental = Installmental && !Mortgage && !outrightpayment

  const enableOnlyMortgage = !Installmental && Mortgage && !outrightpayment

  const [payment, {loading}] = useMutation(MUTATE_PAYMENT_PLAN)

  const {errorToast, successToast} = useNotificationToast()

  const navigate = useNavigate();

  const [selectedService, setSelectedService] = useState<ServiceType | null>( null); // selected state

  const handleServiceSelect = (event: ChangeEvent<HTMLInputElement>) =>  setSelectedService(event.target.value as ServiceType);;


  // check value slected and route accordingly when proceed button is clicked
  async function proceedHandlick(event: MouseEvent<HTMLElement>) {
    try{
      if(selectedService === "OUTRIGHT"){
        const {data} = await payment({
          variables:{
            "payload": {
              "plan": selectedService|| plan
            }
          }
        })
        successToast( data?.message || "Successful!")
      }

      switch (selectedService || plan) {
        case "OUTRIGHT" || outrightpayment:
          return navigate("/initialize-construction/payment");
        case "INSTALLMENTAL" || Installmental:
          return navigate("/initialize-construction/pay-installment");
        case "MORTGAGE" || Mortgage:
          return navigate("/initialize-construction/mortgage-payment");
        default: 
          return null;
      }
  
    }catch(error) {
    const err: any = error
    // console.error("Error creating account:", error);
    errorToast(err?.message)
    }
  }

  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>PAYMENT PLAN</H1>
            </FinanceHeader>
            <SelfEmplyedMain>
              <SimplePlan style={{display: enableOnlyInstallmental ||enableOnlyMortgage? "none": "auto"}}  isselected={selectedService === ServiceType.outrightPayment || outrightpayment}>
                <Radio
                  type="radio"
                  value={ServiceType.outrightPayment}
                  checked={selectedService === ServiceType.outrightPayment}
                  onChange={handleServiceSelect}
                />
                <SimplePlanContent>
                  <OutRightPaymentIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.outrightPayment}>
                      Outright Payment
                    </H2>
                    <SimplePlanDetails
                      isselected={selectedService === ServiceType.outrightPayment}
                    >
                      Pay the complete construction costs and upfront and
                      monitor the construction from your dashboard
                    </SimplePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </SimplePlanContent>
              </SimplePlan>
              <SimplePlan style={{display: enableOnlyOutrightPayment ||enableOnlyMortgage? "none": "auto"}} isselected={selectedService === ServiceType.installmentPayment || Installmental}>
                <Radio
                  type="radio"
                  value={ServiceType.installmentPayment}
                  checked={selectedService === ServiceType.installmentPayment}
                  onChange={handleServiceSelect}
                />
                <SimplePlanContent>
                  <InstallmentIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.installmentPayment}>
                      Installmental Payment
                    </H2>
                    <SimplePlanDetails
                      isselected={selectedService === ServiceType.installmentPayment}
                    >
                      Pay 30% of the construction cost and pay the balance
                      monthly by authorising a direct debit from your Nigerian
                      bank account
                    </SimplePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </SimplePlanContent>
              </SimplePlan>
              <SimplePlan style={{display: enableOnlyOutrightPayment ||enableOnlyInstallmental? "none": "auto"}}
                isselected={selectedService === ServiceType.mortgage || Mortgage}
              >
                <Radio
                  type="radio"
                  value={ServiceType.mortgage}
                  checked={selectedService === ServiceType.mortgage}
                  onChange={handleServiceSelect}
                />
                <SimplePlanContent>
                  <MortgageIconSvg />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.mortgage}>
                      Mortgage Payment
                    </H2>
                    <SimplePlanDetails
                      isselected={selectedService === ServiceType.mortgage}
                    >
                      Pay 30% of the construction cost upfront and get a
                      mortgage to pay the rest from a reputable financial
                      institution in Nigeria
                    </SimplePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </SimplePlanContent>
              </SimplePlan>
            </SelfEmplyedMain>
          </FinanceContent>
          <BtnDiv>
            <SaveButtonComponent
            loading= {loading}
              className="btn"
              name={loading?"loading...": "Save and continue"}
              onClick={proceedHandlick}
            />
          </BtnDiv>
        </Finance>
        {/* <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv> */}
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    margin: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`;

export const FinanceContent = styled.div``;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  margin-top: 1em;
`;
const SelfEmplyedMain = styled.div`
  margin-top: 2.375em;
`;

const SimplePlan = styled.label<{ isselected: boolean }>`
  /* height: 160px; */
  border: 1px solid #0084fe;
  border-radius: 4px;
  /* width: 70%; */
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  
  ${mediaQuery.tablet} {
    width: 100%;
  }
  ${mediaQuery.mobile} {
    padding: 1em;
  }

  cursor: pointer;
  background: ${(props) => (props.isselected ? "rgba(0, 49, 122, 1)" : "")};
  svg {
    fill: ${(props) => (props.isselected ? "#fff" : "#00317a")};
    ${mediaQuery.tablet} {
      width: 29.27px;
      min-width: 10% !important;
    }
  }

  &:hover {
    background: rgba(0, 49, 122, 1);
    p,
    h3 {
      color: #fff !important;
    }
    transition: none;
    svg {
      fill: #fff;
      transition: none;
    }
  }
`;
const Radio = styled.input.attrs({
  type: "radio",
})`
  visibility: hidden;
`;
const SimplePlanContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  ${mediaQuery.mobile} {
    gap: 1em;
  }
  .goodmark {
    height: 56.686790466308594px;
    width: 42px;
    border-radius: 0px;
    ${mediaQuery.mobile} {
      width: 22.77px;
    }
  }
`;
const Icon = styled.div`
  svg {
    fill: #fff;
  }
`;

const Text = styled.div`
  width: 60%;
  ${mediaQuery.tablet} {
    width: auto;
  }
`;
const H2 = styled.h3<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  margin-bottom: 0.5em;
  ${mediaQuery.mobile} {
    font-size: 12.8077px;
    line-height: 16px;
  }
  color: ${(props) => (props.isselected ? "#fff" : "#0084fe")};
`;
const SimplePlanDetails = styled.p<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 15px;
  color: ${(props) => (props.isselected ? "#fff" : "#797474")};
  ${mediaQuery.mobile} {
    font-size: 0.75rem;
    line-height: 15px;
    width: 100%;
  }
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 70%; */
  ${mediaQuery.tablet} {
    width: 100% !important;
  }
  .btn {
    padding: 1em !important;
    ${mediaQuery.tablet} {
    }
  }
`;

// const ChartConponentDiv = styled.div`
//   position: fixed;
//   right: 6em;
//   ${mediaQuery.tablet} {
//     right: 2em;
//     bottom: 1.408125em;
//   }
//   ${mediaQuery.mobile} {
//     right: 1em;
//     bottom: 1em;
//   }
// `;
