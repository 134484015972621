import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface BackdropProps {
    // open: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    loadingBackDrop: boolean;
    handleClose: () =>void;
    handleOpen : () => void
}
export default function SimpleBackdrop(props: BackdropProps) {
  
  

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.loadingBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}