import { styled } from "styled-components";
import { ChartIconComponent } from "../../../utils/ChartBtn";

import { NavigationComponent } from "../../../components/kycNavigation";
import { Link } from "react-router-dom";
import {
  addPhoneLink,
  // bvnLink,
  residenceVerificationLink,
  uploadIDlINK,
} from "../../../links";
import { mediaQuery } from "../../../breakPoint";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export function Kyc() {
  //   const [disabledd, setDisabled] = useState<boolean>(true);

  useEffect(() => {
    AOS.init();
  }, []);

  
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>KYC</H1>
            </FinanceHeader>
            <SelfEmplyedMain>
              <VerifyAccount>
                <H3
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-easing="ease-in-out"
                >
                  Verify your account
                </H3>
                <Text
                  data-aos="fade-up"
                  data-aos-duration="900"
                  data-aos-easing="ease-in-out"
                >
                Please provide the following information before you can proceed with Build now pay later
                </Text>
              </VerifyAccount>
            </SelfEmplyedMain>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-easing="ease-in"
            >
              <Links to={residenceVerificationLink}>
                <NavigationComponent name="RESIDENTIAL VERIFICATION" />
              </Links>
            </div>
            {/* <div
              data-aos="fade-up"
              data-aos-duration="1800"
              data-aos-easing="ease-in"
            >
              <Links to={bvnLink}>
                <NavigationComponent name="BVN VERIFICATION" />
              </Links>
            </div> */}

            <div
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-easing="ease-in"
            >
              <Links to={uploadIDlINK}>
                <NavigationComponent name="ID VERIFICATION" />
              </Links>
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration="2400"
              data-aos-easing="ease-in"
            >
              <Links to={addPhoneLink}>
                <NavigationComponent name="PHONE VERIFICATION" />
              </Links>
            </div>
          </FinanceContent>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  margin-left: 34em;
  [data-aos] {
    opacity: 0;
    transition-property: opacity;
  }

  [data-aos].aos-animate {
    opacity: 1;
  }
  ${mediaQuery.tablet} {
    margin: 2em auto 5em;
    justify-content: center;
  }
  ${mediaQuery.mobile} {
    width: 100%;
    margin: 1em;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    /* margin-top: 3em; */
    width: 90%;
  }
  ${mediaQuery.mobile} {
    width: 95%;
  }
`;

export const FinanceContent = styled.div`
  @media (max-width: 682px) {
    /* border: 2px solid red; */
    width: 100%;
  }
`;
export const FinanceHeader = styled.div`
  .avatar {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    border: 2px solid #0c5be9;
    width: fit-content;
    border-radius: 50%;
    padding: 0.2em;
  }
`;
export const H1 = styled.h1`
  margin-bottom: 2em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
`;
const SelfEmplyedMain = styled.div``;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;

const VerifyAccount = styled.div`
  margin-bottom: 2em;
`;

const H3 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1275rem;
  line-height: 22px;
  margin-bottom: 1em;
  color: #00317a;
`;

const Text = styled.p`
  width: 60%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  color: #797474;
  ${mediaQuery.tablet} {
    width: 90%;
  }
  ${mediaQuery.mobile} {
    width: 100%;
    margin-top: 1em;
  }
`;

const Links = styled(Link)`
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #838383;
  cursor: pointer;
`;
