// import { styled } from "styled-components";
import { ConbuildLogo } from "../../../utils/svg";
import bg from "../../../assets/Blueprint.png";
import bgR from "../../../assets/bgright.png";
import { InputField } from "../../../utils/input";
import { Backdrop, Checkbox, CircularProgress, FormControlLabel, Stack } from "@mui/material";
// import { PhoneInputComponent } from "../../utils/input/phoneInput";
import { AuthBtn } from "../../../utils/buttons/authBtn";
import { Link, useNavigate } from "react-router-dom";
import { otp } from "../../../links";
import { useMutation } from "@apollo/client";
import { CREATE_ACCOUNT_MUTATION } from "../../../api/auth";
import { ChangeEvent, FormEvent, useState } from "react";
import { GOOGLE_AUTH } from "../../../api/mutatation/googleAuth";
import { useDispatch, useSelector } from "react-redux";
import { setotpRef } from "../../../slice/otpRef";

import { RootState } from "../../../store";
import { setEmailError, setFirstNameError, setLastNameError } from "../../../slice/Validation";
import { useNotificationToast } from "../../../hooks/useToast";
import { Mutation, MutationCreateAccountArgs } from "../../../api/types";
import {
  BackgroundCircle,
  BackgroundImage,
  BackgroundImageRight,
  CREATEACCOUNT,
  CreateAccountContent,
  CreateAccountInputForm,
  CreateAccountMain,
  GoogleButton,
  HeaderText,
  InputContainers,
  LineContainer,
  Register,
  P,
  Line,
  Or,
} from "./createAccountStyles";
// import { MuiTelInputInfo } from "mui-tel-input";

const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export function CreateAccount() {
  const { errorToast, successToast } = useNotificationToast();

  const dispatch = useDispatch();

  const { emailError, firstNameError, lastNameError } = useSelector((state: RootState) => state.validationReducer);

  const navigate = useNavigate();

  const [createAccount, { loading }] = useMutation<Mutation, MutationCreateAccountArgs>(CREATE_ACCOUNT_MUTATION);

  const [googleLoading, setGoogleLoading] = useState<boolean>(false);

  // sign up with google
  const [googleAuth] = useMutation(GOOGLE_AUTH);

  async function GoogleSignUp(token: string) {
    try {
      setGoogleLoading(true);
      const { data } = await googleAuth({ variables: { payload: { token } } });

      if (data) {
        const token = data?.googleAuth?.access_token;
        localStorage.setItem("conbuild_token", token);
        window.location.replace("/");
      }

      setGoogleLoading(false);
    } catch (error) {
      setGoogleLoading(false);
      console.log(error);
    }
  }

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  // const [phone, setPhone] = useState<string>("");

  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();

    if (firstName === "") dispatch(setFirstNameError("First name is required *")); // firstname

    if (lastName === "") dispatch(setLastNameError("Last name is required *")); // lastname

    if (email === "") dispatch(setEmailError("Email is required *")); // email

    if (!emailRegex.test(email)) dispatch(setEmailError("Email is invalid *")); // email

    try {
      const { data } = (await createAccount({ variables: { payload: { lastname: lastName, firstname: firstName, email: email } } })) as {
        data: Mutation;
      };
      // const { data } = await createAccount({
      //   variables:{
      //     payload:{

      //     }
      //   }
      // });
      localStorage.setItem("conbuild_otp_token", data.createAccount.access_token);
      dispatch(setotpRef("/create-password"));
      navigate(otp);
      successToast("Successful!");
    } catch (error) {
      const err: any = error;
      // console.error("Error creating account:", error);
      errorToast(err?.message || "An error occurred");
      // console.error('Error creating account:', error);
    }
  }

  return (
    <>
      {/* {error && (<Alert severity="error"><AlertTitle>Error</AlertTitle>{error?.message}</Alert>)} */}
      {googleLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={googleLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CREATEACCOUNT>
        <CreateAccountMain>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <CreateAccountInputForm onSubmit={handleFormSubmit}>
            <CreateAccountContent>
              <ConbuildLogo />
              <HeaderText>
                <Register>Register</Register>
                <P>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</P>
              </HeaderText>
              <InputContainers>
                <InputField
                  error={firstNameError ? firstNameError : ""}
                  label="First Name"
                  placeholder="Enter here"
                  type="text"
                  value={firstName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    dispatch(setFirstNameError(""));
                    setFirstName(event.target.value);
                  }}
                  onBlur={() => {
                    // Perform validation logic when the input field loses focus
                    if (firstName === "") {
                      dispatch(setFirstNameError("First name is required. *"));
                    }
                  }}
                />
                <InputField
                  error={lastNameError ? lastNameError : ""}
                  label="Last Name"
                  placeholder="Enter here"
                  type="text"
                  value={lastName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    dispatch(setLastNameError(""));
                    setLastName(event.target.value);
                  }}
                  onBlur={() => {
                    // Perform validation logic when the input field loses focus
                    if (lastName === "") {
                      dispatch(setLastNameError("Last name is required. *"));
                    }
                  }}
                />
                <InputField
                  error={emailError ? emailError : ""}
                  label="Email"
                  placeholder="Enter here"
                  type="text"
                  value={email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    dispatch(setEmailError(""));
                    setEmail(event.target.value);
                  }}
                  onBlur={() => {
                    // Perform validation logic when the input field loses focus
                    if (email === "") {
                      dispatch(setEmailError("First name is required. *"));
                    }
                    if (email !== "" && !emailRegex.test(email)) {
                      dispatch(setEmailError("Email is invalid"));
                    }
                  }}
                />

                {/* <PhoneInputComponent value={phone} onChange={ (value: string, info: MuiTelInputInfo)=> setPhone(value)}  /> */}
                <FormControlLabel label="keep me Signed in" control={<Checkbox color="default" />} />
              </InputContainers>
              <P>
                Already have an Account?{" "}
                <Link className="link" to="/">
                  Login
                </Link>
              </P>
              <AuthBtn loading={googleLoading || loading} name="Register" />
              <LineContainer>
                <Line></Line>
                <Or>Or</Or>
                <Line></Line>
              </LineContainer>
              <Stack sx={{ width: "100%", marginTop: "1em" }} direction={["column", "column", "row"]} alignContent="center" spacing="2">
                <GoogleButton
                  width="100%"
                  size="large"
                  text="continue_with"
                  context="use"
                  auto_select={false}
                  ux_mode="popup"
                  containerProps={{
                    className: "google",
                  }}
                  onSuccess={async (credentialResponse) => {
                    await GoogleSignUp(credentialResponse?.credential as string);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </Stack>
            </CreateAccountContent>
          </CreateAccountInputForm>
          <BackgroundImage src={bg} />
          <BackgroundImageRight src={bgR} />
        </CreateAccountMain>
      </CREATEACCOUNT>
    </>
  );
}
