import { styled } from "styled-components";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { ExclusiveIcon, GoodMarkIcon, PremiumIcon, SimplePlanIcon } from "../../../../utils/svg";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { exclusivePlanDetails, exclusivePlanLink, initializeProject, premuimPlanLink, simplePlanLink } from "../../../../links";
import { mediaQuery } from "../../../../breakPoint";
import { useDispatch } from "react-redux";
import { setPlan } from "../../../../slice/selectedPlanSlice";
import { useMutation } from "@apollo/client";
import { SERVICE_PLAN } from "../../../../api/mutatation/phase2Plan/servicePlan";
import { useNotificationToast } from "../../../../hooks/useToast";

enum ServiceType {
  Simple = "SIMPLE",
  Premium = "PREMIUM",
  Exclusive = "EXCLUSIVE",
}
export function SelectServiceType() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [assignServicePlan, { loading }] = useMutation(SERVICE_PLAN);

  const { errorToast } = useNotificationToast();

  const [selectedService, setSelectedService] = useState<ServiceType | null>(null); // selected state

  const handleServiceSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedService(event.target.value as ServiceType);
  };

 

  // check value slected and route accordingly when proceed button is clicked
  async function proceedHandlick(event: MouseEvent<HTMLElement>) {
    try {
      await assignServicePlan({ variables: { payload: { service_type: selectedService } } });

      switch (selectedService) {
        case "SIMPLE":
          dispatch(setPlan(simplePlanLink));

          return navigate(`/${initializeProject}`);

        case "PREMIUM":
          dispatch(setPlan(premuimPlanLink));

          return navigate(`/${initializeProject}`);

        case "EXCLUSIVE":
          dispatch(setPlan(exclusivePlanLink));

          return navigate(`/${exclusivePlanDetails}`);

        default:
          return null;
      }
    } catch (err) {
      const error: any = err;

      errorToast(error?.message || "An error occurred");
    }
  }

  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>SELECT SERVICE PLAN</H1>
              <P>Select from one of our service plans to commence development on your land</P>
            </FinanceHeader>
            <SelfEmplyedMain>
              <SimplePlan isselected={selectedService === ServiceType.Simple}>
                <Radio type="radio" value={ServiceType.Simple} checked={selectedService === ServiceType.Simple} onChange={handleServiceSelect} />
                <SimplePlanContent>
                  <SimplePlanIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.Simple}>Simple plan</H2>
                    <SimplePlanDetails isselected={selectedService === ServiceType.Simple}>
                      Upload documentation for your building plan and permit, and after its verified you will be required to pay 30% of the
                      development cost upfront and the remaining 70% over 12 months
                    </SimplePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </SimplePlanContent>
              </SimplePlan>
              <SimplePlan isselected={selectedService === ServiceType.Premium}>
                <Radio type="radio" value={ServiceType.Premium} checked={selectedService === ServiceType.Premium} onChange={handleServiceSelect} />
                <SimplePlanContent>
                  <PremiumIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.Premium}>Premium plan</H2>
                    <SimplePlanDetails isselected={selectedService === ServiceType.Premium}>
                      Choose from our premuim building plans, and we would get a permit for it afterwards, you will be required to pay 30% of the
                      development cost upfront and the remaining 70% over 12 months
                    </SimplePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </SimplePlanContent>
              </SimplePlan>
              <SimplePlan className="exclusive" isselected={selectedService === ServiceType.Exclusive}>
                <Radio
                  type="radio"
                  value={ServiceType.Exclusive}
                  checked={selectedService === ServiceType.Exclusive}
                  onChange={handleServiceSelect}
                />
                <SimplePlanContent>
                  <ExclusiveIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.Exclusive}>Exclusive plan</H2>
                    <SimplePlanDetails isselected={selectedService === ServiceType.Exclusive}>
                      This is tailored for customers who have custom preferences for their choice building plans, we would design a building plan
                      according to your preference and you would be required to pay 30% of the development cost upfront and the remaining 70% over 12
                      months
                    </SimplePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </SimplePlanContent>
              </SimplePlan>
            </SelfEmplyedMain>
          </FinanceContent>
          <BtnDiv>
            <SaveButtonComponent loading={loading} className="btn" name="Save and continue" onClick={proceedHandlick} />
          </BtnDiv>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5em;
  margin-left: 25em;
  margin-top: 4em;
  position: relative;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    margin: 1em;
  }
  ${mediaQuery.mobile} {
    /* margin: auto; */
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  width: 90%;
  margin-inline: auto;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`;

export const FinanceContent = styled.div``;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  margin-top: 1em;
  width: 70%;
`;
const SelfEmplyedMain = styled.div`
  margin-top: 2em;
  display: grid;
  gap: 2em;
  .exclusive {
    grid-column: span 2;
  }
  ${mediaQuery.tablet} {
    display: block;
  }
`;

const SimplePlan = styled.label<{ isselected: boolean }>`
  /* height: 160px; */
  border: 1px solid #0084fe;
  border-radius: 4px;
  /* width: 70%; */
  padding: 1.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
  ${mediaQuery.mobile} {
    padding: 1em;
  }

  cursor: pointer;
  background: ${(props) => (props.isselected ? "rgba(0, 49, 122, 1)" : "")};
  svg {
    fill: ${(props) => (props.isselected ? "#fff" : "#00317a")};
    ${mediaQuery.tablet} {
      width: 29.27px;
      min-width: 10% !important;
    }
  }

  &:hover {
    background: rgba(0, 49, 122, 1);
    p,
    h3 {
      color: #fff !important;
    }
    transition: none;
    svg {
      fill: #fff;
      transition: none;
    }
  }
`;
const Radio = styled.input.attrs({
  type: "radio",
})`
  visibility: hidden;
`;
const SimplePlanContent = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 2em;
  ${mediaQuery.tablet} {
    justify-content: center;
  }
  ${mediaQuery.mobile} {
    gap: 1em;
  }
  .goodmark {
    height: 56.686790466308594px;
    width: 42px;
    border-radius: 0px;
    ${mediaQuery.mobile} {
      width: 22.77px;
    }
  }
`;
const Icon = styled.div`
  svg {
    fill: #fff;
  }
`;

const Text = styled.div`
  width: 60%;
  ${mediaQuery.tablet} {
    width: auto;
  }
`;
const H2 = styled.h3<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  margin-bottom: 0.5em;
  ${mediaQuery.mobile} {
    font-size: 12.8077px;
    line-height: 16px;
  }
  color: ${(props) => (props.isselected ? "#fff" : "#0084fe")};
`;
const SimplePlanDetails = styled.p<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 15px;
  color: ${(props) => (props.isselected ? "#fff" : "#797474")};
  ${mediaQuery.mobile} {
    font-size: 0.75rem;
    line-height: 15px;
    width: 100%;
  }
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 70%; */
  ${mediaQuery.tablet} {
    width: 100% !important;
  }
  .btn {
    padding: 1em !important;
    ${mediaQuery.tablet} {
    }
  }
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
