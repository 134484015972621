import { NavLink, Outlet } from "react-router-dom";
import { styled } from "styled-components";
import { Arrow, CircleIcon, GoodMarkIcon } from "../../utils/svg";
import { buildAidPropertyChoice, buildFinancialVerificationLink, mandateGeneration, paymentHistory } from "../../links";

export function CustomAidNavigation(){
    return(
        <Main>
        <PhaseContainer>
        <PhaseHeader>
          <Phase1Button>
            <Arrow />
            <H3>CUSTOM AID APPLICATION</H3>
          </Phase1Button>
          <GoodMarkIcon />
        </PhaseHeader>
        <PhaseList>
          <Ul>
            <Span  to={buildFinancialVerificationLink} end>
              <CircleIcon />
              <LI>Finance verification</LI>
            </Span>
            {/* <Span to={premium}>
              <CircleIcon />
              <LI>Select Building Plan </LI>
            </Span> */}
            <Span  to={buildAidPropertyChoice}>
              <CircleIcon />
              <LI>Property choice</LI>
            </Span>
            <Span  to={mandateGeneration}>
              <CircleIcon />
              <LI>Mandate verification</LI>
            </Span>
            <Span   to={paymentHistory}>
              <CircleIcon />
              <LI>Contribution history</LI>
            </Span>
          </Ul>
        </PhaseList>
      </PhaseContainer>
      <Outlet/>
        </Main>
    )
}

const Main = styled.div`
display: flex;
margin-top: 1em;
justify-content: center;
gap: 4em;
margin-inline: auto;
 width: 80%;
`

export const PhaseContainer = styled.div`
  margin-top: 2em;
  width: 60%;
  margin-left: 15em;
`;

export const PhaseHeader = styled.div`
    display: flex;
    justify-content: space-between;
svg{
    fill: #33B95D;
}
`
export const Phase1Button = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625em;
`;

export const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 22px;
  color: #000000;
`;

export const PhaseList = styled.div`
  margin-top: 1.5em;
  .active {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
  
`;

export const Ul = styled.ul`

`;

export const Span = styled(NavLink)`
  display: flex;
  gap: 1em;
  align-items: center;
  text-align: left;
  margin-bottom: 0.5em;
  padding: 0.875em 1.25em;
  border-radius: 8px;
  width: 100%;
  transition: all 0.1s ease-in;
  text-decoration: none;
  cursor: pointer;
  .active {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
  svg {
    fill: #00317a;
    transition: none !important;
    cursor: pointer;
  }
  &:hover {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
`;

export const LI = styled.li`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: .95rem;
  line-height: 19px;
  color: #00317a;
`;
