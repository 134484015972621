import { styled } from "styled-components";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS } from "chart.js";
import { mediaQuery } from "../../../breakPoint";
// import { ProgressChartIcon } from "../../utils/svg";

ChartJS.register(ArcElement);
interface ProgressProps {
  className?: string;
}

export function ProgressAmountComponent(props:ProgressProps) {
  return (
    <>
      <BoxMain>
        <Box>
          <BoxDetails>
            <TextNumber>
              <Construction>Construction Progress</Construction>
              <Progress>0/00</Progress>
            </TextNumber>
            <ChartContainer>
              <DoughnutComponent />
            </ChartContainer>
          </BoxDetails>
        </Box>
      </BoxMain>
    </>
  );
}

export function DoughnutComponent() {
  const data = {
    // labels: labels,
    datasets: [
      {
        label: "Costing",
        // backgroundColor: ["rgba(51, 185, 93, 0.3)", "#33B95D"],
        backgroundColor: ["rgba(51, 185, 93, 0.3)"],
        // borderColor: ["rgba(51, 185, 93, 0.3)", "#33B95D"],
        borderColor: ["rgba(51, 185, 93, 0.3)"],
        data: [10, 20, 70],
        Animation: false,
        cutout: "80%",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    tooltips: {
      enabled: false, // Disable the tooltips
    },
    plugins: {
      doughnutlabel: {},
      legend: {
        position: "right" as const, // Set the position to 'bottom'
        labels: {
          usePointStyle: true, // Use circular dots for labels
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0, // Remove border around arcs
      },
    },
    cutoutPercentage: 90, // Change the size of the doughnut (value between 0 and 100)
  };

  return (
    <>
      <ChartDiv>
        <Doughnut data={data} options={options} />
      </ChartDiv>
    </>
  );
}
const ChartDiv = styled.div``;
const BoxMain = styled.div`
  width: 55%;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;

const Box = styled.div`
  background-color: #fff;
  background: #ffffff;
  border-radius: 8px;
  padding: 0.8em;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 25px 0px rgba(170, 168, 168, 0.75);
  ${mediaQuery.tablet} {
    height: 160px;
  }
`;

const BoxDetails = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
`;

const TextNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.8em;
  padding-top: 0.8em;
  ${mediaQuery.tablet} {
    justify-content: start;
    gap: 2em;
  }
`;

const Construction = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  width: 20%;
  color: #3f3f3f;
`;

const Progress = styled.h4``;

const ChartContainer = styled.div`
  width: 100px;
`;
