
import { styled } from "styled-components";
import eye from "../../assets/Magnifer.png"

export function SearchInput(){
  

  return (
    <>
      <PasswordDiv>
        <InputDiv>
            <IMG src={eye} />
          <Input
            placeholder="Search"
            name="q"
          />
        </InputDiv>
      </PasswordDiv>
    </>
  );
};

export const PasswordDiv = styled.div`
  margin-top: 1.3125em;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.8em;
`;

export const InputDiv = styled.div`
  width: 100%;
  border: 0.5px solid #999999;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding-left: .8em;
  background: #fff;
`;
export const Input = styled.input`
  /* width: 100%; */
  padding: 0.875em 1.125em;
  height: 100%;
  border-radius: 40px;
  width: 90%;
  border: none;
  outline: none;
  font-size: 1rem;
`;

const IMG = styled.img`
  cursor: pointer;
`;
