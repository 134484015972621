
import {
    Paper,
    TableBody,
    TableContainer,
    TableHead,
    // TableHead,
    TableRow,
  } from "@mui/material";
  import Table from "@mui/material/Table";
  import TableCell from "@mui/material/TableCell";
  import { styled } from "styled-components";
import { useGetCosting } from "../../../../../hooks/costing/usegetCosting";


  export interface Row {
    id: number;
    option: string | number;
    amount: number | string;
    plan_type?: string;
    substructure_cost?: string | number;
    superstructure_cost?: string | number;
    permit_cost?: string | number;
    land_verification_cost?: string | number;
    minimumFloorArea?: string | number;
    minimumPlotArea?: string | number;
  }
  
  interface TableProps {
    rows: any
  }

  
  
  export default function CostAndEquityTable(props:TableProps) {
  
  
   
    const {permit_cost, substructure_cost,superstructure_cost,land_verification_cost} = useGetCosting()
    
    return (
      <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHeadStyle>
          <TableRow>
            <TableCell className="table_head_cell">ITEMS</TableCell>
            <TableCell className="table_head_cell">DETAILS</TableCell>
            {/* <TableCell className="table_head_cell">Information</TableCell> */}
          </TableRow>
        </TableHeadStyle>
        <TableBodyStyled>
          {/* <TableRowStyled>
            <TableCell>Plan Type</TableCell>
            <TableCell>{plan_type}</TableCell>
          </TableRowStyled> */}
          <TableRowStyled>
            <TableCell>Substructure</TableCell>
            <TableCell>{substructure_cost?.toLocaleString()}</TableCell>
          </TableRowStyled>
          <TableRowStyled>
            <TableCell>Supersctructure</TableCell>
            <TableCell >{superstructure_cost?.toLocaleString()}</TableCell>
            </TableRowStyled>
          <TableRowStyled>
            <TableCell>Permit cost</TableCell>
            <TableCell>{permit_cost?.toLocaleString()}</TableCell>
            </TableRowStyled>
          <TableRowStyled>
            <TableCell>Land verifcation cost</TableCell>
            <TableCell>{land_verification_cost?.toLocaleString()}</TableCell>
          </TableRowStyled>
          {/* <TableRowStyled>
            <TableCell>Minimum Plot Area</TableCell>
            <TableCell>{MFA?.toLocaleString()}</TableCell>
          </TableRowStyled>
          <TableRowStyled>
            <TableCell>minimum Floor Area</TableCell>
            <TableCell>{MPA?.toLocaleString()}</TableCell>
          </TableRowStyled> */}
          {/* ))} */}
        </TableBodyStyled>
      </Table>
    </TableContainer>
  );
}

const TableHeadStyle = styled(TableHead)`
  // background: #eaeaea;

  // .table_head_cell {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 1rem;
    width: 10%;
  //   line-height: 19px;
  //   color: #000000;
  // }
`;

const TableBodyStyled = styled(TableBody)`

`;

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(even) {
    background: #eaeaea;
  }
`;