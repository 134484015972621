import {
  FormControl,
  FormHelperText,
  // InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { SelectDropDownProps } from "../../types";
import { styled } from "styled-components";

export function SelectInput({
  // value,
  defaultName,
  SelectLabel,
  valueItems,
  items,
  onChange,
  error,
}: SelectDropDownProps) {

  const removeUnderscore = (str: string) => str.replace(/_/g, ' ');
  // const removeUnderscore = (str: string) => str.split('_').join(' '); 
  return (
    <>
      <FormControl fullWidth>
        <FormHelperText sx={FormHelperTexts}>{SelectLabel}</FormHelperText>
        <Select value={items} onChange={onChange} displayEmpty>
          <MenuItem  value="" >{defaultName}</MenuItem>
          {valueItems ? (
            valueItems?.map((status, index) => (
              <MenuItem key={index} value={status}>
              {removeUnderscore(status.toString())}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Option Availble</MenuItem>
          )}
        </Select>
        <ValidationLabel >
          {error}
        </ValidationLabel>
      </FormControl>
    </>
  );
}

export const FormControls = styled(FormControl)`
  min-width: 100%;
  width: 433px;
  /* z-index: -1; */
`;

export const FormHelperTexts = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "1.1rem",
  lineHeight: "19px",
  textAlign: "left",
  color: "#525252",
  marginBottom: "0.5625em",
  marginTop: "1.8em",
};


const ValidationLabel = styled.label`
  margin-left: .6em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: rgba(206, 43, 43, 1);
  font-size: .9rem;
  margin-top: .5em;
  `