import { ChangeEvent, FormEvent, useState } from "react";
import { ConbuildLogo } from "../../../utils/svg";
import bg from "../../../assets/Blueprint.png";
import bgR from "../../../assets/bgright.png";
import { InputField } from "../../../utils/input";
import { AuthBtn } from "../../../utils/buttons/authBtn";
// import google from "../../../assets/googlew.png";
import { Link } from "react-router-dom";
import { PasswordInput } from "../../../utils/input/passwordInput";
import { createAccount, resetPassword } from "../../../links";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../../api/auth";
import { Backdrop, CircularProgress, Stack } from "@mui/material";
import { GOOGLE_AUTH } from "../../../api/mutatation/googleAuth";
import { useDispatch, useSelector } from "react-redux";
import { setEmailError, setPasswordError } from "../../../slice/Validation";
import { RootState } from "../../../store";
import { useNotificationToast } from "../../../hooks/useToast";
import { BackgroundImage, BackgroundImageRight, CREATEACCOUNT, CreateAccountContent, CreateAccountInputForm, CreateAccountMain, GoogleButton, HeaderText, InputContainers, Line, LineContainer, Or, P, Register } from "./style.";
import { enqueueSnackbar } from "notistack";
const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export function Login() {

  const { errorToast, successToast } = useNotificationToast()

  const dispatch = useDispatch();

  const { emailError, passwordError } = useSelector((state: RootState) => state.validationReducer)

  const [login, { loading }] = useMutation(LOGIN);


  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");


  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();

    if (!emailRegex.test(email)) {
      dispatch(setEmailError("Email is invalid"))     // email 
      return
    }

    if (email === "") {
      dispatch(setEmailError("Email is required *"))
      return
    }

    if (password === "") {
      setPasswordError("Password is required *")     // password 
      return
    }

    if (password.length < 6) {
      enqueueSnackbar("Your password must consist of a minimum of six digits. Please ensure it meets this requirement for security reasons.",
        { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
      return
    }


    try {
      const { data } = await login({ variables: { payload: { "email": email, "password": password, }, } });

      const token = data.login.access_token;

      localStorage.setItem("conbuild_token", token);

      window.location.href = "/"

      successToast(data?.message || "Successful!")

    }

    catch (error) {
      const err: any = error
      // console.error("Error creating account:", error);
      errorToast(err?.message)
    }
  };

  // google login section 
  const [googleLoading, setGoogleLoading] = useState<boolean>(false);


  const [googleAuth] = useMutation(GOOGLE_AUTH)

  async function GoogleSignUp(token: string) {

    try {
      setGoogleLoading(true)
      const { data } = await googleAuth({ variables: { payload: { token } } })

      if (data) {
        const token = data?.googleAuth?.access_token;
        localStorage.setItem("conbuild_token", token);
        window.location.replace("/")
      }

      setGoogleLoading(false)

    }

    catch (error) {
      setGoogleLoading(false)
      console.log(error)

    }


  }


  return (
    <>
      {/* {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error?.message}
        </Alert>
      )} */}
      {googleLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={googleLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CREATEACCOUNT>
        <CreateAccountMain>
          <CreateAccountInputForm onSubmit={handleFormSubmit}>
            <CreateAccountContent>
              <ConbuildLogo />
              <HeaderText>
                <Register>Login</Register>
                <P>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </P>
              </HeaderText>
              <InputContainers>
                <InputField
                  error={emailError ? emailError : ""}
                  label="Email"
                  placeholder="Enter here"
                  type="email"
                  value={email}

                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    dispatch(setEmailError(""))
                    setEmail(event.target.value)
                  }}

                  onBlur={() => {
                    // Perform validation logic when the input field loses focus
                    if (email === "") {
                      dispatch(setEmailError("Email is required. *"));
                    }
                    if (email !== "" && !emailRegex.test(email)) {
                      dispatch(setEmailError("Email is invalid *"))
                    }
                  }}
                />
                <PasswordInput
                  value={password}
                  onChange={(event) => {
                    dispatch(setPasswordError(""))
                    setPassword(event.target.value)
                  }}
                  label="Password"
                  error={passwordError ? passwordError : ""}

                  onBlur={() => {
                    if (password === "" || !password) {
                      dispatch(setPasswordError("Password is required. *"))
                    }
                  }}

                />
              </InputContainers>
              <span>
                Don't have an Account?{" "}
                <Link className="link" to={createAccount}>
                  Sign up
                </Link>
              </span>
              <AuthBtn loading={googleLoading || loading} name="Login" />
              <div className="password" >
                <Link className="link password" to={resetPassword}>
                  Forgot Password?{" "}
                </Link>
              </div>
              <LineContainer>
                <Line></Line>
                <Or>Or</Or>
                <Line></Line>
              </LineContainer>
              <Stack sx={{ width: "100%", marginTop: "1em" }} direction={['column', 'column', 'row']} alignContent="center" spacing="2" >
                <GoogleButton
                  width="100%"
                  size='large'
                  text="continue_with"
                  context="use"
                  // ux_mode="popup"
                  auto_select={false}
                  containerProps={{
                    className: "google"
                  }}
                  onSuccess={async (credentialResponse) => {
                    await GoogleSignUp(credentialResponse?.credential as string)
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </Stack>
            </CreateAccountContent>
          </CreateAccountInputForm>
          <BackgroundImage src={bg} />
          <BackgroundImageRight src={bgR} />
        </CreateAccountMain>
      </CREATEACCOUNT>
    </>
  );
}


// ROUTER LINK
// const LINK = styled(Link)`
//   text-decoration: none;
// `;
