import { styled } from "styled-components";
import { CopyIcon } from "../../utils/svg";
import { useGetOutRightInstallmentPayment } from "../../hooks/payment/usePayment";
import { useCopyToClipboard } from "../../hooks/useCopyToClip";
import { useState } from "react";


export function PaymentDetailsComponentInstallmental() {
  const {details } = useGetOutRightInstallmentPayment()

  const [, setCopied] = useState(false);

  // const [copy, result] = useCopyToClipboard()


  const installmental = details?.__typename === "InstallmentalPaymentDetails" && details
  const { copyToClipboard } = useCopyToClipboard();

  const handleCopyClick = (textToCopy: any) => {
    copyToClipboard(textToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };
  
  const paymentDetails  =  installmental && installmental?.payment_bank_details


  return (
    <Containter>
      <PaymentDetails>
        <PAY>Account Name</PAY>
        <DetailsText>
          <H4>{ installmental && installmental?.user_bank_info?.account_name}</H4>
          <Copy onClick={() => handleCopyClick(installmental && installmental?.user_bank_info?.account_name)}>
            <Span>Copy</Span>
            <CopyIcon />
          </Copy>
        </DetailsText>
      </PaymentDetails>
      <PaymentDetails className="detail" >
        <PAY>Account Number</PAY>
        <DetailsText>
          <H4>{ installmental && installmental?.user_bank_info?.account_number}</H4>
          <Copy onClick={() => handleCopyClick( installmental && installmental?.user_bank_info?.account_number)}>
            <Span>Copy</Span>
            <CopyIcon />
          </Copy>
        </DetailsText>
      </PaymentDetails>
      <PaymentDetails>
        <PAY>Bank Name</PAY>
        <DetailsText>
          <H4>{ installmental && installmental?.user_bank_info?.bank_name}</H4>
          <Copy onClick={() => handleCopyClick(installmental && installmental?.user_bank_info?.bank_name)}>
            <Span>Copy</Span>
            <CopyIcon />
          </Copy>
        </DetailsText>
      </PaymentDetails>
      <PaymentDetails>
        <PAY>BVN</PAY>
        <DetailsText>
          <H4>{ installmental && installmental?.bvn}</H4>
          <Copy onClick={() => handleCopyClick(installmental && installmental?.bvn)}>
            <Span>Copy</Span>
            <CopyIcon />
          </Copy>
        </DetailsText>
      </PaymentDetails>
      <h2>Bank Payment details</h2>
      <PaymentDetails>
        <PAY>Account Name</PAY>
        <DetailsText>
          <H4>{ paymentDetails && paymentDetails?.bank_payment_info?.account_name}</H4>
          <Copy onClick={() => handleCopyClick( paymentDetails && paymentDetails?.bank_payment_info?.account_name)}>
            <Span>Copy</Span>
            <CopyIcon />
          </Copy>
        </DetailsText>
      </PaymentDetails>
      <PaymentDetails>
        <PAY>Account Number</PAY>
        <DetailsText>
          <H4>{ paymentDetails && paymentDetails?.bank_payment_info?.account_number}</H4>
          <Copy onClick={() => handleCopyClick(paymentDetails && paymentDetails?.bank_payment_info?.account_number)}>
            <Span>Copy</Span>
            <CopyIcon />
          </Copy>
        </DetailsText>
      </PaymentDetails>
      <PaymentDetails>
        <PAY>Bank Name</PAY>
        <DetailsText>
          <H4>{ paymentDetails && paymentDetails?.bank_payment_info?.bank_name}</H4>
          <Copy onClick={() => handleCopyClick(paymentDetails && paymentDetails?.bank_payment_info?.bank_name)}>
            <Span>Copy</Span>
            <CopyIcon />
          </Copy>
        </DetailsText>
      </PaymentDetails>
      {/* <PaymentDetails>
        <PAY>Bank sort code</PAY>
        <DetailsText>
          <H4>123749</H4>
          <Copy>
            <Span>Copy</Span>
            <CopyIcon />
          </Copy>
        </DetailsText>
      </PaymentDetails> */}
    </Containter>
  );
}


const Containter = styled.div`
 
`
const PaymentDetails = styled.div`
  /* display: flex; */
  /* align-items: center; */
  padding: 1em 0 .8rem;
  border-bottom: 0.5px solid #a6a6a6;
  @media (max-width: 440px) {
    width: 100%;
  }
`;
const PAY = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: .9rem;
  line-height: 17px;
  color: #828282;
`;

const DetailsText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10em;
  margin-top: 1rem;
  @media (max-width: 440px) {
    width: 100%;
    gap: 8em;
    margin: auto;
    /* justify-content: center; */
  }
`;

const H4 = styled.h4`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: .8rem;
  line-height: 19px;
  color: #000000;
  @media (max-width: 440px) {
    font-size: 14px;
    width: 30%;
  }
`;

const Copy = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7em;
  cursor: pointer;
`;

const Span = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  @media (max-width: 440px) {
    font-size: 14px;
  }
`;
