import { styled } from "styled-components";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { DropDownIcon } from "../../../../utils/svg";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { SelectIDcardModal } from "../../../../components/modals/IDtypeSelectModal";
import { mediaQuery } from "../../../../breakPoint";
import { verificationStatusLink } from "../../../../links";
import { useUserKyc } from "../../../../hooks/useUserKyc";
import SimpleBackdrop from "../../../../utils/backdrop";
import { IDverificationStatus } from "./idStatus";

export function UploadID() {

  const navigate = useNavigate();

  const { document_status, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen, refetch } = useUserKyc()   // hooks 

  const [openModal, isModalOpen] = useState<boolean>(false);

  const [selectIdTypeModal, setSelectIdTypeModal] = useState<string>("");

  const idCardTypes = ["DRIVERS_LICENSE", "NATIONAL_ID", "WORK_ID"];

  const checkStatus = document_status === "PENDING" || document_status === "REJECTED" || document_status === "APPROVED";

  const noStatus = document_status === "NONE" || !document_status

  // useEffect(() => { refetch() }, []);
  const memoizedRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    memoizedRefetch();
  }, [memoizedRefetch]);

  useEffect(() => {
    if (checkStatus) {
      navigate(verificationStatusLink);
    }
  }, [navigate, checkStatus]);


  return (
    <>
      {loadingBackDrop ? (
        <SimpleBackdrop
          setOpen={setLoadingBackDrop}
          loadingBackDrop={loadingBackDrop}
          handleClose={handleBackDropClose}
          handleOpen={handleBackDropOpen}
        />) :
        noStatus ?
          (<FinanceContainer>
            <Finance>
              <FinanceContent>
                <FinanceHeader>
                  <DropDownIcon
                    className="drop_down"
                    onClick={() => navigate(-1)}
                  />
                  <H1>KYC</H1>
                </FinanceHeader>
                <SelfEmplyedMain>
                  <VerifyAccount>
                    <H3>ID card verification</H3>
                    <Text>
                      Please upload a valid ID, corresponding to your country of residence
                    </Text>
                  </VerifyAccount>
                </SelfEmplyedMain>
                <SaveButtonComponent
                  onClick={() => isModalOpen(true)}
                  name="Upload ID"
                  className="btn"
                />
              </FinanceContent>
              {openModal && (
                <SelectIDcardModal
                  // select dfropdown items
                  valueItems={idCardTypes}
                  defaultName="Select"
                  onChange={(e) => setSelectIdTypeModal(e.target.value)}
                  items={selectIdTypeModal}
                  setItems={setSelectIdTypeModal}
                  SelectLabel="Select ID Type"
                  // save button
                  // text area description 
                  // description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  // do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  name="ID card verification"
                  // function to close and open the modal 
                  onClose={() => isModalOpen(false)}
                  open={openModal}
                  setOpen={isModalOpen}
                // submitIDdetails = {HandleSubmit}
                // LOADIN STATE 
                />
              )}
            </Finance>
            <ChartConponentDiv>
              <ChartIconComponent />
            </ChartConponentDiv>
          </FinanceContainer>) :
          <IDverificationStatus />}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  margin-left: 15em;
  width: 100%;
  padding: 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    margin: 4.5em auto 2em;
  }
  ${mediaQuery.mobile}{
    margin: 1em auto
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
  width: 80%;
  padding: 0em 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    width: 90%;
  }
`;

export const FinanceContent = styled.div`
  .btn {
    padding: 1em !important;
  }
  /* width: 60%; */
  margin-top: 2em;
`;
export const FinanceHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  gap: 1em;
  .drop_down {
    transform: rotate(90deg);
    cursor: pointer;
  }
`;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
`;
const SelfEmplyedMain = styled.div`
  margin-top: 3em;
  ${mediaQuery.tablet} {
    margin-bottom: 5em;
  }
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;

const VerifyAccount = styled.div`
  margin-bottom: 2em;
`;

const H3 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1275rem;
  line-height: 30px;
  margin-bottom: 0.4em;
  color: #00317a;
`;

const Text = styled.p`
  /* width: 60%; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;

  color: #797474;
  ${mediaQuery.mobile} {
    font-size: 0.9rem;
    width: 100%;
  }
`;
