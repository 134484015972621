import {
  MuiTelInput,
  MuiTelInputContinent,
  MuiTelInputInfo,
} from "mui-tel-input";
import { styled } from "styled-components";

interface PhoneInputProps {
  value: string;
  onChange: (value: string, info: MuiTelInputInfo) => void;
  error?: string;
  onBlur?: () => void;
}

export function PhoneInputComponent(props: PhoneInputProps) {
  const continents: MuiTelInputContinent[] = ["AF"];
  return (
    <PhoneInputDiv>
      <Label>Number</Label>
      <MuiTelInputStyled
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={props.value}
        continents={continents}
        defaultCountry="NG"
        required
      />
      <ValidationLabel >
        {props.error}
      </ValidationLabel>
    </PhoneInputDiv>
  );
}

const PhoneInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.3125em;
`;

const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #828282;
  margin-bottom: 0.56em;
`;

const MuiTelInputStyled = styled(MuiTelInput)`
  & input {
    padding: 1em 1.125em;
    width: 100%;
    border: 0.5px solid #999999;
    border-radius: 8px;
    font-size: 1rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }
`;

const ValidationLabel = styled.label`
margin-left: .6em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: rgba(206, 43, 43, 1);
  font-size: .9rem;
  margin-top: .5em;
  `

