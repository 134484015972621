import { styled } from "styled-components";
import { Dispatch, SetStateAction } from "react";
import { FileUpload } from "../../../../../components/imageUploads/fileUpload";
import { InputField } from "../../../../../utils/input";
import { mediaQuery } from "../../../../../breakPoint";

interface SelfEmployedUserProps {
  employerName: string;
  setEmployerName: Dispatch<SetStateAction<string>>;
  employedJobDescription: string;
  setEmployedJobDescription: Dispatch<SetStateAction<string>>;
  workAddress: string;
  setWorkAddress: Dispatch<SetStateAction<string>>;
  salary: string
  setSalary: Dispatch<SetStateAction<string>>;
  // image file
  setEmployedUserBankStatements: Dispatch<SetStateAction<File[]>>;
  // work id 
  setEmployedUserWorkIDFront: Dispatch<SetStateAction<File[]>>;
  setEmployedUserWorkIDBack: Dispatch<SetStateAction<File[]>>;
}
export function CustomAidFinanceEmployed(props:SelfEmployedUserProps) {
  // const [salaryRange, setSalaryRange] = useState<string>("");

  // const amounts = ["N500", "N2000", "N5000", "N1000000", "N100", "N10000"];
  // const selectAmountHandleChange = (event: SelectChangeEvent) => {
  //   setSalaryRange(event.target.value as string);
  // };
  return (
    <>
      <SelfEmplyedMain>
        <ImageUpLoadDiv>
          <FileUpload
            name="Upload Bank statement (1 year)"
            label="Bank Statement"
            src={props.setEmployedUserBankStatements}
          />
        </ImageUpLoadDiv>
        <FieldContainer>
          <InputField
            label="Employer name"
            type="text"
            placeholder="Employer name"
            className="input"
            value={props.employerName}
            onChange={(event) => props.setEmployerName(event.target.value)}
          />
          <InputField
            label="Job Description"
            type="text"
            placeholder="Enter Here"
            className="input"
            value = {props.employedJobDescription}
            onChange={(event) => props.setEmployedJobDescription(event.target.value)}
          />
          <InputField
            label="Work address"
            type="text"
            placeholder="Enter Address"
            className="input"
            value = {props.workAddress}
            onChange={(event) => props.setWorkAddress(event.target.value)}
          />
          <InputField
            label="Salary"
            type="tel"
            placeholder="Enter Amount"
            className="input"
            value = {props.salary}
            onChange={(event) => props.setSalary(event.target.value)}
          />
           <InputField
            label="Pension Fund Adminstrastor"
            type="tel"
            placeholder="Select Pension Adminstrastor"
            className="input"
            value = {props.salary}
            onChange={(event) => props.setSalary(event.target.value)}
          />
           <InputField
            label="Pension ID"
            type="tel"
            placeholder="Enter Pension adminsitrator"
            className="input"
            value = {props.salary}
            onChange={(event) => props.setSalary(event.target.value)}
          />
        </FieldContainer>
        <ImageUpLoadDiv>
          <FileUpload src={props.setEmployedUserWorkIDFront} label="Work ID Front" name="Upload work ID front" />
          <FileUpload src={props.setEmployedUserWorkIDBack} label="Work ID Back" name="Upload work ID back" />
        </ImageUpLoadDiv>
      </SelfEmplyedMain>
    </>
  );
}

const SelfEmplyedMain = styled.div`

`;

const ImageUpLoadDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.4em;
  
`;

const FieldContainer = styled.div`
.input{
    ${mediaQuery.tablet}{
      width: 100% !important;
    }
}
`;
