
// assets
import toilet from "../../../../../assets/toilet.png";
import bedroom from "../../../../../assets/bedrom.png";
import parkingSpace from "../../../../../assets/parkingspace.png";
import water from "../../../../../assets/water.png";
import planImage from "../../../../../assets/planImage.png";
import planStructure from "../../../../../assets/hplan.png";
import frontElevation from "../../../../../assets/frontE.png";
import sideElevation from "../../../../../assets/sideEl.png";
import TableStripe from "./detailsTable";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { ChartIconComponent } from "../../../../../utils/ChartBtn";
import { styled } from "styled-components";
import { mediaQuery } from "../../../../../breakPoint";
// import planImage1 from "../../../../../../../assets/planImage.png";
// import planImage2 from "../../../../../../../assets/planImage.png";
// import planImage3 from "../../../../../../../assets/planImage.png";
// import slideIcon from "../../../../../../../assets/sliderIcon.png";

export function PremiumPropertyChoiceDetails() {
  const navigate = useNavigate();
  const price = 250000;
  //   const [currentIndex, setCurrentIndex] = useState(0);

  //   const handleSlideChange = (index: number) => {
  //     setCurrentIndex(index);
  //   };
  //   const items = [
  //     <img src={planImage} alt="" />,
  //     <img src={planImage1} alt="" />,
  //     <img src={planImage2} alt="" />,
  //     <img src={planImage3} alt="" />,
  //   ];

  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>Plan 1234gh</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
              <DetailsIcon>
                <IconD>
                  <img src={toilet} alt="" /> 4 Toilet{" "}
                </IconD>
                <IconD>
                  <img src={bedroom} alt="" /> 3 bedrooms{" "}
                </IconD>
                <IconD>
                  <img src={parkingSpace} alt="" /> Parking space{" "}
                </IconD>
                <IconD>
                  <img src={water} alt="" /> Treated water{" "}
                </IconD>
              </DetailsIcon>
            </FinanceHeader>
            <CarouselImage>
              <img  className="plan_view"  src={planImage} alt="" />
            </CarouselImage>
            <PlanDetailsSection>
              <Text>
                <H2>Plan</H2>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </Paragraph>
              </Text>
              <img className="plan_img" src={planStructure} alt="" />
            </PlanDetailsSection>
            <PlanDetailsSection>
              <Text>
                <H2>Front Elevation</H2>
              </Text>
              <img className="plan_img" src={frontElevation} alt="" />
            </PlanDetailsSection>
            <PlanDetailsSection>
              <Text>
                <H2>Side elevatioin</H2>
              </Text>
              <img className="plan_img" src={sideElevation} alt="" />
            </PlanDetailsSection>
            <PlanDetailsSection>
            <Text>
              <H2>Payment details</H2>
              <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Paragraph>
            </Text>
            <Amount>
              <H3>
                <Sup>NGN</Sup>
                {price.toLocaleString()}
                <Sup>.00</Sup>
              </H3>
            </Amount>
            <div className="tableContainer">
              <TableStripe />
            </div>
            <div className="btn">
              <ButtonStyled
                onClick={() => navigate(-1)}
                className="outlin"
                variant="outlined"
              >
                Go back to plans
              </ButtonStyled>
              <ButtonStyled
                // onClick={() => navigate(proceedLink)}
                className="contain"
                variant="contained"
              >
                Use this plan
              </ButtonStyled>
            </div>
          </PlanDetailsSection>
          </FinanceContent>
         
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 2em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  /* width: 50%; */
   /* border: 2px solid red; */
  margin-left: 10em;
  ${mediaQuery.tablet} {
    margin-top: 2em;
    margin-inline: auto;
    display: flex;
    justify-content: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
 
`;
export const Finance = styled.div`
width: 80%;
margin-inline: auto;
  .btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    margin-top: 2.375em;
    .outlin {
      background: #ffffff;
      border: 1px solid #00317a;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 19px;
      color: #00317a;
     ${mediaQuery.mobile} {
        font-size: 0.7rem;
        padding: 1em !important;
        width: 100%;
      }
    }
    .contain {
      background: #0084fe;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      ${mediaQuery.mobile}{
        font-size: 0.75rem;
        padding: 1em !important;
        width: 100%;
      }
    }
  }
`;

export const FinanceContent = styled.div`
width: 60%;
margin: auto;
 ${mediaQuery.tablet} {
  margin: auto;
 }
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 20px;
  /* max-width: 420px;
  max-height: 53px; */
  color: #797474;
  width: 90%;
  margin-top: 1em;
  ${mediaQuery.mobile} {
    font-size: 0.875rem;
    line-height: 19px;
  }
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
const DetailsIcon = styled.div`
  margin-top: 2em;
  display: flex;
  align-items: center;
  gap: 2em;
  ${mediaQuery.tablet} {
    gap: 1em;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 3em;
  }
  ${mediaQuery.mobile} {
    margin-top: 4.5em;
    justify-content: space-between;
  }
`;

const IconD = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
`;

const CarouselImage = styled.div`
  width: 90%;
  margin-top: 2.375em;
  ${mediaQuery.tablet}{
    width: 100%;
  }
  img {
    width: 100%;
  }
`;

const PlanDetailsSection = styled.div`
  width: 90%;
  margin-top: 3em;
  ${mediaQuery.tablet} {
    width: 100%;
    /* border: 2px solid blue; */
  }
  .tableContainer {
    margin-top: 2.375em;
  }
  .plan_img {
    width: 100%;
    margin-top: 2em;
  }
`;

const Text = styled.div``;
const H2 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: .8em;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const Paragraph = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  color: #797474;
`;

const ButtonStyled = styled(Button)`
  && {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    padding: 1em;
    width: 100%;
  }
`;

const Amount = styled.div`
  text-align: center;
  margin-top: 2em;
`;


const Sup = styled.sup`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  ${mediaQuery.tablet} {
    font-size: 0.9rem;
  }
`;


const H3 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 65px;
  color: #000000;
  margin-bottom: 0.5em;
  margin-top: 0.2em;
  ${mediaQuery.tablet} {
    /* font-size: 2.5rem; */
  }
`;
