import { useQuery } from "@apollo/client"
import { COSTING } from "../../api/query/servicePlan/phase2Costing"
import { BuildNowPhase, ProjectCosting } from "../../api/types";
import { useMemo, useState } from "react";

export const useGetCosting = () =>{

    const {data,loading} = useQuery(COSTING);

    const costing = data?.currentBuildNowPhase?.costing;

    const costingData:ProjectCosting = data?.currentBuildNowPhase?.costing;
    
    const paymentTerms: BuildNowPhase = data?.currentBuildNowPhase

     // backdrop loadinng funnction 
     const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

     const handleBackDropClose = () => setLoadingBackDrop(false);
 
     const handleBackDropOpen = () => setLoadingBackDrop(true);
 
     useMemo(() => {
 
         (loading) && handleBackDropOpen();
 
         (!loading) && handleBackDropClose();
 
     }, [loading]);


    return {...costingData,loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen,data,loading,costing,...paymentTerms}
    
}