import { styled } from "styled-components";
import { Header } from "../header";
import {
  AidIcon,
  ConstructionIcon,
  DahboardIcon,
  // DocumentIcon,
  DropDownIcon,
  InitializeContructionIcon,
  // MortageIcon,
  SettingsIcon,
  // SettingsIcon,
  UserIcon,
} from "../../utils/svg";
// import img from "../../../assets/navimage.png";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  FA,
  buildAid,
  construction,
  // initializeProject,
  kyc,
  profile,
  // servicePlan,
} from "../../links";
import { useEffect, useState } from "react";
import { mediaQuery } from "../../breakPoint";
import { UseBuildNowServicePlan } from "../../hooks/useServicePlan";
import SimpleBackdrop from "../../utils/backdrop";

type DropDownProps = {
  showdropdown?: any;
};

export function SideNavigation() {   // this navigation component is just for desktop alone 

  const { serviceTypeRoute,loadingBackDrop,setLoadingBackDrop,handleOpen,handleClose } = UseBuildNowServicePlan()

  const [showDropdown, setShowDropDown] = useState<boolean>(false);

  const [logout, setLogout] = useState<boolean>(false);

  const navigate = useNavigate();

  const LogOut = () => {
    localStorage.removeItem("conbuild_login_token");

    localStorage.removeItem("conbuild_token");

    // localStorage.clear();  // remove this line

    setLogout(true);

  };

  useEffect(() => {
    if (logout) {
      window.location.replace("/");
    }

  }, [logout, navigate]);


   const handleNavLinkClick = (to: string |any) => {
    // Replace the current history entry with the new route
    navigate(to, { replace: true });
  };

  

  return (
    <>
     {/* loading */}
     <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
      <Navigation>
        <Header />
        <Children>
          <DIV>
            <AsideNav>
              <div className="navigation">
                <AsideNavContainer>
                  <TopList showdropdown={showDropdown ? 1: 0}>
                    <Ul>
                      <Span to="/">
                        <DahboardIcon className="das" />
                        <LI>Dashboard</LI>
                      </Span>
                      <span
                        onClick={() => setShowDropDown(!showDropdown)
                        }
                        className="profile_drop_down"
                      >
                        <UserIcon className="user_svg" />
                        <LI>Profile</LI>
                        <DropDownIcon className="dropIcon" />
                      </span>
                      <Output showdropdown={showDropdown? 1: 0} className="drop_down">
                        <Span to={profile}>
                          {" "}
                          <LI>Personal info</LI>
                        </Span>
                        <Span to={kyc}>
                          <LI>KYC</LI>
                        </Span>
                        {/* <Span to="/">
                        <LI>Reset password</LI>
                      </Span> */}
                        {/* <Span to={FA}>
                        <LI>2FA Verification</LI>
                      </Span> */}
                      </Output>
                      <Span to={`/${serviceTypeRoute}`} onClick={() => handleNavLinkClick(`/${serviceTypeRoute}`)} replace end>
                        <InitializeContructionIcon />
                        <LI>Build Now</LI>
                      </Span>
                      <Span to={buildAid}>
                        <AidIcon />
                        <LI>Build Later</LI>
                      </Span>
                      <Span to={construction}>
                        <ConstructionIcon />
                        <LI>Construction</LI>
                      </Span>
                      {/* <Span to="*">
                      <MortageIcon />
                      <LI>Mortage</LI>
                    </Span> */}
                      {/* <Span to="*">
                      <DocumentIcon />
                      <LI>My Document</LI>
                    </Span> */}
                      <Span to={FA}>
                        <SettingsIcon />
                        <LI>Settings</LI>
                      </Span>
                    </Ul>
                  </TopList>
                  {/* <NavImageContainer>
                    <ImageContent>
                      <Image src={img} />
                    </ImageContent>
                    <ImageP>Quick Images preview</ImageP>
                    <Line></Line>
                  </NavImageContainer> */}
                  <button onClick={LogOut} className="logout">
                    Logout
                  </button>
                </AsideNavContainer>
              </div>
            </AsideNav>
          </DIV>
        </Children>
        {/* mobile  */}
        {/* <NestedComponent className="mobile" >
          <Outlet />
        </NestedComponent> */}
        {/* outlet for desktop */}
        <NestedComponent className="mobile">
          <Outlet />
        </NestedComponent>
      </Navigation>
    </>
  );
}

export const Navigation = styled.div`
  .mobile {
  }
`;

export const Children = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1550px;
  .navigation {
    ${mediaQuery.tablet} {
      display: none;
    }
  }
`;
export const DIV = styled.div``;

export const AsideNav = styled.div`
  display: flex;
  height: fit-content;
  margin-top: 4.5em;
  left: 0;
  min-height: fit-content;
`;

export const AsideNavContainer = styled.div`
  border-right: 0.5px solid #7d7d7d;
  height: 100vh;
  margin-left: 2.5em;
  position: fixed;
  .logout {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.99rem;
    line-height: 19px;
    color: #00317a;
    padding: 0.6em;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    /* top: 3em; */
    width: 50%;
    left: 4em;
    margin: auto;
    border: 0.5px solid #edc1c1;
  }
`;

export const TopList = styled.div<DropDownProps>`
  margin-bottom: 3em;

  .profile_drop_down {
    display: flex;
    justify-content: space-between;
    gap: 2em;
    align-items: center;
    text-align: left;
    margin-bottom: 0.8em;
    padding: 0.875em 1.25em;
    border-radius: 8px;
    transition: all 0.1s ease-in;
    text-decoration: none;
    cursor: pointer;
    li {
      margin-right: 4em;
      list-style: none !important;
    }
    .user_svg {
      fill: #00317a;
      transition: none !important;
    }
    .active {
      background: #00317a;
      svg {
        fill: #fff;
      }
      li {
        color: #fff;
        list-style: none !important;
      }
    }
  }

  .dropIcon {
    transform: ${(props) => (props.showdropdown ? "rotate(180deg)" : "")};
  }
  .drop_down {
    li {
      margin-left: 3em;
      text-align: left;
      border-radius: 8px;
      transition: all 0.1s ease-in;
      list-style: none !important;
      text-decoration: none !important;
    }
  }
`;
export const Ul = styled.ul`
  padding: 1.8em;

  .active {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      list-style: none !important;
      color: #fff;
    }
  }
`;

const Output = styled.output<DropDownProps>`
  transition: all 0.3s ease-in;
  display: ${(props) => (props.showdropdown ? "block" : "none")};
`;

export const Span = styled(NavLink)`
  display: flex;
  gap: 2em;
  align-items: center;
  text-align: left;
  margin-bottom: 0.8em;
  padding: 0.875em 1.25em;
  border-radius: 8px;
  transition: all 0.1s ease-in;
  text-decoration: none;
  cursor: pointer;
  svg {
    fill: #00317a;
    transition: none !important;
  }
  &:hover {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
      list-style: none !important;
    }
  }
`;

export const LI = styled.li`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.99rem;
  line-height: 19px;
  color: #00317a;
`;

export const NavImageContainer = styled.div`
  margin-left: 2em;
`;
export const ImageContent = styled.div`
  margin-bottom: 1em;
`;
export const Image = styled.img``;

export const ImageP = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 15px;
  color: #797979;
  margin-bottom: 1em;
`;

export const Line = styled.div`
  border: 0.5px solid #999999;
  width: 90%;
`;

export const SpanB = styled.span`
  margin-bottom: 0.2em;
  display: flex;
  gap: 2em;
  align-items: center;
  text-align: left;
`;

export const NestedComponent = styled.div`
  width: 100%;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
`;
