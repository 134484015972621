import { styled } from "styled-components";
import { ChartIconComponent } from "../../utils/ChartBtn";
import Switch from "@mui/material/Switch";
import { mediaQuery } from "../../breakPoint";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const label = { inputProps: { "aria-label": "Size switch demo" } };
export function FAverification() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <FinanceContainer>
        <Finance>
          <SelfEmplyedMain>
            <Text>
              <FinanceHeader
                data-aos="fade-center"
                data-aos-duration="900"
                data-aos-easing="ease-in"
              >
                <H1>2FA</H1>
              </FinanceHeader>
              <H3
                data-aos="fade-center"
                data-aos-duration="1000"
                data-aos-easing="ease-in"
              >
                Enable 2FA on your account
              </H3>
              <p
                data-aos="fade-center"
                data-aos-duration="1400"
                data-aos-easing="ease-in"
                className="paragraph"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </Text>
            <Authentication
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-easing="ease-in"
            >
              <p className="name">Email authentication</p>
              <Switchs {...label}  />
            </Authentication>
            <Authentication
              data-aos="fade-up"
              data-aos-duration="1600"
              data-aos-easing="ease-in"
            >
              <p className="name">SMS Authentication</p>
              <Switchs {...label}  />
            </Authentication>
            <Authentication
              data-aos="fade-up"
              data-aos-duration="2000"
              data-aos-easing="ease-in"
            >
              <p className="name">Authentication app</p>
              <Switchs {...label} defaultChecked />
            </Authentication>
          </SelfEmplyedMain>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  margin-left: 35em;
  width: 100%;
  [data-aos] {
    opacity: 0;
    transition-property: opacity;
  }

  [data-aos].aos-animate {
    opacity: 1;
  }
  @media (max-width: 1120px) {
    margin-left: 30em;
  }
  ${mediaQuery.tablet} {
    margin: 3em auto 5em;
    margin-inline: auto;
    justify-content: center;
  }

  ${mediaQuery.mobile} {
    margin-top: 1em;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
const Finance = styled.div`
  width: 65%;
  @media (max-width: 1120px) {
    width: 75%;
    display: flex;
    justify-content: center;
  }
  ${mediaQuery.tablet} {
    margin: auto;
    margin-top: 1em;
    width: 90%;
  }
`;

const FinanceHeader = styled.div`
  margin-bottom: 1em;
`;
const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
`;

const SelfEmplyedMain = styled.div``;

const Text = styled.div`
  border-bottom: 0.5px solid #919191;
  padding: 1em;
  .paragraph {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #797474;
    margin-top: 0.8em;
    width: 100%;
    ${mediaQuery.mobile} {
      font-size: 0.98rem;
    }
  }
`;
const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1275rem;
  line-height: 22px;
  color: #00317a;
`;

const Authentication = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #919191;
  padding: 1em;
`;

const Switchs = styled(Switch)``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;
