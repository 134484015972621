import { styled } from "styled-components";
import { InputLabelProps } from "../../types";


export function InputField(props: InputLabelProps) {
  return (
    <>
      <InputLabelDiv>
        <Label>{props.label}</Label>
        <INPUT
          onChange={props.onChange}
          value={props.value}
          type={props.type}
          placeholder={props.placeholder}
          className={props.className}
          required
          onBlur={props.onBlur}
          disabled = {props.disabled}
          maxLength={props.maxlength}
        />
        <ValidationLabel >
          {props.error}
        </ValidationLabel>
      </InputLabelDiv>
    </>
  );
}

export const InputLabelDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.3125em;
`;

export const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #828282;
  margin-bottom: 0.56em;
`;

export const INPUT = styled.input`
  padding: 1.5em 1.125em;
  width: 100%;
  border: 0.5px solid #999999;
  border-radius: 8px;
  font-size: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
`;

export const ValidationLabel = styled.label`
  margin-left: .6em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: rgba(206, 43, 43, 1);
  font-size: .9rem;
  margin-top: .5em;
  `
