import { gql } from "@apollo/client";

export const GET_FINANCE_VERIFICATION = gql`
query CurrentBuildNowPhase {
  currentBuildNowPhase {
   finance_verification {
     employment_details {
       ... on IsEmployed {
         bank_statement {
           src
           mimetype
         }
         employer_name
         job_description
         work_address
         salary
         work_id {
           front
           back
         }
       }
       ... on IsSelfEmployed {
         bank_statement {
           src
           mimetype
         }
         company_bank_statement {
           src
           mimetype
         }
         job_description
         salary
         company_reg_number
         COR {
           src
           mimetype
         }
         CO2 {
           src
           mimetype
         }
       }
     }
     status
      timestamp_created
      timestamp_updated
      employment_status
   }
 }
}`