import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  SelectChangeEvent,
  Snackbar,
} from "@mui/material";
import { styled } from "styled-components";
import { SaveButtonComponent } from "../../../utils/buttons/saveButton";
import { InputField } from "../../../utils/input";
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useState } from "react";

import CountrySelect, { CountryType } from "../../../utils/countrySelect";
import { RESIDENTIAL_VERIFICATION } from "../../../api/mutatation/residentialVerification";
import { useMutation, useQuery } from "@apollo/client";
import { COUNTRY_ } from "../../../api/query/country";
import { useNavigate } from "react-router-dom";
import { residenceVerificationStatusLink } from "../../../links";
import { useDispatch, useSelector } from "react-redux";
import { setResidentialVerificationStatus } from "../../../slice/residentialsSlice";
import { RootState } from "../../../store";
import { setAddressError, setCountryError } from "../../../slice/Validation";
import { useNotificationToast } from "../../../hooks/useToast";


interface ResidentialProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: (event: SelectChangeEvent<boolean>) => void;
  handleOpen?: (event: SelectChangeEvent<boolean>) => void;
  name?: string;
  description?: string;
  value?: string;
  buttonName: string;
  open: boolean;
}

const style = {
  boxShadow: 24,
};

export function ResidentialVerificationModal(props: ResidentialProps) {
  const { successToast } = useNotificationToast()
  const navigate = useNavigate();

  const dispatch = useDispatch() // redux 

  const country = useQuery(COUNTRY_);  // fetch country list

  const { addressError, countryError } = useSelector((state: RootState) => state.validationReducer)

  const [verifyResidence, { loading, error }] = useMutation(RESIDENTIAL_VERIFICATION);

  const [selectedCode, setSelectedCode] = useState<string | CountryType | null>();

  const [selectedValue, setSelectedValue] = useState<string | CountryType | null>();

  const [address, setAddress] = useState<string>("");

  const [openModal] = useState<boolean>(true);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (address === "") {
      dispatch(setAddressError("address is required *"))
      return
    }

    if (selectedCode === "") {
      dispatch(setCountryError("country code is required *"))
      return
    }

    if (selectedValue === "") {
      dispatch(setCountryError("country value is required *"));
      return
    }

    try {
      const { data } = await verifyResidence({ variables: { payload: { country_code: selectedCode, country: selectedValue, address: address, }, }, });

      if (data) {
        navigate(residenceVerificationStatusLink, { replace: true });

        dispatch(setResidentialVerificationStatus(data?.verifyResidence))
      }

      successToast(data?.verifyResidence?.message??"Successful!")
    }

    catch (error) {
      // const err: any = error
      // errorToast(err?.message)
      console.error(error);
    }

  }




  return (
    <>
      <Main onSubmit={handleSubmit}>
        <ModaL
          open={openModal}
          onClose={props.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BoxDiv sx={style}>
            {error && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={error ? true : false}
                autoHideDuration={6000}
              >
                <Alert sx={{ textTransform: "capitalize" }} severity="error">{error?.message}</Alert>
              </Snackbar>
            )}
            <Content>
              <Text>
                <h2>{props?.name}</h2>
                <p>{props?.description}</p>
              </Text>
              <div className="input_container">
                {/* <InputField
                  label="Country"
                  placeholder="Enter your country"
                  type="text" 
                /> */}
                <div className="select">
                  {country.loading === true && (
                    <div className="loader">
                      <CircularProgress />
                    </div>
                  )}
                  <CountrySelect
                    // onBlur={() => {
                    //   dispatch(setCountryError("country required *"));
                    // }}
                    error={countryError.countryCodeError || countryError.countryValueError}
                    onSelect={(value) => {
                      dispatch(setCountryError(""))
                      setSelectedCode(value?.code);
                      setSelectedValue(value?.label);
                    }}
                  />
                </div>
                <InputField
                  label="Address"
                  placeholder="Enter your Address"
                  type="text"
                  value={address}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setAddress(event?.target.value)}
                  error={addressError ? addressError : ""}
                  onBlur={() => {
                    if (address === "") {
                      dispatch(setAddressError("address is required"))
                    }
                  }}
                />
              </div>
              {/* <section className="images">
                <FileUpload name="Upload of Proof of Address" />
              </section> */}
              <SaveButtonComponent
                // onClick={() => navigate(residenceVerificationStatusLink)}
                onClick={handleSubmit}
                name={props.buttonName}
                loading={loading}
              />
              <StyledButton onClick={() => navigate(-1)} variant="text">
                Cancel
              </StyledButton>
            </Content>
          </BoxDiv>
        </ModaL>
      </Main>
    </>
  );
}

const Main = styled.form``;

const ModaL = styled(Modal)`
  width: 60%;
  margin: auto;
`;

const BoxDiv = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background: #ffffff;
  border-radius: 4px;

  padding: 1.5em;
`;

const Content = styled.form`
  .select {
    position: relative;
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
  .input {
    padding: 1.2em;
  }
  .input_container {
    margin-bottom: 2.5em;
  }
`;
const Text = styled.div`
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.1275rem;
    line-height: 22px;
    color: #00317a;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 19px;
    margin-top: 1em;
    color: #797474;
  }
`;

const StyledButton = styled(Button)`
  && {
    background: #ffffff;
    border: 1px solid #00317a;
    border-radius: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: #00317a;
    width: 100%;
    margin-top: 1em;
    padding: 0.7em;
  }
`;
