import { gql } from "@apollo/client";

export const GET_LAND_DETAILS = gql`
  query CurrentBuildNowPhase {
    currentBuildNowPhase {
      land_verification {
        land_description
        land_floor_area
        land_location
        land_photos
        land_plot_area
        land_title
        status
        timestamp_created
        timestamp_updated
      }
    }
  }
`;
