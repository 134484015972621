import { styled } from "styled-components";
// import bImg from "../../assets/contruct.png";
// import { useNavigate } from "react-router-dom";
import { ChartIconComponent } from "../../utils/ChartBtn";
import { mediaQuery } from "../../breakPoint";

export function ConstructionScreen() {
  //   const navigate = useNavigate();
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>Summary</H1>
              {/* <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P> */}
               <P>No Active Construction ongoing</P>
            </FinanceHeader>
           
            {/* <H2>Media</H2> */}
            {/* <ImageDiv>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <div className="title">Title of the image goes here</div>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <div className="title">Title of the image goes here</div>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <div className="title">Title of the image goes here</div>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <div className="title">Title of the image goes here</div>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <div className="title">Title of the image goes here</div>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <div className="title">Title of the image goes here</div>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <div className="title">Title of the image goes here</div>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <div className="title">Title of the image goes here</div>
              </ImageContent>
            </ImageDiv> */}
          </FinanceContent>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 2em;
  display: flex;
  gap: 5em;
  z-index: 1;
  width: 100%;

  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile}{
    margin-top: 0;
  }
  margin-right: 2em;
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  ${mediaQuery.tablet} {
  }
`;

export const FinanceContent = styled.div`
  width: 65%;
  margin-inline: auto;
  @media (max-width: 1020px) {
    width: 90%;
  }
  ${mediaQuery.tablet} {
    width: 90%;
  }
`;
export const FinanceHeader = styled.div`
margin-top: 3rem;
`;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  width: 496px;
  color: #797474;
  margin-top: 1em;
  line-height: 17px;
  /* width: 100%; */
`;

// const H2 = styled.h2`
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 500;
//   font-size: 1.5rem;
//   line-height: 29px;
//   color: #000000;
//   margin-top: 1.5em;
//   width: 100%;
// `;

// const ImageDiv = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 1.5em;
//   margin-top: 1em;
//   width: 496px;
//   width: 100%;
// `;

// const IMG = styled.img`
//   width: 100%;
//   border-radius: 8px;
// `;

// const ImageContent = styled.div`
//   div {
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 1rem;
//     line-height: 19px;
//     color: #000000;
//   }
// `;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 5em;
  ${mediaQuery.tablet} {
    display: none;
  }
`;
