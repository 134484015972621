import { FC } from "react";
import { Arrow, CircleIcon, GoodMarkIcon } from "../../../utils/svg";
import { styled } from "styled-components";
import { NavLink } from "react-router-dom";
import { financeLink, landDetilsLink, propertyChoiceLink } from "../../../links";
import { useQuery } from "@apollo/client";
import { PHASE1_STATUS } from "../../../api/query/phase1Status";
import { CircularProgress } from "@mui/material";
export const pendingColor = "#DFAE00";

interface SpanProps {
  status: string;
}

export const Phase1: FC = () => {
  const { data, loading } = useQuery(PHASE1_STATUS);

  const allStatus = data?.currentBuildNowPhase; 

  const financeStatus = allStatus?.finance_verification?.status;

  const landDetailsStatus = allStatus?.land_verification?.status;

  const propertyChoiceStatus = allStatus?.property_choice?.status;

  // disable tabs 
  const disableLandDetails = financeStatus === "NONE" || landDetailsStatus === "NONE" || !landDetailsStatus;

  const disablePropertyChoice = landDetailsStatus === "NONE" || propertyChoiceStatus === "NONE" || !propertyChoiceStatus


  return (
    <>
      <PhaseContainer>
        <PhaseHeader>
          <Phase1Button>
            <Arrow />
            <H3>PHASE</H3>
          </Phase1Button>
          <GoodMarkIcon />
        </PhaseHeader>
        <PhaseList>
          <Ul>
            <Span status={financeStatus} end to={financeLink}>
              {loading ? (
                <CircularProgress size={16} />
              ) : financeStatus === "APPROVED" ? (
                <GoodMarkIcon />
              ) : financeStatus === "PENDING" ? (
                <GoodMarkIcon fill={pendingColor} />
              ) : financeStatus === "REJECTED" ? (
                <CircleIcon fill="rgba(239, 0, 0, 1)" />
              ) : (
                <CircleIcon />
              )}
              <LI>Finance verification</LI>
            </Span>
            <Span
              status={landDetailsStatus}
              to={landDetilsLink}
              style={{ pointerEvents: disableLandDetails ? "none" : "auto" }}
            >
              {loading ? (
                <CircularProgress size={16} />
              ) : landDetailsStatus === "APPROVED" ? (
                <GoodMarkIcon />
              ) : landDetailsStatus === "PENDING" ? (
                <GoodMarkIcon fill={pendingColor} />
              ) : landDetailsStatus === "REJECTED" ? (
                <CircleIcon fill="rgba(239, 0, 0, 1)" />
              ) : (
                <CircleIcon className="circleIcon" />
              )}
              <LI>Land details</LI>
            </Span>
            <Span
              status={propertyChoiceStatus}
              style={{ pointerEvents: disablePropertyChoice ? "none" : "auto" }}
              to={propertyChoiceLink}>
              {loading ? (
                <CircularProgress size={16} />
              ) : propertyChoiceStatus === "APPROVED" ? (
                <GoodMarkIcon />
              ) : propertyChoiceStatus === "PENDING" ? (
                <GoodMarkIcon fill={pendingColor} />
              ) : propertyChoiceStatus === "REJECTED" ? (
                <CircleIcon fill="rgba(239, 0, 0, 1)" />
              ) : (
                <CircleIcon className="circleIcon" />
              )}
              <LI>Property choice</LI>
            </Span>
            {/* <Span to={summaryLink} >
              <CircleIcon />
              <LI>Report and Summary</LI>
            </Span> */}
          </Ul>
        </PhaseList>
      </PhaseContainer>
    </>
  );
};

export const PhaseContainer = styled.div`
  margin-top: 2em;
`;

export const PhaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    fill: #33b95d;
  }
`;

export const Phase1Button = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625em;
`;

export const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 22px;
  color: #000000;
`;

export const PhaseList = styled.div`
  margin-top: 1.5em;

  .active {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
`;

export const Ul = styled.ul``;

export const Span = styled(NavLink) <SpanProps>`
  display: flex;
  gap: 1em;
  align-items: center;
  text-align: left;
  margin-bottom: 0.5em;
  padding: 0.875em 1.25em;
  border-radius: 8px;
  width: 100%;
  transition: all 0.1s ease-in;
  text-decoration: none;
  cursor: pointer;

  .active {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
  svg {
    fill: #00317a;
    transition: none !important;
    cursor: pointer;
    /* fill: ${(props) => (props.status === "NONE" ? "#00317a" : "#DFAE00")}; */
  }
  &:hover {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
`;

export const LI = styled.li`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 19px;
  color: #00317a;
`;
