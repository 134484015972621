import {
  // Arrow, 
  CircleIcon, GoodMarkIcon,
  // GoodMarkIcon 
} from "../../../utils/svg";
import { costEquity, exclusivePlanLink, ownership, payment, premuimPlanLink, simplePlanLink } from "../../../links";
import { UseBuildNowServicePlan } from "../../../hooks/useServicePlan";
import { LI, PhaseContainer, PhaseList, Span, Ul } from "./phase2Styles";
import { useGetSimpleBuildingSpecification } from "../../../hooks/buildSpecificationState/simple/useBuildingDocument";
import { CircularProgress } from "@mui/material";
import { pendingColor } from "../phase1";
import { useGetCosting } from "../../../hooks/costing/usegetCosting";
import { useGetOutRightInstallmentPayment } from "../../../hooks/payment/usePayment";
import { useGetPropertyChoice } from "../../../hooks/usePropertyChoice";

const redColor = "rgba(239, 0, 0, 1)"

export function Phase2() {

  const { status: property_choice_status } = useGetPropertyChoice()

  // const selectedPlan = useSelector((state: RootState) => state.selectedPlanReducer.plan)
  const { service_type } = UseBuildNowServicePlan();

  const { status: payments_status, loading: payment_loading } = useGetOutRightInstallmentPayment()

  const { costing, loading: costing_loading } = useGetCosting()

  const buildingSpecification = service_type === "SIMPLE" ? simplePlanLink : service_type === "PREMIUM" ? premuimPlanLink : service_type === "EXCLUSIVE" && exclusivePlanLink

  const { status, loadingBackDrop } = useGetSimpleBuildingSpecification() //  building specification hook to check status 

  const buidlingSpecificationStatus = status

  const isCostingAvailable = costing !== null


  return (
    <>
      <PhaseContainer>
        {/* <PhaseHeader>
          <Phase1Button>
            <Arrow />
            <H3>PHASE2</H3>
          </Phase1Button>
          <GoodMarkIcon />
        </PhaseHeader> */}
        <PhaseList>
          <Ul>
            <Span to={buildingSpecification as string} style={{pointerEvents: property_choice_status !== "APPROVED"? "none": "auto"}}>
              {loadingBackDrop ? (
                <CircularProgress size={16} />
              ) : buidlingSpecificationStatus === "APPROVED" || isCostingAvailable ? (
                <GoodMarkIcon />
              ) : buidlingSpecificationStatus === "PENDING" ? (
                <GoodMarkIcon fill={pendingColor} />
              ) : buidlingSpecificationStatus === "REJECTED" ? (
                <CircleIcon fill={redColor} />
              ) : (
                <CircleIcon />
              )}
              <LI>Building Specification</LI>
            </Span>
            {/* <Span to={premium}>
              <CircleIcon />
              <LI>Select Building Plan </LI>
            </Span> */}
            <Span to={costEquity} style={{ pointerEvents: !isCostingAvailable ? "none" : "auto" }}>
              {costing_loading ? (
                <CircularProgress size={16} />
              ) : payments_status === "PENDING" ? (
                <GoodMarkIcon />
              ) : isCostingAvailable ? (
                <GoodMarkIcon fill={pendingColor} />
              )
                : (
                  <CircleIcon />
                )}
              <LI>Costing and Equity</LI>
            </Span>
            <Span to={payment} style={{ pointerEvents: !payments_status? "none": "auto" }}>
              {payment_loading ? (
                <CircularProgress size={16} />
              ) : payments_status === "PENDING" ? (
                <GoodMarkIcon fill={pendingColor} />
              )
                : (
                  <CircleIcon />
                )}
              <LI>Payment</LI>
            </Span>
            <Span to={ownership} style={{ pointerEvents: "none" }}>
              <CircleIcon />
              <LI>Deed Transfer</LI>
            </Span>
            {/* <Span to="*">
              <CircleIcon />
              <LI>Report</LI>
            </Span> */}
          </Ul>
        </PhaseList>
      </PhaseContainer>
    </>
  );
};


