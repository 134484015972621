import { styled } from "styled-components";
import { EditIcon } from "../../../../../utils/svg";
import { useState } from "react";
import { Button } from "@mui/material";
import { mediaQuery } from "../../../../../breakPoint";

export function FinanceVerification() {
  const [disabledd, setDisabled] = useState<boolean>(true);
  return (
    <>
      <ContactInfoDiv>
        <Content>
          <Header>
            <H2>FINANCE VERIFICATION</H2>
            <EditIcon
              className="edit"
              onClick={() => setDisabled(!disabledd)}
            />
          </Header>
          <Paragragh>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Paragragh>
          <DetailsContainer>
            <DETAILS>
              <H3>EMPLOYMENT STATUS</H3>
              <input
                type="text"
                disabled={disabledd ? true : false}
                defaultValue="Employed"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>employer name</H3>
              <input
                disabled={disabledd ? true : false}
                type="text"
                defaultValue="Chain consults limited"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>job description</H3>
              <input
                disabled={disabledd ? true : false}
                type="text"
                defaultValue="Quality assurance officer"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>work address</H3>
              <input
                disabled={disabledd ? true : false}
                type="text"
                defaultValue="221 Lekki phase 1, Lekki, Lagos"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Salary range</H3>
              <input
                disabled={disabledd ? true : false}
                type="text"
                defaultValue="NGN 500k - NGN 1 million"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>work id</H3>
              <Button variant = "text" size="small">id_front.jpg</Button>
              <Button variant = "text" size="small">id_front.jpg</Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>bank statement (1 year)</H3>
              <Button variant = "text" size="small">gtbank_statement_127483.pdf</Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
        </Content>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  margin-top: 2.375em;
  margin-bottom: 3.5em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;

const Content = styled.div``;

const Header = styled.div`
  /* width: 432px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8125em;
  .edit {
    cursor: pointer;
  }
`;
const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;

  color: #00317a;

`;
const Paragragh = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  width: 432px;
  color: #797474;
  ${mediaQuery.tablet} {
    line-height: 19px;
    width: 100%;
  }
  ${mediaQuery.mobile}{
    font-size: .89rem;
  }
`;

const DetailsContainer = styled.div``;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1em;
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;



