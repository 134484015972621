import { styled } from "styled-components";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { FormEvent, useState } from "react";
import { Box, SelectChangeEvent, Slider } from "@mui/material";
import { InputField } from "../../../../utils/input";
import { SelectInput } from "../../../../utils/select";
import { TextArea } from "../../../../utils/textArea";
import { mediaQuery } from "../../../../breakPoint";
import { useMutation } from "@apollo/client";
import { GET_PROPERTY_CHOICE } from "../../../../api/mutatation/propertyChoice";
import { useNavigate } from "react-router-dom";
import { exclusivePlanLink, premuimPlanLink, simplePlanLink } from "../../../../links";
import AlertDialogSlide from "../../../../utils/dialog";
import { GetPropertyChoiceDetails } from "./propertyChoiceDetails";
import { useGetPropertyChoice, usePropertyChoiceState } from "../../../../hooks/usePropertyChoice";
import { UseBuildNowServicePlan } from "../../../../hooks/useServicePlan";
import { useNotificationToast } from "../../../../hooks/useToast";
import SimpleBackdrop from "../../../../utils/backdrop";

export function PropertyChoice() {

  const { errorToast } = useNotificationToast();

  // ROUTE CONDITIONALLY TO BUILDING SPECIFICATION ACCORDING TO SERVICE TYPE/PLAN SELECTED
  const { service_type } = UseBuildNowServicePlan();

  const buildingSpecification = service_type === "SIMPLE" ? simplePlanLink : service_type === "PREMIUM" ? premuimPlanLink :service_type === "EXCLUSIVE" && exclusivePlanLink

  const navigate = useNavigate();
  const {
    projectTitle,
    setProjectTitile,
    buildingType,
    setBuidldingType,
    units,
    setUnits,
    description,
    setDescription,
    openDialog,
    setOpenDialog,
    postData,
    setPostData
  } = usePropertyChoiceState();

  const { status, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen } = useGetPropertyChoice();

  const hasStatus = status === "PENDING" || status === "APPROVED" || status === "REJECTED";
  const hasNoStatus = status === "NONE" || undefined || !status;

  const buildingTypes = [
    "Duplex",
    // "Apartment",
    // "Townhouse",
    // "Single-Family Home",
    // "Condominium",
    "Bungalow",
    // "Villa",
    // "Mansion",
    // "Cottage",
  ];


  const numberOfUnits = [1, 2 ];

  const landTypeHandleChange = (event: SelectChangeEvent<string>) => {
    setBuidldingType(event?.target.value as string);

  };

  const unitOnchange = (event: SelectChangeEvent<string>) => {

    setUnits(event?.target.value as string);
  };

  //   mui slider value
  const [sliderValue, setSliderValue] = useState<number | string | Array<number | string>>(1);
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue);
  };


  // post request
  const [propertyChoice, { loading }] = useMutation(GET_PROPERTY_CHOICE);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      const { data } = await propertyChoice({
        variables: {
          payload: {
            project_title: projectTitle,
            building_type: buildingType,
            units: units,
            property_description: description,
            bedrooms: sliderValue,
          }
        },
      })

      // setStateData(data)
      // navigate(summaryLink)
      setOpenDialog(true)
      setPostData(data?.message)
    } catch (error) {
      const err: any = error
      errorToast(err?.message || "An Error Occured")
    }
  }

  function closeDialog() {
    setOpenDialog(false)
    navigate(buildingSpecification as string, { replace: true })
  }



  return (
    <>
      {/* success message */}
      <AlertDialogSlide next="Next" handleClose={closeDialog} open={openDialog} details={postData || "Successful!"} />
      {/* LOADING */}
      {loadingBackDrop ?(
      <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />):
      hasNoStatus ? (
        <FinanceContainer>
          <Finance onSubmit={handleSubmit}>
            <FinanceContent>
              <FinanceHeader>
                <H1>PROPERTY CHOICE</H1>
                <P>
                Enter your choice of property you would like to develop on your land
                </P>
              </FinanceHeader>
              <SelfEmplyedMain>
                <FieldContainer>
                  <InputField
                    label="Project title"
                    type="text"
                    placeholder="Enter Here"
                    className="input"
                    value={projectTitle}
                    onChange={(event) => setProjectTitile(event.target.value)}
                  />
                  <SelectInput
                    SelectLabel="Building type"
                    defaultName="Select"
                    valueItems={buildingTypes}
                    items={buildingType}
                    setItems={setBuidldingType}
                    onChange={landTypeHandleChange}
                  />
                  <SelectInput
                    SelectLabel="Number of units"
                    defaultName="Select"
                    valueItems={numberOfUnits}
                    items={units}
                    setItems={setUnits}
                    onChange={unitOnchange}
                  />
                  <Boxs>
                    <Label>{sliderValue} Bedroom</Label>
                    <Slider
                      value={typeof sliderValue === "number" ? sliderValue : 0}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                      max={7}
                    />
                  </Boxs>
                  <TextArea
                    label="Description"
                    placeholder="Property Description"
                    onchange={(event) => setDescription(event.target.value)}
                    value={description}
                  />
                </FieldContainer>
              </SelfEmplyedMain>
            </FinanceContent>
            <SaveButtonComponent type="submit" loading={loading} className="btn" name="Save and continue" />
          </Finance>
          <ChartConponentDiv>
            <ChartIconComponent />
          </ChartConponentDiv>
        </FinanceContainer>) :
        hasStatus && (
          <FinanceContainer>
            <GetPropertyChoiceDetails buildingSpecification={buildingSpecification} />
          </FinanceContainer>)}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  margin-left: 2em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    justify-content: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.form`
  ${mediaQuery.tablet} {
    width: 100%;
  }
  ${mediaQuery.mobile} {
    /* width: 95%; */
  }

  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
    }
  }
`;

const FinanceContent = styled.div`
  margin-bottom: 2.375em;
`;
const FinanceHeader = styled.div``;
const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 1em;
  @media (max-width: 682px) {
    font-size: 18px;
  }
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;
const SelfEmplyedMain = styled.div``;

const FieldContainer = styled.div`
  .input {
    ${mediaQuery.tablet} {
      width: 100% !important;
    }
  }
`;
// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;

const Boxs = styled(Box)`
  margin-top: 2.375em;
`;

const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;

  color: #000000;
`;
