import { useState } from "react";
import { styled } from "styled-components";
import eye from "../../assets/eye.png";
import slash from "../../assets/eye-slash.png";
import { PasswordProps } from "../../types";

export function PasswordInput(props: PasswordProps) {
  const [showPassword, setShowPassWord] = useState<boolean>(false);

  return (
    <>
      <PasswordDiv>
        <Label>{props.label}</Label>
        <InputDiv>
          <Input
            type={showPassword ? "text" : "password"}
            placeholder="Enter here"
            value={props.value}
            onChange={props.onChange}
            required
            onBlur={props.onBlur}
          />
          {showPassword && (
            <IMG onClick={() => setShowPassWord(false)} src={eye} />
          )}
          {!showPassword && (
            <IMG onClick={() => setShowPassWord(true)} src={slash} />
          )}
        </InputDiv>
        <ValidationLabel >
          {props.error}
        </ValidationLabel>
      </PasswordDiv>
    </>
  );
}

export const PasswordDiv = styled.div`
  margin-top: 1.3125em;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.8em;
`;

export const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #828282;
  margin-bottom: 0.56em;
`;

export const InputDiv = styled.div`
  width: 100%;
  border: 0.5px solid #999999;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;
export const Input = styled.input`
  /* width: 100%; */
  padding: 1.5em 1.125em;
  height: 100%;
  border-radius: 8px;
  width: 90%;
  border: none;
  outline: none;
  font-size: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
`;

const IMG = styled.img`
  cursor: pointer;
  margin-right: .8em;
`;


 const ValidationLabel = styled.label`
margin-left: .6em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: rgba(206, 43, 43, 1);
  font-size: .9rem;
  margin-top: .5em;
  `
