import { gql } from "@apollo/client";

export const OUT_RIGHT_INSTALLMENT_PAYMENT = gql`
  query Payment {
    currentBuildNowPhase {
      payment {
        plan
        details {
          ... on OutrightPaymentDetails {
            virtual_id
            bank_payment_info {
              account_name
              account_number
              bank_name
            }
            proof_of_payment
          }
          ... on InstallmentalPaymentDetails {
            user_bank_info {
              account_name
              account_number
              bank_name
              bank_code
            }
            bvn
            payment_bank_details {
              virtual_id
              bank_payment_info {
                account_name
                account_number
                bank_name
              }
              proof_of_payment
              payment_details {
                total_cost
                upfront_cost
                monthly_cost
              }
            }
          }
        }
        status
        createdon
      }
    }
  }
`;
