import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components";

type AutocompleteProps = {
    options?: string[] | any
    onChange?: any;
    value?: string
    label?: string
}

export function AutoComplete(props: AutocompleteProps) {
  return (
    <>
      <AutoCompleteStyle
        disablePortal
        onChange={props.onChange}
        id="combo-box-demo"
        options={props.options}
        value={props.value}
        renderInput={(params) => <TextField {...params} label={props.label} />}
      />
    </>
  );
}


export const AutoCompleteStyle = styled(Autocomplete)`
    
`
