import { styled } from "styled-components";
import { mediaQuery } from "../../breakPoint";
import { ChangeEvent, MouseEvent, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { SaveButtonComponent } from "../../utils/buttons/saveButton";
// import { ChartIconComponent } from "../../utils/ChartBtn";
import {
  GoodMarkIcon, PremiumIcon,
  //  SimplePlanIcon 
} from "../../utils/svg";
import { buildFinancialVerificationLink, premiumFinancialVerificationLink } from "../../links";
import { useNavigate } from "react-router-dom";

enum ServiceType {
  Simple = "SIMPLE",
  Premium = "PREMIUM",
  Exclusive = "EXCLUSIVE",
}
export function NewAid() {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState<ServiceType | null>(
    null
  ); // selected state

  const handleServiceSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedService(event.target.value as ServiceType);
  };

  // check value slected and route accordingly when proceed button is clicked
  async function proceedHandlick(event: MouseEvent<HTMLElement>) {
    switch (selectedService) {
      case "SIMPLE":
        return navigate(buildFinancialVerificationLink);
      case "PREMIUM":
        return navigate(premiumFinancialVerificationLink);
      default:
        return null;
    }
  }
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>Build Later</H1>
              <P>
                For customers who cant afford to pay 40% development cost up front, Build Later helps you commence development and pay the 40% in instalmentally
              </P>
            </FinanceHeader>
            <SelfEmplyedMain>
              {/* <SimplePlan isselected={selectedService === ServiceType.Simple}>
                <Radio
                  type="radio"
                  value={ServiceType.Simple}
                  checked={selectedService === ServiceType.Simple}
                  onChange={handleServiceSelect}
                />
                <SimplePlanContent>
                  <SimplePlanIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.Simple}>
                      Custom Aid
                    </H2>
                    <SimplePlanDetails
                      isselected={selectedService === ServiceType.Simple}
                    >
                      Upload your own building plan and building permit, system
                      check and if its verified you proceed to the next phase
                    </SimplePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </SimplePlanContent>
              </SimplePlan> */}
              <SimplePlan isselected={selectedService === ServiceType.Premium}>
                <Radio
                  type="radio"
                  value={ServiceType.Premium}
                  checked={selectedService === ServiceType.Premium}
                  onChange={handleServiceSelect}
                />
                <SimplePlanContent>
                  <PremiumIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.Premium}>
                      Premium Aid
                    </H2>
                    <SimplePlanDetails
                      isselected={selectedService === ServiceType.Premium}
                    >
                     Select from our Premium Building plans, and pay the 40% upfront development cost over the next 2 years and the balance 60% over an extra 3 years
                    </SimplePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </SimplePlanContent>
              </SimplePlan>
            </SelfEmplyedMain>
          </FinanceContent>
          <BtnDiv>
            <SaveButtonComponent
              className="btn"
              name="Save and continue"
              onClick={proceedHandlick}
            />
          </BtnDiv>
        </Finance>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
margin-top: 2em;
width: 70%;
margin-left: 20em;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    margin: auto;
    width: 85%;
  }
  ${mediaQuery.mobile} {
    margin: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
margin-top: 2em;
width: 80%;
margin-inline: auto;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`;

export const FinanceContent = styled.div`
width: 100%;
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  margin-top: 1em;
  width: 70%;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;
const SelfEmplyedMain = styled.div`
  margin-top: 2.375em;
  display: flex;
  gap: 2em;
`;

const SimplePlan = styled.label<{ isselected: boolean }>`
  /* height: 160px; */
  border: 1px solid #0084fe;
  border-radius: 4px;
  /* width: 70%; */
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  ${mediaQuery.tablet} {
    width: 100%;
    margin-top: 1rem;
  }
  ${mediaQuery.mobile} {
    padding: 1em;
  }

  cursor: pointer;
  background: ${(props) => (props.isselected ? "rgba(0, 49, 122, 1)" : "")};
  svg {
    fill: ${(props) => (props.isselected ? "#fff" : "#00317a")};
    ${mediaQuery.tablet} {
      width: 29.27px;
      min-width: 10% !important;
    }
  }

  &:hover {
    background: rgba(0, 49, 122, 1);
    p,
    h3 {
      color: #fff !important;
    }
    transition: none;
    svg {
      fill: #fff;
      transition: none;
    }
  }
`;
const Radio = styled.input.attrs({
  type: "radio",
})`
  visibility: hidden;
`;
const SimplePlanContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  ${mediaQuery.mobile} {
    gap: 1em;
  }
  .goodmark {
    height: 56.686790466308594px;
    width: 42px;
    border-radius: 0px;
    ${mediaQuery.mobile} {
      width: 22.77px;
    }
  }
`;
const Icon = styled.div`
  svg {
    fill: #fff;
  }
`;

const Text = styled.div`
  width: 60%;
  ${mediaQuery.tablet} {
    width: auto;
  }
`;
const H2 = styled.h3<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  margin-bottom: 0.5em;
  ${mediaQuery.mobile} {
    font-size: 12.8077px;
    line-height: 16px;
  }
  color: ${(props) => (props.isselected ? "#fff" : "#0084fe")};
`;
const SimplePlanDetails = styled.p<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 15px;
  color: ${(props) => (props.isselected ? "#fff" : "#797474")};
  ${mediaQuery.mobile} {
    font-size: 0.75rem;
    line-height: 15px;
    width: 100%;
  }
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 70%; */
  ${mediaQuery.tablet} {
    width: 100% !important;
  }
  .btn {
    padding: 1em !important;
    ${mediaQuery.tablet} {
    }
  }
`;

// const ChartConponentDiv = styled.div`
//   position: fixed;
//   right: 6em;
//   ${mediaQuery.tablet} {
//     right: 2em;
//     bottom: 1.408125em;
//   }
//   ${mediaQuery.mobile} {
//     right: 1em;
//     bottom: 1em;
//   }
// `;
