import { styled } from "styled-components";
import progressIcon from "../../../../../../../../assets/time.png";
import { useNavigate } from "react-router-dom";
import { SaveButtonComponent } from "../../../../../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../../../../../utils/ChartBtn";
import { mediaQuery } from "../../../../../../../../breakPoint";
import { nonExisingProgress } from "../../../../../../../../links";

export function NonExistingPlanRequestCustomPlanProgress() {
  const navigate = useNavigate();
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <img src={progressIcon} alt="" />
            <FinanceHeader>
              <H1>PLAN DEVELOPMENT IN PROGRESS</H1>
              <P>
                Your Plan choice is currently under review, you would be
                contacted for clarifications where necessary, once your plan is
                approved you can proceed
              </P>
            </FinanceHeader>
          </FinanceContent>
          <SaveButtonComponent
          className="btn"
            name="Talk to us"
            onClick={() => navigate(`/${nonExisingProgress}`)}
          />
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;

  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  width: 55%;
  margin-left: 15em;
  ${mediaQuery.tablet} {
    width: 100%;
    margin: auto;
    margin-inline: auto;
    justify-content: center;
  }
  ${mediaQuery.mobile} {
    margin: auto auto 5em;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
  .btn {
    padding: 1em !important;
   ${mediaQuery.tablet} {
      width: 100%;
      
    }
  }
`;
export const Finance = styled.div`
margin-inline: auto;
width: 100%;
 ${mediaQuery.tablet} {
    margin: 2em;
  
  }
  
`;

const FinanceContent = styled.div`
  margin-bottom: 2.375em;
  text-align: center;
  margin-top: 3.75em;
  width: 100%;
`;
const FinanceHeader = styled.div`
  margin-top: 2em;
`;
const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  text-align: center;
  color: #000000;
  ${mediaQuery.mobile} {
    width: 100%;
  }
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 19px;
  /* width: 400px; */
  /* height: 53px; */
  text-align: center;
  color: #797474;
  margin-top: 1em;
  ${mediaQuery.mobile} {
    width: 100%;
  }
`;
// const SelfEmplyedMain = styled.div``;

// const FieldContainer = styled.div``;
// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
