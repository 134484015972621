import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ResidentialProps {
    country: {
        name: string;
        code: string;
    }
    address: string;
    residential_status: string;
    createdon: string;
    
}

const initialState: ResidentialProps = {
    country: {
        name: "",
        code: ""
    },
    address: "",
    residential_status: "",
    createdon: "",
}

const residentialSlice = createSlice({
    name:'residential',
        initialState,
        reducers: {
            setResidentialVerificationStatus: (state, action: PayloadAction<ResidentialProps>) =>{
                state.address = action.payload.address;
                state.country = action.payload.country;
                state.residential_status = action.payload.residential_status;
                state.createdon = action.payload.createdon;
            }
        }
})


export const {setResidentialVerificationStatus} = residentialSlice.actions;

export default residentialSlice.reducer