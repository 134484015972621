import { styled } from "styled-components";
import { mediaQuery } from "../../../../../breakPoint";
import { AlertIconSvg, CopyIcon } from "../../../../../utils/svg";
import remita from "../../../../../assets/Remita-Logo 1.png"
import { DownloadBtnComponent } from "../../../../../utils/buttons/downloadBtn";

export function MandateGenerationDetails() {
  const price = 2500000;
  // const monthlyPay = 1250000.0;
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>MANDATE GENERATION</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <Amount>
              <H2>
                <Sup>NGN</Sup>
                {price.toLocaleString()}
                <Sup>.00</Sup>
              </H2>
            </Amount>
          </FinanceContent>
          <Details>
            <div className="details_container">
              <div className="contents">
                <div className="text">
                  <span className="account">Account name</span>
                  <p className="name">John Doe</p>
                </div>
                <div className="copy">
                  <span>copy</span>
                  <CopyIcon />
                </div>
              </div>
              <div className="line"></div>
              <div className="contents">
                <div className="text">
                  <span className="account">Account number</span>
                  <p className="name">John Doe</p>
                </div>
                <div className="copy">
                  <span>copy</span>
                  <CopyIcon />
                </div>
              </div>
              <div className="line"></div>
              <div className="contents">
                <div className="text">
                  <span className="account">Bank name</span>
                  <p className="name">John Doe</p>
                </div>
                <div className="copy">
                  <span>copy</span>
                  <CopyIcon />
                </div>
              </div>
              <div className="line"></div>
              <div className="contents">
                <div className="text">
                  <span className="account">Bank sort code</span>
                  <p className="name">1234</p>
                </div>
                <div className="copy">
                  <span>copy</span>
                  <CopyIcon />
                </div>
              </div>
              <div className="line"></div>
            </div>
          </Details>
        </Finance>
        <Alert>
          <div className="alert_container">
            <AlertIconSvg/>
            <div className="text">
              <p className="paragraph">
                Download the document below and take it to your bank to activate
                the lawful debit of NGN 416,000 from your bank account monthly.
              </p>
            </div>
            <div className="img">
            <img src={remita} alt="" />
            </div>
          </div>
        </Alert>
        <DownloadBtnComponent name="Mandate" variant="text" className="download_btn" />
      </FinanceContainer>
    </>
  );
}

const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 1.8em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  justify-content: center;
  align-items: center;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    margin-top: 0;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }

  .download_btn{
    width: 45%;
  }
`;

const Finance = styled.div`
  width: 80%;
  margin-inline: auto;
  ${mediaQuery.tablet} {
    width: 100%;
    margin: auto;
  }
  .btn {
    margin-top: 2em;
    padding: 1em !important;
  }
`;

const FinanceContent = styled.div`
  width: 100%;
`;

const FinanceHeader = styled.div``;

const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 20px;
  height: 53px;
  color: #797474;
  ${mediaQuery.mobile} {
    font-size: 0.875rem;
    line-height: 19px;
    width: 100%;
  }
`;

const Amount = styled.div`
  text-align: center;
  margin-top: 2em;
`;
const H2 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 65px;
  color: #000000;
  margin-bottom: 0.5em;
  margin-top: 0.2em;
  ${mediaQuery.tablet} {
    /* font-size: 2.5rem; */
  }
`;

const Sup = styled.sup`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  ${mediaQuery.tablet} {
    font-size: 0.9rem;
  }
`;

const Details = styled.div`
  .details_container {
    .contents {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 0.8em;
      margin-top: 0.8em;
      .text {
        .account {
          color: #828282;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .copy {
        display: flex;
        align-items: center;
        gap: 0.5em;
      }
    }
    .line {
      border: 0.5px solid #a6a6a6;
      width: 100%;
    }
  }
`;

const Alert = styled.div`
  border-radius: 10px;
  border: 1px solid #0084fe;
  background: rgba(0, 132, 254, 0.1);
  width: 80%;
  padding: 1.2em;
  .alert_container {
    text-align: center;
    .text {
      p {
        color: #0084fe;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 166.023%;

      }
    }
    .img{
        text-align: center;
        margin-top: 1.8em;
    }
  }
`;
