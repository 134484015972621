
import {
  Paper,
  TableBody,
  TableContainer,
  // TableHead,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import { styled } from "styled-components";

export default function TableStripe() {
  const rows = [
    {
      id: 1,
      section: "Upfront Paymet (40%)",
      amount: 1000000,
    },
    {
      id: 2,
      section: "Mothly Contribution",
      amount: 4000000,
    },
    {
      id: 2,
      section: "Payment Duration",
      amount: "2 Years",
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        {/* <TableHeadStyle >
          <TableRow>
            <TableCell className="table_head_cell">Section</TableCell>
            <TableCell className="table_head_cell">Material</TableCell>
            <TableCell className="table_head_cell">Information</TableCell>
          </TableRow>
        </TableHeadStyle> */}
        <TableBodyStyled>
          {rows.map((row) => (
            <TableRowStyled key={row.id}>
              <TableCell  >{row.section}</TableCell>
              <TableCell>{row?.amount?.toLocaleString()}</TableCell>
            </TableRowStyled>
          ))}
        </TableBodyStyled>
      </Table>
    </TableContainer>
  );
}


// const TableHeadStyle = styled(TableHead)`
// background: #eaeaea;
  
//   .table_head_cell {
//     font-family: "Inter";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 1rem;
//     width: 10%;
//     line-height: 19px;
//     color: #000000;
//   }
// `;

const TableBodyStyled = styled(TableBody)`

`

const TableRowStyled = styled(TableRow)`
    &:nth-of-type(even){
        background: #eaeaea;
    }
 
`
