import { FormControl, FormLabel, TextareaAutosize } from "@mui/material";
import { ChangeEventHandler } from "react";
import { styled } from "styled-components";

type textAreaProps = {
  label: string;
  placeholder: string
  value?: string;
  onchange?: ChangeEventHandler<HTMLTextAreaElement>;
}
export function TextArea(props: textAreaProps) {
  return (
    <TextAreaDiv>
      <Formcontrol>
        <Label>{props.label}</Label>
        <TextAreaMain required value={props.value} onChange={props.onchange} placeholder={props.placeholder} minRows={8} />
      </Formcontrol>
    </TextAreaDiv>
  );
}


const TextAreaDiv = styled.div`
  margin-top: 2em;
  @media (max-width: 440px) {
  width: 100%;
}
`;

const Formcontrol = styled(FormControl)`
  position: relative;
  width: 100%;
  /* z-index: 1; */
`;

const Label = styled(FormLabel)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 19px;
  margin-bottom: 0.8em;
  color: #828282;
`;

const TextAreaMain = styled(TextareaAutosize)`
  background: #ffffff;
  border: 0.5px solid #999999;
  border-radius: 8px;
  font-family: "Inter";
  padding: 0.8em;
  font-size: 1em;
`;






