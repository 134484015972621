import { styled } from "styled-components";

import { SaveButtonComponent } from "../../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../../utils/ChartBtn";
// import { BasicInfo } from "./basicInfo";
import { FinanceVerification } from "./financeVerification";
import { CustomAlert } from "../../../../../utils/customPlanAlert";
import { mediaQuery } from "../../../../../breakPoint";
import { ChoiceSummary } from "./choice";

export function PropertyChoiceSummary() {
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <AlertContainer>
              <CustomAlert
                className="payment_status"
                status="Verified"
                date="March 10, 2023"
              />
            </AlertContainer>
            {/* <FinanceHeader>
              <H1>SUMMARY</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader> */}
            <SelfEmplyedMain>
              <FieldContainer>
                {/* BASIC INFO */}
                {/* <BasicInfo /> */}
                {/* FINANCE VERIFICATION */}
                <FinanceVerification />
                {/* LAND DETAILS */}
                <ChoiceSummary />
              </FieldContainer>
            </SelfEmplyedMain>
          </FinanceContent>
          <BtnDiv>
            <SaveButtonComponent className="btn" name="Save and continue" />
          </BtnDiv>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  position: relative;
  /* margin-left: 2em; */
  width: 100%;
  margin-top: 2em;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;

const AlertContainer = styled.div`
  margin-top: 1.5em;
  margin-bottom: 2em;
  ${mediaQuery.tablet} {
    width: 100%;
    margin-top: 0em;
  }
  .payment_status {
    padding: 0.875em 1.1875em;
    background: rgba(51, 185, 93, 0.1);
    border: 1px solid #33b95d;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #33b95d;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;

      color: #33b95d;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;

      color: #33b95d;
    }
  }
`;
export const Finance = styled.div`
  ${mediaQuery.tablet} {
    width: 100%;
    margin-inline: auto;
  }

  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
      width: 90%;
    }
  }
`;

export const FinanceContent = styled.div`
  ${mediaQuery.tablet} {
    margin: auto;
  }
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  ${mediaQuery.tablet} {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  margin-top: 1em;
  ${mediaQuery.mobile} {
    font-size: 14px;
    line-height: 19px;
    width: 100%;
  }
`;
const SelfEmplyedMain = styled.div``;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;

  ${mediaQuery.tablet} {
    margin: 2.375em auto 3.5em;
    width: 100%;
  }

  @media (max-width: 450px) {
    /* width: 90%; */
  }
`;

const FieldContainer = styled.div``;
// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;
