import { useMemo, useState } from "react";
import { QUERY_PROPERTY_CHOICE } from "../api/query/getPropertyChoice";
import { useQuery } from "@apollo/client";
import { PropertyChoice } from "../api/types";

export const usePropertyChoiceState = () =>{
    const [projectTitle, setProjectTitile] = useState<string>("");
    const [buildingType, setBuidldingType] = useState<string>("");
    const [units, setUnits] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [postData, setPostData] = useState<string[] | string>("");

    return {
        projectTitle, 
        setProjectTitile,
        buildingType, 
        setBuidldingType,
        units, 
        setUnits,
        description, 
        setDescription,
        openDialog, 
        setOpenDialog,
        postData, 
        setPostData
    }
}


export const useGetPropertyChoice = () =>{
    const {data,loading, error} = useQuery(QUERY_PROPERTY_CHOICE);

    const propertyChoiceDetails: PropertyChoice = data?.currentBuildNowPhase?.property_choice;

    const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

    const handleBackDropClose = () => setLoadingBackDrop(false);

    const handleBackDropOpen = () => setLoadingBackDrop(true);

    useMemo(() => {

        (loading) && handleBackDropOpen();

        (!loading) && handleBackDropClose();

    }, [loading]);

    return {...propertyChoiceDetails,error, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen}
}