import { styled } from "styled-components";
import { ChangeEvent, useState } from "react";
type Props = {
  navAppearance?: boolean;
};
enum ServiceType {
  Simple = "simple",
  Premium = "premium",
  Exclusive = "exclusive",
}
export function TrackConstrunctionMobile(props: Props) {
  const [selectedService, setSelectedService] = useState<ServiceType | null>(
    null
  ); // selected state
 
  const handleServiceSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedService(event.target.value as ServiceType);
  };    
  return (
    <>
    <Main>
      <ProjectDiv>
        <ProjectContainer>
          <ProjecHeader>
            <H3>TRACK CONSTRUCTION </H3>
          </ProjecHeader>
          <SimplePlan isselected={selectedService === ServiceType.Simple}>
            {/* <Radio
              type="radio"
              value={ServiceType.Simple}
              checked={selectedService === ServiceType.Simple}
              onChange={handleServiceSelect}
            /> */}
            <SimplePlanContent>
              <Text>
                <H2 isselected={selectedService === ServiceType.Simple}>
                  Miscellenous
                </H2>
                <SimplePlanDetails
                  isselected={selectedService === ServiceType.Simple}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliq
                </SimplePlanDetails>
              </Text>
              <Icon></Icon>
            </SimplePlanContent>
          </SimplePlan>
          <SimplePlan isselected={selectedService === ServiceType.Simple}>
            <Radio
              type="radio"
              value={ServiceType.Simple}
              checked={selectedService === ServiceType.Simple}
              onChange={handleServiceSelect}
            />
            <SimplePlanContent>
              <Text>
                <H2 isselected={selectedService === ServiceType.Simple}>
                  Painting
                </H2>
                <SimplePlanDetails
                  isselected={selectedService === ServiceType.Simple}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliq
                </SimplePlanDetails>
              </Text>
              <Icon></Icon>
            </SimplePlanContent>
          </SimplePlan>
          <SimplePlan isselected={selectedService === ServiceType.Simple}>
            <Radio
              type="radio"
              value={ServiceType.Simple}
              checked={selectedService === ServiceType.Simple}
              onChange={handleServiceSelect}
            />
            <SimplePlanContent>
              <Text>
                <H2 isselected={selectedService === ServiceType.Simple}>
                  Laying of Tiles
                </H2>
                <SimplePlanDetails
                  isselected={selectedService === ServiceType.Simple}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliq
                </SimplePlanDetails>
              </Text>
              <Icon></Icon>
            </SimplePlanContent>
          </SimplePlan>
          <SimplePlan isselected={selectedService === ServiceType.Simple}>
            <Radio
              type="radio"
              value={ServiceType.Simple}
              checked={selectedService === ServiceType.Simple}
              onChange={handleServiceSelect}
            />
            <SimplePlanContent>
              <Text>
                <H2 isselected={selectedService === ServiceType.Simple}>
                  Electrical and Plumbing
                </H2>
                <SimplePlanDetails
                  isselected={selectedService === ServiceType.Simple}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliq
                </SimplePlanDetails>
              </Text>
              <Icon></Icon>
            </SimplePlanContent>
          </SimplePlan>
          <SimplePlan isselected={selectedService === ServiceType.Simple}>
            <Radio
              type="radio"
              value={ServiceType.Simple}
              checked={selectedService === ServiceType.Simple}
              onChange={handleServiceSelect}
            />
            <SimplePlanContent>
              <Text>
                <H2 isselected={selectedService === ServiceType.Simple}>
                  Fixing of Doors and Windows
                </H2>
                <SimplePlanDetails
                  isselected={selectedService === ServiceType.Simple}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliq
                </SimplePlanDetails>
              </Text>
              <Icon></Icon>
            </SimplePlanContent>
          </SimplePlan>
        </ProjectContainer>
      </ProjectDiv>
      
    </Main>
  </>
  );
}

const Main = styled.div`
  display: flex;
  justify-content: space-between;
    
`;
const ProjectDiv = styled.div`
  /* width: 30%; */
  max-width: 366px;
  /* height: 80vh; */
  padding: 2em;
  /* position: fixed; */
  overflow-y: scroll;
  /* left: 22em; */
  margin-top: 1em;
  /* border-right: 0.5px solid #7d7d7d; */
  /* -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 25px 0px rgba(170, 168, 168, 0.75); */
  border-radius: 5px;
`;

const ProjectContainer = styled.div`
 
`;

const ProjecHeader = styled.div`
margin-bottom: 2em;
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: .5em;
`;

// const OutleatMain = styled.div`
//   margin-left: 50em;
//   margin: auto;
// `;


const SimplePlan = styled.label<{ isselected: boolean }>`
  /* height: 160px; */
  border: 1px solid #33b95d;
  border-radius: 4px;
  /* width: 45%; */
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  cursor: pointer;
  background: ${(props) => (props.isselected ? "#fff" : "")};
  color: #33b95d;
  svg {
    fill: ${(props) => (props.isselected ? "#fff" : "#33B95D")};
  }

  &:hover {
    background: #33b95d;
    p,
    h3 {
      color: #fff !important;
    }
    transition: none;
    svg {
      fill: #fff;
      transition: none;
    }
  }
`;
const Radio = styled.input.attrs({
  type: "radio",
})`
  visibility: hidden;
`;
const SimplePlanContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  .goodmark {
    height: 56.686790466308594px;
    width: 42px;
    border-radius: 0px;
  }
`;
const Icon = styled.div`
  svg {
    fill: #fff;
  }
`;

const Text = styled.div`
  
`;
const H2 = styled.h3<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  margin-bottom: 0.5em;
  color: ${(props) => (props.isselected ? "#33B95D" : "#33B95D")};
`;
const SimplePlanDetails = styled.p<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 15px;
  color: ${(props) => (props.isselected ? "#33B95D" : "#33B95D")};
`;



