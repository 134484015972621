import { gql } from "@apollo/client";

export const GET_PREMIUM_BUILDING_LIST = gql`
  query BuildingPlanList($page: Int, $limit: Int, $sort: SortType) {
    buildingPlanList(page: $page, limit: $limit, sort: $sort) {
      results {
        bathrooms
        bedrooms
        toilets
        id
        cover_photos {
          src
          mimetype
        }
        plan_name
        plan_description
      }
      total_plans
      page
      limit
      pages
    }
  }
`;

export const GET_PREMIUM_BUILDING_DETAILS = gql`
  query BuildingPlanDetails($id: String) {
    buildingPlanDetails(id: $id) {
      bathrooms
      bedrooms
      toilets
      MFA
      MPA
      cover_photos {
        src
        mimetype
      }
      floor_plans {
        src
        mimetype
      }
      id
      plan_description
      plan_type
      superstructure_cost
      substructure_cost
      permit_cost
      land_verification_cost
      plan_name
    }
  }
`;
