// auth
const createAccount = "/create-account";
const login = "/login";
const otp = "/otp";
const password = "/set-password";
const resetPassword = "/reset-password";
const forgotPassword = "/forgot-password";

// nested protected routes
const nestNavigationRoute = "/";
// component
// Dashboard
const dashboard = "dashboard";
// profile dropdown
const profile = "profile";
// profile link
const profileLink = "/profile";
// kyc
const kyc = "kyc-verifcation";
const kycVerificationLink = "/kyc-verification";
// verify bvn
const bvn = "kyc-verifcation/bvn";
const bvnLink = "bvn";
// status
const BvnStatus = "kyc-verifcation/bvn-status";
const BvnStatusLink = "/kyc-verifcation/bvn-status";

// id card
const uploadID = "kyc-verifcation/upload-id";
const uploadIDlINK = "upload-id";
// status
const verificationStatus = "kyc-verifcation/id";
const verificationStatusLink = "/kyc-verifcation/id";
// phone number
const addPhone = "kyc-verifcation/add-phone";
const addPhoneLink = "/kyc-verifcation/add-phone";
// status
const phoneVerification = "kyc-verifcation/phone-verification";
const phoneVerificationLink = "/kyc-verifcation/phone-verification";
// residence verification
const residenceVerification = "kyc-verifcation/residence";
const residenceVerificationLink = "/kyc-verifcation/residence";
// status
const residenceVerificationStatus = "kyc-verifcation/residence-status";
const residenceVerificationStatusLink = "/kyc-verifcation/residence-status";
// kyc ends
// 2FA
const FA = "2FA-verifcation";
// NESTED Initialize project
const initializeProject = "initialize-construction";
// phase1
const landDetails = "land-details";
const financeVerification = "finance-verification";
const propertyChoice = "property-choice";
const summary = "summary";
// phase 1 links
const financeLink = "/initialize-construction";
const landDetilsLink = "/initialize-construction/land-details";
const propertyChoiceLink = "/initialize-construction/property-choice";
const summaryLink = "/initialize-construction/summary";
// phase2
const servicePlan = "service-plan";
// premium
const premium = "building-plan";
const premuimPlanLink = "/initialize-construction/building-plan";
// plan
const plan = "/initialize-construction/building-plan/details/:id";
const planDetailsLink = "/initialize-construction/building-plan/details";
const proceed = "proceed";
const proceedLink = "/initialize-construction/proceed";

// simple
const simple = "simple-plan";
const simplePlanLink = "/initialize-construction/simple-plan";

// exclusive
const exclusivePlan = "custom-plan";
const exclusivePlanLink = "/initialize-construction/custom-plan";
const progressPlan = "progress";
const progressPlanLink = "/initialize-construction/progress";
const customPlanVerifcation = "custom-status";
const customPlanVerifcationLink = "/initialize-construction/custom-status";

// exclusive plan
const exclusivePlanDetails = "exclusive-plan";
const noExistingCustomPlan = "no-existing-custom-plan";
// non existing plan development progress 
const nonExisingProgress = "non-existing-plan-development-progress";
// service type ends

// cost and equity
const costEquity = "cost-equity";
const costEquityLink = "/initialize-construction/cost-equity"
// installment
const installment = "pay-installment";
const mortgage = "mortgage-payment";

// transfer of ownership
const ownership = "transfer-ownership";

// payment
const payment = "payment";
const installmentPaymentDetails = "installmental-payment-details";
const installmentPaymentDetailsLink = "/initialize-construction/installmental-payment-details"


// contruction track
const construction = "construction";

// build Aid
const buildAid = "build-later";
// custom aid
// finance verification
const buildAidFinanceVerification = `${buildAid}/custom-aid`;
const buildFinancialVerificationLink = `/${buildAid}/custom-aid`;
// property choice
const buildAidPropertyChoice = "property-choice";
const buildAidPropertyChoiceDetails = "property-choice/property-choice-details";
const buildAidPropertyChoiceDetailsLink = `${buildFinancialVerificationLink}/${buildAidPropertyChoice}/property-choice-details`;


// mandate
const mandateGeneration = "mandate-generation";
const mandateDetails = `${buildFinancialVerificationLink}/${mandateGeneration}/mandate-details`

// history
const paymentHistory = "payment-history";

// premium Aid
const premiumAid = `${buildAid}/premium-aid`;
const premiumFinancialVerificationLink = `/${buildAid}/premium-aid`;

const buildLaterLandDetails = "land-details"
const buildAidbuildLaterLandDetailsLink = `/${premiumAid}/${buildLaterLandDetails}`
// property choice
const premuimAidPropertyChoice = "property-choice";
const propertyChoiceDetails = `/${buildAid}/property-choice-details`;

// mandate
const premiumMandateGeneration = "mandate-generation";

// history
const premiumPaymentHistory = "payment-history";

export {
  createAccount,
  otp,
  login,
  password,
  resetPassword,
  nestNavigationRoute,
  forgotPassword,
  // initializeProject
  initializeProject,
  // dashboard
  dashboard,
  // profile
  profile,
  profileLink,
  // phase1
  landDetails,
  financeVerification,
  propertyChoice,
  summary,
  // phase1 link
  financeLink,
  landDetilsLink,
  propertyChoiceLink,
  summaryLink,
  // phase 2
  servicePlan,
  // premium
  premium,
  premuimPlanLink,
  plan,
  planDetailsLink,
  proceed,
  proceedLink,
  // simple
  simplePlanLink,
  simple,
  // exclusive
  exclusivePlan,
  exclusivePlanLink,
  progressPlan,
  progressPlanLink,
  customPlanVerifcation,
  customPlanVerifcationLink,
  // const and equity
  costEquity,
  costEquityLink,
  ownership,
  payment,
  
  // installment
  installmentPaymentDetails,
  installmentPaymentDetailsLink,
  installment,
  // mortagete
  mortgage,
  // kyc
  kycVerificationLink,
  kyc,
  bvn,
  bvnLink,
  // bvn status
  BvnStatus,
  BvnStatusLink,
  // id
  uploadID,
  uploadIDlINK,
  // status
  verificationStatus,
  verificationStatusLink,
  // phone
  addPhone,
  addPhoneLink,
  // status
  phoneVerification,
  phoneVerificationLink,
  // residence
  residenceVerification,
  residenceVerificationLink,
  // status
  residenceVerificationStatus,
  residenceVerificationStatusLink,
  // KYC END
  FA,

  // exclusive plann 
  exclusivePlanDetails,
  noExistingCustomPlan,
  nonExisingProgress,

  // BUILD AID
  buildAid,
  buildAidFinanceVerification,
  // link
  buildFinancialVerificationLink,
  // prperty choice
  buildAidPropertyChoice,
  buildAidPropertyChoiceDetails,
  buildAidPropertyChoiceDetailsLink,
  // LAND DETAILS 
  buildAidbuildLaterLandDetailsLink,
  buildLaterLandDetails,

  // mandate
  mandateGeneration,
  mandateDetails,
  // history
  paymentHistory,

  // premium aid
  premiumAid,
  premiumFinancialVerificationLink,
  // property choice
  premuimAidPropertyChoice,
  propertyChoiceDetails,
  premiumMandateGeneration,
  premiumPaymentHistory,

  // construction
  construction,
};
