import { styled } from "styled-components";

import { SaveButtonComponent } from "../../../../../utils/buttons/saveButton";
import { mediaQuery } from "../../../../../breakPoint";
import { InputField } from "../../../../../utils/input";
import { useNavigate } from "react-router-dom";
import { FormEvent, useEffect, useMemo, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useMutation } from "@apollo/client";
import { POST_INSTALLMENT_PAYMENT } from "../../../../../api/mutatation/phase2Plan/installmentalPayMent";
import { AutoComplete } from "../../../../../components/autocomplete";
import { useBankList } from "../../../../../hooks/useBankList";
import { useGetLookUpService } from "../../../../../hooks/useBankLookup";
import { installmentPaymentDetailsLink } from "../../../../../links";

export function InstallmentPayment() {
  useEffect(() => {
    AOS.init();
  }, []);
  const navigate = useNavigate();


  const { bankOptions } = useBankList() // hook the fetched the bank list 


  const [bankName, setBankName] = useState<string>("")
  const [accountNumber, setAccountNumber] = useState<string>("")
  const [bvn, setBvn] = useState<string>("")
  const [selectedBankCode, setCode] = useState<string>("")


  const [installmental, { loading }] = useMutation(POST_INSTALLMENT_PAYMENT)

  const onChangeBankSelectList = (event: any, newValue: { label: string; bankCode: string }) => {

    setBankName(newValue?.label)
    setCode(newValue?.bankCode)
  }

  // BANK VERIFICATION 
  const { accountName, bankValidation, successToast, errorToast } = useGetLookUpService()     // bank verification hook 

  const enterAccountDetails = accountNumber.length === 10 && bankName !== "" // check if the bank name and account number is inputed

  // const isBankValidated = accountNumber.length === 10 && bankName !== "" && accountName === ""

  useMemo(() => {
    if (enterAccountDetails) {
      bankValidation({
        account_number: accountNumber, bank_code: selectedBankCode
      })
    }
    
// eslint-disable-next-line
  }, [accountNumber, selectedBankCode,enterAccountDetails])
  // console.log(lookUpLoading)



  async function submitInstallmentData(event: FormEvent) {
    event.preventDefault();

    try {
      const { data } = await installmental({
        variables: {
          payload: {
            "payment_details": {
              "account_name": accountName,
              "account_number": accountNumber,
              "bank_code": selectedBankCode,
              "bank_name": bankName,
              "bvn": bvn
            },
            "plan": "INSTALLMENTAL"
          }
        }
      })
      if(data){
        successToast("Successfully!")
      }

      navigate(installmentPaymentDetailsLink)

    } catch (error) {
      const err: any = error;
      // console.error("Error creating account:", error);
      errorToast(err?.message || "An error occurred");
      // console.error('Error creating account:', error);
    }
  }




  return (
    <>
      <FinanceContainer>
        <Finance onSubmit={submitInstallmentData}>
          <FinanceContent>
            <FinanceHeader>
              <H1
                // data-aos="fade-center"
                data-aos-duration="500"
                data-aos-easing="ease-in"
              >
                Installmental Payment
              </H1>
              <P
                // data-aos="fade-center"
                data-aos-duration="600"
                data-aos-easing="ease-in"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <SelectInputDiv>
              <FieldContainer>
                <div
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-easing="ease-in"
                >
                  {/* <InputField
                    label="Bank Name"
                    type="text"
                    placeholder="Enter Here"
                    className="input"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  /> */}
                  <AutoComplete label="Select Bank" value={bankName} onChange={onChangeBankSelectList} options={bankOptions} />
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in"
                >
                  <InputField
                    label="Account Number"
                    type="text"
                    placeholder="Enter Here"
                    className="input"
                    maxlength="10"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-easing="ease-in"
                >
                  <InputField
                    label="Account Name"
                    type="text"
                    placeholder="Enter Here"
                    className="input"
                    value={accountName}
                    disabled={true}
                  />
                  {/* {lookUpLoading &&(<span>.....</span>)} */}
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  data-aos-easing="ease-in"
                >
                  <InputField
                    label="BVN"
                    type="tel"
                    placeholder="Enter Here"
                    className="input"
                    value={bvn}
                    onChange={(e) => setBvn(e.target.value)}
                  />
                </div>
              </FieldContainer>
            </SelectInputDiv>
          </FinanceContent>
          <SaveButtonComponent
            data-aos="fade-up"
            data-aos-duration="1800"
            data-aos-easing="ease-in"
            className="btn"
            loading = {loading}
            name="Save and continue"
            type="submit"
          />
        </Finance>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 4em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  /* margin-left: 2em; */
  margin-inline: auto;
  width: 100%;
  [data-aos] {
    opacity: 0;
    transition-property: opacity;
  }

  [data-aos].aos-animate {
    opacity: 1;
  }
  ${mediaQuery.tablet} {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.form`
  margin-inline: auto;
 &&{
   width: 100%;
 }
  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
      width: 100%;
    }
  }
`;

export const FinanceContent = styled.div`
  margin-bottom: 3em;
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 1em;
  ${mediaQuery.tablet} {
    font-size: 18px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  width: 90%;
  ${mediaQuery.mobile} {
    width: 100%;
  }
`;

const SelectInputDiv = styled.div``;

const FieldContainer = styled.div`
  .input {
    ${mediaQuery.tablet} {
      width: 100% !important;
    }
  }
`;
