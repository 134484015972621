import { useQuery } from "@apollo/client";
import { GET_BANK_LIST } from "../api/query/banklist";
import { useMemo, useState } from "react";

export function useBankList (){
    const [bankOptions, setBankOptions] = useState([]);
    const {data, loading} = useQuery(GET_BANK_LIST)

    const bankList = data?.bankList;
    

    useMemo(() =>{
        if(bankList && bankList.length > 0){
            setBankOptions(bankList?.map((bank: any) =>({
                bankCode: bank?.bankCode,
                label: bank?.bankName,

            })))
        }
    },[bankList,setBankOptions])

    return {bankList,bankOptions, loading}
}