import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { GET_SIMPLE_BUILDING_SPECIFICATION } from "../../../api/query/servicePlan/simple";
import { SimpleBuildingSpecification } from "../../../api/types";

export const useSimplePlanUploadBuildingDocumentStates = () => {
    const [structuralDrawing, setStructuralDrawing] = useState<string[]>([]);
    const [mechanicalDrawing, setMechanicalDrawing] = useState<string[]>([]);
    const [architecturalDrawing, setArchitecturalDrawing] = useState<string[]>([]);
    const [buildingPermit, setBuildingPermit] = useState<string[]>([]);
    const [electricalDrawing, setElectricalDrawing] = useState<string[]>([]);
    const [surveyPlan, setSurveyPlan] = useState<string[]>([]);

    const [uploadBuildingDocumentLoadingState, setUploadBuildingDocumentLoadingState] = useState<boolean>(false);
    const [uploadBuildingDocumentData, setUploadBuildingDocumentData] = useState<string[] | string>([]);

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    return {
        structuralDrawing,
        setStructuralDrawing,
        mechanicalDrawing,
        setMechanicalDrawing,
        architecturalDrawing,
        setArchitecturalDrawing,
        buildingPermit,
        setBuildingPermit,
        electricalDrawing,
        setElectricalDrawing,
        surveyPlan,
        setSurveyPlan,

        // loading
        uploadBuildingDocumentLoadingState,
        setUploadBuildingDocumentLoadingState,
        // data after post
        uploadBuildingDocumentData,
        setUploadBuildingDocumentData,
        openDialog,
        setOpenDialog,
    };
};

export const useGetSimpleBuildingSpecification = () => {
    const { data, loading } = useQuery(GET_SIMPLE_BUILDING_SPECIFICATION)

    const simpleBuildingSpecificationDetails : SimpleBuildingSpecification =  data?.currentBuildNowPhase?.building_specification

    // backdrop loadinng funnction 
    const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

    const handleBackDropClose = () => setLoadingBackDrop(false);

    const handleBackDropOpen = () => setLoadingBackDrop(true);

    useMemo(() => {

        (loading) && handleBackDropOpen();

        (!loading) && handleBackDropClose();

    }, [loading]);

    return { loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen,...simpleBuildingSpecificationDetails,data }
};
