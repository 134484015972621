import { styled } from "styled-components";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { SelfEmployed } from "./selfEmployed";
import { FormEvent, useState } from "react";
import {
  SelectChangeEvent,
} from "@mui/material";
import { Employed } from "./employed";
import { EmploymentStatusSelect } from "../../../../utils/employmentStatusSelect";
import { mediaQuery } from "../../../../breakPoint";
import { useNavigate } from "react-router-dom";
import { landDetilsLink } from "../../../../links";
// import { GetFinanceVerificationDetails } from "./getFinanceVerificationDetails";

// hook 
import { useFinanceVerification, useGetFinnanceVerification } from "../../../../hooks/useFinanceVerification";
import { GetFinanceVerificationDetails } from "./getFinanceVerificationDetails";
import SimpleBackdrop from "../../../../utils/backdrop";
import apiFetchClient from "../../../../apiProvider/axiosInstance";
import { useNotificationToast } from "../../../../hooks/useToast";
import { AuthBtn } from "../../../../utils/buttons/authBtn";
import AlertDialogSlide from "../../../../utils/dialog";

export function FinanceVerification() {
  const navigate = useNavigate();
  const {
    EmployementStatus,
    setEmploymentStatus,
    financeData,
    setFinanceData,

    // SLEF EMPLOYED
    jobDescription,
    setJobDescription,
    averageEarning,
    setAverageEarning,
    companyReg,
    setCompanyReg,

    // image file
    personalBankStatements,
    setPersonalBankStatements,
    companyBankStatements,
    setCompanyBankStatements,
    formCo2,
    setFormCo2,
    certificateRegistration,
    setCertificationRegistration,
    // selft emplyed ends ÷

    // EMPLOYED
    employerName,
    setEmployerName,
    employedJobDescription,
    setEmployedJobDescription,
    workAddress,
    setWorkAddress,
    salary,
    setSalary,

    // image file
    employedUserBankStatements,
    setEmployedUserBankStatements,

    // work id
    employedUserWorkIDFront,
    setEmployedUserWorkIDFront,
    employedUserWorkIDBack,
    setEmployedUserWorkIDBack,
    // emplyed user ends ÷
  } = useFinanceVerification()

  const { status, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen } = useGetFinnanceVerification()     // get financeverifiaction status from hook 

  const { errorToast } = useNotificationToast()

  const hasStatus = status === "PENDING" || status === "APPROVED" || status === "REJECTED"
  const hasNoStatus = status === "NONE" || undefined || !status;

  // success boolean state 
  const [isPostSuccessful, setIsPostSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const valueItems = ["Employed", "Self Employed"];

  // employment status select onchange
  const handleChange = (event: SelectChangeEvent<string>) => {
    if (setEmploymentStatus) {
      setEmploymentStatus(event?.target.value as string);
    }
  };


  // close dialog and navigate to landDetails page 
  async function postFinanceVerificationRequest(event: FormEvent) {
    event.preventDefault()
    try {
      setLoading(true)

      const formData = new FormData();

      // for selfEmployed user only
      if (EmployementStatus === "Self Employed") {
        personalBankStatements.forEach((file) => formData.append("bank_statement", file));

        companyBankStatements.forEach((file) => formData.append("company_bank_statement", file));

        formCo2.forEach((file) => formData.append("CO2", file));

        certificateRegistration.forEach((file) => formData.append("COR", file));

        formData.append("employment_status", "SELF_EMPLOYED");

        formData.append("employment_details", JSON.stringify({
          job_description: jobDescription,
          salary: averageEarning,
          company_reg_number: companyReg,
        }));
      }

      // for emplyed users only
      if (EmployementStatus === "Employed") {
        employedUserBankStatements.forEach((file) => formData.append("bank_statement", file));

        employedUserWorkIDFront.forEach((file) => formData.append("work_id", file));

        employedUserWorkIDBack.forEach((file) => formData.append("work_id", file));

        formData.append("employment_status", "EMPLOYED");

        formData.append("employment_details", JSON.stringify({
          employer_name: employerName,
          job_description: employedJobDescription,
          work_address: workAddress, salary: salary,
        }));
      }

      const response = await apiFetchClient.post("/construction/build-now/finance-verification", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      )
      setLoading(false);

      setFinanceData(response?.data?.message);

      setIsPostSuccess(true);

    } catch (error:any) {
      const errorMessage  = error?.response?.data?.message

      const err = Array.isArray(errorMessage)

      err? errorToast(errorMessage[0]) : errorToast(errorMessage || "An error occurred");
      
      setLoading(false);
    }
    // navigate(landDetilsLink);
  }

    // close dialog and navigate to landDetails page 
    function closeDialog() {
      setIsPostSuccess(false)
      navigate(landDetilsLink);
    }


  return (
    <>
      {/* success message */}
      <AlertDialogSlide next="Next Land Details" handleClose={closeDialog} open = {isPostSuccessful} details = {financeData}/>
      {/* loading */}
      {loadingBackDrop ?(
      <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />):
      hasNoStatus ?
        (<FinanceContainer>
          <Finance onSubmit={postFinanceVerificationRequest}>
            <FinanceContent>
              <FinanceHeader>
                <H1>FINANCE VERIFICATION</H1>
                <P>
                  Please provided your financial information and work details to proceed
                </P>
              </FinanceHeader>
              <SelectInputDiv>
                <EmploymentStatusSelect
                  SelectLabel="Employment status"
                  defaultName="select"
                  valueItems={valueItems}
                  setEmploymentStatus={setEmploymentStatus}
                  handleChange={handleChange}
                  EmployementStatus={EmployementStatus}
                />
              </SelectInputDiv>
              {EmployementStatus === "Self Employed" && (
                <SelfEmployed
                  averageEarning={averageEarning}
                  setAverageEarning={setAverageEarning}
                  setJobDescription={setJobDescription}
                  jobDescription={jobDescription}
                  companyReg={companyReg}
                  setCompanyReg={setCompanyReg}
                  // image upload
                  setPersonalBankStatements={setPersonalBankStatements}
                  setCompanyBankStatements={setCompanyBankStatements}
                  setFormCo2={setFormCo2}
                  setCertificationRegistration={setCertificationRegistration}
                />
              )}
              {EmployementStatus === "Employed" && (
                <Employed
                  employerName={employerName}
                  setEmployerName={setEmployerName}
                  employedJobDescription={employedJobDescription}
                  setEmployedJobDescription={setEmployedJobDescription}
                  workAddress={workAddress}
                  setWorkAddress={setWorkAddress}
                  salary={salary}
                  setSalary={setSalary}
                  // image file
                  setEmployedUserBankStatements={setEmployedUserBankStatements}
                  // work id
                  setEmployedUserWorkIDFront={setEmployedUserWorkIDFront}
                  setEmployedUserWorkIDBack={setEmployedUserWorkIDBack}
                />
              )}
            </FinanceContent>
            <AuthBtn
              loading={loading}
              className="btn"
              name="Next"
            />
          </Finance>
          <ChartConponentDiv>
            <ChartIconComponent />
          </ChartConponentDiv>
        </FinanceContainer>)
        :
        hasStatus && (
          <FinanceContainer>
            <GetFinanceVerificationDetails />
          </FinanceContainer>
        )}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 2em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  /* margin-left: 2em; */
  margin-inline: auto;
  width: 100%;
  ${mediaQuery.tablet} {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.form`
  margin-inline: auto;
  width: 90%;
  ${mediaQuery.tablet} {
    width: 100%;
  }
  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
    }
  }
`;

export const FinanceContent = styled.div``;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 1em;
  ${mediaQuery.tablet} {
    font-size: 18px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  ${mediaQuery.mobile} {
    width: 100%;
  }
`;

const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
