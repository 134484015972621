import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { DropDownIcon } from "../../../../../utils/svg";
import { ChartIconComponent } from "../../../../../utils/ChartBtn";
import { CustomAlert } from "../../../../../utils/customPlanAlert";
import { useUserKyc } from "../../../../../hooks/useUserKyc";
import { mediaQuery } from "../../../../../breakPoint";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { useDateMoment } from "../../../../../hooks/useMomentDate";
import { useMemo } from "react";
import SimpleBackdrop from "../../../../../utils/backdrop";
import { useNotificationToast } from "../../../../../hooks/useToast";

export function ResidentVerificationStatus() {

  const navigate = useNavigate();
  const { errorToast } = useNotificationToast()

  const {
    error,
    country,
    address,
    residential_status,
    createdon,
    loadingBackDrop,
    setLoadingBackDrop,
    handleBackDropClose,
    handleBackDropOpen
  } = useUserKyc();   // hooks

  const { convertDate, formattedDate } = useDateMoment()

  const statusDetails = useSelector((state: RootState) => state.residentialSlice);  // redux useSelector

  const postRequestResponseStatus = statusDetails?.residential_status

  const countryName = country?.name?.toLocaleLowerCase();

  const countryCode = country?.code && country?.code?.toUpperCase();

  const status = postRequestResponseStatus || residential_status;

  const date = createdon || statusDetails?.createdon;   // convert date 

  useMemo(() => convertDate({ dateString: date }), [convertDate, date,])

  error && errorToast(error?.message ?? "An Error Occured");

  return (
    <>
      {/* loading */}
      <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <DropDownIcon
                className="drop_down"
                onClick={() => navigate("/kyc-verifcation")}
              />
              <H1>KYC</H1>
            </FinanceHeader>
            <SelfEmplyedMain>
              <VerifyAccount>
                <H3>Verification Status</H3>
                {/* <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Text> */}
              </VerifyAccount>
            </SelfEmplyedMain>
            <AlertContainer>
              <CustomAlert
                className={
                  status === "PENDING" ?
                    "pending_status" :
                    status === "REJECTED" ?
                      "rejected_status" :
                      status === "APPROVED" ?
                        "verified" : "pending_status"
                }
                status={status}
                date={formattedDate ?? "loading Date"}
              />
            </AlertContainer>
          </FinanceContent>
          <div className="details">
            <div className="address">
              <p>Address:</p>
              <p className="value">{address && address?.toUpperCase()}</p>
            </div>
            <div className="address">
              <p>Country:</p>
              <p className="value">{countryName}</p>
            </div>
            <div className="address">
              <p>Country Code:</p>
              <p className="value">{countryCode}</p>
            </div>
          </div>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
 margin-bottom: 5em;
  display: flex;
  gap: 5em;
  margin-top: 2em;
  margin-left: 22em;
  width: 100%;
  padding: 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    margin: 4.5em auto 2em;
  }
  ${mediaQuery.mobile}{
    margin: 1em auto
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
  width: 80%;
  padding: 0em 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    width: 90%;
  }

  .details{
    .address{
      display: flex;
      align-items: center;
      margin-top: 2em;
      justify-content: space-between;
      border-bottom: .5px solid #b1acac;
      padding-bottom: .8rem;
      .value{
        color: #797474;
        font-size: 16px;
        font-family: Inter;
        font-weight: 300;
      }
    }
  }
`;

export const FinanceContent = styled.div`
 
`;
export const FinanceHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  gap: 1em;
  .drop_down {
    transform: rotate(90deg);
    cursor: pointer;
  }
`;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
`;
const SelfEmplyedMain = styled.div`
  margin-top: 3em;
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;

const VerifyAccount = styled.div`
  margin-bottom: 2em;
`;

const H3 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1275rem;
  line-height: 30px;
  margin-bottom: 0.4em;
  color: #00317a;
`;

// const Text = styled.p`
//   /* width: 60%; */
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 300;
//   font-size: 1rem;
//   line-height: 19px;
//   color: #797474;
//   ${mediaQuery.tablet} {
//     width: 100%;
//     margin-top: 1em;
//   }
// `;

const AlertContainer = styled.div`
  margin-top: 3.375em;
  .verified {
    padding: 0.875em 1.1875em;
    background: rgba(51, 185, 93, 0.1);
    border: 1px solid #33b95d;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #33b95d;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;

      color: #33b95d;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;

      color: #33b95d;
    }
  }
  .pending_status {
    padding: 0.875em 1.1875em;
    background: rgba(223, 174, 0, 0.1);
    border: 1px solid #dfae00;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #dfae00;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: #dfae00;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #dfae00;
    }
  }
  .rejected_status {
    padding: 0.875em 1.1875em;
    background: rgba(206, 43, 43, 0.13);
    border: 1px solid #ce2b2b;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #ce2b2b;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: #ce2b2b;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #ce2b2b;
    }
  }
`;
