import { styled } from "styled-components"
import { DownloadIcon } from "../../svg"
import { Button } from "@mui/material"
import { DownloadBtnProps } from "../../../types"

export function DownloadBtnComponent (props:DownloadBtnProps){
    return(
        <>
        <DownloadBtn className={props.className} variant={props.variant} endIcon = {<DownloadIcon/>} >Download {props?.name}</DownloadBtn>
        </>
    )
}

const DownloadBtn = styled(Button)`
&&{
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
color: #0084FE;
text-transform: none;
background: #FFFFFF;
border: 1.2px solid #0084FE;
border-radius: 29px;
@media (max-width: 682px) {
  width: 100%;
}
}
    svg{
        fill: #0084FE;
    }
`