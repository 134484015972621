import { styled } from "styled-components";
import { Button } from "@mui/material";
import { mediaQuery } from "../../../../../../../breakPoint";
import { useGetSimpleBuildingSpecification } from "../../../../../../../hooks/buildSpecificationState/simple/useBuildingDocument";

export function SimplePlanItems() {
  const {
    status,
    structural_drawing,
    mechanical_drawing,
    architectural_drawing,
    electrical_drawing,
    building_permit,
    survey_plan,
  } = useGetSimpleBuildingSpecification();

  return (
    <>
      <ContactInfoDiv>
        <Content>
          <DetailsContainer>
            <DETAILS>
              <H3>STATUS</H3>
              <H2 status={status}>{status}</H2>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          {/* <DetailsContainer>
            <DETAILS>
              <H3>Service Type</H3>
              <p>{service_type}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer> */}
          <DetailsContainer>
            <DETAILS>
              <H3>Structural Drawing</H3>
              <Button href={structural_drawing?.src} variant="text" size="small" download>
                Structural Drawing
              </Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Mechanical Drawing</H3>
              <Button href={mechanical_drawing?.src} variant="text" size="small" download>
                Mechanical Drawing
              </Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Architectural Drawing</H3>
              <Button href={architectural_drawing?.src} variant="text" size="small" download>
                Architectural Drawing
              </Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Electrical Drawing</H3>
              <Button href={electrical_drawing?.src} variant="text" size="small" download>
                Electrical Drawing
              </Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Builing Permit</H3>
              <Button href={building_permit?.src} variant="text" size="small" download>
                Building Permit
              </Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Survey Plan</H3>
              <Button href={survey_plan?.src} variant="text" size="small" download>
                Survey Plan
              </Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
        </Content>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  // margin-top: 2.375em;
  margin-bottom: 3.5em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;

const Content = styled.div``;

const DetailsContainer = styled.div``;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1em;
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #2c2c2c;
    text-transform: capitalize;
  }
  .btn {
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;

export const H2 = styled.h2<{ status: string }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  color: ${({ status }) =>
    status === "APPROVED" ? "#33B95D;" : status === "PENDING" ? "#DFAE00;" : status === "REJECTED" ? "#CE2B2B;" : "#00317a"};
`;
