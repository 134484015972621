import { gql } from "@apollo/client";

export const RESIDENTIAL_VERIFICATION = gql`
mutation VerifyResidence($payload: ResidenceVerificationDto!) {
  verifyResidence(payload: $payload) {
    country {
      code
      name
    }
    address
    residential_status
  }
}
`;
