import { FormEvent, useState } from "react";
import { styled } from "styled-components";
import { ConbuildLogo } from "../../../utils/svg";
import bg from "../../../assets/passwordbg.png";
// import bgR from "../../../assets/bgright.png";
import { AuthBtn } from "../../../utils/buttons/authBtn";
import {  useNavigate } from "react-router-dom";
import { PasswordInput } from "../../../utils/input/passwordInput";
import { useMutation } from "@apollo/client";
import { GET_FORGOT_PASSWORD } from "../../../api/mutatation/forgotPassword";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setConfirmPasswordError, setPasswordError } from "../../../slice/Validation";
import { useNotificationToast } from "../../../hooks/useToast";


export function ForgotPassword() {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { errorToast, successToast } = useNotificationToast();  // toast hook

  const { passwordError, confirmPasswordError } = useSelector((state: RootState) => state.validationReducer);

  const [password, setPassword] = useState<number | string>("");

  const [confirmPassword, setConfirmPassword] = useState<number | string>("");

  const [resetPasswordUpdate, { loading }] = useMutation(GET_FORGOT_PASSWORD);

  
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault(); 

   
    if(password !== confirmPassword) {
       return dispatch(setPasswordError("Passwords do not match"))
      };   // password 

    (password === "") && dispatch(setPasswordError("Password is required *"));  // password 

     (confirmPassword === "") && dispatch(setConfirmPasswordError("Confirm Password is required *"));    // confirm password 

     (confirmPassword === "") && dispatch(setConfirmPasswordError("required *"));   // confirm password 


    try {
      const token = localStorage.getItem("conbuild_otp_token");

      const { data } = await resetPasswordUpdate({ variables: { payload: { "password": confirmPassword, }},context:{headers: {Authorization: `Bearer ${token}`,},}});

      if(data){
        // const token = data?.finaliseCreateAccount.access_token
        // localStorage.setItem("conbuild_token", token)
        localStorage.removeItem("conbuild_otp_token");

        navigate("/")
      }
      successToast( data?.message || "Successful!")

    } catch (error) {

      const err: any = error

      errorToast(err?.message || "An error has occurred")

      // console.error('Error creating account:', error);
    }
  }







  return (
    <>
    {/* {error &&(<Alert severity="error"><AlertTitle>Error</AlertTitle>{error?.message}</Alert>)} */}
      <CREATEACCOUNT>
        <CreateAccountMain>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <CreateAccountInputForm onSubmit={handleSubmit}> 
            <CreateAccountContent>
              <ConbuildLogo />
              <HeaderText>
                <Register>Forgot Password</Register>
                <P>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </P>
              </HeaderText>
              <InputContainers>
               <PasswordInput
                  value={password} 
                  onChange={(event) => {
                    dispatch(setPasswordError(""))
                    setPassword(event.target.value)
                  }}

                  error={passwordError ? passwordError : ""}

                  onBlur={() => {
                    if (password === "" || !password) {
                      dispatch(setPasswordError("Password is required. *"))
                    }
                  }}
                  label="Password"
                />
                <PasswordInput
                  error={confirmPasswordError ? confirmPasswordError : ""}
                  value={confirmPassword}
                  onChange={(event) => {
                    dispatch(setConfirmPasswordError(""))
                    setConfirmPassword(event.target.value)
                  }}

                  onBlur={() => {
                    if (confirmPassword === "" || !confirmPassword) {
                      dispatch(setConfirmPasswordError("required. *"))
                    }
                  }}

                  label="Re-type password"
                />
              </InputContainers>
                <AuthBtn loading = {loading} name="Submit" />
              {/* <LineContainer>
                <Line></Line>
                <Or>Or</Or>
                <Line></Line>
              </LineContainer>
              <ImageGoogle src={google} /> */}
            </CreateAccountContent>
          </CreateAccountInputForm>
          <BackgroundImage src={bg} />
          {/* <BackgroundImageRight src={bgR} /> */}
        </CreateAccountMain>
      </CREATEACCOUNT>
    </>
  );
};

export const CREATEACCOUNT = styled.div`
  @media (min-width: 1020px) {
    background: #c6c6c6;
    height: 100%;
    min-height: 100vh;
    position: relative;
  }
`;

export const CreateAccountMain = styled.div`
  padding: 1em;
`;

export const BackgroundCircle = styled.div``;

export const HeaderText = styled.div`
  /* width: 96px; */
  max-width: 80%;
  margin-top: 1.836em;
`;

export const Register = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #6F5DE7;
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  color: #797474;
  margin-top: 1em;
`;

export const CreateAccountInputForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2em;
  /* width: 90%; */
`;

export const CreateAccountContent = styled.div`
  width: 100%;
  padding: 1.5em;
  @media (min-width: 600px) {
    width: 530px;
    margin-top: 8.5em;
  }
  @media (min-width: 900px) {
    width: 500px;
    margin-top: 6em;
  }
  @media (min-width: 1020px) {
    width: 500px;
    margin-top: 2.5em;
    background: #fff;
    border-radius: 3px;
    padding: 2em;
    z-index: 1;
  }
`;

export const InputContainers = styled.div`
  margin: auto;
`;

export const LineContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
`;

export const Line = styled.div`
  border: 0.5px solid #6F5DE7;
  width: 45%;
`;

export const Or = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

export const ImageGoogle = styled.img`
  width: 100%;
  margin-top: 1.3em;
  cursor: pointer;
`;

export const BackgroundImage = styled.img`
  display: none;
  @media (min-width: 1020px) {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const BackgroundImageRight = styled.img`
  display: none;
  @media (min-width: 1020px) {
    width: 40%;
    display: block;
    position: absolute;
    right: 0;
    bottom: 4em;
  }
`;

// ROUTER LINK
// const LINK = styled(Link)`
//   text-decoration: none;
// `;
