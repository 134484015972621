import { styled } from "styled-components";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { DropDownIcon } from "../../../../utils/svg";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ResidentialVerificationModal } from "../../../../components/modals/residentialVerificationModal";
import { mediaQuery } from "../../../../breakPoint";
import { residenceVerificationStatusLink } from "../../../../links";
import { useUserKyc } from "../../../../hooks/useUserKyc";
import SimpleBackdrop from "../../../../utils/backdrop";
import { ResidentVerificationStatus } from "./residentialStatus";


export function ResidentialVerification() {

  const navigate = useNavigate();

  const { residential_status, refetch, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen } = useUserKyc()   // hooks 

  const [openModal, isModalOpen] = useState<boolean>(false);

  const checkStatus = residential_status === "PENDING" || residential_status === "REJECTED" || residential_status === "APPROVED"

  const noResidentialStatus = residential_status === "NONE" || !residential_status

  // useEffect(() => { refetch() }, [])
  const memoizedRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    memoizedRefetch();
  }, [memoizedRefetch]);

  useEffect(() => {
    // refetch()
    if (checkStatus) {
      navigate(residenceVerificationStatusLink, { replace: true })
    }

  }, [navigate, checkStatus]);

  return (
    <>
      {loadingBackDrop ? (
        <SimpleBackdrop
          setOpen={setLoadingBackDrop}
          loadingBackDrop={loadingBackDrop}
          handleClose={handleBackDropClose}
          handleOpen={handleBackDropOpen}
        />) :
        noResidentialStatus ? (
          <FinanceContainer>
            <Finance>
              <FinanceContent>
                <FinanceHeader>
                  <DropDownIcon
                    className="drop_down"
                    onClick={() => navigate(-1)}
                  />
                  <H1>KYC</H1>
                </FinanceHeader>
                <SelfEmplyedMain>
                  <VerifyAccount>
                    <H3>Residential Verification</H3>
                    <Text>
                      Please verify your place of residence, for Non-Nigerian residents, please add your valid address and country of Residence
                    </Text>
                  </VerifyAccount>
                </SelfEmplyedMain>
                <SaveButtonComponent
                  onClick={() => isModalOpen(true)}
                  name="Update Residential Information"
                  className="update_bvn_btn"
                />
              </FinanceContent>
              {openModal && (
                <ResidentialVerificationModal
                  buttonName="Save"
                  // description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  // do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  // name="Residential Verification"
                  onClose={() => isModalOpen(false)}
                  open={openModal}
                  setOpen={isModalOpen}
                />
              )}
            </Finance>
            <ChartConponentDiv>
              <ChartIconComponent />
            </ChartConponentDiv>
          </FinanceContainer>
        ) : <ResidentVerificationStatus />}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  margin-top: 2em;
  margin-left: 22em;
  width: 100%;
  padding: 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    margin: 4.5em auto 2em;
  }
  ${mediaQuery.mobile}{
    margin: 1em auto
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
  width: 80%;
  padding: 0em 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    width: 90%;
  }
`;

export const FinanceContent = styled.div`
  .update_bvn_btn {
    padding: 1em !important;
    ${mediaQuery.tablet} {
      font-size: 0.8rem;
    }
  }
`;
export const FinanceHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  gap: 1em;
  .drop_down {
    transform: rotate(90deg);
    cursor: pointer;
  }
`;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
`;
const SelfEmplyedMain = styled.div`
  margin-top: 3em;
  @media (max-width: 682px) {
    margin-bottom: 5em;
  }
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  @media (max-width: 682px) {
    right: 2em;
    bottom: 1.408125em;
  }
`;

const VerifyAccount = styled.div`
  margin-bottom: 2em;
`;

const H3 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1275rem;
  line-height: 30px;
  margin-bottom: 0.4em;
  color: #00317a;
`;

const Text = styled.p`
  /* width: 60%; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;

  color: #797474;
`;
