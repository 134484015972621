import React, { useEffect, useMemo, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RouterProvider } from "react-router-dom";
import { router } from "./ROUTE";
// import { routerAuth } from "./ROUTE/authLayout/authRoot";
import { Backdrop, CircularProgress } from "@mui/material";
import { NetworkError } from "./pages/networkError";
import { SnackbarProvider } from "notistack";


function App() {
  const [, setAccess_token] = useState<string | null>(null);
  const [networkError, setNetworkError] = useState(false);
  const [loading, setLoading] = useState(true);

  useMemo(() => {
    const token = localStorage.getItem("conbuild_token");
    setAccess_token(token);
    
    setLoading(false);
  }, [setAccess_token,setLoading]);

  // network
  useEffect(() => {
    const handleOnline = () => {
      setNetworkError(false);
      window.location.reload(); // Refresh the page when the network connection is restored
    };

    const handleOffline = () => {
      setNetworkError(true);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <SnackbarProvider >
      <AnimatePresence>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {networkError ? (
              <NetworkError />
            ) : (
              <RouterProvider router={router} />
            )}
          </LocalizationProvider>
        )}
      </AnimatePresence>
    </SnackbarProvider>
  );
}

export default App;
