import { gql } from "@apollo/client";

export const CREATE_ACCOUNT_MUTATION = gql`
  mutation CreateAccount($payload: RegisterUserDto!) {
    createAccount(payload: $payload) {
      access_token
    }
  }
`;

export const CREATEPASSWORD = gql`
  mutation FinaliseCreateAccount($payload: FinalizeRegisterUserDto!) {
    finaliseCreateAccount(payload: $payload) {
      access_token
    }
  }
`;

export const LOGIN = gql`
  mutation LoginAccount($payload: LoginUserDto!) {
    login(payload: $payload) {
      access_token
    }
  }
`;

export const OTP = gql`
  mutation VerifyOTP($payload: AuthOTP!) {
    verifyOTP(payload: $payload)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($payload: ResetPasswordDto!) {
    resetPassword(payload: $payload) {
      access_token
    }
  }
`;

export const RESEND_OTP = gql`
  query ResendOTP {
    resendOTP
  }
`;