import { styled } from "styled-components";
import { Button } from "@mui/material";
import { useGetFinnanceVerification } from "../../../../../../../hooks/useFinanceVerification";

export function SelfEmployedDetails() {
    const { employment_details } = useGetFinnanceVerification();

    const selfEmployedDetails = employment_details?.__typename === "IsSelfEmployed" && employment_details;

    return (
        <>
            <DetailsContainer>
                <DETAILS>
                    <H3>Company Bank Statement</H3>
                    <Button
                        href={
                            selfEmployedDetails ? selfEmployedDetails?.company_bank_statement?.src : ""
                        }
                        variant="text"
                        size="small"
                        download
                    >
                        Company Bank Statement
                    </Button>
                </DETAILS>
                <Line></Line>
            </DetailsContainer>
            <DetailsContainer>
                <DETAILS>
                    <H3>Company Reg Number</H3>
                    <p>{selfEmployedDetails && selfEmployedDetails?.company_reg_number}</p>
                </DETAILS>
                <Line></Line>
            </DetailsContainer>
            <DetailsContainer>
                <DETAILS>
                    <H3>Average Monthly Earning</H3>
                    <p>{selfEmployedDetails && selfEmployedDetails?.salary}</p>
                </DETAILS>
                <Line></Line>
            </DetailsContainer>
            <DetailsContainer>
                <DETAILS>
                    <H3>job description</H3>
                    <p>
                        {selfEmployedDetails && selfEmployedDetails?.job_description}
                    </p>
                </DETAILS>
                <Line></Line>
            </DetailsContainer>
            {/* <DetailsContainer>
              <DETAILS>
                <H3>Land Title Deed</H3>
                <Button
                  href={selfEmployedDetails? selfEmployedDetails?.land_title_deed?.src:""}
                  variant="text"
                  size="small"
                  download
                >
                  Land Title Deed
                </Button>
              </DETAILS>
              <Line></Line>
            </DetailsContainer> */}
            <DetailsContainer>
                <DETAILS>
                    <H3>CO2</H3>
                    <Button
                        href={selfEmployedDetails ? selfEmployedDetails?.CO2?.src as string : ""}
                        variant="text"
                        size="small"
                        download
                    >
                        CO2
                    </Button>
                </DETAILS>
                <Line></Line>
            </DetailsContainer>
            <DetailsContainer>
                <DETAILS>
                    <H3>COR</H3>
                    <Button
                        href={selfEmployedDetails ? selfEmployedDetails?.COR?.src : ""}
                        variant="text"
                        size="small"
                        download
                    >
                        COR
                    </Button>
                </DETAILS>
                <Line></Line>
            </DetailsContainer>
        </>
    )
}


const DetailsContainer = styled.div``;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1em;
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #2c2c2c;
    text-transform: capitalize;
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;

export const H2 = styled.h2<{ status: string | any }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  color: ${({ status }) =>
        status === "APPROVED"
            ? "#33B95D;"
            : status === "PENDING"
                ? "#DFAE00;"
                : status === "REJECTED"
                    ? "#CE2B2B;"
                    : "#00317a"};
`;
