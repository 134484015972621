import { NavLink, Outlet } from "react-router-dom";
import { styled } from "styled-components";
import { Arrow, CircleIcon, GoodMarkIcon, IntializeConstructionHamburgerIcon } from "../../utils/svg";
import { buildAidbuildLaterLandDetailsLink, premiumFinancialVerificationLink, premiumMandateGeneration, premiumPaymentHistory, premuimAidPropertyChoice } from "../../links";
import { Fragment, useState } from "react";
import { mediaQuery } from "../../breakPoint";
import { Drawer } from "@mui/material";

type AnchorNav = "left";
export function PremiumAidNavigation() {
  // DRAWER OR MOBILE VIEW
  const [showMobileDrawer, setShowDrawer] = useState({ left: false });

  const toggleDrawer =
    (anchor: AnchorNav, open: boolean) =>
      (event: KeyboardEvent | MouseEvent | any) => {
        if (
          event.type === "keydown" &&
          ((event as KeyboardEvent).key === "Tab" ||
            (event as KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setShowDrawer({ ...showMobileDrawer, [anchor]: open });
      };


      

  const mobileList = (anchor: AnchorNav) => {
    return(
      <MobileBuildLaterContainer
      role="navigation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="phase"
      >
        <PhaseContainerMobile>
          <PhaseHeader>
            <Phase1Button>
              <Arrow />
              <H3MOBILE>PREMIUM AID APPLICATION</H3MOBILE>
            </Phase1Button>
            <GoodMarkIcon />
          </PhaseHeader>
          <PhaseList>
            <Ul>
              <Span to={premiumFinancialVerificationLink} end>
                <CircleIcon />
                <LI>Finance verification</LI>
              </Span>
              {/* <Span to={premium}>
              <CircleIcon />
              <LI>Select Building Plan </LI>
            </Span> */}
              <Span to={buildAidbuildLaterLandDetailsLink}>
                <CircleIcon />
                <LI>Land Details</LI>
              </Span>
              <Span to={premuimAidPropertyChoice}>
                <CircleIcon />
                <LI>Property choice</LI>
              </Span>
              <Span to={premiumMandateGeneration}>
                <CircleIcon />
                <LI>Mandate verification</LI>
              </Span>
              <Span to={premiumPaymentHistory}>
                <CircleIcon />
                <LI>Contribution history</LI>
              </Span>
            </Ul>
          </PhaseList>
        </PhaseContainerMobile>
      </MobileBuildLaterContainer>
    )
  }


  return (
    <>
    <Main>
      <PhaseContainer>
        <PhaseHeader>
          <Phase1Button>
            <Arrow />
            <H3>PREMIUM AID APPLICATION</H3>
          </Phase1Button>
          <GoodMarkIcon />
        </PhaseHeader>
        <PhaseList>
          <Ul>
            <Span to={premiumFinancialVerificationLink} end>
              <CircleIcon />
              <LI>Finance verification</LI>
            </Span>
            {/* <Span to={premium}>
              <CircleIcon />
              <LI>Select Building Plan </LI>
            </Span> */}
            <Span to={buildAidbuildLaterLandDetailsLink}>
              <CircleIcon />
              <LI>Land Details</LI>
            </Span>
            <Span to={premuimAidPropertyChoice}>
              <CircleIcon />
              <LI>Property choice</LI>
            </Span>
            <Span to={premiumMandateGeneration}>
              <CircleIcon />
              <LI>Mandate verification</LI>
            </Span>
            <Span to={premiumPaymentHistory}>
              <CircleIcon />
              <LI>Contribution history</LI>
            </Span>
          </Ul>
        </PhaseList>
      </PhaseContainer>
      <Outlet />
    </Main>
    <MainContainer>
      {(["left"] as const).map((anchor) =>(
        <Fragment key={anchor}>
          <MobilePremiumNNav>
          <MobileNavToggle>
                <div onClick={toggleDrawer(anchor, true)} className="icon">
                  <IntializeConstructionHamburgerIcon />
                </div>
                <Text>
                  <p className="name">Build Now </p>/
                  <p className="route">Employment verification </p>
                </Text>
              </MobileNavToggle>
          </MobilePremiumNNav>
          <Drawer
              anchor={anchor}
              open={showMobileDrawer[anchor]}
              onClose={toggleDrawer(anchor, false)}
              sx={{ height: "fitContent" }}
            >
              {mobileList(anchor)}
            </Drawer>
        </Fragment>
      ))}
    </MainContainer>
    </>
  )
}


const Main = styled.div`
display: flex;
margin-top: 1em;
justify-content: center;
gap: 4em;
margin-inline: auto;
 width: 80%;
 ${mediaQuery.tablet}{
  margin-top: 4.6rem;
 }
`

export const PhaseContainer = styled.div`
  margin-top: 2em;
  width: 60%;
  margin-left: 15em;
  ${mediaQuery.tablet} {
    display: none;
  }
`;

export const PhaseHeader = styled.div`
    display: flex;
    justify-content: space-between;
    gap: .5rem;
svg{
    fill: #33B95D;
}
`
export const Phase1Button = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625em;
`;

export const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 22px;
  color: #000000;
`;

export const PhaseList = styled.div`
  margin-top: 1.5em;
  .active {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
  
`;

export const Ul = styled.ul`

`;

export const Span = styled(NavLink)`
  display: flex;
  gap: 1em;
  align-items: center;
  text-align: left;
  margin-bottom: 0.5em;
  padding: 0.875em 1.25em;
  border-radius: 8px;
  width: 100%;
  transition: all 0.1s ease-in;
  text-decoration: none;
  cursor: pointer;
  .active {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
  svg {
    fill: #00317a;
    transition: none !important;
    cursor: pointer;
  }
  &:hover {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
`;

export const LI = styled.li`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: .95rem;
  line-height: 19px;
  color: #00317a;
`;



// moblie 
const MobileBuildLaterContainer = styled.div`
 padding: 1em;
  z-index: -1;
  overflow: hidden;
`;


const MobilePremiumNNav = styled.div`
   display: none;
  ${mediaQuery.tablet} {
    display: block;
  }
`;


const MobileNavToggle = styled.div`
 display: flex;
  align-items: center;
  gap: 1.416875em;
  margin: auto;
  padding-left: 2em;
  ${mediaQuery.mobile} {
    padding-left: 1em;
  }
  .icon {
    cursor: pointer;
  }
`;


const Text = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2em;
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .route {
    text-decoration: underline;
  }
`;


const MainContainer = styled.div`
  display: none;
  ${mediaQuery.tablet} {
    display: block;
  }
  position: fixed;
  top: 6.35em;
  width: 100%;
  background: rgba(6, 123, 232, 0.2);
  border-bottom: 0.5px solid #a4a4a4;
  height: 50px;
  padding: 0.8em;
  margin: auto;
  z-index: 2;
  ${mediaQuery.mobile} {
    top: 4.1em;
  }
  .mobile_outlet {
    display: none;
    ${mediaQuery.tablet} {
      display: flex;
    }
  }
`;

 const PhaseContainerMobile = styled.div`
  margin-top: 2em;

`;

const H3MOBILE = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: .9rem;
  line-height: 22px;
  color: #000000;
`

