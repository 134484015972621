import { styled } from "styled-components";
import { ChartIconComponent } from "../../../../../../../../utils/ChartBtn";
import { SaveButtonComponent } from "../../../../../../../../utils/buttons/saveButton";
import { TextArea } from "../../../../../../../../utils/textArea";
import { mediaQuery } from "../../../../../../../../breakPoint";
import { nonExisingProgress } from "../../../../../../../../links";
import { useNavigate } from "react-router-dom";
import { SelectInput } from "../../../../../../../../utils/select";

export function NoexistingLandCustomPlan() {
  const navigate = useNavigate();
  const options  = [
    "Lagos",
    "Abuja",
    "Portharcourt",
    "Enugu",
    "Owerri",
    "Kaduna"
  ]
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>REQUEST CUSTOM PLAN</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <SelfEmplyedMain>
              <SelectInput 
              SelectLabel="Select Building location"
              valueItems = {options}
               />
              <FieldContainer>
                <TextArea
                  label="Description"
                  placeholder="Please enter Description and custom message"
                />
              </FieldContainer>
            </SelfEmplyedMain>
          </FinanceContent>
          <SaveButtonComponent
            className="btn"
            name="Submit"
            onClick={() => navigate(`/${nonExisingProgress}`)}
          />
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  justify-content: center;
  gap: 5em;
  /* z-index: 1; */
  margin-top: 3em;
  position: relative;
  margin-left: 15em;
  width: 60%;
  ${mediaQuery.tablet} {
    width: 100%;
    margin-inline: auto;
    margin: 1em auto auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  width: 100%;
  ${mediaQuery.tablet} {
    margin: 2.5em;
  }
  .btn {
    padding: 1em !important;
    ${mediaQuery.tablet} {
      width: 100%;
    }
  }
`;

const FinanceContent = styled.div`
  margin-bottom: 2.375em;
`;
const FinanceHeader = styled.div``;
const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  ${mediaQuery.mobile} {
    font-size: 18.5px;
    line-height: 22px;
  }
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 19px;
  width: 330px;
  height: 53px;
  color: #797474;
  width: 100%;
  ${mediaQuery.mobile} {
    font-size: 0.8rem;
  }
`;
const SelfEmplyedMain = styled.div``;

const FieldContainer = styled.div``;
// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
