import { styled } from "styled-components";

import { SaveButtonComponent } from "../../../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../../../utils/ChartBtn";
// import bImg from "../../../../../../assets/img.png";
import toiletIcon from "../../../../../../assets/toilet.png";
import bedroomIcon from "../../../../../../assets/bedrom.png";
import { useNavigate } from "react-router-dom";
import { planDetailsLink } from "../../../../../../links";
import { mediaQuery } from "../../../../../../breakPoint";
import { useGetPremiumBuildingPlanList } from "../../../../../../hooks/buildSpecificationState/premium/useBuildingPlanList";
import SimpleBackdrop from "../../../../../../utils/backdrop";
import { Pagination } from "@mui/material";

export function SelectBuildingPremium() {
  const navigate = useNavigate();

  const {results,pages, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen, setCurrentPage,currentPage} = useGetPremiumBuildingPlanList();

  const handlePaginationChange = (event: any, page: any) => {
    setCurrentPage(page);
  };

  

  // const pageN = Math.ceil(results?.length / itemsPerpage)
  


  return (
    <>
      {/* LOADING */}
      <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />
      {results && (
        <FinanceContainer>
          <Finance>
            <FinanceContent>
              <FinanceHeader>
                <H1>SELECT BUILDING</H1>
                <P>
                Select any of our Premium building plans
                </P>
              </FinanceHeader>
              <ImageDiv>
                {results.map((items) => (
                  <ImageContent key={items?.id}>
                    {items?.cover_photos && items?.cover_photos[0] && (
                    <div className="img_container" >  <IMG src={items?.cover_photos[0]?.src} alt={items?.plan_name} loading="lazy" /></div>
                    )}
                    <Text>
                      <H2>{items?.plan_name}</H2>
                      <Paragraph>{items?.plan_description}</Paragraph>
                    </Text>
                    <Line></Line>
                    <IconDetailsDiv>
                      <IconD>
                        <img src={toiletIcon} alt="" /> {items?.toilets} Toilet{" "}
                      </IconD>
                      <IconD>
                        <img src={bedroomIcon} alt="" /> {items?.bedrooms} Bedrooms{" "}
                      </IconD>
                    </IconDetailsDiv>
                    <BTN>
                      <SaveButtonComponent name="View Details" onClick={() => navigate(`${planDetailsLink}/${items?.id}`)} />
                    </BTN>
                  </ImageContent>
                ))}
              </ImageDiv>
            </FinanceContent>
            <Pagination
            count={pages}
            page={currentPage}
            onChange={handlePaginationChange} // Use the handlePaginationChange function
          />
          </Finance>
          <ChartConponentDiv>
            <ChartIconComponent />
          </ChartConponentDiv>
        </FinanceContainer>
      )}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 2em;
  display: flex;
  gap: 5em;
  z-index: 1;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;

    margin: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
`;

export const FinanceContent = styled.div`
  width: 100%;
  margin-inline: auto;
`;
export const FinanceHeader = styled.div`
  margin-bottom: 1.5em;
`;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  text-transform: capitalize;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    font-size: 1.2rem;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 15px;
  /* max-width: 420px;
  max-height: 53px; */
  color: #797474;
  margin-top: 1em;
  width: 100%;
  ${mediaQuery.mobile} {
    font-size: 14px;
    line-height: 19px;
  }
`;

const ImageDiv = styled.div`
  display: block;
  width: 100%;
  padding: 0 !important;
  @media (min-width: 1395px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* display: flex; */
    /* flex-wrap: wrap; */
    gap: 0.5em;
    margin-top: 1.5em;
    margin-inline: auto;
    justify-content: center;
    width: 100%;
  }
`;

const IMG = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  @media (min-width: 1000px) {
    height: 100% !important;
    width: 100%;
  }
`;
const Text = styled.div`
  margin-top: 0.8em;
  width: 100%;
`;

const Line = styled.div`
  border: 0.5px solid #bbbbbb;
  width: 100%;
  margin-top: 1em;
`;

const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  color: #000000;
  ${mediaQuery.mobile} {
    font-size: 16px;
    line-height: 30px;
    width: 100%;
  }
`;

const Paragraph = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  color: #797474;
  width: 100%;
  ${mediaQuery.mobile} {
    font-size: 14px;
    line-height: 25px;
  }
`;
const ImageContent = styled.div`
  min-width: 342px;
  margin-bottom: 1em;
  ${mediaQuery.tablet} {
    width: 100%;
    min-width: 100%;
    margin-bottom: 2em;
  }
  .img_container{
    @media (min-width: 1000px) {
    height: 350px;
  }
  }
`;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;

const BTN = styled.div`
  margin-top: 1.5em;
  && {
    button {
      padding: 1em;
    }
  }
`;

const IconDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  margin-top: 1em;
`;

const IconD = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;
