// imageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Id_props {
    document_image: {
        front: string;
        back: string;
      }
      document_type: string;
      document_status: string;
      createdon: string;
    }
    

const initialState: Id_props = {
    document_image :{
        front: "",
        back: ""
      },
      document_type: "",
      document_status: "",
      createdon: ""
    }

const IdVerifiactionSuccess = createSlice({
    name: 'otp',
    initialState,
    reducers: {
        setIDcardVerificationSuccess: (state, action: PayloadAction<Id_props>) => {
           state.document_image= action.payload.document_image;
           state.document_status = action.payload.document_status;
           state.document_type = action.payload.document_type;
           state.createdon = action.payload.createdon;
    }
}
});


export const {setIDcardVerificationSuccess } = IdVerifiactionSuccess.actions;

export default IdVerifiactionSuccess.reducer;


