// imageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PasswordProps {
    value: string;
    loginRef: string;
}

const initialState: PasswordProps = {
    value: "",
    loginRef: ""
};

const otpRef = createSlice({
    name: 'password',
    initialState,
    reducers: {
        setotpRef: (state, action: PayloadAction<string >) => {
        state.value = action.payload;
        },
        setLoginRef: (state, action: PayloadAction<string>) => {
            state.loginRef = action.payload;
        }
    },
});

export const { setotpRef,setLoginRef } = otpRef.actions;

export default otpRef.reducer;


