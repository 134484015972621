import { useQuery } from "@apollo/client";
import { GET_PREMIUM_BUILDING_DETAILS } from "../../../api/query/servicePlan/premiumBuildingPlanList";
import { BuildingPlan } from "../../../api/types";
import { useMemo, useState } from "react";

export const useBuildingPlanDetails = ({ id }: string | any) => {
  
  const { data,loading } = useQuery(GET_PREMIUM_BUILDING_DETAILS, {
    variables: { id },
  });

  const premiumPlanSpecificationDetails: BuildingPlan = data?.buildingPlanDetails;

  // backdrop loadinng funnction
  const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

  const handleBackDropClose = () => setLoadingBackDrop(false);

  const handleBackDropOpen = () => setLoadingBackDrop(true);

  useMemo(() => {
    loading && handleBackDropOpen();

    !loading && handleBackDropClose();
  }, [loading]);

  return { ...premiumPlanSpecificationDetails,loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen,data };
};
