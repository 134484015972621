import { gql } from "@apollo/client";

export const PHASE1_STATUS = gql`
query CurrentBuildNowPhase {
  currentBuildNowPhase {
    finance_verification {
      status
    }
    land_verification {
      status
    }
    property_choice {
      status
    }
  }
}
`