import { useQuery } from "@apollo/client";
import { EXCLUSIVE_BUILDING_SPECIFICATION_DETAILS_EXISTING } from "../../../api/query/servicePlan/exclusiveLandType";
import { useMemo, useState } from "react";
import { ExclusiveBuildingSpecification } from "../../../api/types";

export const useGetExistingExclusiveSpeicifaction = () => {
  const { data, loading } = useQuery(EXCLUSIVE_BUILDING_SPECIFICATION_DETAILS_EXISTING);

  const existingExclusiveBuildingDetailsData:ExclusiveBuildingSpecification = data?.currentBuildNowPhase?.building_specification;

  // backdrop loadinng funnction
  const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

  const handleBackDropClose = () => setLoadingBackDrop(false);

  const handleBackDropOpen = () => setLoadingBackDrop(true);

  useMemo(() => {
    loading && handleBackDropOpen();

    !loading && handleBackDropClose();
  }, [loading]);

  return { loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen,...existingExclusiveBuildingDetailsData,loading}
};
