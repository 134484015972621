import { styled } from "styled-components";
import { EditIcon } from "../../../../../utils/svg";
import { useState } from "react";
import { mediaQuery } from "../../../../../breakPoint";

export function BasicInfo() {
  const [disabledd, setDisabled] = useState<boolean>(true);
  return (
    <>
      <ContactInfoDiv>
        <Content>
          <Header>
            <H2>BASIC INFO</H2>
            <EditIcon
              className="edit"
              onClick={() => setDisabled(!disabledd)}
            />
          </Header>
          <Paragragh>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Paragragh>
          <DetailsContainer>
            <DETAILS>
              <H3>Project title</H3>
              <input
                type="text"
                disabled={disabledd ? true : false}
                defaultValue="The Anyawu's home town resident"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>BUILDING TYPE</H3>
              <input
                disabled={disabledd ? true : false}
                type="text"
                defaultValue="Semi detached duplex"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>NO. OF ROOM</H3>
              <input
                disabled={disabledd ? true : false}
                type="number"
                defaultValue="6"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>BUILDING DESC.</H3>
              <textarea
                disabled={disabledd ? true : false}
                rows={8}
                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
        </Content>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  margin-top: 2.375em;
  margin-bottom: 3.5em;
`;

const Content = styled.div``;

const Header = styled.div`
  /* width: 432px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8125em;
  .edit {
    cursor: pointer;
  }
`;
const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  color: #00317a;
 ${mediaQuery.tablet} {
    /* font-size: 1rem; */
    line-height: 22px;
    font-size: 14px;
  }
`;
const Paragragh = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  width: 432px;
  color: #797474;
  ${mediaQuery.tablet} {
    line-height: 19px;
    width: 100%;
  }
  ${mediaQuery.mobile}{
    font-size: .89rem;
  }
`;

const DetailsContainer = styled.div``;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1em;
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem !important;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
    ${mediaQuery.mobile}{
      font-size: .89rem;
    }
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;
  color: #838383;
`;
