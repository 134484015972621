import { styled } from "styled-components";

import { Outlet } from "react-router-dom";
import {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  MouseEvent,
  useState,
} from "react";
import { IntializeConstructionHamburgerIcon } from "../../utils/svg";
import { Drawer } from "@mui/material";
import { TrackConstrunctionMobile } from "./mobileView";
import { mediaQuery } from "../../breakPoint";
type Props = {
  navAppearance?: boolean;
};

type AnchorNav = "left";
enum ServiceType {
  Simple = "simple",
  Premium = "premium",
  Exclusive = "exclusive",
}
export function TrackConstruction(props: Props) {
  const [selectedService, setSelectedService] = useState<ServiceType | null>(
    null
  ); // selected state

  const handleServiceSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedService(event.target.value as ServiceType);
  };

  // DRAWER OR MOBILE VIEW
  const [showMobileDrawer, setShowDrawer] = useState({ left: false });
  const toggleDrawer =
    (anchor: AnchorNav, open: boolean) =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setShowDrawer({ ...showMobileDrawer, [anchor]: open });
    };

  // navigation mobile component return
  const list = (anchor: AnchorNav) => (
    <MobilePhaseContainer
      role="navigation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="phase"
      style={{ width: "315px" }}
    >
      <TrackConstrunctionMobile />
    </MobilePhaseContainer>
  );
  return (
    <>
      <Main>
        <ProjectDiv>
          <ProjectContainer>
            <ProjecHeader>
              <H3>TRACK CONSTRUCTION </H3>
            </ProjecHeader>
            <SimplePlan isselected={selectedService === ServiceType.Simple}>
              <SimplePlanContent>
                <Text>
                  <H2 isselected={selectedService === ServiceType.Simple}>
                    Soil Test
                  </H2>
                  <SimplePlanDetails
                    isselected={selectedService === ServiceType.Simple}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliq
                  </SimplePlanDetails>
                </Text>
                <Icon></Icon>
              </SimplePlanContent>
            </SimplePlan>
            <SimplePlan isselected={selectedService === ServiceType.Simple}>
              <Radio
                type="radio"
                value={ServiceType.Simple}
                checked={selectedService === ServiceType.Simple}
                onChange={handleServiceSelect}
              />
              <SimplePlanContent>
                <Text>
                  <H2 isselected={selectedService === ServiceType.Simple}>
                    Foundation
                  </H2>
                  <SimplePlanDetails
                    isselected={selectedService === ServiceType.Simple}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliq
                  </SimplePlanDetails>
                </Text>
                <Icon></Icon>
              </SimplePlanContent>
            </SimplePlan>
            <SimplePlan isselected={selectedService === ServiceType.Simple}>
              <Radio
                type="radio"
                value={ServiceType.Simple}
                checked={selectedService === ServiceType.Simple}
                onChange={handleServiceSelect}
              />
              <SimplePlanContent>
                <Text>
                  <H2 isselected={selectedService === ServiceType.Simple}>
                    Block Work
                  </H2>
                  <SimplePlanDetails
                    isselected={selectedService === ServiceType.Simple}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliq
                  </SimplePlanDetails>
                </Text>
                <Icon></Icon>
              </SimplePlanContent>
            </SimplePlan>
            <SimplePlan isselected={selectedService === ServiceType.Simple}>
              <Radio
                type="radio"
                value={ServiceType.Simple}
                checked={selectedService === ServiceType.Simple}
                onChange={handleServiceSelect}
              />
              <SimplePlanContent>
                <Text>
                  <H2 isselected={selectedService === ServiceType.Simple}>
                    Lintel to Roof
                  </H2>
                  <SimplePlanDetails
                    isselected={selectedService === ServiceType.Simple}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliq
                  </SimplePlanDetails>
                </Text>
                <Icon></Icon>
              </SimplePlanContent>
            </SimplePlan>
            <SimplePlan isselected={selectedService === ServiceType.Simple}>
              <Radio
                type="radio"
                value={ServiceType.Simple}
                checked={selectedService === ServiceType.Simple}
                onChange={handleServiceSelect}
              />
              <SimplePlanContent>
                <Text>
                  <H2 isselected={selectedService === ServiceType.Simple}>
                    Roofing
                  </H2>
                  <SimplePlanDetails
                    isselected={selectedService === ServiceType.Simple}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliq
                  </SimplePlanDetails>
                </Text>
                <Icon></Icon>
              </SimplePlanContent>
            </SimplePlan>
            <SimplePlan isselected={selectedService === ServiceType.Simple}>
              <Radio
                type="radio"
                value={ServiceType.Simple}
                checked={selectedService === ServiceType.Simple}
                onChange={handleServiceSelect}
              />
              <SimplePlanContent>
                <Text>
                  <H2 isselected={selectedService === ServiceType.Simple}>
                    Pipping
                  </H2>
                  <SimplePlanDetails
                    isselected={selectedService === ServiceType.Simple}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliq
                  </SimplePlanDetails>
                </Text>
                <Icon></Icon>
              </SimplePlanContent>
            </SimplePlan>
            <SimplePlan isselected={selectedService === ServiceType.Simple}>
              <Radio
                type="radio"
                value={ServiceType.Simple}
                checked={selectedService === ServiceType.Simple}
                onChange={handleServiceSelect}
              />
              <SimplePlanContent>
                <Text>
                  <H2 isselected={selectedService === ServiceType.Simple}>
                    Wall Rendering
                  </H2>
                  <SimplePlanDetails
                    isselected={selectedService === ServiceType.Simple}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliq
                  </SimplePlanDetails>
                </Text>
                <Icon></Icon>
              </SimplePlanContent>
            </SimplePlan>
          </ProjectContainer>
        </ProjectDiv>
        <OutleatMain className="mobile_outlet">
          <Outlet />
        </OutleatMain>
      </Main>
      <MainContainer>
        {(["left"] as const).map((anchor) => (
          <Fragment key={anchor}>
            <MobileNav>
              <MobileNavToggle>
                <div onClick={toggleDrawer(anchor, true)} className="icon">
                  <IntializeConstructionHamburgerIcon />
                </div>
                <MobileText>
                  <p className="name">Initialise construction </p>/
                  <p className="route">Employment verification </p>
                </MobileText>
              </MobileNavToggle>
            </MobileNav>
            <Drawer
              anchor={anchor}
              open={showMobileDrawer[anchor]}
              onClose={toggleDrawer(anchor, false)}
              sx={{ height: "fitContent" }}
            >
              {list(anchor)}
            </Drawer>
          </Fragment>
        ))}
        {/* <OutleatMain className="mobile_outlet">
          <Outlet />
        </OutleatMain> */}
      </MainContainer>
    </>
  );
}

const Main = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  .mobile_outlet {
    ${mediaQuery.tablet} {
      width: 100%;
    }
  }
`;
const ProjectDiv = styled.div`
  width: 30%;
  max-width: 366px;
  height: 80vh;
  padding: 2em;
  position: fixed;
  overflow-y: scroll;
  left: 22em;
  margin-top: 1em;
  border-radius: 5px;
  ${mediaQuery.tablet} {
    display: none;
  }
`;

const ProjectContainer = styled.div`
  width: 100%;
`;

const ProjecHeader = styled.div`
  margin-bottom: 2em;
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.5em;
`;

const OutleatMain = styled.div`
  margin-left: 40em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin: 5em auto 5em;
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    margin: 4em auto 5em;
    margin-inline: auto;
  }
`;

const SimplePlan = styled.label<{ isselected: boolean }>`
  /* height: 160px; */
  border: 1px solid #33b95d;
  border-radius: 4px;
  /* width: 45%; */
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  cursor: pointer;
  background: ${(props) => (props.isselected ? "#fff" : "")};
  color: #33b95d;
  svg {
    fill: ${(props) => (props.isselected ? "#fff" : "#33B95D")};
  }

  &:hover {
    background: #33b95d;
    p,
    h3 {
      color: #fff !important;
    }
    transition: none;
    svg {
      fill: #fff;
      transition: none;
    }
  }
`;
const Radio = styled.input.attrs({
  type: "radio",
})`
  visibility: hidden;
`;
const SimplePlanContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  .goodmark {
    height: 56.686790466308594px;
    width: 42px;
    border-radius: 0px;
  }
`;
const Icon = styled.div`
  svg {
    fill: #fff;
  }
`;

const Text = styled.div``;
const H2 = styled.h3<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  margin-bottom: 0.5em;
  color: ${(props) => (props.isselected ? "#33B95D" : "#33B95D")};
`;
const SimplePlanDetails = styled.p<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 15px;
  color: ${(props) => (props.isselected ? "#33B95D" : "#33B95D")};
`;

// mobile
const MobileNav = styled.div`
  display: none;
  ${mediaQuery.tablet} {
    display: block;
  }
  ${mediaQuery.mobile} {
    /* top: 4.1em; */
  }
`;

const MobileNavToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 1.416875em;
  margin: auto;
  padding-left: 2em;
  ${mediaQuery.mobile} {
    padding-left: 1em;
  }
  .icon {
    cursor: pointer;
  }
`;

const MainContainer = styled.div`
  ${mediaQuery.tablet} {
    display: block;
    position: fixed;
    top: 6.2em;
    width: 100%;
    background: rgba(6, 123, 232, 0.2);
    border-bottom: 0.5px solid #a4a4a4;
    height: 50px;
    padding: 0.8em;
    margin: auto;
    z-index: 2;
  }
  ${mediaQuery.mobile} {
    top: 4em;
  }

  .mobile_outlet {
    display: none;
    ${mediaQuery.tablet} {
      display: flex;
    }
  }
`;

const MobileText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2em;
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  .route {
    text-decoration: underline;
  }
`;

const MobilePhaseContainer = styled.div``;
