import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { GET_FINANCE_VERIFICATION } from "../api/query/financeVerification";
import { FinanceVerification } from "../api/types";

export function useFinanceVerification() {
  // api
  const [EmployementStatus, setEmploymentStatus] = useState<string>("Self Employed");
  const [selfEmployedLoading, setSelfEmployedLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>("");
  const [financeData, setFinanceData] = useState<string[]>([]);

  // SLEF EMPLOYED
  const [jobDescription, setJobDescription] = useState<string>("");
  const [averageEarning, setAverageEarning] = useState<string>("");
  const [companyReg, setCompanyReg] = useState<string>("");
  // image file
  const [personalBankStatements, setPersonalBankStatements] = useState<File[]>([]);
  const [companyBankStatements, setCompanyBankStatements] = useState<File[]>([]);
  const [formCo2, setFormCo2] = useState<File[]>([]);
  const [certificateRegistration, setCertificationRegistration] = useState<File[]>([]);
  // selft emplyed ends ÷

  // EMPLOYED
  const [employerName, setEmployerName] = useState<string>("");
  const [employedJobDescription, setEmployedJobDescription] = useState<string>("");
  const [workAddress, setWorkAddress] = useState<string>("");
  const [salary, setSalary] = useState<string>("");
  // image file
  const [employedUserBankStatements, setEmployedUserBankStatements] = useState<File[]>([]);
  // work id
  const [employedUserWorkIDFront, setEmployedUserWorkIDFront] = useState<File[]>([]);
  const [employedUserWorkIDBack, setEmployedUserWorkIDBack] = useState<File[]>([]);
  // emplyed user ends ÷


  return {
    EmployementStatus,
    setEmploymentStatus,
    selfEmployedLoading,
    setSelfEmployedLoading,
    error,
    setError,
    financeData,
    setFinanceData,
    // SLEF EMPLOYED
    jobDescription,
    setJobDescription,
    averageEarning,
    setAverageEarning,
    companyReg,
    setCompanyReg,
    // image file
    personalBankStatements,
    setPersonalBankStatements,
    companyBankStatements,
    setCompanyBankStatements,
    formCo2,
    setFormCo2,
    certificateRegistration,
    setCertificationRegistration,
    // selft emplyed ends ÷

    // EMPLOYED
    employerName,
    setEmployerName,
    employedJobDescription,
    setEmployedJobDescription,
    workAddress,
    setWorkAddress,
    salary,
    setSalary,

    // image file
    employedUserBankStatements,
    setEmployedUserBankStatements,
    
    // work id
    employedUserWorkIDFront,
    setEmployedUserWorkIDFront,
    employedUserWorkIDBack,
    setEmployedUserWorkIDBack,
    // emplyed user ends ÷
  };
}



export function useGetFinnanceVerification (){

    // fetch the current finance verification status
    const { data, loading,error } = useQuery(GET_FINANCE_VERIFICATION);


    const financeVerificationDetails : FinanceVerification = data?.currentBuildNowPhase?.finance_verification;

     // backdrop loadinng funnction 
     const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

     const handleBackDropClose = () => setLoadingBackDrop(false);
 
     const handleBackDropOpen = () => setLoadingBackDrop(true);
 
     useMemo(() => {
 
         (loading) && handleBackDropOpen();
 
         (!loading) && handleBackDropClose();
 
     }, [loading]);

    return {...financeVerificationDetails,error, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen }
}