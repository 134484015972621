import { gql } from "@apollo/client";

export const QUERY_PROPERTY_CHOICE = gql`
query CurrentBuildNowPhase {
  currentBuildNowPhase {
    property_choice {
      bedrooms
      building_type
      project_title
      property_description
      status
      timestamp_created
      timestamp_updated
      units
    }
  }
}
`;
