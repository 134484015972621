import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError,ErrorResponse } from '@apollo/client/link/error';



// Create an http link
const httpLink = createHttpLink({
  uri: 'https://conbuild-api.onrender.com/graphql',
});


// Add headers to the http link
const authLink = setContext((_, { headers }) => {
    
    const token = localStorage.getItem("conbuild_token");
   
    const newHeaders = {
      ...headers,
      ...(token && {
         Authorization: `Bearer ${token}`,
      })
      
    };
  
    
    return {
      headers: newHeaders,
    };
  });

  
  
  // create error link to handle token expiration error 

  const errorLink = onError((error: ErrorResponse) =>{

    // if(!localStorage.getItem("conbuild_token")){
    //   window.location.replace('/') 
    // }
 
    // check if there's an error 
    if(error.graphQLErrors){
      // loops through error and return the  token expiration errror
      error.graphQLErrors.forEach((graphQLErrorr) =>{
   console.log(graphQLErrorr.message)
        if(graphQLErrorr.message === "authorization bearer token expired: User not found!" || graphQLErrorr.message === "authorization bearer token expired: jwt expired"){
          
          localStorage.removeItem("conbuild_token")

          window.location.replace('/login') 

          
        }
       
      })
    }
  })
  

// Create the Apollo Client instance
export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});
