import { useMutation } from "@apollo/client"
import { BANK_NAME_LOOKUP } from "../api/mutatation/bankNameLookUp"
import { useCallback, useState } from "react";
import { useNotificationToast } from "./useToast";

type LookUpProps = {
    account_number: string;
    bank_code: string;
}

export const useGetLookUpService = () =>{
    const [accountNameLookup, {loading: lookUpLoading}] = useMutation(BANK_NAME_LOOKUP);

    const [accountName, setAccountName] = useState<string>("")
    

    const {errorToast,successToast} = useNotificationToast()

    const bankValidation = useCallback(async({account_number, bank_code}:LookUpProps) =>{
        try{
    
            const {data} = await accountNameLookup({variables: {
                payload:{
                   account_number,
                    bank_code
                }
            }})

            setAccountName(data?.accountNameLookup)

        }catch(error){
            const err: any = error;
            errorToast(err?.message || "An error occurred");
        }
    },[setAccountName,errorToast,accountNameLookup])

    return{ lookUpLoading,accountName,bankValidation,successToast,errorToast}
}