import { Alert, Box, Button, Modal, Snackbar } from "@mui/material";
import { styled } from "styled-components";
import { ModalProps } from "../../../types";
// import { InputField } from "../../input";
import { SaveButtonComponent } from "../../../utils/buttons/saveButton";
import { mediaQuery } from "../../../breakPoint";
import { useMutation } from "@apollo/client";
import { PHONE_VERIFICATION } from "../../../api/mutatation/phoneVerification";
import { useDispatch, useSelector } from "react-redux";
import { setPhoneSubmit, setPhoneValue } from "../../../slice/phoneSubmit";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PhoneInputComponent } from "../../../utils/input/phoneInput";
import { MuiTelInputInfo } from "mui-tel-input";
import { RootState } from "../../../store";
import { setPhoneError } from "../../../slice/Validation";
import { useNotificationToast } from "../../../hooks/useToast";


export function ModalComponent(props: ModalProps) {

  const {successToast} = useNotificationToast()
  const navigate = useNavigate();

  const [phone, setPhone] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [verifyPhone, { error }] = useMutation(PHONE_VERIFICATION);

  
  const dispatch = useDispatch();
  const {phoneError} = useSelector((state: RootState) => state.validationReducer);

  const style = {
    boxShadow: 24,
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if(phone === ""){
      dispatch(setPhoneError("Required *"))
      return
    }

    try {
      setLoading(true)
      const { data } = await verifyPhone({variables: {payload:  { phone: phone}}});

        dispatch(setPhoneSubmit(data?.verifyPhone));
        setLoading(false);
        props.setOpen(false);

      dispatch(setPhoneValue(phone));

      successToast(data?.message || "Successful!")

    } 
    
    catch (error) {
      setLoading(false);

      return error;

    }

  };


  return (
    <>
      <Main>
        <ModaL
          open={true}
          onClose={props.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BoxDiv sx={style}>
            {error && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={error ? true : false}
                autoHideDuration={6000}
              >
                <Alert sx= {{textTransform: "capitalize"}} severity="error">{error?.message}</Alert>
              </Snackbar>
            )}
            <Content>
              <Text>
                <h2>{props?.name}</h2>
                <p>{props?.description}</p>
              </Text>
              <div className="input_container">
                <PhoneInputComponent 
                onBlur={() =>{
                  if(phone === ""){
                    dispatch(setPhoneError("Required *"))
                  }
                }} 
                error = {phoneError? phoneError: ""}
                value={phone} 
                onChange={ (value: string, info: MuiTelInputInfo)=> {
                  dispatch(setPhoneError(""))
                  setPhone(value)
                }}  
                />
                {/* <InputField
                  className="input"
                  label={props.label}
                  type="tel"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  placeholder="Enter Phone"
                /> */}
              </div>
              <SaveButtonComponent
                loading={loading}
                onClick={handleSubmit}
                name={props?.buttonName}
              />
              <StyledButton onClick={() => navigate(-1)} variant="text">Cancel</StyledButton>
            </Content>
          </BoxDiv>
        </ModaL>
      </Main>
    </>
  );
}

const Main = styled.main``;

const ModaL = styled(Modal)`
  width: 60%;
  margin: auto;
  ${mediaQuery.mobile}{
    width: 50%;
  }
`;

const BoxDiv = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background: #ffffff;
  border-radius: 4px;

  padding: 1.5em;
`;

const Content = styled.div`
  .input {
    padding: 1.2em;
  }
  .input_container {
    margin-bottom: 2.5em;
  }
`;
const Text = styled.div`
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.1275rem;
    line-height: 22px;
    color: #00317a;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 19px;
    margin-top: 1em;
    color: #797474;
  }
`;

const StyledButton = styled(Button)`
  && {
    background: #ffffff;
    border: 1px solid #00317a;
    border-radius: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: #00317a;
    width: 100%;
    margin-top: 1em;
    padding: 0.7em;
  }
`;
