import React from "react";
import error from "../../assets/404.gif";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

export function PageNotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="not_found_page">
        <Container className="page_not_found_content">
          <img src={error} alt="error 404" />
          <div className="btn_h2">
            <button onClick={() => navigate(-1)}>Back</button>
            <h2 className="not_found_p">Page not found</h2>
          </div>
        </Container>
      </div>
    </>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: auto; */
  position: relative;
  img {
    padding: 0;
  }
  .btn_h2 {
    position: absolute;
    bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  button {
    border: none;
    outline: none;
    border-radius: 6px;
    color: #fff;
    background: #0084fe;
    font-family: "inter";
    padding: 1em;
    cursor: pointer;
  }
`;
