import { useNavigate } from "react-router-dom";
import { SaveButtonComponent } from "../../../../../../utils/buttons/saveButton";
import { styled } from "styled-components";
import { mediaQuery } from "../../../../../../breakPoint";
import { SimplePlanItems } from "./list";
import { costEquityLink } from "../../../../../../links";


export function GetSimplePlanDetails() {
  const navigate = useNavigate()

  return (
    <>

      <ContactInfoDiv>
        <Content>
          <Header>
            <H2>Service Building Specification</H2>
          </Header>
          {/* <Paragragh>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Paragragh> */}
          <SimplePlanItems />
        </Content>
        <SaveButtonComponent onClick={() => navigate(costEquityLink)} name="Next" />
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  // margin-top: 2.375em;
  margin-bottom: 3.5em;
  width: 100%;
  margin-inline: auto;
  ${mediaQuery.tablet} {
    margin-top: auto;
  }
`;

const Content = styled.div``;

const Header = styled.div`
  /* width: 432px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8125em;
  .edit {
    cursor: pointer;
  }
`;
const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;

  color: #00317a;

`;
// const Paragragh = styled.p`
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 300;
//   font-size: 1rem;
//   line-height: 19px;
//   width: 432px;
//   color: #797474;
//   ${mediaQuery.tablet} {
//     line-height: 19px;
//     width: 100%;
//   }
//   ${mediaQuery.mobile}{
//     font-size: .89rem;
//   }
// `;