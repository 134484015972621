import { ProgressAmountComponent } from "../../components/dashboardcomp/progressTop";
import { AmountPaid } from "../../components/dashboardcomp/amountPayed";
import { NewProjectBoard } from "../../components/newProjectDashnoard";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers";
// import { ContactPerson } from "../../../contactPerson";
import { ImageIcon } from "../../utils/svg";
import { useUserKyc } from "../../hooks/useUserKyc";
import { NavButton } from "../../utils/buttons/authBtn";
import { Aside, Gallary, LocalizationProviders, MainContainer, MainDashboard, TopChart } from "./dashboardNewStyles";
import { kyc } from "../../links";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
// import { Skeleton } from "@mui/material";

export function NewProjectDashboard() {
  const navigate = useNavigate() 
   // const {data, loading} = useQuery(GET_PERSONAL_INFO)

  const { residential_status, phone_status, document_status, refetch,loading } = useUserKyc();   // kyc api hook

  const memoizedRefetch = useCallback(() => {
    refetch();
  }, [refetch]);
  

  useEffect(() => {
    memoizedRefetch();
  }, [memoizedRefetch]);



  const validStatuses = ["REJECTED", "APPROVED", "PENDING"];


  const checkIfResidentialStatusAvailable = validStatuses.includes(residential_status);

  const checkIfPhoneStatusAvailable = validStatuses.includes(phone_status);

  const checkIfDocumentStatusAvailable = validStatuses.includes(document_status);

  // const allStatusesAvailable = checkIfResidentialStatusAvailable && checkIfPhoneStatusAvailable && checkIfDocumentStatusAvailable;   // check if all status is available.


  // check if any one is not available (specified)
  const checkIfNoResidentialStatus = !validStatuses.includes(residential_status);

  const checkIfNoPhoneStatus = !validStatuses.includes(phone_status);

  const checkIfNoDocumentStatus = !validStatuses.includes(document_status);

  const noKycAvailable = checkIfNoResidentialStatus || checkIfNoPhoneStatus || checkIfNoDocumentStatus;    //  all not available 


  // this is to check the particular status that is not verified if the others are verified already 
  const phoneOnly = checkIfNoPhoneStatus && checkIfResidentialStatusAvailable && checkIfDocumentStatusAvailable
  const documentOnly = checkIfNoDocumentStatus && checkIfResidentialStatusAvailable && checkIfPhoneStatusAvailable
  const residentialOnly = checkIfNoResidentialStatus && checkIfPhoneStatusAvailable && checkIfDocumentStatusAvailable



  return (
    <MainDashboard>
      {loading?(
        <>...</>
      ):
      noKycAvailable && (<section className="kyc_section">
        <p className="status">
          Please complete your{" "}
         { phoneOnly ? "Phone Verification" : documentOnly ? "ID Verification" : residentialOnly ? "Residential Verification": "KYC"} 
         {" "}
         to verify your account
         </p>
        <NavButton name="Complete KYC now" onClick={() => navigate(kyc)} />
      </section>)}
      <MainContainer>

        <TopChart>
          <div className="head">
            <ProgressAmountComponent />
            <AmountPaid />
          </div>
          <NewProjectBoard text="No project yet, Chart will display here after you have started construction" />
          <NewProjectBoard
            name="Project Info"
            text="No project yet, Chart will display here after you have started construction"
          />
          <NewProjectBoard
            name="Mortgage"
            text="No project yet, Chart will display here after you have started construction"
          />
        </TopChart>
        <Aside>
          <LocalizationProviders dateAdapter={AdapterDayjs}>
            <DateCalendar />
          </LocalizationProviders>
          <Gallary>
            <h3>Gallary</h3>
            <div className="gallary">
              <div className="icon">
                <ImageIcon />
              </div>
              <p>
                No project yet, Chart will display here after you have started
                construction
              </p>
            </div>
          </Gallary>
          {/* <Contact>
          {loading ? (
                <Skeleton  animation="wave" variant="text" sx={skeleton} />
              ) : data ? (
                <ContactPerson
                  header="CONTACT PERSON"
                  name={`${data?.userProfile.firstname} ${data?.userProfile.lastname}`}
                  email={data?.userProfile.email}
                />
              ) : (
                ""
              )}
          </Contact> */}
        </Aside>
      </MainContainer>
    </MainDashboard>
  );
}


// const skeleton = {
//   fontSize: "1rem",
//   background: "#e3e3e3",
//   width: "50%",
//   padding: "5em 7em 1em 1em",
// };

