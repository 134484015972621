import { useQuery } from "@apollo/client";
import { BuildingPlanList } from "../../../api/types";
import { useMemo, useState } from "react";
import { GET_PREMIUM_BUILDING_LIST } from "../../../api/query/servicePlan/premiumBuildingPlanList";

export const useGetPremiumBuildingPlanList = () => {
  const [itemsPerpage] = useState(6);

  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading } = useQuery(GET_PREMIUM_BUILDING_LIST, {
    variables: {
      page: currentPage,
      limit: itemsPerpage,
      sort: "DESC",
    },
  });

  const getBuildingPlanList: BuildingPlanList = data?.buildingPlanList;

  // backdrop loadinng funnction
  const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

  const handleBackDropClose = () => setLoadingBackDrop(false);

  const handleBackDropOpen = () => setLoadingBackDrop(true);

  useMemo(() => {
    loading && handleBackDropOpen();

    !loading && handleBackDropClose();
  }, [loading]);

  return {
    ...getBuildingPlanList,
    loadingBackDrop,
    setLoadingBackDrop,
    handleBackDropClose,
    handleBackDropOpen,
    itemsPerpage,
    setCurrentPage,
    currentPage,
  };
};
