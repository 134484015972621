import { Paper, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import { styled } from "styled-components";

type BuildingTableDetails = {
  plan_type?: string;
  substructure_cost?: string | number;
  superstructure_cost?: string | number;
  permit_cost?: string | number;
  land_verification_cost?: string | number;
  minimumFloorArea: string | number;
  minimumPlotArea: string | number;
};

export default function TableStripe({ plan_type, substructure_cost, superstructure_cost, permit_cost, land_verification_cost,minimumFloorArea, minimumPlotArea }: BuildingTableDetails) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHeadStyle>
          <TableRow>
            <TableCell className="table_head_cell">ITEMS</TableCell>
            <TableCell className="table_head_cell">DETAILS</TableCell>
            {/* <TableCell className="table_head_cell">Information</TableCell> */}
          </TableRow>
        </TableHeadStyle>
        <TableBodyStyled>
          <TableRowStyled>
            <TableCell>Plan Type</TableCell>
            <TableCell>{plan_type}</TableCell>
          </TableRowStyled>
          <TableRowStyled>
            <TableCell>Substructure</TableCell>
            <TableCell>{substructure_cost?.toLocaleString()}</TableCell>
          </TableRowStyled>
          <TableRowStyled>
            <TableCell>Supersctructure</TableCell>
            <TableCell >{superstructure_cost?.toLocaleString()}</TableCell>
            </TableRowStyled>
          <TableRowStyled>
            <TableCell>Permit cost</TableCell>
            <TableCell>{permit_cost?.toLocaleString()}</TableCell>
            </TableRowStyled>
          <TableRowStyled>
            <TableCell>Land verifcation cost</TableCell>
            <TableCell>{land_verification_cost?.toLocaleString()}</TableCell>
          </TableRowStyled>
          <TableRowStyled>
            <TableCell>Minimum Plot Area</TableCell>
            <TableCell>{minimumPlotArea?.toLocaleString()}</TableCell>
          </TableRowStyled>
          <TableRowStyled>
            <TableCell>minimum Floor Area</TableCell>
            <TableCell>{minimumFloorArea?.toLocaleString()}</TableCell>
          </TableRowStyled>
          {/* ))} */}
        </TableBodyStyled>
      </Table>
    </TableContainer>
  );
}

const TableHeadStyle = styled(TableHead)`
  // background: #eaeaea;

  // .table_head_cell {
  //   font-family: "Inter";
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 1rem;
    width: 10%;
  //   line-height: 19px;
  //   color: #000000;
  // }
`;

const TableBodyStyled = styled(TableBody)`

`;

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(even) {
    background: #eaeaea;
  }
`;
