// imageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ImageState {
    uploadedImageUrlBack: File | string | null;
}

const initialState: ImageState = {
    uploadedImageUrlBack: null
};

const imageBackSlice = createSlice({
    name: 'imageBack',
    initialState,
    reducers: {
        setUploadedImageUrlBack: (state, action: PayloadAction<File | string | null>) => {
        state.uploadedImageUrlBack = action.payload;
        },
    },
});

export const { setUploadedImageUrlBack } = imageBackSlice.actions;

export default imageBackSlice.reducer;


