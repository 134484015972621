// imageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ImageState {
  uploadedImageUrl: File | null ;
}

const initialState: ImageState = {
  uploadedImageUrl: null,
};

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setUploadedImageUrl: (state, action: PayloadAction<File | null>) => {
      state.uploadedImageUrl = action.payload;
    },
  },
});

export const { setUploadedImageUrl } = imageSlice.actions;

export default imageSlice.reducer;
