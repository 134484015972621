import { styled } from "styled-components";

import { SaveButtonComponent } from "../../../../../utils/buttons/saveButton";
import { mediaQuery } from "../../../../../breakPoint";
import { InputField } from "../../../../../utils/input";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export function MortagagePayment() {
  useEffect(() => {
    AOS.init();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>Mortgage Payment</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <SelectInputDiv>
              <FieldContainer>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-easing="ease-in"
                >
                  <InputField
                    label="Bank Name"
                    type="text"
                    placeholder="Enter Here"
                    className="input"
                  />
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1400"
                  data-aos-easing="ease-in"
                >
                  <InputField
                    label="Account Name"
                    type="text"
                    placeholder="Enter Here"
                    className="input"
                  />
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1600"
                  data-aos-easing="ease-in"
                >
                  <InputField
                    label="Account Number"
                    type="text"
                    placeholder="Enter Here"
                    className="input"
                  />
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1800"
                  data-aos-easing="ease-in"
                >
                  <InputField
                    label="BVN"
                    type="tel"
                    placeholder="Enter Here"
                    className="input"
                  />
                </div>
              </FieldContainer>
            </SelectInputDiv>
          </FinanceContent>
          <SaveButtonComponent
            onClick={() =>
              navigate("/initialize-construction/transfer-ownership", {
                replace: true,
              })
            }
            className="btn"
            name="Save and continue"
          />
        </Finance>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 4em;
  display: flex;
  gap: 5em;
  margin-inline: auto;
  width: 100%;
  [data-aos] {
    opacity: 0;
    transition-property: opacity;
  }

  [data-aos].aos-animate {
    opacity: 1;
  }
  ${mediaQuery.tablet} {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin-inline: auto;
  width: 80%;
  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
      width: 100%;
    }
  }
`;

export const FinanceContent = styled.div`
  margin-bottom: 3em;
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 1em;
  ${mediaQuery.tablet} {
    font-size: 18px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  width: 90%;
  ${mediaQuery.mobile} {
    width: 100%;
  }
`;

const SelectInputDiv = styled.div``;

const FieldContainer = styled.div`
  .input {
    ${mediaQuery.tablet} {
      width: 100% !important;
    }
  }
`;
