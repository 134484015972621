import { styled } from "styled-components";

// assets
import toilet from "../../../../../../assets/toilet.png";
import bedroom from "../../../../../../assets/bedrom.png";
import parkingSpace from "../../../../../../assets/parkingspace.png";
import water from "../../../../../../assets/water.png";
import planImage from "../../../../../../assets/planImage.png";

import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { ChartIconComponent } from "../../../../../../utils/ChartBtn";
import { CustomAlert } from "../../../../../../utils/customPlanAlert";
import { mediaQuery } from "../../../../../../breakPoint";

export function CustomPlanVerificationStatus() {
  const navigate = useNavigate();

  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <Alerts>
              <CustomAlert
                className="custom_alert"
                status="Custom Plan"
                date="March 10, 2023"
              />
            </Alerts>
            <FinanceHeader>
              <H1>CUSTOM PLAN</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <PlanDetailsSection>
              <Text>
                <H2>Plan 1234gh</H2>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua. magna aliqua.
                </Paragraph>
              </Text>
              <DetailsIcon>
                <IconD>
                  <img src={toilet} alt="" /> 4 Toilet{" "}
                </IconD>
                <IconD>
                  <img src={bedroom} alt="" /> 3 bedrooms{" "}
                </IconD>
                <IconD>
                  <img src={parkingSpace} alt="" /> Parking space{" "}
                </IconD>
                <IconD>
                  <img src={water} alt="" /> Treated water{" "}
                </IconD>
              </DetailsIcon>
            </PlanDetailsSection>
            <CarouselImage>
              <img src={planImage} alt="" />
            </CarouselImage>

            <div className="btn">
              <ButtonStyled
                onClick={() => navigate(-1)}
                className="outlin"
                variant="outlined"
              >
                See Details
              </ButtonStyled>
              <ButtonStyled
                onClick={() =>
                  navigate("/initialize-construction/cost-equity", {
                    replace: true,
                  })
                }
                className="contain"
                variant="contained"
              >
                Proceed
              </ButtonStyled>
            </div>
          </FinanceContent>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 2em;
  display: flex;
  gap: 5em;
  z-index: 1;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    display: flex;
    justify-content: center;
  }
  /* margin-right: 2em; */
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;

const Alerts = styled.div`
  .custom_alert {
    padding: 0.875em 1.1875em;
    background: rgba(51, 185, 93, 0.1);
    border: 1px solid #33b95d;
    border-radius: 12px;
    width: 95%;
    ${mediaQuery.tablet} {
      width: 100%;
    }
    svg {
      fill: #33b95d;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #33b95d;
      ${mediaQuery.mobile} {
        font-size: 14px;
      }
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;

      color: #33b95d;
      ${mediaQuery.mobile} {
        font-size: 12px;
      }
    }
  }
`;
export const Finance = styled.div`
  .btn {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    margin-top: 2.375em;
    ${mediaQuery.tablet} {
      width: 100%;
      gap: 0.3em;
    }

    .outlin {
      background: #ffffff;
      border: 1px solid #00317a;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 19px;
      padding: 1em !important;
      color: #00317a;
      ${mediaQuery.tablet} {
        font-size: 0.9rem;
      }
      ${mediaQuery.mobile} {
        font-size: 0.7rem;
        width: 100%;
      }
    }
    .contain {
      background: #0084fe;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 19px;
      width: 100%;
      color: #ffffff;
      ${mediaQuery.tablet} {
        font-size: 0.9rem;
      }
      ${mediaQuery.mobile} {
        width: 100%;
        font-size: 0.75rem;
      }
    }
  }
`;

export const FinanceContent = styled.div`
  width: 100%;
  ${mediaQuery.tablet} {
    margin: auto;
  }
`;
export const FinanceHeader = styled.div`
  margin-top: 2.375em;
`;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 20px;
  color: #797474;
  width: 100%;
  margin-top: 1em;
  ${mediaQuery.mobile} {
    font-size: 0.875rem;
    line-height: 19px;
    width: 100%;
  }
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
const DetailsIcon = styled.div`
  margin-top: 2em;
  display: flex;
  align-items: center;
  gap: 2em;
  ${mediaQuery.tablet} {
    gap: 1em;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 3em;
  }
  ${mediaQuery.mobile} {
    margin-top: 4.5em;
    justify-content: space-between;
  }
`;

const IconD = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
`;

const CarouselImage = styled.div`
  width: 90%;
  margin-top: 2.375em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
  img {
    width: 100%;
  }
`;

const PlanDetailsSection = styled.div`
  width: 90%;
  margin-top: 3em;
  ${mediaQuery.tablet} {
    width: 100%;
    /* border: 2px solid blue; */
  }
  .tableContainer {
    margin-top: 2.375em;
  }
  .plan_img {
    width: 100%;
    margin-top: 2em;
  }
`;

const Text = styled.div``;
const H2 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.7em;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const Paragraph = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 22px;
  color: #797474;
  width: 100%;
  ${mediaQuery.mobile} {
    font-size: 0.99rem;
  }
`;

const ButtonStyled = styled(Button)`
  && {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    padding: 1em;
    width: 100%;
    .contain {
      font-size: 0.9rem !important;
    }
  }
`;
