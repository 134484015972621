import { gql } from "@apollo/client";

export const GET_SIMPLE_BUILDING_SPECIFICATION = gql`
query CurrentBuildNowPhase {
  currentBuildNowPhase {
    building_specification {
      ... on SimpleBuildingSpecification {
        structural_drawing {
           src
          mimetype
        }
        mechanical_drawing {
           src
          mimetype
        }
        architectural_drawing {
          src
          mimetype
        }
        electrical_drawing {
           src
          mimetype
        }
        building_permit {
           src
          mimetype
        }
        survey_plan {
           src
          mimetype
        }
        status
      }
    }
  }
}
`;
