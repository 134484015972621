import { gql } from "@apollo/client";

export const BUILD_NOW_SERVICE_PLAN = gql`
  query CurrentBuildNowPhase {
    currentBuildNowPhase {
      service_type
      paymentTerms
    }
  }
`;
