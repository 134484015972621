import axios, { AxiosInstance } from "axios";


// const token = localStorage.getItem("conbuild_token");

 const apiFetchClient = ((): AxiosInstance => {

    const option ={
        baseURL : "https://conbuild-api.onrender.com/v1/api/extcall",
        header: {
            "Content-Type": "application/json",
        },
    };

    const axiosInstance: AxiosInstance = axios.create(option);


    // instance for request 
    axiosInstance.interceptors.request.use(function (config) {
        const token = localStorage.getItem("conbuild_token");
        config.headers.Authorization = token ? `Bearer ${token}` : "";
        return config 
    })

    axiosInstance.interceptors.response.use(
        response => response,

        error => {
            const token = localStorage.getItem("conbuild_token");
            if(token && error.response.status === 401){
            localStorage.removeItem("conbuild_token");
            // window.location.href = '/';
            window.location.replace('/login')
            }

        //     if(error.response.status === 401 && window.location.href !== '/login'){
        //         window.location.replace('/')
        //     }
            
        //     if(error === 409){
        //         window.location.href = "/"
        //     }

            return Promise.reject(error);

        } 
    )

    return axiosInstance
})()

export default apiFetchClient
