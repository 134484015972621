import { styled } from "styled-components";
import { DropDownIcon } from "../../../../../utils/svg";
import { useMemo } from "react";
import { CustomAlert } from "../../../../../utils/customPlanAlert";
import { ChartIconComponent } from "../../../../../utils/ChartBtn";
import { mediaQuery } from "../../../../../breakPoint";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { useNavigate } from "react-router-dom";

import { useUserKyc } from "../../../../../hooks/useUserKyc";
import { useNotificationToast } from "../../../../../hooks/useToast";
import { useDateMoment } from "../../../../../hooks/useMomentDate";
import SimpleBackdrop from "../../../../../utils/backdrop";

export function IDverificationStatus() {
  const navigate = useNavigate();
  const {errorToast} = useNotificationToast()

  const {
    error,
    document_image,
    document_type,
    document_status,
    createdon,
    loadingBackDrop,
    setLoadingBackDrop,
    handleBackDropClose,
    handleBackDropOpen
  } = useUserKyc();   // hooks
 

  const { convertDate, formattedDate } = useDateMoment()

  const statusDetails = useSelector((state: RootState) => state.IdVerifiactionSuccess);  // redux useSelector

  const postRequestResponseStatus = statusDetails?.document_status;

  // images fetched 
  const imageFront = document_image?.front;
  const imageBack = document_image?.back;

  // response from the post id card verification saved from the post request modal component 
  const imageFrontFromResponsePost = statusDetails?.document_image?.front;
  const imageBackFromResponsePost = statusDetails?.document_image?.back;

  const status = postRequestResponseStatus || document_status;

  const userPostDocumentType = statusDetails?.document_type   // document type from response after user uploaded And posted 

  const date = createdon || statusDetails?.createdon;   // convert date 

  useMemo(() => convertDate({ dateString: date }), [convertDate, date,])

  error && errorToast(error?.message ?? "An Error Occured");



  return (
    <>
    {/* loading */}
    <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />
      <ContactInfoDiv>
        <Content>
          <DropDownIcon
            className="drop_down"
            onClick={() => navigate("/kyc-verifcation")}
          />
          <AlertContainer>
            <CustomAlert
              className={
                status === "PENDING" ?
                  "pending_status" :
                  status === "REJECTED" ?
                    "rejected_status" :
                    status === "APPROVED" ?
                      "verified" : "pending_status"
              }
              status={status}
              date={formattedDate ?? "loading Date"}
            />
          </AlertContainer>
          <Header>
            <H2>BASIC INFO</H2>
            {/* <EditIcon
              className="edit"
              onClick={() => setDisabled(!disabledd)}
            /> */}
          </Header>
          <Paragragh>
          Your Valid ID is used to validate your account before you can use the Build now pay later service
          </Paragragh>
          <DetailsContainer>
            <DETAILS>
              <H3>ID TYPE</H3>
              <div className="address">
                <p className="value">{userPostDocumentType || document_type?.replace(/_/g, ' ')}</p>
              </div>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <div className="img_back_front">
            <div className="front">
              <img src={imageFront || imageFrontFromResponsePost} alt="id card front" />
              <p>Front View</p>
            </div>
            <div className="back">
              <img src={imageBack || imageBackFromResponsePost} alt="id card back" />
              <p>Back View</p>
            </div>
          </div>
        </Content>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
 margin-bottom: 5em;
  display: flex;
  gap: 5em;
  margin-top: 2em;
  margin-left: 16em;
  width: 70%;
  padding: 2em;
  ${mediaQuery.tablet}{
    width: 100%;
    padding: 0;
    margin: 4.5em auto 2em;
  }
  ${mediaQuery.mobile}{
    margin: 1em auto
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
  .drop_down {
    transform: rotate(90deg);
    cursor: pointer;
  }
`;

const Content = styled.div`
margin: auto;
  width: 80%;
  padding: 0em 2em;
  ${mediaQuery.tablet}{
    padding: 0;
    width: 90%;
  }
  ${mediaQuery.tablet}{
  width: 90%;
}
.img_back_front{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2em;
  /* max-width: 420px; */
  width: 100%;
  gap: 2em;
  p{
    margin-top: 1em;
  }
  ${mediaQuery.mobile}{
  flex-direction: column;
}
  img{
    width: 100%;
    max-height: 300px;
  }
}
`;

const Header = styled.div`
margin-top: 2.5em;
  /* width: 432px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8125em;
  .edit {
    cursor: pointer;
  }
`;
const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;

  color: #00317a;
`;
const Paragragh = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  width: 432px;
  color: #797474;
  ${mediaQuery.mobile} {
    width: 100%;
    font-size: .9rem;
  }
`;

const DetailsContainer = styled.div`

`;

const AlertContainer = styled.div`
  margin-top: 3.375em;
  .verified {
    padding: 0.875em 1.1875em;
    background: rgba(51, 185, 93, 0.1);
    border: 1px solid #33b95d;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #33b95d;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;

      color: #33b95d;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: .875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;

      color: #33b95d;
    }
  }

  .pending_status {
    padding: 0.875em 1.1875em;
    background: rgba(223, 174, 0, 0.1);
    border: 1px solid #dfae00;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #dfae00;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: #dfae00;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #dfae00;
    }
  }
  .rejected_status {
    padding: 0.875em 1.1875em;
    background: rgba(206, 43, 43, 0.13);
    border: 1px solid #ce2b2b;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #ce2b2b;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: #ce2b2b;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #ce2b2b;
    }
  }
`;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* gap: 1em; */
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
    text-align: right;
    
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;
  color: #838383;
`;


const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
 ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;