import { ChangeEvent, FC, FormEvent, useState } from "react";
import { styled } from "styled-components";
import { ConbuildLogo } from "../../../utils/svg";
import bg from "../../../assets/otpbg.png";
// import bgR from "../../../assets/bgright.png";
import { InputField } from "../../../utils/input";
// import { Checkbox, FormControlLabel } from "@mui/material";
// import { PhoneInputComponent } from "../../utils/input/phoneInput";
import { AuthBtn } from "../../../utils/buttons/authBtn";

// import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../../api/auth";
import { useNavigate } from "react-router-dom";
import { forgotPassword, otp } from "../../../links";
import { useDispatch, useSelector } from "react-redux";
import { setotpRef } from "../../../slice/otpRef";
import { RootState } from "../../../store";
import { setEmailError } from "../../../slice/Validation";
import { useNotificationToast } from "../../../hooks/useToast";
const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
// import { PasswordInput } from "../../utils/input/passwordInput";

export const ResetPassword: FC = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { errorToast, successToast } = useNotificationToast();  // toast hook

  const { emailError } = useSelector((state: RootState) => state.validationReducer);

  const [ResetPasswordUpdate, { loading }] = useMutation(RESET_PASSWORD);

  const [email, setEmail] = useState<string>("");


  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    // email 
    if (!emailRegex.test(email)) dispatch(setEmailError("Email is invalid"))

    if (email === "") dispatch(setEmailError("Email is required *"))


    try {
      const { data } = await ResetPasswordUpdate({ variables: { payload: { email: email, }, }, });

      localStorage.setItem("conbuild_otp_token", data?.resetPassword.access_token)

      dispatch(setotpRef(forgotPassword));

      navigate(otp, { replace: true });

      (data) && successToast(data?.message || "Successful!")

    }

    catch (error) {
      const err: any = error

      errorToast(err?.message || "An error has occurred")
      // console.error("Error creating account:", error);
    }

  };


  return (
    <>
      {/* {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error?.message}
        </Alert>
      )} */}
      <CREATEACCOUNT>
        <CreateAccountMain>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <BackgroundCircle></BackgroundCircle>
          <CreateAccountInputForm onSubmit={handleFormSubmit}>
            <CreateAccountContent>
              <ConbuildLogo />
              <HeaderText>
                <Register>Reset password</Register>
                <P>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </P>
              </HeaderText>
              <InputContainers>
                <InputField
                  error={emailError ? emailError : ""}
                  label="Email"
                  placeholder="Enter here"
                  type="text"
                  value={email}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    dispatch(setEmailError(""))
                    setEmail(event.target.value)
                  }}

                  onBlur={() => {
                    // Perform validation logic when the input field loses focus
                    if (email === "") {
                      dispatch(setEmailError("Email is required. *"));
                    }
                    if (email !== "" && !emailRegex.test(email)) {
                      dispatch(setEmailError("Email is invalid *"))
                    }
                  }}
                />
              </InputContainers>
              <AuthBtn  loading={loading} name="Send OTP" />
              <P onClick={() => navigate(-1)} className="link">Back</P>
              {/* <LineContainer>
                <Line></Line>
                <Or>Or</Or>
                <Line></Line>
              </LineContainer>
              <ImageGoogle src={google} /> */}
            </CreateAccountContent>
          </CreateAccountInputForm>
          <BackgroundImage src={bg} />
          {/* <BackgroundImageRight src={bgR} /> */}
        </CreateAccountMain>
      </CREATEACCOUNT>
    </>
  );
};

export const CREATEACCOUNT = styled.div`
  @media (min-width: 1020px) {
    background: #c6c6c6;
    height: 100%;
    min-height: 100vh;
    position: relative;
  }

  .link{
    color:#6F5DE7;
    font-size: .9rem !important;
    text-decoration: none;
    cursor: pointer;
  }

`;

export const CreateAccountMain = styled.div`
  padding: 1em;
`;

export const BackgroundCircle = styled.div``;

export const HeaderText = styled.div`
  /* width: 96px; */
  max-width: 80%;
  margin-top: 1.836em;
`;

export const Register = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #6F5DE7;
  
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  color: #797474;
  margin-top: 1em;
 
`;

export const CreateAccountInputForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2em;
  /* width: 90%; */
`;

export const CreateAccountContent = styled.div`
  width: 100%;
  padding: 1.5em;
  @media (min-width: 600px) {
    width: 530px;
    margin-top: 8.5em;
  }
  @media (min-width: 900px) {
    width: 500px;
    margin-top: 6em;
  }
  @media (min-width: 1020px) {
    width: 500px;
    margin-top: 2.5em;
    background: #fff;
    border-radius: 3px;
    padding: 2em;
    z-index: 1;
  }
`;

export const InputContainers = styled.div`
  margin: auto;
`;

export const LineContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
`;

export const Line = styled.div`
  border: 0.5px solid #6F5DE7;
  width: 45%;
`;

export const Or = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

export const ImageGoogle = styled.img`
  width: 100%;
  margin-top: 1.3em;
  cursor: pointer;
`;

export const BackgroundImage = styled.img`
  display: none;
  @media (min-width: 1020px) {
    width: 90%;
    max-width: 92%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const BackgroundImageRight = styled.img`
  display: none;
  @media (min-width: 1020px) {
    width: 40%;
    display: block;
    position: absolute;
    right: 0;
    bottom: 4em;
  }
`;

// ROUTER LINK
// const LINK = styled(Link)`
//   text-decoration: none;
// `;
