import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

interface DialogProps {
    details: string | string[];
    open: boolean;
    handleClose: () => void;
    next: string;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
    width: '100%',
    padding: "4em",
    backgroundColor: "rgba(0, 49, 122, 1)",
    color: "white",
    textAlign: "center",
    fontSize: "1.5em"
}

const text={
    color:"rgba(51, 185, 93, 1)",
    backgroundColor: "rgba(0, 49, 122, 1)",
    textAlign: "center",
}

const content={
    color: "white",
    fontWeight: "500",
    width: "100%",
    textTransform:"capitalize",
}


export default function AlertDialogSlide(props: DialogProps) {

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={text}>Successful</DialogTitle>
        <DialogContent sx={style}>
          <DialogContentText sx={content} id="alert-dialog-slide-description">
            {props.details}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={content} >
          <Button onClick={props.handleClose}>{props.next}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
