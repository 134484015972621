// import { FC } from "react";
import { styled } from "styled-components";
import {
  ConbuildHeaderLogo,
  ConbuildMobileLogo,
  // HamburgerIcon,
  NotificationIcon,
} from "../../utils/svg";
import { SearchInput } from "../../utils/input/searchInput";
import { Avatar, Skeleton } from "@mui/material";
// import avatar from "../../../assets/avater.png";
import { MobileNavigation } from "../mobileNavigation";
import { mediaQuery } from "../../breakPoint";
import { useNavigate } from "react-router-dom";
import { profileLink } from "../../links";
import { useUserProfileDetails } from "../../hooks/useProfile";

export function Header() {
  const navigate = useNavigate();

  const { firstname, profileImg, loading, fullName } = useUserProfileDetails();
  

  return (
    <>
      <HeaderSection>
        <HeaderContainer>
          <HeaderContent>
            <Logo>
              <ConbuildHeaderLogo className="logo" fill="#fff" />
            </Logo>
            <MobileLogo>
              <ConbuildMobileLogo className="mobile_logo" />
            </MobileLogo>
            <WelcomeText>
              {loading ? (
                <Skeleton animation="wave" variant="text" sx={skeleton} />
              ) : (
                <H4>
                  <Span>Welcome</Span>, {firstname}
                </H4>
              )}
            </WelcomeText>
            <SearchInputDiv>
              <SearchInput />
            </SearchInputDiv>
            {/* notification div with avatar and hamburger icon on mobile start*/}
            <NotificationDIV>
              {/* hamburger icon on mobile screens  */}
              <HamburgerIconIconDiv>
                <MobileNavigation />
              </HamburgerIconIconDiv>
              {/* notification icon on desktop  */}
              <NotificationIconDiv>
                <NotificationIcon />
              </NotificationIconDiv>
              <NAMEText>
                {loading ? (
                  <Skeleton animation="wave" variant="text" sx={skeleton} />
                ) : (
                  <>
                    <H5>{firstname}</H5>
                    <P>{fullName}</P>
                  </>
                )}
              </NAMEText>
              <StyledClickableDiv onClick={() => navigate(profileLink)}>
                <Avatar src={profileImg as string} alt={fullName} />
              </StyledClickableDiv>
            </NotificationDIV>
            {/* notification div with avatar and hamburger icon on mobile end*/}
          </HeaderContent>
        </HeaderContainer>
      </HeaderSection>
    </>
  );
}

export const skeleton = {
  fontSize: "1rem",
  background: "#bebcbc",
  width: "100%",
  padding: ".5em 7em 1em 1em",
};

export const HeaderSection = styled.div`
  z-index: 100;
  position: relative;
`;

export const HeaderContainer = styled.div`
  background: #00317a;
  padding-left: 6em;
  position: fixed;
  width: 100%;
  top: 0;
  @media (max-width: 1000px) {
    padding-left: 2em;
    padding-right: 1em;
  }
  ${mediaQuery.tablet} {
    padding: 0.6em 0em;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  ${mediaQuery.tablet} {
    width: 100%;
    justify-content: space-around;
  }
  ${mediaQuery.mobile} {
    justify-content: space-between;
    padding-left: 2em;
  }

  @media (max-width: 282px) {
    padding-left: 1em;
  }
`;

export const Logo = styled.div`
  cursor: pointer;
  ${mediaQuery.tablet} {
    display: none;
  }
`;

const MobileLogo = styled.div`
  display: none;
  ${mediaQuery.tablet} {
    display: block;
  }
`;
export const WelcomeText = styled.div`
  ${mediaQuery.tablet} {
    display: none;
  }
`;
export const H4 = styled.h4`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 22px;
  color: #ffffff;
`;

export const Span = styled.span``;

export const SearchInputDiv = styled.div`
  width: 30%;

  ${mediaQuery.mobile} {
    display: none;
  }
`;

export const NotificationDIV = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 250px;
  cursor: pointer;
  ${mediaQuery.tablet} {
    flex-direction: row-reverse;
  }
  ${mediaQuery.mobile} {
    gap: 1.1em;
    width: 250px;
  }
  @media (max-width: 340px) {
    gap: 1em;
    width: 200px;
  }
`;

export const NotificationIconDiv = styled.div`
  padding: 0.5em;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
`;

const HamburgerIconIconDiv = styled.div`
  display: none;
  ${mediaQuery.tablet} {
    display: block;
  }
`;

export const NAMEText = styled.div`
  ${mediaQuery.tablet} {
    display: none;
  }
`;

export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 15px;

  color: #ffffff;
`;

export const H5 = styled.h5`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 17px;

  color: #ffffff;
`;

const StyledClickableDiv = styled("div")({
  display: "inline-block",
  cursor: "pointer",
});
