import { styled } from "styled-components";

import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { InputField } from "../../../../utils/input";
import { SelectInput } from "../../../../utils/select";
import { TextArea } from "../../../../utils/textArea";
import { FileUpload } from "../../../../components/imageUploads/fileUpload";
import { mediaQuery } from "../../../../breakPoint";
import { useLanDetails } from "../../../../hooks/uselandDetails";

import { useNavigate } from "react-router-dom";

export function BuildLaterLandDetails() {
  
  const navigate = useNavigate();
  // details value hook
  const {
    landArea,
    setLandArea,
    landLocation,
    setLandLocation,
    landDescription,
    setLandDescription,
    // setLandDocumentUpload,
    // loading and error state
    isLandDetailsLoading,

  } = useLanDetails();

  const [landType, setLandType] = useState<string>("");
  const [, setPostLandType] = useState<string>("")

  const landTypeList = [
    "Certificate of occupancy",
    "Deed of conveyance",
    "Deed of survey",
    "Land certificate",
    "Statutory Right of occupancy",
  ];


  // const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
  //   setLandDescription(event.target.value);
  // };

  useMemo(() => {
    if (landType === "Certificate of occupancy") return setPostLandType("CERTIFICATE_OF_OCCUPANCY");
    if (landType === "Deed of conveyance") return setPostLandType("DEED_OF_CONVEYANCE");
    if (landType === "Deed of survey") return setPostLandType("DEED_OF_SURVEY");
    if (landType === "Land certificate") return setPostLandType("LAND_CERTIFICATE");
    if (landType === "Statutory Right of occupancy") return setPostLandType("STATUTORY_RIGHT_OF_OCCUPANCY");
  }, [setPostLandType, landType])


  const landTypeHandleChange = (event: SelectChangeEvent<string>) => {
    if (setLandType) {
      setLandType(event?.target.value as string);
    }

  };

  // close dialog 
  function handleSubmit() {
    navigate("/initialize-construction/property-choice",{replace:true})
  }

  return (
    <>
    {/* success message */}
    {/* error  */}
    <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>LAND DETAILS</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <SelfEmplyedMain>
              <FieldContainer>
                <InputField
                  label="Total Plot Area (m2)"
                  type="number"
                  // min="45"
                  placeholder="Enter Here"
                  className="input"
                  value={landArea}
                  onChange={(event) => setLandArea(event.target.value)}
                />
                <InputField
                  label="Total Floor Area (m2)"
                  type="number"
                  placeholder="Enter Here"
                  className="input"
                  value={landArea}
                  onChange={(event) => setLandArea(event.target.value)}
                />
                <InputField
                  label="Location"
                  type="text"
                  placeholder="Enter Here"
                  className="input"
                  value={landLocation}
                  onChange={(event) => setLandLocation(event.target.value)}
                />
                <SelectInput
                  SelectLabel="Title type"
                  defaultName="Select"
                  valueItems={landTypeList}
                  items={landType}
                  setItems={setLandType}
                  onChange={landTypeHandleChange}
                />
                <TextArea
                  label="Land Description"
                  placeholder="Land Description"
                  value={landDescription}
                  onchange={(event) => setLandDescription(event.target.value)}
                />
              </FieldContainer>
              <LandPhotoDiv>
                <H3>Land Photos</H3>
                <ImageUpLoadDiv>
                  <FileUpload  name="add land photo" />
                  <FileUpload  name="add land photo" />
                  <FileUpload name="add land photo" />
                </ImageUpLoadDiv>
              </LandPhotoDiv>
              <LandProof>
                {/* <FileUpload
                  label="Proof of land ownership "
                  name="Upload proof of Land ownership"
                /> */}
                {/* <FileUpload
                  label="Land title document"
                  name="Upload Land title deed"
                  src={setLandDocumentUpload}
                /> */}
              </LandProof>
            </SelfEmplyedMain>
          </FinanceContent>
          <SaveButtonComponent loading={isLandDetailsLoading} onClick={handleSubmit} className="btn" name="Save and continue" />
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  margin-left: 2em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    justify-content: center;
    margin: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    /* width: 433px; */
    height: 52px;
  }
`;
export const Finance = styled.div`
  ${mediaQuery.tablet} {
    width: 100%;
  }
  ${mediaQuery.mobile} {
    /* width: 95%; */
  }

  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
    }
  }
`;

export const FinanceContent = styled.div`
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 1em;
  ${mediaQuery.tablet} {
    font-size: 1.125rem;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;
const SelfEmplyedMain = styled.div``;

const ImageUpLoadDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.8em;
`;

const LandPhotoDiv = styled.div`
  margin-top: 2.375em;
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
`;
const LandProof = styled.div`
  width: 100%;
`;
const FieldContainer = styled.div`
  ${mediaQuery.tablet} {
    .input {
      width: 100% !important;
    }
  }
`;
// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
