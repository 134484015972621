import { styled } from "styled-components";
import { ChartIconComponent } from "../../../../../../../utils/ChartBtn";
// assets
import toilet from "../../../../../../../assets/toilet.png";
import bedroom from "../../../../../../../assets/bedrom.png";
import parkingSpace from "../../../../../../../assets/parkingspace.png";
import water from "../../../../../../../assets/water.png";
import planImage from "../../../../../../../assets/planImage.png";

import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { mediaQuery } from "../../../../../../../breakPoint";
import { Link } from "react-router-dom";

export function ProceedWithPlan() {
  const navigate = useNavigate();

  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>SELECT BUILDING</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
              <DetailsIcon>
                <IconD>
                  <img src={toilet} alt="" /> 4 Toilet{" "}
                </IconD>
                <IconD>
                  <img src={bedroom} alt="" /> 3 bedrooms{" "}
                </IconD>
                <IconD>
                  <img src={parkingSpace} alt="" /> Parking space{" "}
                </IconD>
                <IconD>
                  <img src={water} alt="" /> Treated water{" "}
                </IconD>
              </DetailsIcon>
            </FinanceHeader>
            <PlanDetailsSection>
              <Text>
                <H2>Plan 1234gh</H2>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing
                  elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </Paragraph>
              </Text>
            </PlanDetailsSection>
            <CarouselImage>
              <img src={planImage} alt="" />
            </CarouselImage>

            <div className="btn">
              <ButtonStyled
                onClick={() => navigate(-1)}
                className="outlin"
                variant="outlined"
              >
                Change plan
              </ButtonStyled>
              <Link className="linkk" to="/initialize-construction/cost-equity">
                <ButtonStyled className="contain" variant="contained">
                  Proceed with this plan
                </ButtonStyled>
              </Link>
            </div>
          </FinanceContent>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 2em;
  display: flex;
  gap: 5em;
  z-index: 1;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    display: flex;
    justify-content: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
  width: 100%;
  .btn {
    .linkk {
      width: 100%;
    }
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    margin-top: 2.375em;
    @media (max-width: 682px) {
      width: 100%;
      gap: 0.5em;
    }
    .outlin {
      background: #ffffff;
      border: 1px solid #00317a;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 19px;
      width: 100%;
      color: #00317a;
      ${mediaQuery.mobile} {
        font-size: 0.7rem;
        padding: 1em !important;
      }
    }
    .contain {
      background: #0084fe;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 19px;
      width: 100%;
      color: #ffffff;
      ${mediaQuery.tablet} {
        font-size: 0.89rem;
      }
      ${mediaQuery.mobile} {
        font-size: 0.7rem;
        padding: 0.45em !important;
        width: 100%;
      }
    }
  }
`;

export const FinanceContent = styled.div`
  width: 100%;
  ${mediaQuery.tablet} {
    margin: auto;
  }
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 29px;
  color: #000000;
  ${mediaQuery.tablet} {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.99rem;
  line-height: 18px;
  color: #797474;
  width: 90%;
  margin-top: 1em;
  ${mediaQuery.mobile} {
    font-size: 0.875rem;
    line-height: 19px;
    width: 100%;
  }
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
const DetailsIcon = styled.div`
  margin-top: 2em;
  display: flex;
  align-items: center;
  gap: 2em;
  ${mediaQuery.tablet} {
    gap: 1em;
    width: 100%;
    /* flex-wrap: wrap; */
    margin-top: 3em;
  }
  ${mediaQuery.mobile} {
    margin-top: 4.5em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const IconD = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  line-height: 20px;
  color: #000000;
  ${mediaQuery.tablet} {
    font-size: 14px;
  }
`;

const CarouselImage = styled.div`
  width: 90%;
  margin-top: 2.375em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
  img {
    width: 100%;
  }
`;

const PlanDetailsSection = styled.div`
  width: 100%;
  margin-top: 3em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
  .tableContainer {
    margin-top: 2.375em;
  }
  .plan_img {
    width: 100%;
    margin-top: 2em;
  }
`;

const Text = styled.div``;
const H2 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const Paragraph = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 22px;
  color: #797474;
  width: 100%;
  ${mediaQuery.tablet} {
    font-size: 0.99rem;
  }
`;

const ButtonStyled = styled(Button)`
  && {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem !important;
    line-height: 19px;
    padding: 1em;
    width: 100%;
    .contain {
      padding: 1.2em;
    }
  }
`;
