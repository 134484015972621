import { styled } from "styled-components";
import { EditIcon } from "../../../../../utils/svg";
import { useState } from "react";
import landPhoto from "../../../../../assets/land.png"
import { Button } from "@mui/material";


export function LandDetailsSummary() {
  const [disabledd, setDisabled] = useState<boolean>(true);
  return (
    <>
      <ContactInfoDiv>
        <Content>
          <Header>
            <H2>LAND DETAILS</H2>
            <EditIcon
              className="edit"
              onClick={() => setDisabled(!disabledd)}
            />
          </Header>
          <Paragragh>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Paragragh>
          <DetailsContainer>
            <DETAILS>
              <H3>land area (M/sqr)</H3>
              <input
                type="text"
                disabled={disabledd ? true : false}
                defaultValue="230.45 m2"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>LOCATION</H3>
              <input
                disabled={disabledd ? true : false}
                type="text"
                defaultValue="Plot 234 Lekki-Epe express way, Lagos"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>land type</H3>
              <input
                disabled={disabledd ? true : false}
                type="text"
                defaultValue="Sole ownership"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>BUILDING DESC.</H3>
              <textarea
                disabled={disabledd ? true : false}
                rows={8}
                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip"
              />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>land photos</H3>
              <img src={landPhoto} alt="lands" />
              <img src={landPhoto} alt="lands" />
              <img src={landPhoto} alt="lands" />
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>proof of land ownership</H3>
              <Button variant = "text" size="small">Doc_1829743.pdf</Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS className="details" >
              <H3>land title deed</H3>
              <Button variant = "text" size="small">family_land_title_deed_127483.pdf</Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
        </Content>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  margin-top: 2.375em;
  margin-bottom: 3.5em;
@media (max-width: 682px) {
  width: 100%;
}
`;

const Content = styled.div`

`;

const Header = styled.div`
  /* width: 432px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8125em;
  .edit {
    cursor: pointer;
  }
`;
const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;

  color: #00317a;
`;
const Paragragh = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  width: 432px;
  color: #797474;
  @media (max-width: 680px) {
    width: 100%;
  }
`;

const DetailsContainer = styled.div`
width: 100%;
.details{
  @media (max-width: 380px) {
    gap: 2em;
  }
}
`;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  
    &&{
      button{
      @media (max-width: 480px) {
      /* width: 90%; */
      /* border: 2px solid red; */
      /* font-size: .7rem; */
    }
    }
  }
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;

