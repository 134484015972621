import { useMemo, useState } from "react";
import { GET_LAND_DETAILS } from "../api/query/getLandDetails";
import { useQuery } from "@apollo/client";
import { LandVerification } from "../api/types";

export function useLanDetails() {
  const [landArea, setLandArea] = useState<string>("");
  const [landLocation, setLandLocation] = useState<string>("");
  const [landDescription, setLandDescription] = useState<string>("");
  const [floorArea, setFloorArea] = useState<string>("");
//   photos
  const [firstLandPhoto, setFirstLandPhoto] = useState<File[]>([]);
  const [secondLandPhoto, setSecondLandPhoto] = useState<File[]>([]);
  const [thirdLandPhoto, setThirdLandPhoto] = useState<string[]>([]);
//   document 
const [landDocumentUpload, setLandDocumentUpload] = useState<string[]>([]);
// loading, errror
const [isLandDetailsLoading, setLandDetailsLoading] = useState<boolean>(false);
const [landDetailsError, setLandDetailsError] = useState<any>("")
  return {
    landArea,
    setLandArea,
    landLocation,
    setLandLocation,
    landDescription,
    setLandDescription,
    firstLandPhoto,
    setFirstLandPhoto,
    secondLandPhoto,
    setSecondLandPhoto,
    thirdLandPhoto,
    setThirdLandPhoto,
    landDocumentUpload, 
    setLandDocumentUpload,
    isLandDetailsLoading, 
    setLandDetailsLoading,
    landDetailsError, 
    setLandDetailsError,
    floorArea, 
    setFloorArea
  };
}


export function useGetLandDetails (){
   // fetch the current finance verification status
   const { data, loading,error } = useQuery(GET_LAND_DETAILS);

   const landVerificationDetails : LandVerification = data?.currentBuildNowPhase?.land_verification;
 
    // backdrop loadinng funnction 
    const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

    const handleBackDropClose = () => setLoadingBackDrop(false);

    const handleBackDropOpen = () => setLoadingBackDrop(true);

    useMemo(() => {

        (loading) && handleBackDropOpen();

        (!loading) && handleBackDropClose();

    }, [loading]);

    return {...landVerificationDetails,error, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen}
}
