import { gql } from "@apollo/client";

export const GET_BANK_LIST = gql`
  query BankList {
    bankList {
      bankCode
      bankName
    }
  }
`;
