import { styled } from "styled-components";
import { ChartIconComponent } from "../../../../../../../utils/ChartBtn";
// assets
import toilet from "../../../../../../../assets/toilet.png";
import bedroom from "../../../../../../../assets/bedrom.png";
// import parkingSpace from "../../../../../../../assets/parkingspace.png";
// import water from "../../../../../../../assets/water.png";
// import planImage from "../../../../../../../assets/planImage.png";
// import planStructure from "../../../../../../../assets/hplan.png";
// import frontElevation from "../../../../../../../assets/frontE.png";
// import sideElevation from "../../../../../../../assets/sideEl.png";
import TableStripe from "./detailsTable";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
// import { proceedLink } from "../../../../../../../links";
import { mediaQuery } from "../../../../../../../breakPoint";
import { useBuildingPlanDetails } from "../../../../../../../hooks/buildSpecificationState/premium/useBuildingPlanDetails";
import SimpleBackdrop from "../../../../../../../utils/backdrop";
import apiFetchClient from "../../../../../../../apiProvider/axiosInstance";
import { useNotificationToast } from "../../../../../../../hooks/useToast";
import { useState } from "react";
// import planImage1 from "../../../../../../../assets/planImage.png";
// import planImage2 from "../../../../../../../assets/planImage.png";
// import planImage3 from "../../../../../../../assets/planImage.png";
// import slideIcon from "../../../../../../../assets/sliderIcon.png";

export function PlanDetails() {
  const { id } = useParams();
  const {
    plan_name,
    data,
    // bathrooms,
    bedrooms,
    toilets,
    cover_photos,
    plan_description,
    floor_plans,
    loadingBackDrop,
    setLoadingBackDrop,
    handleBackDropClose,
    handleBackDropOpen,
    // table content 
    plan_type,
    substructure_cost,
    superstructure_cost,
    permit_cost,
    land_verification_cost,
    MFA,
    MPA,

  } = useBuildingPlanDetails({ id });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)

  const { successToast } = useNotificationToast()
  async function buildingSpecification() {
    try {

      setLoading(true)
      const { data } = await apiFetchClient.post("/construction/build-now/building-specification", {
        building_plan_id: id
      })
      setLoading(false)
      successToast(data?.message || "successful")
      navigate("/initialize-construction/cost-equity")
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  }


  return (
    <>
      {/* loading */}
      <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />
      {data && (
        <FinanceContainer>
          <Finance>
            <FinanceContent>
              <FinanceHeader>
                <H1>{plan_name}</H1>
                <P>
                  {plan_description}
                </P>
                <DetailsIcon>
                  <IconD>
                    <img src={toilet} alt="" /> {toilets} Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroom} alt="" /> {bedrooms} bedrooms{" "}
                  </IconD>
                  {/* <IconD>
                  <img src={parkingSpace} alt="" /> Parking space{" "}
                </IconD> */}
                  {/* <IconD>
                  <img src={water} alt="" /> Treated water{" "}
                </IconD> */}
                </DetailsIcon>
              </FinanceHeader>
              {cover_photos &&
                cover_photos.map((image, index) => (
                  <CarouselImage key={index}>
                    <img className="plan_view" src={image?.src} alt="cover" />
                  </CarouselImage>
                ))}
              <PlanDetailsSection>
                <Text>
                  <H2>{plan_name}</H2>
                  <Paragraph>{plan_description}</Paragraph>
                </Text>
                {floor_plans &&
                  floor_plans.map((floor_plan, index) => (
                    <CarouselImage key={index}>
                      <img className="plan_img" src={floor_plan?.src} alt="floor plan" />
                    </CarouselImage>
                  ))}
              </PlanDetailsSection>
              {/* <PlanDetailsSection>
              <Text>
                <H2>Front Elevation</H2>
              </Text>
              <img className="plan_img" src={frontElevation} alt="" />
            </PlanDetailsSection> */}
              {/* <PlanDetailsSection>
              <Text>
                <H2>Side elevatioin</H2>
              </Text>
              <img className="plan_img" src={sideElevation} alt="" />
            </PlanDetailsSection> */}
            </FinanceContent>
            <PlanDetailsSection>
              <Text>
                <H2>Plan details</H2>
                {/* <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Paragraph> */}
              </Text>
              <div className="tableContainer">
                <TableStripe
                  plan_type={plan_type}
                  substructure_cost={substructure_cost}
                  superstructure_cost={superstructure_cost}
                  permit_cost={permit_cost}
                  land_verification_cost={land_verification_cost}
                  minimumFloorArea={MFA}
                  minimumPlotArea={MPA}
                />
              </div>
              <div className="btn">
                <ButtonStyled onClick={() => navigate(-1)} className="outlin" variant="outlined">
                  Go back
                </ButtonStyled>
                <ButtonStyled onClick={buildingSpecification} className="contain" variant="contained">

                  {loading ? "loading..." : "Use this plan"}
                </ButtonStyled>
              </div>
            </PlanDetailsSection>
          </Finance>
          <ChartConponentDiv>
            <ChartIconComponent />
          </ChartConponentDiv>
        </FinanceContainer>
      )}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 2em;
  display: flex;
  gap: 5em;
  z-index: 1;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-top: 2em;
    margin-inline: auto;
    display: flex;
    justify-content: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  width: 90%;
  max-width: 800px;
  margin-inline: auto;
  ${mediaQuery.mobile} {
    width: 100%;
  }
  .btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    margin-top: 2.375em;
    .outlin {
      background: #ffffff;
      border: 1px solid #00317a;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 19px;
      color: #00317a;
      ${mediaQuery.mobile} {
        font-size: 0.7rem;
        padding: 1em !important;
        width: 100%;
      }
    }
    .contain {
      background: #0084fe;
      border-radius: 4px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      ${mediaQuery.mobile} {
        font-size: 0.75rem;
        padding: 1em !important;
        width: 100%;
      }
    }
  }
`;

export const FinanceContent = styled.div`
  ${mediaQuery.tablet} {
    margin: auto;
  }
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 20px;
  /* max-width: 420px;
  max-height: 53px; */
  color: #797474;
  width: 90%;
  margin-top: 1em;
  ${mediaQuery.mobile} {
    width: 100%;
    font-size: 0.875rem;
    line-height: 19px;
  }
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
const DetailsIcon = styled.div`
  margin-top: 2em;
  display: flex;
  align-items: center;
  gap: 2em;
  ${mediaQuery.tablet} {
    gap: 1em;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 3em;
  }
  ${mediaQuery.mobile} {
    margin-top: 4.5em;
    justify-content: space-between;
  }
`;

const IconD = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
`;

const CarouselImage = styled.div`
  margin-top: 2.375em;
  @media (min-width: 900px) {
    max-width: 600px;
    width: 100%;
  }
  ${mediaQuery.tablet} {
    width: 100%;
  }
  img {
    width: 100%;
  }
`;

const PlanDetailsSection = styled.div`
  width: 90%;
  margin-top: 3em;
  ${mediaQuery.tablet} {
    width: 100%;
    /* border: 2px solid blue; */
  }
  .tableContainer {
    margin-top: 2.375em;
  }
  .plan_img {
    width: 100%;
    margin-top: 2em;
  }
`;

const Text = styled.div``;
const H2 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const Paragraph = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  color: #797474;
`;

const ButtonStyled = styled(Button)`
  && {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    padding: 1em;
    width: 100%;
  }
`;
