import { Navigate } from "react-router-dom";
import { login } from "../../../links";
import { FC, ReactNode } from "react";

type props = {
    children: ReactNode
}

export const AuthGuard: FC<props>= ({children}) =>{
    const authToken = localStorage.getItem("conbuild_token");
    
    if(!authToken){
        return <Navigate to={login} />
    }

    return <>{children}</>
   
}
