import { styled } from "styled-components";
import { saveBtnProps } from "../../../types";
import { LoadingButton } from "@mui/lab";


export function SaveButtonComponent({ name,loading,onClick,className,disabled, type }:saveBtnProps) {
  return <SaveButton type={type} disabled = {disabled} loading ={loading} className={className} onClick={onClick} variant="contained">{name}</SaveButton>;
};

export const SaveButton = styled(LoadingButton)`
  && {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: .85rem;
    line-height: 19px;
    padding: 14px 129px;
    background: #6F5DE7;
    border-radius: 4px;
    color: #ffffff;
    width: 100%;
  }
`;
