import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { BuildNowPayments } from "../../api/types";
import { OUT_RIGHT_INSTALLMENT_PAYMENT } from "../../api/query/servicePlan/payment";

export function useGetOutRightInstallmentPayment() {

    const {data, loading} = useQuery(OUT_RIGHT_INSTALLMENT_PAYMENT)

    const paymentData:BuildNowPayments = data?.currentBuildNowPhase?.payment;


    // backdrop loadinng funnction 
    const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

    const handleBackDropClose = () => setLoadingBackDrop(false);

    const handleBackDropOpen = () => setLoadingBackDrop(true);

    useMemo(() => {

        (loading) && handleBackDropOpen();

        (!loading) && handleBackDropClose();

    }, [loading]);

    return {...paymentData,loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen,data,loading}
}