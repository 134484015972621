import { styled } from "styled-components";
import bImg from "../../../../assets/img.png";
import toiletIcon from "../../../../assets/toilet.png";
import bedroomIcon from "../../../../assets/bedrom.png";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { mediaQuery } from "../../../../breakPoint";
import { propertyChoiceDetails } from "../../../../links";
import { useNavigate } from "react-router-dom";

export function PremiumPropertyChoice() {
    const navigate = useNavigate()
 
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>SELECT BUILDING</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <ImageDiv>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <Text>
                  <H2>Plan 23h4B</H2>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragraph>
                </Text>
                <Line></Line>
                <IconDetailsDiv>
                  <IconD>
                    <img src={toiletIcon} alt="" /> 4 Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroomIcon} alt="" /> 3 Bedrooms{" "}
                  </IconD>
                </IconDetailsDiv>
                <BTN>
                  <SaveButtonComponent
                    name="View Details"
                    onClick={() => navigate(propertyChoiceDetails)}
                  />
                </BTN>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <Text>
                  <H2>Plan 23h4B</H2>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragraph>
                </Text>
                <Line></Line>
                <IconDetailsDiv>
                  <IconD>
                    <img src={toiletIcon} alt="" /> 4 Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroomIcon} alt="" /> 3 Bedrooms{" "}
                  </IconD>
                </IconDetailsDiv>
                <BTN>
                  <SaveButtonComponent
                    name="View Details"
                    onClick={() => navigate(propertyChoiceDetails)}
                  />
                </BTN>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <Text>
                  <H2>Plan 23h4B</H2>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragraph>
                </Text>
                <Line></Line>
                <IconDetailsDiv>
                  <IconD>
                    <img src={toiletIcon} alt="" /> 4 Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroomIcon} alt="" /> 3 Bedrooms{" "}
                  </IconD>
                </IconDetailsDiv>
                <BTN>
                  <SaveButtonComponent
                    name="View Details"
                    onClick={() => navigate(propertyChoiceDetails)}
                  />
                </BTN>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <Text>
                  <H2>Plan 23h4B</H2>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragraph>
                </Text>
                <Line></Line>
                <IconDetailsDiv>
                  <IconD>
                    <img src={toiletIcon} alt="" /> 4 Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroomIcon} alt="" /> 3 Bedrooms{" "}
                  </IconD>
                </IconDetailsDiv>
                <BTN>
                  <SaveButtonComponent
                    name="View Details"
                    onClick={() => navigate(propertyChoiceDetails)}
                  />
                </BTN>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <Text>
                  <H2>Plan 23h4B</H2>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragraph>
                </Text>
                <Line></Line>
                <IconDetailsDiv>
                  <IconD>
                    <img src={toiletIcon} alt="" /> 4 Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroomIcon} alt="" /> 3 Bedrooms{" "}
                  </IconD>
                </IconDetailsDiv>
                <BTN>
                  <SaveButtonComponent
                    name="View Details"
                    onClick={() => navigate(propertyChoiceDetails)}
                  />
                </BTN>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <Text>
                  <H2>Plan 23h4B</H2>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragraph>
                </Text>
                <Line></Line>
                <IconDetailsDiv>
                  <IconD>
                    <img src={toiletIcon} alt="" /> 4 Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroomIcon} alt="" /> 3 Bedrooms{" "}
                  </IconD>
                </IconDetailsDiv>
                <BTN>
                  <SaveButtonComponent
                    name="View Details"
                    onClick={() => navigate(propertyChoiceDetails)}
                  />
                </BTN>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <Text>
                  <H2>Plan 23h4B</H2>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragraph>
                </Text>
                <Line></Line>
                <IconDetailsDiv>
                  <IconD>
                    <img src={toiletIcon} alt="" /> 4 Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroomIcon} alt="" /> 3 Bedrooms{" "}
                  </IconD>
                </IconDetailsDiv>
                <BTN>
                  <SaveButtonComponent
                    name="View Details"
                    onClick={() => navigate(propertyChoiceDetails)}
                  />
                </BTN>
              </ImageContent>
              <ImageContent>
                <IMG src={bImg} alt="" />
                <Text>
                  <H2>Plan 23h4B</H2>
                  <Paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragraph>
                </Text>
                <Line></Line>
                <IconDetailsDiv>
                  <IconD>
                    <img src={toiletIcon} alt="" /> 4 Toilet{" "}
                  </IconD>
                  <IconD>
                    <img src={bedroomIcon} alt="" /> 3 Bedrooms{" "}
                  </IconD>
                </IconDetailsDiv>
                <BTN>
                  <SaveButtonComponent
                    name="View Details"
                    onClick={() => navigate(propertyChoiceDetails)}
                  />
                </BTN>
              </ImageContent>
            </ImageDiv>
          </FinanceContent>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 2em;
  margin-left: 2em;
  display: flex;
  width: 100%;
  gap: 5em;
  z-index: 1;
  ${mediaQuery.tablet} {
    margin-inline: auto;

    margin: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
   /* width: 50%; */
`;

export const FinanceContent = styled.div`
  width: 100%;
  margin-inline: auto;
`;
export const FinanceHeader = styled.div`
  margin-bottom: 1.5em;
`;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    font-size: 1.2rem;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 15px;
  /* max-width: 420px;
  max-height: 53px; */
  color: #797474;
  margin-top: 1em;
  width: 100%;
  ${mediaQuery.mobile} {
    font-size: 14px;
    line-height: 19px;
  }
`;

const ImageDiv = styled.div`
  display: block;
  /* width: 100%; */
  padding: 0 !important;
  @media (min-width: 1395px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* display: flex; */
    /* flex-wrap: wrap; */
    gap: 0.5em;
    margin-top: 1.5em;
    margin-inline: auto;
    justify-content: center;
    width: 100%;
  }
`;

const IMG = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;
const Text = styled.div`
  margin-top: 0.8em;
  width: 100%;
`;

const Line = styled.div`
  border: 0.5px solid #bbbbbb;
  width: 100%;
  margin-top: 1em;
`;

const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  color: #000000;
  ${mediaQuery.mobile} {
    font-size: 16px;
    line-height: 30px;
    width: 100%;
  }
`;

const Paragraph = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  color: #797474;
  width: 100%;
  ${mediaQuery.mobile} {
    font-size: 14px;
    line-height: 25px;
  }
`;
const ImageContent = styled.div`
  /* min-width: 342px; */
  margin-bottom: 1em;
  
  ${mediaQuery.tablet} {
    width: 100%;
    min-width: 100%;
  }
`;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;

const BTN = styled.div`
  margin-top: 1.5em;
  /* margin-bottom: ; */
  && {
    button {
      padding: 1em;
    }
  }
`;

const IconDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  margin-top: 1em;
`;

const IconD = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;
