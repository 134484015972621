import { ChangeEvent, MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  GoodMarkIcon,
  PremiumIcon,
  SimplePlanIcon,
} from "../../../../../../utils/svg";
import { SaveButtonComponent } from "../../../../../../utils/buttons/saveButton";
import { styled } from "styled-components";
import { mediaQuery } from "../../../../../../breakPoint";
import { initializeProject, noExistingCustomPlan } from "../../../../../../links";
import { useMutation } from "@apollo/client";
import { EXCLUSIVE_PLAN_TYPE } from "../../../../../../api/mutatation/phase2Plan/exclusivePlan";
import { useNotificationToast } from "../../../../../../hooks/useToast";

enum ServiceType {
  existing = "EXISTING",
  new = "NEW",
}

export function ExclusivePlan() {

  const navigate = useNavigate();
 
  const[buildNowLandType,{loading}] = useMutation(EXCLUSIVE_PLAN_TYPE)

  const {errorToast, successToast} = useNotificationToast()

  const [selectedService, setSelectedService] = useState<ServiceType | null>(null); // selected state

  const handleServiceSelect = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedService(event.target.value as ServiceType);
  };

  // check value slected and route accordingly when proceed button is clicked
  async function proceedHandlick(event: MouseEvent<HTMLElement>) {
    try {
     await buildNowLandType({variables:{payload:{"land_type": selectedService}}});

      successToast("successful!")

      switch (selectedService) {

        case "EXISTING":

          return navigate(`/${initializeProject}`);

        case "NEW":

          return navigate(`/${noExistingCustomPlan}`);

        default:
          return null;
      }

    }
    catch (error) {
      const err: string | any = error
      const errMessage = Array?.isArray(err?.message) ?err?.message[0]: err?.message;

      errorToast(errMessage || "An Error Occurred")
    }
  }
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>Exclusive Plan</H1>
              <P>
                You are about to create a new aid request onsectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua.
              </P>
            </FinanceHeader>
            <SelfEmplyedMain>
              <ExclusivePlanDiv isselected={selectedService === ServiceType.existing}>
                <Radio
                  type="radio"
                  value={ServiceType.existing}
                  checked={selectedService === ServiceType.existing}
                  onChange={handleServiceSelect}
                />
                <ExclusiveContent>
                  <SimplePlanIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.existing}>
                      Build on Existing Land
                    </H2>
                    <ExclusivePlanDetails
                      isselected={selectedService === ServiceType.existing}
                    >
                      Build a custom made design on your existing land, we would
                      tailor a building plan according to your requests and
                      procure a permit for it
                    </ExclusivePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </ExclusiveContent>
              </ExclusivePlanDiv>
              <ExclusivePlanDiv isselected={selectedService === ServiceType.new}>
                <Radio
                  type="radio"
                  value={ServiceType.new}
                  checked={selectedService === ServiceType.new}
                  onChange={handleServiceSelect}
                />
                <ExclusiveContent>
                  <PremiumIcon />
                  <Text>
                    <H2 isselected={selectedService === ServiceType.new}>
                      No Existing Land
                    </H2>
                    <ExclusivePlanDetails
                      isselected={selectedService === ServiceType.new}
                    >
                      No Existing Land, we would help you procure a land that
                      fits your requests and develop a bespoke building plan
                    </ExclusivePlanDetails>
                  </Text>
                  <Icon>
                    <GoodMarkIcon className="goodmark" />
                  </Icon>
                </ExclusiveContent>
              </ExclusivePlanDiv>
            </SelfEmplyedMain>
          </FinanceContent>
          <BtnDiv>
            <SaveButtonComponent
            loading = {loading}
              className="btn"
              name="Save and continue"
              onClick={proceedHandlick}
            />
          </BtnDiv>
        </Finance>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-top: 2em;
  width: 70%;
  margin-left: 20em;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    margin: auto;
    width: 100%;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin-top: 2em;
  width: 80%;
  margin-inline: auto;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    margin: 4em auto 1em;
    width: 90%;
  }
  ${mediaQuery.mobile}{
    margin-top: 1.5em;
  }
  
`;

export const FinanceContent = styled.div`
  width: 100%;
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  margin-top: 1em;
  width: 70%;
  ${mediaQuery.tablet}{
    width: 100%;
  }
`;
const SelfEmplyedMain = styled.div`
  margin-top: 2.375em;
  display: flex;
  gap: 2em;
  align-items: center;
  ${mediaQuery.tablet}{
    flex-direction: column;
  }
`;

const ExclusivePlanDiv = styled.label<{ isselected: boolean }>`
  /* height: 160px; */
  border: 1px solid #0084fe;
  border-radius: 4px;
  /* width: 70%; */
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
  ${mediaQuery.mobile} {
    padding: 1em;
  }

  cursor: pointer;
  background: ${(props) => (props.isselected ? "rgba(0, 49, 122, 1)" : "")};
  svg {
    fill: ${(props) => (props.isselected ? "#fff" : "#00317a")};
    ${mediaQuery.tablet} {
      width: 29.27px;
      min-width: 10% !important;
    }
  }

  &:hover {
    background: rgba(0, 49, 122, 1);
    p,
    h3 {
      color: #fff !important;
    }
    transition: none;
    svg {
      fill: #fff;
      transition: none;
    }
  }
`;
const Radio = styled.input.attrs({
  type: "radio",
})`
  visibility: hidden;
`;
const ExclusiveContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  ${mediaQuery.mobile} {
    gap: 1em;
  }
  .goodmark {
    height: 56.686790466308594px;
    width: 42px;
    border-radius: 0px;
    ${mediaQuery.mobile} {
      width: 22.77px;
    }
  }
`;
const Icon = styled.div`
  svg {
    fill: #fff;
  }
`;

const Text = styled.div`
  width: 60%;
  ${mediaQuery.tablet} {
    width: auto;
  }
`;
const H2 = styled.h3<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  margin-bottom: 0.5em;
  ${mediaQuery.mobile} {
    font-size: 12.8077px;
    line-height: 16px;
  }
  color: ${(props) => (props.isselected ? "#fff" : "#0084fe")};
`;
const ExclusivePlanDetails = styled.p<{ isselected: boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 15px;
  color: ${(props) => (props.isselected ? "#fff" : "#797474")};
  ${mediaQuery.mobile} {
    font-size: 0.75rem;
    line-height: 15px;
    width: 100%;
  }
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 70%; */
  ${mediaQuery.tablet} {
    width: 100% !important;
  }
  .btn {
    padding: 1em !important;
    ${mediaQuery.tablet} {
    }
  }
`;

// const ChartConponentDiv = styled.div`
//   position: fixed;
//   right: 6em;
//   ${mediaQuery.tablet} {
//     right: 2em;
//     bottom: 1.408125em;
//   }
//   ${mediaQuery.mobile} {
//     right: 1em;
//     bottom: 1em;
//   }
// `;
