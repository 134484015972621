import { styled } from "styled-components";
import { ChartIconComponent } from "../../../utils/ChartBtn";
import { Avatar, Skeleton } from "@mui/material";
import { ContactPerson } from "../../../components/contactPerson";
import { mediaQuery } from "../../../breakPoint";
import { useUserProfileDetails } from "../../../hooks/useProfile";
import { useCallback, useEffect, useMemo, useState } from "react";
import SimpleBackdrop from "../../../utils/backdrop";
import { Link } from "react-router-dom";



export function PersonalInfo() {

  const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

  const { loading, profileImg, firstname, fullName, lastname, email, kyc,refetch } = useUserProfileDetails();

  const handleClose = () => setLoadingBackDrop(false);

  const handleOpen = () => setLoadingBackDrop(true);

  // render backdrop when loading 
  useMemo(() => {

    (loading) && handleOpen();

    (!loading) && handleClose();

  }, [loading]);


  const memoizedRefetch = useCallback(() => {
    refetch();
  }, [refetch]);
  

  useEffect(() => {
    memoizedRefetch();
  }, [memoizedRefetch]);

  


  return (
    <>
      {/* loading */}
      <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>PROFILE</H1>
              <div className="avatar">
                <Avatar
                  src={profileImg as string}
                  sx={{ width: 70, height: 70 }}
                />
              </div>
            </FinanceHeader>
            <SelfEmplyedMain>
              <ContactInfoDiv>
                <Content>
                  <Header>
                    <H2>BASIC INFO</H2>
                    {/* <EditIcon
                      className="edit"
                      onClick={() => setDisabled(!disabledd)}
                    /> */}
                  </Header>
                  {/* <Paragragh>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Paragragh> */}
                  <DetailsContainer>
                    <DETAILS>
                      <H3>FIRSTNAME</H3>
                      <input
                        type="text"
                        disabled
                        defaultValue={firstname as string}
                      />
                    </DETAILS>
                    <Line></Line>
                  </DetailsContainer>
                  <DetailsContainer>
                    <DETAILS>
                      <H3>Last name</H3>
                      <input
                        disabled
                        type="text"
                        defaultValue={lastname as string}
                      />
                    </DETAILS>
                    <Line></Line>
                  </DetailsContainer>
                  <DetailsContainer>
                    <DETAILS>
                      <H3>EMAIL</H3>
                      <input
                        disabled
                        type="email"
                        defaultValue={email}
                      />
                    </DETAILS>
                    <Line></Line>
                  </DetailsContainer>
                  <DetailsContainer>
                    <DETAILS>
                      <H3>PHONE NUMBER</H3>
                      <input
                        disabled
                        type="text"
                        defaultValue={kyc?.phone === null || kyc === null ? "Not Available" : kyc?.phone}
                        className={kyc?.phone === null || kyc === null ? "input_address" : "verified_address"}
                      />
                    </DETAILS>
                    {kyc === null || kyc?.phone === null ?(<Link to="/kyc-verifcation" ><button >Verify Now</button></Link>): ""}
                    <Line></Line>
                  </DetailsContainer>
                  <DetailsContainer>
                    <DETAILS>
                      <H3>ADDRESS</H3>
                      <input
                        disabled
                        type="text"
                        defaultValue={kyc?.address === null || kyc === null ? "Not Available" : kyc?.address}
                        className={kyc?.address === null || kyc === null ? "input_address" : "verified_address"}
                      />
                    </DETAILS>
                    {kyc === null || kyc?.phone === null ?(<Link to="/kyc-verifcation" ><button >Verify Now</button></Link>): ""}
                    <Line></Line>
                  </DetailsContainer>
                </Content>
              </ContactInfoDiv>
            </SelfEmplyedMain>
             {loading ? (
                <Skeleton  animation="wave" variant="text" sx={skeleton} />
              ) :(
                <ContactPerson
                  header="CONTACT PERSON"
                  name={fullName}
                  email={email}
                />
              )}
          </FinanceContent>
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}


const skeleton = {
  fontSize: "1rem",
  background: "#e3e3e3",
  width: "100%",
  padding: ".5em 7em 1em 1em",
};


export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  margin-left: 20em;
  width: 65%;

  ${mediaQuery.tablet} {
    margin: 2em auto 5em;
    width: 100%;
  }
  ${mediaQuery.mobile} {
    width: 88%;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
  width: 60%;
  ${mediaQuery.tablet} {
    width: 80%;
  }
  ${mediaQuery.mobile} {
    width: 95%;
  }
`;

export const FinanceContent = styled.div``;
export const FinanceHeader = styled.div`
  .avatar {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
    border: 2px solid #0c5be9;
    width: fit-content;
    border-radius: 50%;
    padding: 0.2em;
  }
`;
export const H1 = styled.h1`
  margin-bottom: 2em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
`;
const SelfEmplyedMain = styled.div``;

const ContactInfoDiv = styled.div`
  margin-top: 2.375em;
  margin-bottom: 3.5em;
`;

const Content = styled.div``;

const Header = styled.div`
  /* width: 432px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8125em;
  .edit {
    cursor: pointer;
  }
`;
const H2 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;

  color: #00317a;
`;
// const Paragragh = styled.p`
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 300;
//   font-size: 1rem;
//   line-height: 19px;
//   width: 100%;
//   color: #797474;
//   ${mediaQuery.tablet} {
//     /* width: 100%; */
//   }
// `;

const DetailsContainer = styled.div`
button{
  padding: 10px;
  outline: none;
  border: none;
  color: #fff;
  background: #0084fe;
  border-radius: 4px;
  cursor: pointer;
}
`;


const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .input_address {
    color: rgb(238, 188, 38) !important;
    font-weight: 500;
  }

  .verified_address{
    color: #0e0e0e;
    font-weight: 500;
  }
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
    ${mediaQuery.tablet} {
      text-align: right;
    }
  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;
