import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
query UserProfile {
  userProfile {
    email
    firstname
    lastname
    profileImg
    kyc {
      country {
        code
        name
      }
      address
      residential_status
      document_type
      document_image {
        front
        back
      }
      document_status
      phone
      phone_status
      
    }
  }
}
`;
