import { GoogleLogin } from "@react-oauth/google";
import styled from "styled-components";

export const GoogleButton = styled(GoogleLogin)`
  width: 100%;
 
 
`

export const CREATEACCOUNT = styled.div`
  @media (min-width: 1020px) {
    background: #c6c6c6;
    height: 100%;
    min-height: 100vh;
    /* position: relative; */
   padding-top: 1rem;
   padding-bottom: 2rem;
  }
  .google{
    margin: auto;
    text-align: center;
  }
`;

export const CreateAccountMain = styled.div`
  padding: 1em;
  .password{
    margin-top: .5em !important;
    color: #6F5DE7;
    text-align: right;
    font-size: .9rem;
  }
`;

export const BackgroundCircle = styled.div``;

export const HeaderText = styled.div`
  /* width: 96px; */
  max-width: 80%;
  margin-top: 1.836em;
`;

export const Register = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #6F5DE7;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  color: #797474;
  margin-top: 1em;
`;

export const CreateAccountInputForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 2em;
  /* width: 90%; */
`;

export const CreateAccountContent = styled.div`
  width: 100%;
  padding: 1.5em;
  @media (min-width: 600px) {
    width: 530px;
    margin-top: 8.5em;
  }
  @media (min-width: 900px) {
    width: 500px;
    margin-top: 6em;
  }
  @media (min-width: 1020px) {
    width: 500px;
    margin-top: 0em;
    background: #fff;
    border-radius: 3px;
    padding: 2em;
    z-index: 1;
  }

  span {
    font-weight: 400;
    font-size: .9rem;
    .link {
      color: #6F5DE7;
    }
  }
`;

export const InputContainers = styled.div`
  margin: auto;
`;

export const LineContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1em;
`;

export const Line = styled.div`
  border: 0.5px solid #000000;
  width: 45%;
`;

export const Or = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

export const ImageGoogle = styled.img`
  width: 100%;
  margin-top: 1.3em;
  cursor: pointer;
`;

export const BackgroundImage = styled.img`
  display: none;
  @media (min-width: 1020px) {
    width: 70%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const BackgroundImageRight = styled.img`
  display: none;
  @media (min-width: 1020px) {
    width: 40%;
    display: block;
    position: absolute;
    right: 0;
    bottom: 4em;
  }
`;