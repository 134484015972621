import { styled } from "styled-components";
import { mediaQuery } from "../../../../breakPoint";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { useFinanceVerification } from "../../../../hooks/useFinanceVerification";
import { EmploymentStatusSelect } from "../../../../utils/employmentStatusSelect";
import { SelectChangeEvent } from "@mui/material";
import { CustomAidFinanceEmployed } from "./employed";

export function PremiumFinancialVerification() {

    const {
        EmployementStatus,
        setEmploymentStatus,
    
        // EMPLOYED
        employerName,
        setEmployerName,
        employedJobDescription,
        setEmployedJobDescription,
        workAddress,
        setWorkAddress,
        salary,
        setSalary,
        // image file
    
        setEmployedUserBankStatements,
        // work id
       
        setEmployedUserWorkIDFront,
    
        setEmployedUserWorkIDBack,
        // emplyed user ends ÷
      } = useFinanceVerification();

      const valueItems = ["Employed", "Self Employed"];

      const handleChange = (event: SelectChangeEvent<string>) => {
        if (setEmploymentStatus) {
          setEmploymentStatus(event?.target.value as string);
        }
      };
    return(
        <>
        <FinanceContainer>
          <Finance>
            <FinanceContent>
              <FinanceHeader>
                <H1>FINANCE VERIFICATION</H1>
                <P>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </P>
              </FinanceHeader>
              <SelectInputDiv>
                <EmploymentStatusSelect
                  SelectLabel="Employment status"
                  defaultName="select"
                  valueItems={valueItems}
                  setEmploymentStatus={setEmploymentStatus}
                  handleChange={handleChange}
                  EmployementStatus={EmployementStatus}
                />
              </SelectInputDiv>
                <CustomAidFinanceEmployed
                  employerName={employerName}
                  setEmployerName={setEmployerName}
                  employedJobDescription={employedJobDescription}
                  setEmployedJobDescription={setEmployedJobDescription}
                  workAddress={workAddress}
                  setWorkAddress={setWorkAddress}
                  salary={salary}
                  setSalary={setSalary}
                  // image file
                  setEmployedUserBankStatements={setEmployedUserBankStatements}
                  // work id
                  setEmployedUserWorkIDFront={setEmployedUserWorkIDFront}
                  setEmployedUserWorkIDBack={setEmployedUserWorkIDBack}
                />
            </FinanceContent>
            <SaveButtonComponent
              className="btn"
              name="Next"
            />
          </Finance>
          <ChartConponentDiv>
            <ChartIconComponent />
          </ChartConponentDiv>
        </FinanceContainer>
        </>
    )
}



export const FinanceContainer = styled.form`
  margin-bottom: 5em;
  margin-top: 2em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  /* margin-left: 2em; */
  margin-inline: auto;
  width: 100%;
  ${mediaQuery.tablet} {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin-inline: auto;
  width: 80%;
  ${mediaQuery.tablet} {
    width: 100%;
  }
  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
    }
  }
`;

export const FinanceContent = styled.div``;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 1em;
  ${mediaQuery.tablet} {
    font-size: 18px;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  ${mediaQuery.mobile} {
    width: 100%;
  }
`;

const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
