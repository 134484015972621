import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useQuery } from '@apollo/client';
import { COUNTRY_ } from '../../api/query/country';
import { styled } from 'styled-components';

export interface CountryProps {
  name?: string;
  code?: string;
  label?: number;
  countries?: readonly CountryType[];
  phone?: number;
  onSelect?: (value: CountryType | null) => void;
  onBlur?: () => void;
  error? : string
}


const SelectStyle = {
  width: "100%",
  marginTop: "2em"
}

export default function CountrySelect(props: CountryProps) {

  const { onSelect } = props;
  const [selected, setSeleected] = React.useState<CountryType | null>(null);
  const [country, setCountryList] = React.useState<CountryType[]>([])


  const { data } = useQuery(COUNTRY_);

  React.useMemo(() => {
    // let country: CountryType[] = []
    if (data) {
      const countries: CountryType[] = data?.getCountries.map((country: any) => ({
        code: country?.code ?? undefined,
        label: country?.name,

      }));
      setCountryList(countries)
    }
  }, [data, setCountryList]);


  const handleSelect = (value: CountryType | null) => {
    setSeleected(value);
    if (onSelect) {
      onSelect(value);
    }
  };


  return (
    <>
      <Autocomplete
        onBlur={props.onBlur}
        id="country-select-demo"
        sx={SelectStyle}
        options={country}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label} ({option.code}) +{option.phone}
          </Box>

        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a country"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        onChange={(event, value) => handleSelect(value)}
        value={selected}
      />
      <ValidationLabel>
        {props.error}
      </ValidationLabel>
    </>
  );
}

export interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}


export const ValidationLabel = styled.label`
  margin-left: .6em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: rgba(206, 43, 43, 1);
  font-size: .9rem;
  margin-top: .5em;
  `

