import { styled } from "styled-components";
import { Button } from "@mui/material";
import { CreateIcon } from "../../utils/svg";
import { useNavigate } from "react-router-dom";
import { kyc } from "../../links";
// import { kycVerificationLink } from "../../links";
interface BoardIcon {
    name?: string;
    text: string;

}
export function NewProjectBoard(props:BoardIcon) {
  const navigate = useNavigate()
  return (
    <>
      <DashboardMain>
        <div className="main">
          <div className="content">
            <h3>{props.name}</h3>
            <div className="line"></div>
            <div className="icon">
              <CreateIcon />
            </div>
            <div className="text">
              <p>
               {props.text}
              </p>
            </div>
            <Button onClick={() => navigate(kyc)} className="btn" variant="contained" sx={buttonStyle}>
              Start New Project
            </Button>
          </div>
        </div>
      </DashboardMain>
    </>
  );
}
// button
const buttonStyle = {
  background: "#6F5DE7",
  borderRadius: "26px",
  textTransform: "none",
  marginTop: "1.2em",
  fontSize: ".7rem",
};
const DashboardMain = styled.div`
  @media (min-width: 1020px) {
    width: 55%;
  }
  @media (min-width: 1140px) {
    width: 100%;
  }
  .main {
    margin-inline: auto;
    padding: 1em;
    background: #ffffff;
    border: 0.5px solid #e1e1e1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    text-align: center;

    .content {
      h3 {
        text-align: left !important;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #3f3f3f;
        padding: 0.8em;
      }
      .line {
        border: 0.5px solid #cfcfcf;
        width: 95%;
        margin: auto;
      }
      .icon {
        margin-bottom: 0.5em;
        margin-top: 0.5em;
      }
      .text {
        @media (min-width: 1020px) {
          margin-top: 1em;
          margin-bottom: 1em;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          color: rgba(0, 49, 122, 0.51);
          @media (min-width: 1020px) {
            font-size: 14px;
            width: 70%;
            text-align: center !important;
            margin: auto;
          }
        }
      }
    }
  }
`;
