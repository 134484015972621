import { EmploymentStatusSelectProps } from "../../types";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { styled } from "styled-components";

export function EmploymentStatusSelect({
  defaultName,
  SelectLabel,
  valueItems,
  EmployementStatus,
  handleChange,
}: EmploymentStatusSelectProps) {
  return (
    <>
      <FormControl fullWidth>
        <FormHelperText sx={FormHelperTexts}>{SelectLabel}</FormHelperText>
        <Select required value={EmployementStatus} onChange={handleChange} displayEmpty>
          <MenuItem value="">{defaultName}</MenuItem>
          {valueItems ? (
            valueItems?.map((status, index) => (
              <MenuItem key={index} value={status}>
                {status}
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Option Availble</MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
}

export const FormControls = styled(FormControl)`
  min-width: 100%;
  width: 433px;
  /* z-index: -1; */
`;

export const FormHelperTexts = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "1.1rem",
  lineHeight: "19px",
  textAlign: "left",
  color: "#525252",
  marginBottom: "0.5625em",
  marginTop: "1.8em",
};
