import { useQuery } from "@apollo/client";

import { GET_USER_PROFILE } from "../api/query/getProfile";
import { useNotificationToast } from "./useToast";
import { UserAccount } from "../api/types";


// type UserKyc = {
//     country: {
//         code: string;
//         name: string;
//     };
//     address: string;
//     residential_status: string;
//     bvn: string;
//     bvn_status: string;
//     document_type: string;
//     document_image: {
//         front: string;
//         back: string;
//     };
//     document_status: string;
//     phone: string;
//     phone_status: string;
//     createdon: string;
// }


// type UserProfile = {
//     email: string;
//     firstname: string;
//     profileImg: string;
//     lastname: string;
//     kyc: UserKyc | null;
// };


export function useUserProfileDetails() {
    const { loading, data, error, refetch } = useQuery(GET_USER_PROFILE);

    const { errorToast } = useNotificationToast();

    const userData: UserAccount = data?.userProfile

    const fullName = `${userData?.firstname} ${userData?.lastname}`;

    // error && errorToast(error?.message || "An Error Occured");

    return { loading, fullName, ...userData,errorToast,error, refetch };
}
