import { gql } from "@apollo/client";

export const COSTING = gql`
query CurrentBuildNowPhase {
  currentBuildNowPhase {
    paymentTerms
    costing {
      duration
      land_verification_cost
      permit_cost
      substructure_cost
      superstructure_cost
    }
  }
}
`;
