import { Alert, Box, Button, CircularProgress, Modal, Snackbar } from "@mui/material";
import { styled } from "styled-components";
import { PhoneNumberOtpProps } from "../../../types";
import { SaveButtonComponent } from "../../../utils/buttons/saveButton";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate } from "react-router-dom";
import { mediaQuery } from "../../../breakPoint";
import { useMutation } from "@apollo/client";
import { PHONE_OTP_VERIFIACTION } from "../../../api/mutatation/phoneOtp";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { phoneVerificationLink } from "../../../links";
import { setOtpPostResponse } from "../../../slice/phoneOtpSuccess";
import { RootState } from "../../../store";
import { LoadingButton } from "@mui/lab";
import { useNotificationToast } from "../../../hooks/useToast";
const style = {
  boxShadow: 24,
};
export function OtpModalComponent(props: PhoneNumberOtpProps) {

  const {successToast} = useNotificationToast()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [value, setValue] = useState<string>("");

  const [isOtpButtonDisabled, setIsOtpButtonDisabled] = useState<boolean>(true);

  const [verifyPhoneOTP, { error, loading }] = useMutation(PHONE_OTP_VERIFIACTION)   // api call 

  const phoneNumberSubmitted = useSelector((state: RootState) => state?.phoneSubmitSuccess?.phone)

  const handleChange = (event: ChangeEvent<HTMLInputElement> | string) => {
    const newValue = typeof event === 'string' ? event : event.target.value;
    setValue(newValue);
  };



  const handleComplete = async (finalValue: string) => {
    try {

      const { data } = await verifyPhoneOTP({
        variables: {
          payload: {
            token: finalValue !== "" ? finalValue : value,
            phone: phoneNumberSubmitted
          }
        },
      });
      dispatch(setOtpPostResponse(data?.verifyPhoneOTP))
      navigate(phoneVerificationLink);

      successToast(data?.message ?? "Successful!")

    } catch (error) {
      console.error(error)
    }
  }


  // submit with button 
  async function handleSubmit(event: FormEvent) {

    event.preventDefault();

    try {
      const { data } = await verifyPhoneOTP({variables: {payload: value}, });

      dispatch(setOtpPostResponse(data?.verifyPhoneOTP))

      navigate(phoneVerificationLink);

      successToast(data?.message ?? "Successful!")
    } catch (error) {

      console.error(error);
    }
    
  }

  const [remainingTime, setRemainingTime] = useState<number>(60);
  const [resendDisabled, setResendDisabled] = useState<boolean>(true);

  useEffect(() => {

    if(value.length === 6){
      setIsOtpButtonDisabled(false)
    }
    if(value.length !== 6){
      setIsOtpButtonDisabled(true)
    }

    if (remainingTime > 0) {
      const timerId = setTimeout(() => { setRemainingTime((prevRemainingTime) => prevRemainingTime - 1); }, 1000);

      return () => clearTimeout(timerId);
    }

    else {
      setResendDisabled(false)
    }

  }, [remainingTime, setResendDisabled,setIsOtpButtonDisabled,value]);

  useEffect(() => timer(remainingTime), [remainingTime]);  // Call the timer function on component mount



  const timer = (remaining: number) => {
    // let m: number | string = Math.floor(remaining / 60);
    // let s: number | string = remaining % 60;

    // m = m < 10 ? "0" + m : m;
    // s = s < 10 ? "0" + s : s;

    remaining -= 1;

    if (remaining >= 0) {
      setTimeout(function () {
        setRemainingTime(remaining);
      }, 1000);
    }

    else {
      setResendDisabled(false); // Enable the resend button after timeout
    }

  };

  const handleResendOTP = () => {
    // Perform the logic to resend OTP
    setRemainingTime(120); // Reset the timer to 120 seconds
    setResendDisabled(true); // Disable the resend button
  };



  return (
    <>
      <Main>
        <ModaL
          open={true}
          onClose={props.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BoxDiv sx={style}>
            {error && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={error ? true : false}
                autoHideDuration={6000}
              >
                <Alert sx={{ textTransform: "capitalize" }} severity="error">{error?.message}</Alert>
              </Snackbar>
            )}
            <Content>
              <Text>
                <h2>{props?.name}</h2>
                <p>{props?.description}</p>
              </Text>
              <div className="input_container">
                {loading === true && (
                  <div className="loader" >
                    <CircularProgress />
                  </div>)}
                <MuiOtpInputStyled
                  value={value}
                  onChange={handleChange}
                  onComplete={handleComplete}
                  length={6}
                  validateChar={(character: string, index: number) => true}
                />
              </div>
              <p className="remaining">Remaining Time: {remainingTime}</p>
              <BUTTON onClick={handleResendOTP} disabled={resendDisabled} variant="text" size="small" >Resend Otp</BUTTON>
              <SaveButtonComponent disabled = {isOtpButtonDisabled} onClick={handleSubmit} name={props.buttonName} />
              <StyledButton onClick={() => navigate(-1)} variant="text">Cancel</StyledButton>
            </Content>
          </BoxDiv>
        </ModaL>
      </Main>
    </>
  );
}

const Main = styled.main``;

const ModaL = styled(Modal)`
  width: 60%;
  margin: auto;
  ${mediaQuery.mobile}{
    width: 50%;
  }
`;

const BoxDiv = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background: #ffffff;
  border-radius: 4px;

  padding: 1.5em;
`;

const Content = styled.div`
  .input {
    padding: 1.2em;
  }
  .input_container {
    margin-bottom: 2.5em;
    position: relative;
    .loader{
    position: absolute;
    top: 50%;
    left: 50%;
}
  }
`;
const Text = styled.div`
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.1275rem;
    line-height: 22px;
    color: #00317a;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 19px;
    margin-top: 1em;
    color: #797474;
  }
`;

const StyledButton = styled(Button)`
  && {
    background: #ffffff;
    border: 1px solid #00317a;
    border-radius: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: #00317a;
    width: 100%;
    margin-top: 1em;
    padding: 0.7em;
  }
`;

const MuiOtpInputStyled = styled(MuiOtpInput)`
  max-width: 650px;
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 2.1em;
  ${mediaQuery.tablet}{
    width: 100%;
  }
`;


const BUTTON = styled(LoadingButton)`
  margin-top: 1em;
`;
