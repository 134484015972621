import { styled } from "styled-components";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { FileUpload } from "../../../../components/imageUploads/fileUpload";
import { PaymentDetailsComponent } from "../../../../components/paymentList/outright";
import { DownloadBtnComponent } from "../../../../utils/buttons/downloadBtn";
import { CustomAlert } from "../../../../utils/customPlanAlert";
import { useGetOutRightInstallmentPayment } from "../../../../hooks/payment/usePayment";
import SimpleBackdrop from "../../../../utils/backdrop";
import { useDateMoment } from "../../../../hooks/useMomentDate";
import { useMemo } from "react";

export function PayInitialDeposit() {

  const { convertDate, formattedDate } = useDateMoment()

  const {createdon, status, setLoadingBackDrop, loadingBackDrop, handleBackDropClose, handleBackDropOpen, loading } = useGetOutRightInstallmentPayment()

  useMemo(() => convertDate({ dateString: createdon }), [convertDate, createdon,])

  return (
    <>
      {loading ? (<SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />):(
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>PAY INITIAL DEPOSIT</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <PaymentDiv>
              <PaymentDetailsComponent />
            </PaymentDiv>
            <BtnDiv>
              <DownloadBtnComponent className="download_btn" variant="outlined" />
            </BtnDiv>
            <AlertContainer>
              <CustomAlert
                 className={
                   status === "PENDING" ?
                     "pending_status" :
                     status === "FAILED" ?
                       "rejected_status" :
                       status === "PAID" ?
                         "verified" : "pending_status"
                 }
                 status={status|| "Unvailable"}
                 date={formattedDate ?? "loading Date"}
              />
            </AlertContainer>
            <Text>
              <P className="p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </Text>
            <FileUpload
              name="Upload Proof of Payment"
              label="Proof of payment"
            />
          </FinanceContent>
          <SaveButtonComponent name="Proceed" />
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>)}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 4em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  margin-left: 2em;
  width: 100%;
  @media (max-width: 682px) {
    margin-inline: auto;
    margin-top: 0;
  }
  .p{
    text-align: center;
  margin: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
width: 100%;
margin-inline: auto;
@media (max-width: 682px) {
  width: 100% !important;
  margin: auto;
}
`;

export const FinanceContent = styled.div``;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;
color: #000000;
@media (max-width: 440px) {
  font-size: 1.2rem;
}
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  margin-top: 0.8em;
 @media (max-width: 682px) {
  width: 100%;
 }
`;

// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  @media (max-width: 682px) {
    right: 2em;
    bottom: 1.408125em;
  }
  @media (max-width: 500px) {
    right: 1em;
    bottom: 1em;
  }
`;

const PaymentDiv = styled.div`
`;
const BtnDiv = styled.div`
  text-align: right;
  margin-top: 1.2em;
  .download_btn{
    @media (max-width: 682px) {
    padding: 1em;
  }
  }
  
`;
const Text = styled.div`
  margin-top: 1.2em;
`;

const AlertContainer = styled.div`
margin-top: 3.375em;
.verified {
    padding: 0.875em 1.1875em;
    background: rgba(51, 185, 93, 0.1);
    border: 1px solid #33b95d;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #33b95d;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;

      color: #33b95d;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;

      color: #33b95d;
    }
  }
  .pending_status {
    padding: 0.875em 1.1875em;
    background: rgba(223, 174, 0, 0.1);
    border: 1px solid #dfae00;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #dfae00;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: #dfae00;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #dfae00;
    }
  }
  .rejected_status {
    padding: 0.875em 1.1875em;
    background: rgba(206, 43, 43, 0.13);
    border: 1px solid #ce2b2b;
    border-radius: 12px;
    width: 100%;
    svg {
      fill: #ce2b2b;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 19px;
      color: #ce2b2b;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #ce2b2b;
    }
  }
`;
