import { styled } from "styled-components";
import { SecurityIcon } from "../svg";

type CustomAlertProps = {
  status: string;
  date: string;
  className: string;
}
export function CustomAlert(props: CustomAlertProps) {
  return (
    <>
      <AlertMain>
        <Alert className={props.className} >
          <AlertContent>
            <CustomIconText>
              <SecurityIcon />
              <P>{props.status}</P>
            </CustomIconText>
            <DateDiv>
              <Date>{props.date}</Date>
            </DateDiv>
          </AlertContent>
        </Alert>
      </AlertMain>
    </>
  );
}

const AlertMain = styled.div``;

const Alert = styled.div`

`;

const AlertContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomIconText = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2em;
  
`;

const P = styled.p`
  text-transform: capitalize !important;
`;

const DateDiv = styled.div``;
const Date = styled.span`
  
`;
