// ROUTERS
import { createBrowserRouter } from "react-router-dom";

// LINKS
import {
  // BvnStatus,
  FA,
  addPhone,
  buildAid,
  buildAidFinanceVerification,
  buildAidPropertyChoice,
  buildAidPropertyChoiceDetails,
  buildLaterLandDetails,
  // bvn,
  construction,
  costEquity,
  createAccount,
  customPlanVerifcation,
  exclusivePlan,
  exclusivePlanDetails,
  forgotPassword,
  initializeProject,
  installment,
  installmentPaymentDetails,
  kyc,
  landDetails,
  login,
  mandateDetails,
  mandateGeneration,
  mortgage,
  noExistingCustomPlan,
  nonExisingProgress,
  otp,
  ownership,
  password,
  payment,
  paymentHistory,
  phoneVerification,
  plan,
  premium,
  premiumAid,
  premiumMandateGeneration,
  premiumPaymentHistory,
  premuimAidPropertyChoice,
  proceed,
  profile,
  progressPlan,
  propertyChoice,
  propertyChoiceDetails,
  resetPassword,
  residenceVerification,
  residenceVerificationStatus,
  servicePlan,
  simple,
  summary,
  uploadID,
  verificationStatus,
} from "../links";
// Root
import { Root } from "./root";

// protected routes components
import { SideNavigation } from "../layouts/navigation";
// import { Dashboard } from "../components/layouts/pages/dashboard";
import { PersonalInfo } from "../pages/profile/personalInfo";
import { Kyc } from "../pages/profile/kyc";
// import { UpadateBvn } from "../components/layouts/pages/profile/kyc/UpdateBvn";
import { UploadID } from "../pages/profile/kyc/uploadID";
import { PhoneVerifiaction } from "../pages/profile/kyc/phoneVerifiaction";
// import { BVNstatus } from "../components/layouts/pages/profile/kyc/UpdateBvn/BVNstatus";
import { IDverificationStatus } from "../pages/profile/kyc/uploadID/idStatus";
import { PhoneVerificationStatus } from "../pages/profile/kyc/phoneVerifiaction/phoneVerification";
import { FAverification } from "../pages/settings";
import { NavigationProject } from "../layouts/buildNow";
import { FinanceVerification } from "../pages/buildNow/phase1/financeVerification";
import { LandDetails } from "../pages/buildNow/phase1/landDetails";
import { PropertyChoice } from "../pages/buildNow/phase1/PropertyChoice";
import { Summary } from "../pages/buildNow/phase1/summary";
import { SelectServiceType } from "../pages/buildNow/phase2/serviceType";
import { UploadBuildingDocument } from "../pages/buildNow/phase2/serviceType/simplePlan";
import { RequestCustomPlan } from "../pages/buildNow/phase2/serviceType/exclusive/requestCustomPlan";
import { SelectBuildingPremium } from "../pages/buildNow/phase2/serviceType/premium/selectBuilding";
import { PlanDetails } from "../pages/buildNow/phase2/serviceType/premium/selectBuilding/buildingPlanDetails";
import { ProceedWithPlan } from "../pages/buildNow/phase2/serviceType/premium/selectBuilding/proceed";
import { RequestCustomPlanProgress } from "../pages/buildNow/phase2/serviceType/exclusive/planDevelopmentProgress";
import { CustomPlanVerificationStatus } from "../pages/buildNow/phase2/serviceType/exclusive/customPlan";
import { Costing } from "../pages/buildNow/phase2/costingAndEquity";
import { TransferOfOwnerShip } from "../pages/buildNow/phase2/transferOfOwnerShip";
import { PayInitialDeposit } from "../pages/buildNow/phase2/paymentDetails/outRight";
import { TrackConstruction } from "../layouts/traclConstructionTab";
import { ConstructionScreen } from "../pages/constructionScreens";
import { PageNotFound } from "../pages/NotFound";
import { InstallmentPayment } from "../pages/buildNow/phase2/costingAndEquity/installment";
import { MortagagePayment } from "../pages/buildNow/phase2/costingAndEquity/mortgage";
import { ResidentialVerification } from "../pages/profile/kyc/ResidentialUpdate";
import { ResidentVerificationStatus } from "../pages/profile/kyc/ResidentialUpdate/residentialStatus";
import { NewProjectDashboard } from "../pages/dashboardNewProject";
import { NewAid } from "../pages/buildLater";
import { CustomAidNavigation } from "../layouts/customAid";
import { CustomFinancialVerification } from "../pages/buildLater/customAid/financialVerification";
import { CustomAidPropertyChoice } from "../pages/buildLater/customAid/propertyChoice";
import { MandateGeneration } from "../pages/buildLater/customAid/mandateGeneration";
import { PaymentHistory } from "../pages/buildLater/customAid/paymentHistory";
import { PremiumAidNavigation } from "../layouts/premiumAid";
import { PremiumFinancialVerification } from "../pages/buildLater/premiumAid/financialVerification";
import { PremiumPropertyChoice } from "../pages/buildLater/premiumAid/propertyChoice";
import { PremiumMandateGeneration } from "../pages/buildLater/premiumAid/mandateGeneration";
import { PremiumPaymentHistory } from "../pages/buildLater/premiumAid/paymentHistory";
import { PropertyChoiceSummary } from "../pages/buildLater/customAid/propertyChoice/summary";
import { MandateGenerationDetails } from "../pages/buildLater/customAid/mandateGeneration/mandateDetails";
import { PremiumPropertyChoiceDetails } from "../pages/buildLater/premiumAid/propertyChoice/apartmentDetails";
import { ExclusivePlan } from "../pages/buildNow/phase2/serviceType/exclusive/exclusivePlan";
import { NoexistingLandCustomPlan } from "../pages/buildNow/phase2/serviceType/exclusive/exclusivePlan/nonExistingLand/noExisitingCustomPlan";
import { NonExistingPlanRequestCustomPlanProgress } from "../pages/buildNow/phase2/serviceType/exclusive/exclusivePlan/nonExistingLand/planDevelopmentProgress";
import { BuildLaterLandDetails } from "../pages/buildLater/premiumAid/landDetails";
import { InstallmentalPaymentDetails } from "../pages/buildNow/phase2/paymentDetails/installmental";
// import { AuthLayout } from "./authLayout";
import { Login } from "../components/auth/login";
import { CreateAccount } from "../components/auth/createAccount";
import { OtpInputComponent } from "../components/auth/otp";
import { SetPassword } from "../components/auth/createPassword";
import { ForgotPassword } from "../components/auth/forgotPassword";
import { ResetPassword } from "../components/auth/resetPassword";
import { AuthGuard } from "./HOC/AuthGuard";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <Root />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <SideNavigation />,
        children: [
          // DASHBOARD
          {
            index: true,
            element: <NewProjectDashboard />,
          },
          // profile dropDown
          {
            path: profile,
            element: <PersonalInfo />,
          },
          // kyc
          {
            path: kyc,
            element: <Kyc />,
          },
          {
            path: uploadID,
            element: <UploadID />,
          },
          {
            path: addPhone,
            element: <PhoneVerifiaction />,
          },
          {
            path: residenceVerification,
            element: <ResidentialVerification />,
          },
          // kyc status
          {
            path: verificationStatus,
            element: <IDverificationStatus />,
          },
          {
            path: phoneVerification,
            element: <PhoneVerificationStatus />,
          },
          {
            path: residenceVerificationStatus,
            element: <ResidentVerificationStatus />,
          },
          // 2FA /settings
          {
            path: FA,
            element: <FAverification />,
          },
          {
            path: servicePlan,
            element: <SelectServiceType />,
          },
          // initialize construction
          {
            path: initializeProject,
            element: <NavigationProject />,
            children: [
              // Phase 1
              {
                path: "",
                index: true,
                element: <FinanceVerification />,
              },
              {
                path: landDetails,
                element: <LandDetails />,
              },
              {
                path: propertyChoice,
                element: <PropertyChoice />,
              },
              {
                path: summary,
                element: <Summary />,
              },
              // phase 2
              // service plan options
              {
                path: simple,
                element: <UploadBuildingDocument />,
              },
              {
                path: exclusivePlan,
                element: <RequestCustomPlan />,
              },
              {
                path: premium,
                element: <SelectBuildingPremium />,
              },
              {
                path: plan,
                element: <PlanDetails />,
              },
              {
                path: proceed,
                element: <ProceedWithPlan />,
              },
              {
                path: progressPlan,
                element: <RequestCustomPlanProgress />,
              },
              {
                path: customPlanVerifcation,
                element: <CustomPlanVerificationStatus />,
              },
              // costing and equity
              {
                path: costEquity,
                element: <Costing />,
              },
              // installment
              {
                path: installment,
                element: <InstallmentPayment />,
              },
              // mortgage
              {
                path: mortgage,
                element: <MortagagePayment />,
              },
              // transfer ownership
              {
                path: ownership,
                element: <TransferOfOwnerShip />,
              },
              // payment
              {
                path: payment,
                element: <PayInitialDeposit />,
              },
              {
                path: installmentPaymentDetails,
                element: <InstallmentalPaymentDetails />,
              },
            ],
          },
          // EXCLUSIVE PLAN
          {
            path: exclusivePlanDetails,
            element: <ExclusivePlan />,
          },
          // when a user click on no existing plan this is the route to the custom plan without the nested side navigation
          {
            path: noExistingCustomPlan,
            element: <NoexistingLandCustomPlan />,
          },
          {
            path: nonExisingProgress,
            element: <NonExistingPlanRequestCustomPlanProgress />,
          },
          // BUILD AID
          {
            path: buildAid,
            element: <NewAid />,
          },
          // custom
          {
            path: buildAidFinanceVerification,
            element: <CustomAidNavigation />,
            children: [
              {
                index: true,
                element: <CustomFinancialVerification />,
              },
              {
                path: buildAidPropertyChoice,
                element: <CustomAidPropertyChoice />,
              },
              {
                path: buildAidPropertyChoiceDetails,
                element: <PropertyChoiceSummary />,
              },
              {
                path: mandateGeneration,
                element: <MandateGeneration />,
              },
              {
                path: mandateDetails,
                element: <MandateGenerationDetails />,
              },
              {
                path: paymentHistory,
                element: <PaymentHistory />,
              },
            ],
          },
          // premium
          {
            path: premiumAid,
            element: <PremiumAidNavigation />,
            children: [
              {
                index: true,
                element: <PremiumFinancialVerification />,
              },
              {
                path: buildLaterLandDetails,
                element: <BuildLaterLandDetails />,
              },
              {
                path: premuimAidPropertyChoice,
                element: <PremiumPropertyChoice />,
              },
              {
                path: premiumMandateGeneration,
                element: <PremiumMandateGeneration />,
              },
              {
                path: premiumPaymentHistory,
                element: <PremiumPaymentHistory />,
              },
            ],
          },
          {
            path: propertyChoiceDetails,
            element: <PremiumPropertyChoiceDetails />,
          },
          // construction screens
          {
            path: construction,
            element: <TrackConstruction />,
            children: [
              {
                index: true,
                element: <ConstructionScreen />,
              },
            ],
          },
        ],
      },

      // not found
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  // {
  //   path: "/auth",
  //   element: <AuthLayout />,
  //   children: [
      {
        // index: true,
        path: login,
        element: <Login />,
      },
      {
        path: createAccount,
        element: <CreateAccount />,
      },
      {
        path: otp,
        element: <OtpInputComponent />,
      },
      {
        path: password,
        element: <SetPassword />,
      },
      {
        path: forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: resetPassword,
        element: <ResetPassword />,
      },
      // {
      //   path: "*",
      //   element: <PageNotFound />,
      // },
    // ],
  // },
]);
