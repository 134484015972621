import { styled } from "styled-components";

import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { FormEvent, useMemo, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { InputField } from "../../../../utils/input";
import { SelectInput } from "../../../../utils/select";
import { TextArea } from "../../../../utils/textArea";
import { FileUpload } from "../../../../components/imageUploads/fileUpload";
import { mediaQuery } from "../../../../breakPoint";
import { useGetLandDetails, useLanDetails } from "../../../../hooks/uselandDetails";

import { useNavigate } from "react-router-dom";
import apiFetchClient from "../../../../apiProvider/axiosInstance";
import { useNotificationToast } from "../../../../hooks/useToast";
import AlertDialogSlide from "../../../../utils/dialog";
import { propertyChoiceLink } from "../../../../links";
import { GetLandDetails } from "./getLandDetails";
import SimpleBackdrop from "../../../../utils/backdrop";

export function LandDetails() {

  const navigate = useNavigate();

  // details value hook
  const {
    landArea,
    setLandArea,
    landLocation,
    setLandLocation,
    landDescription,
    setLandDescription,
    floorArea,
    setFloorArea,

    // image
    firstLandPhoto,
    setFirstLandPhoto,
    secondLandPhoto,
    setSecondLandPhoto,
    thirdLandPhoto,
    setThirdLandPhoto,
    // landDocumentUpload,
    setLandDocumentUpload,

    // loading and error state
    isLandDetailsLoading,
    setLandDetailsLoading,

  } = useLanDetails();

  const { status, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen } = useGetLandDetails();

  const hasStatus = status === "PENDING" || status === "APPROVED" || status === "REJECTED";
  const hasNoStatus = status === "NONE" || undefined || !status;

  const { errorToast } = useNotificationToast();

  const [landType, setLandType] = useState<string>("");
  const [postLandType, setPostLandType] = useState<string>("")
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [landDetailsStatus, setLandDetailsStatus] = useState<string[]>([]);

  const landTypeList = [
    "Certificate of occupancy",
    "Deed of conveyance",
    // "Deed of survey",
    "Land certificate",
    "Statutory Right of occupancy",
    // "Contract of sale",
    "Deed of assignment"
  ];


  // const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
  //   setLandDescription(event.target.value);
  // };

  useMemo(() => {
    if (landType === "Certificate of occupancy") return setPostLandType("CERTIFICATE_OF_OCCUPANCY");

    if (landType === "Deed of conveyance") return setPostLandType("DEED_OF_CONVEYANCE");

    if (landType === "Deed of survey") return setPostLandType("DEED_OF_SURVEY");

    if (landType === "Land certificate") return setPostLandType("LAND_CERTIFICATE");

    if (landType === "Statutory Right of occupancy") return setPostLandType("STATUTORY_RIGHT_OF_OCCUPANCY");

  }, [setPostLandType, landType])


  const landTypeHandleChange = (event: SelectChangeEvent<string>) => {
    if (setLandType) {
      setLandType(event?.target.value as string);
    }

  };


  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      setLandDetailsLoading(true)
      // LAND PHOTO 
      firstLandPhoto.forEach((file) => formData.append("land_photos", file));

      secondLandPhoto.forEach((file) => formData.append("land_photos", file));

      thirdLandPhoto.forEach((file) => formData.append("land_photos", file));

      // DOCUMENT PHOTO 
      // landDocumentUpload.forEach((file) => formData.append("land_document", file))

      // details 
      formData.append("land_title", postLandType);

      formData.append("land_plot_area", landArea);

      formData.append("land_floor_area", floorArea);

      formData.append("land_location", landLocation);

      formData.append("land_description", landDescription);


      const { data } = await apiFetchClient.post("/construction/build-now/land-details", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setLandDetailsLoading(false);
      setOpenDialog(true)
      setLandDetailsStatus(data?.message);

      
    } catch (e) {
      setLandDetailsLoading(false);

      const error: string | string[] | any = e

      const errorMessage = error?.response?.data?.message

      const err = Array.isArray(errorMessage)

      err ? errorToast(errorMessage[0]) : errorToast(errorMessage || "An error occurred")

    }

  }

  // close dialog 
  function closeDialog() {
    setOpenDialog(false)
    navigate(propertyChoiceLink, { replace: true })
  }

  return (
    <>
      <AlertDialogSlide next="Next Property Choice" handleClose={closeDialog} open={openDialog} details={landDetailsStatus} />
       {/* LOADING */}
       {loadingBackDrop?(
       <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleBackDropClose}
        handleOpen={handleBackDropOpen}
      />):
      hasNoStatus ? (
        <FinanceContainer>
          <Finance onSubmit={handleSubmit}>
            <FinanceContent>
              <FinanceHeader>
                <H1>LAND DETAILS</H1>
                <P>
                Please provide your land information to proceed
                </P>
              </FinanceHeader>
              <SelfEmplyedMain>
                <FieldContainer>
                  <InputField
                    label="Total Plot Area (m2)"
                    type="tel"
                    // min="45"
                    placeholder="Enter Here"
                    className="input"
                    value={landArea}
                    onChange={(event) => setLandArea(event.target.value)}
                  />
                  <InputField
                    label="Total Floor Area (m2)"
                    type="tel"
                    placeholder="Enter Here"
                    className="input"
                    value={floorArea}
                    onChange={(event) => setFloorArea(event.target.value)}
                  />
                  <InputField
                    label="Location"
                    type="text"
                    placeholder="Enter Here"
                    className="input"
                    value={landLocation}
                    onChange={(event) => setLandLocation(event.target.value)}
                  />
                  <SelectInput
                    SelectLabel="Title type"
                    defaultName="Select"
                    valueItems={landTypeList}
                    items={landType}
                    setItems={setLandType}
                    onChange={landTypeHandleChange}
                  />
                  <TextArea
                    label="Land Description"
                    placeholder="Land Description"
                    value={landDescription}
                    onchange={(event) => setLandDescription(event.target.value)}
                  />
                </FieldContainer>
                <LandPhotoDiv>
                  <H3>Land Photos</H3>
                  <ImageUpLoadDiv>
                    <FileUpload src={setFirstLandPhoto} name="add land photo" />
                    <FileUpload src={setSecondLandPhoto} name="add land photo" />
                    <FileUpload src={setThirdLandPhoto} name="add land photo" />
                  </ImageUpLoadDiv>
                </LandPhotoDiv>
                <LandProof>
                  {/* <FileUpload
                  label="Proof of land ownership "
                  name="Upload proof of Land ownership"
                /> */}
                  <FileUpload
                    label="Land title document"
                    name="Upload Land title deed"
                    src={setLandDocumentUpload}
                  />
                </LandProof>
              </SelfEmplyedMain>
            </FinanceContent>
            <SaveButtonComponent loading={isLandDetailsLoading} type="submit" className="btn" name="Save and continue" />
          </Finance>
          <ChartConponentDiv>
            <ChartIconComponent />
          </ChartConponentDiv>
        </FinanceContainer>) :
        hasStatus && (<FinanceContainer><GetLandDetails /></FinanceContainer>)}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  margin-left: 2em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    justify-content: center;
    margin: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    /* width: 433px; */
    height: 52px;
  }
`;
export const Finance = styled.form`
width: 80%;
  ${mediaQuery.tablet} {
    width: 100%;
  }
  ${mediaQuery.mobile} {
    /* width: 95%; */
  }

  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
    }
  }
`;

export const FinanceContent = styled.div`
`;
export const FinanceHeader = styled.div``;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 1em;
  ${mediaQuery.tablet} {
    font-size: 1.125rem;
  }
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;
const SelfEmplyedMain = styled.div``;

const ImageUpLoadDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.8em;
`;

const LandPhotoDiv = styled.div`
  margin-top: 2.375em;
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 19px;
  color: #000000;
`;
const LandProof = styled.div`
  width: 100%;
`;
const FieldContainer = styled.div`
  ${mediaQuery.tablet} {
    .input {
      width: 100% !important;
    }
  }
`;
// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
