import { Box, Button, Drawer, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import {
  AidIcon,
  ConstructionIcon,
  DahboardIcon,
  // DocumentIcon,
  DropDownIcon,
  HamburgerIcon,
  InitializeContructionIcon,
  // MortageIcon,
  SettingsIcon,
  UserIcon,
} from "../../utils/svg";
import { styled } from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { FA, buildAid, construction, kyc, profile } from "../../links";
import { UseBuildNowServicePlan } from "../../hooks/useServicePlan";
import SimpleBackdrop from "../../utils/backdrop";
// import { ContactPerson } from "../../components/contactPerson";
// import { useQuery } from "@apollo/client";
// import { GET_PERSONAL_INFO } from "../../api/query/personalInfo";

type Anchor = "top" | "left" | "bottom" | "right";

export function MobileNavigation() {

  const navigate = useNavigate();

  const { serviceTypeRoute, loadingBackDrop, setLoadingBackDrop, handleOpen, handleClose } = UseBuildNowServicePlan();

  const [logout, setLogout] = useState<boolean>(false);

  // logout
  const LogOut = () => {
    localStorage.removeItem("conbuild_login_token");

    localStorage.removeItem("conbuild_token");

    setLogout(true);
  };

  useEffect(() => {
    if (logout) {
      window.location.replace("/login");
    }
  }, [logout, navigate]);
  // API CALLS
  // const { data, loading } = useQuery(GET_PERSONAL_INFO);


  const handleNavLinkClick = (to: string |any) => {
    // Replace the current history entry with the new route
    navigate(to, { replace: true });
  };


  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);   // dropdown popover

  const [showMobileNav, setShowMobileNav] = useState({ right: false, });  // mobile nav apearance

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {

      return;
    }

    setShowMobileNav({ ...showMobileNav, [anchor]: open });

  };

  // popover dropdown
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);


  // nav list
  const list = (anchor: Anchor) => (

    <Box sx={{ width: 315 }} role="navigation">
      <TopList>
        <Ul>
          <Span
            to="/"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <DahboardIcon className="das" />
            <LI>Dashboard</LI>
          </Span>
          <Button
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className="profile_drop_down"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, false)} 
          >
            <UserIcon className="user_svg" />
            <LI>Profile</LI>
            <DropDownIcon className="dropIcon" />
          </Button>
          <Output
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
            className="drop_down"
          >
            <Span
              to={profile}
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <LI>Personal info</LI>
            </Span>
            <Span
              to={kyc}
              onClick={toggleDrawer(anchor, false)}
              onKeyDown={toggleDrawer(anchor, false)}
            >
              <LI>KYC</LI>
            </Span>
          </Output>
          {/* <Span
            to={initializeProject}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <InitializeContructionIcon />
            <LI>Initialize construction</LI>
          </Span> */}
          <Span to={`/${serviceTypeRoute}`} onClick={() => handleNavLinkClick(`/${serviceTypeRoute}`)} replace end>
            <InitializeContructionIcon />
            <LI>Build Now</LI>
          </Span>
          <Span to={buildAid}>
            <AidIcon />
            <LI>Build Later</LI>
          </Span>
          <Span
            to={construction}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <ConstructionIcon />
            <LI>Construction</LI>
          </Span>
          {/* <Span
            to="*"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <MortageIcon />
            <LI>Mortage</LI>
          </Span>
          <Span
            to="*"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <DocumentIcon />
            <LI>My Document</LI>
          </Span> */}
          <Span
            to={FA}
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          >
            <SettingsIcon />
            <LI>Settings</LI>
          </Span>
        </Ul>
        <span onClick={LogOut} className="logout">
          Logout
        </span>
      </TopList>
      {/* <Contact>
        {loading ? (
          <Skeleton variant="text" sx={skeleton} />
        ) : data ? (
          <ContactPerson
            header="CONTACT PERSON"
            name={`${data?.userProfile.firstname} ${data?.userProfile.lastname}`}
            email={data?.userProfile.email}
          />
        ) : (
          ""
        )}
      </Contact> */}
    </Box>
  );

  // toggle button to show drawer
  return (
    <>
      {/* loading */}
      <SimpleBackdrop
        setOpen={setLoadingBackDrop}
        loadingBackDrop={loadingBackDrop}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
      <DIV>
        {(["right"] as const).map((anchor) => (
          <div key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <HamburgerIcon />
            </Button>
            <Drawer
              onClick={toggleDrawer(anchor, false)}
              anchor={anchor}
              open={showMobileNav[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </div>
        ))}
      </DIV>
    </>
  );
}

// const skeleton = {
//   fontSize: "1rem",
//   background: "gray",
//   width: "100%",
//   padding: ".5em 7em 1em 1em",
// };

const DIV = styled.div``;

const TopList = styled.div`
  margin-bottom: 3em;
  .logout {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.99rem;
    line-height: 19px;
    color: #00317a;
    padding: 0.6em;
    border-radius: 6px;
    cursor: pointer;
    margin: 3.5em;
    border: 0.5px solid red;
  }
  .profile_drop_down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-bottom: 0.8em;
    padding: 0.875em 1.25em;
    border-radius: 8px;
    transition: all 0.1s ease-in;
    text-decoration: none;
    cursor: pointer;
    li {
      margin-right: 4em;
    }
    .user_svg {
      fill: #00317a;
      transition: none !important;
    }
    .active {
      background: #00317a;
      svg {
        fill: #fff;
      }
      li {
        color: #fff;
      }
    }
  }
  .dropIcon {
  }
  .drop_down {
    display: none;
    li {
      margin-left: 3em;
      text-align: left;
      border-radius: 8px;
      transition: all 0.1s ease-in;
      text-decoration: none;
    }
  }
`;
const Ul = styled.ul`
  padding: 1.8em;

  .active {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
  button {
    outline: none;
    width: 100%;
    border: none;
    background: none;
  }
`;

const Output = styled(Popover)`
  transition: all 0.3s ease-in;
`;

const Span = styled(NavLink)`
  display: flex;
  gap: 2em;
  align-items: center;
  text-align: left;
  margin-bottom: 0.8em;
  padding: 0.875em 1.25em;
  border-radius: 8px;
  transition: all 0.1s ease-in;
  text-decoration: none;
  cursor: pointer;
  svg {
    fill: #00317a;
    transition: none !important;
  }
  &:hover {
    background: #00317a;
    svg {
      fill: #fff;
    }
    li {
      color: #fff;
    }
  }
`;

const LI = styled.li`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #00317a;
`;

// const Contact = styled.div`
//   margin: 2em;
// `;
