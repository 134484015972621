import { styled } from "styled-components";
import { mediaQuery } from "../../../../../../breakPoint";
import { useGetPropertyChoice } from "../../../../../../hooks/usePropertyChoice";


export function PropertyChoiceDetailsList() {

  const { status, project_title, building_type, property_description, units, bedrooms } = useGetPropertyChoice()



  return (
    <>
      <ContactInfoDiv>
        <Content>

          <DetailsContainer>
            <DETAILS>
              <H3>STATUS</H3>
              <H2 status={status} >{status}</H2>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Project Title</H3>
              <p>{project_title}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Building Type</H3>
              <p>{building_type}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Property description</H3>
              <p>{property_description}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Bedrooms</H3>
              <p>{bedrooms}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Units</H3>
              <p>{units}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
        </Content>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  margin-top: 2.375em;
  margin-bottom: 3.5em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;

const Content = styled.div``;

const DetailsContainer = styled.div``;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1em;
  padding-right: 1em;
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
  }
  p{
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #2c2c2c;
    text-transform: capitalize;
  }
  .btn{

  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;


export const H2 = styled.h2<{ status: string | any }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  color: ${({ status }) =>
    status === "APPROVED"
      ? "#33B95D;"
      : status === "PENDING"
        ? "#DFAE00;"
        : status === "REJECTED"
          ? "#CE2B2B;"
          : "#00317a"};
`


