import { Box, SelectChangeEvent, Slider } from "@mui/material";
import { useState } from "react";
import { styled } from "styled-components";
import { InputField } from "../../../../utils/input";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { SelectInput } from "../../../../utils/select";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { mediaQuery } from "../../../../breakPoint";
import CostAndEquityTable, { Row } from "../../../buildNow/phase2/costingAndEquity/table";
import { buildAidPropertyChoiceDetailsLink } from "../../../../links";
import { useNavigate } from "react-router-dom";

export function CustomAidPropertyChoice() {
  const navigate = useNavigate()
    const price = 2500000;

    const rows: Row[] = [
        {
          id: 1,
          option: "Upfront Payment (40%)",
          amount:  2459000.00,
        },
        {
          id: 2,
          option: "Monthly Contribution",
          amount:  2459000.00,
        },
        {
          id: 3,
          option: "Payment Duration",
          amount:  "2 Years",
        },
        
      ];

  const [projectTitle, setProjectTitile] = useState<string>("");
  const [buildingType, setBuidldingType] = useState<string>("");
  const [units, setUnits] = useState<string>("");

  const buildingTypes = [
    "Ikeja",
    "Victoria Island",
    "Lekki",
    "Ikoyi",
    "Apapa",
    "Surulere"
  ];
  ;


  const numberOfUnits = [1, 2, 3, 4, 5, 5, 7];

  const landTypeHandleChange = (event: SelectChangeEvent<string>) => {
    setBuidldingType(event?.target.value as string);

  };

  const unitOnchange = (event: SelectChangeEvent<string>) => {

    setUnits(event?.target.value as string);
  };

  //   mui slider value
  const [sliderValue, setSliderValue] = useState<number | string | Array<number | string>>(1);
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue);
  };


  return (
    <>
      {/* success message */}
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>PROPERTY CHOICE</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <SelfEmplyedMain>
              <FieldContainer>
                <InputField
                  label="Project title"
                  type="text"
                  placeholder="Enter Here"
                  className="input"
                  value={projectTitle}
                  onChange={(event) => setProjectTitile(event.target.value)}
                />
                <SelectInput
                  SelectLabel="Location"
                  defaultName="Select"
                  valueItems={buildingTypes}
                  items={buildingType}
                  setItems={setBuidldingType}
                  onChange={landTypeHandleChange}
                />
                <SelectInput
                  SelectLabel="Number of units"
                  defaultName="Select"
                  valueItems={numberOfUnits}
                  items={units}
                  setItems={setUnits}
                  onChange={unitOnchange}
                />
                <Boxs>
                  <Label>{sliderValue} Bedroom</Label>
                  <Slider
                    value={typeof sliderValue === "number" ? sliderValue : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    max={7}
                  />
                </Boxs>
              </FieldContainer>
              <Costing>
              <Amount>
              <H2>
                <Sup>NGN</Sup>
                {price.toLocaleString()}
                <Sup>.00</Sup>
              </H2>
            </Amount>
            <Table>
              <CostAndEquityTable rows = {rows} />
            </Table>
              </Costing>
            </SelfEmplyedMain>
          </FinanceContent>
          <SaveButtonComponent onClick={() => navigate(buildAidPropertyChoiceDetailsLink)} className="btn" name="Save and continue" />
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 2em;
  position: relative;
  margin-left: 2em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    justify-content: center;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  ${mediaQuery.tablet} {
    width: 100%;
  }
  ${mediaQuery.mobile} {
    /* width: 95%; */
  }

  .btn {
    ${mediaQuery.tablet} {
      padding: 1em !important;
    }
  }
`;

const FinanceContent = styled.div`
  margin-bottom: 2.375em;
`;
const FinanceHeader = styled.div``;
const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 22px;
  color: #000000;
  margin-bottom: 1em;
  @media (max-width: 682px) {
    font-size: 18px;
  }
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;
const SelfEmplyedMain = styled.div``;

const FieldContainer = styled.div`
  .input {
    ${mediaQuery.tablet} {
      width: 100% !important;
    }
  }
`;
// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;

const Boxs = styled(Box)`
  margin-top: 2.375em;
`;

const Label = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;

  color: #000000;
`;


const Costing = styled.div`
    margin-top: 2em;
`

const Amount = styled.div`
 text-align: center;
`;
const H2 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 65px;
  color: #000000;
  margin-bottom: 0.5em;
  margin-top: 0.2em;
  ${mediaQuery.tablet} {
  font-size: 2.5rem;
}
`;

const Sup = styled.sup`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  ${mediaQuery.tablet} {
  font-size: .9rem;
}
`;

const Table = styled.div`
  margin-top: 1em;
`;