import { styled } from "styled-components";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { DropDownIcon } from "../../../../utils/svg";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ModalComponent } from "../../../../components/modals/bvnPhoneModal";
import { OtpModalComponent } from "../../../../components/modals/phoneOtpNumber";
import { mediaQuery } from "../../../../breakPoint";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { phoneVerificationLink } from "../../../../links";
import { useUserKyc } from "../../../../hooks/useUserKyc";
import SimpleBackdrop from "../../../../utils/backdrop";
import { PhoneVerificationStatus } from "./phoneVerification";

export function PhoneVerifiaction() {

  const navigate = useNavigate();

  const { phone_status, loadingBackDrop, setLoadingBackDrop, handleBackDropClose, handleBackDropOpen, refetch } = useUserKyc()   // hooks 

  const [openModal, isModalOpen] = useState<boolean>(false);

  const [openOtpModal, isOtpModalOpen] = useState<boolean>(false);

  const [value, setValue] = useState<string>("");

  const isPhoneNUmberPost = useSelector((state: RootState) => state?.phoneSubmitSuccess.value);


  const handleChangeOtpModal = (newValue: string) => setValue(newValue);

  const checkStatus = phone_status === "PENDING" || phone_status === "REJECTED" || phone_status === "APPROVED"

  const noStatus = phone_status === "NONE" || !checkStatus

  // useEffect(() => { refetch() }, []);
  const memoizedRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    memoizedRefetch();
  }, [memoizedRefetch]);

  useEffect(() => {
    if (checkStatus) {
      navigate(phoneVerificationLink, { replace: true });
    }
  }, [navigate, checkStatus]);



  return (
    <>
      {loadingBackDrop ? (
        <SimpleBackdrop
          setOpen={setLoadingBackDrop}
          loadingBackDrop={loadingBackDrop}
          handleClose={handleBackDropClose}
          handleOpen={handleBackDropOpen}
        />) :
        noStatus ? (
          <FinanceContainer>
            <Finance>
              <FinanceContent>
                <FinanceHeader>
                  <DropDownIcon
                    className="drop_down"
                    onClick={() => navigate(-1)}
                  />
                  <H1>KYC</H1>
                </FinanceHeader>
                <SelfEmplyedMain>
                  <VerifyAccount>
                    <H3>Phone verification</H3>
                    <Text>
                    Verify your phone number to complete your KYC process
                    </Text>
                  </VerifyAccount>
                </SelfEmplyedMain>
                <SaveButtonComponent
                  onClick={() => isModalOpen(true)}
                  name="Add Phone number"
                  className="update_bvn_btn"
                />
              </FinanceContent>
              {openModal && (
                <ModalComponent
                  label="Phone"
                  buttonName="Verify"
                  description="You would get a 6 Digit OTP to validate your phone number"
                  name="Phone verification"
                  onClose={() => isModalOpen(false)}
                  open={openModal}
                  setOpen={isModalOpen}
                />
              )}
              {isPhoneNUmberPost && (
                <OtpModalComponent
                  name="Enter OTP"
                  onClose={() => isOtpModalOpen(false)}
                  description="Please enter the 6 Digit sent to your phone number to continue"
                  open={openOtpModal}
                  setOpen={isOtpModalOpen}
                  value={value}
                  buttonName="Verify"
                  handleChange={handleChangeOtpModal}
                />
              )}
            </Finance>
            <ChartConponentDiv>
              <ChartIconComponent />
            </ChartConponentDiv>
          </FinanceContainer>) : <PhoneVerificationStatus />}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 4em;
  position: relative;
  margin-left: 15em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
  margin: auto;
  width: 40%;
  ${mediaQuery.tablet} {
    width: 90%;
  }
`;

export const FinanceContent = styled.div`
  .update_bvn_btn {
    padding: 1em !important;
  }
`;
export const FinanceHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  gap: 1em;
  .drop_down {
    transform: rotate(90deg);
    cursor: pointer;
  }
`;
export const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 15px;
  width: 330px;
  height: 53px;
  color: #797474;
`;
const SelfEmplyedMain = styled.div`
  margin-top: 3em;
  ${mediaQuery.tablet} {
    margin-bottom: 5em;
  }
`;

const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
`;

const VerifyAccount = styled.div`
  margin-bottom: 2em;
`;

const H3 = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1275rem;
  line-height: 30px;
  margin-bottom: 0.4em;
  color: #00317a;
`;

const Text = styled.p`
  /* width: 60%; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;

  color: #797474;
`;
