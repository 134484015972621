import { useQuery } from "@apollo/client"
import { BUILD_NOW_SERVICE_PLAN } from "../api/query/servicePlan/selectedPlan"
import { useMemo, useState } from "react";
import { exclusivePlanDetails, initializeProject, servicePlan } from "../links";
import { EXCLUSIVE_LAND_TYPE } from "../api/query/servicePlan/exclusiveLandType";
import { ExclusivePlanLandTypeDto } from "../api/types";

type ServicePlan = {
    service_type: string;
}

export const UseBuildNowServicePlan = () => {

    const [loadingBackDrop, setLoadingBackDrop] = useState<boolean>(false);

     // route according the service type selected by user 
     const [serviceTypeRoute, setServiceTypeRoute] = useState<string>(servicePlan)

    const {data, loading} = useQuery(BUILD_NOW_SERVICE_PLAN);

    const servicePlanChoiceData: ServicePlan = data?.currentBuildNowPhase;

    const {data: exclusiveLandType, loading: exclusiveLandTypeLoading} = useQuery(EXCLUSIVE_LAND_TYPE); // exclusive plan type 

    const exclusiveLandTypeData: ExclusivePlanLandTypeDto = exclusiveLandType?.currentBuildNowPhase;

     // loading states 
     const handleClose = () => setLoadingBackDrop(false); 

     const handleOpen = () => setLoadingBackDrop(true);

    useMemo(() =>{
       
        if(servicePlanChoiceData?.service_type === "SIMPLE"){
            setServiceTypeRoute(initializeProject)
        }

        if(servicePlanChoiceData?.service_type === "PREMIUM"){
            setServiceTypeRoute(initializeProject)
        }

        if(servicePlanChoiceData?.service_type === "EXCLUSIVE" && !exclusiveLandTypeData?.land_type){
            setServiceTypeRoute(exclusivePlanDetails)
        }
       
        if(servicePlanChoiceData?.service_type === "EXCLUSIVE" && exclusiveLandTypeData?.land_type === "EXISTING"){
            setServiceTypeRoute(initializeProject)
        }

        if(servicePlanChoiceData?.service_type === "NONE") {
            setServiceTypeRoute(servicePlan)
        }
        // loading function 
        (loading || exclusiveLandTypeLoading) && handleOpen();

        (!loading || !exclusiveLandTypeLoading) && handleClose();

    },[servicePlanChoiceData,setServiceTypeRoute,loading,exclusiveLandTypeLoading,exclusiveLandTypeData?.land_type]);

    return {...servicePlanChoiceData,serviceTypeRoute, loadingBackDrop,setLoadingBackDrop,handleOpen,handleClose, ...exclusiveLandTypeData}
}