import { useCallback, useState } from "react";
import moment from "moment";

export const useDateMoment = () =>{

   const [formattedDate, setFormattedDate] = useState<string>("");

   const convertDate = useCallback(async (dateString: any) => {

    const date = moment(dateString).format("MMMM DD, YYYY");
    setFormattedDate(date);

  }, [setFormattedDate]);

  return {convertDate,formattedDate}
}