import { gql } from "@apollo/client";

export const PHONE_OTP_VERIFIACTION = gql`
  mutation Mutation($payload: VerifyPhoneOTP!) {
    verifyPhoneOTP(payload: $payload) {
      phone
      phone_status
    }
  }
`;
