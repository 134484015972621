import { styled } from "styled-components";
import { mediaQuery } from "../../../../breakPoint";
import { InputField } from "../../../../utils/input";
import { SelectInput } from "../../../../utils/select";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";

export function PremiumMandateGeneration() {
  const nigerianBanks = [
    "Access Bank",
    "Guaranty Trust Bank (GTBank)",
    "First Bank of Nigeria",
    "Zenith Bank",
  ];
  const price = 2500000;
  // const monthlyPay = 1250000.0;
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>MANDATE GENERATION</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <Amount>
              <H2>
                <Sup>NGN</Sup>
                {price.toLocaleString()}
                <Sup>.00</Sup>
              </H2>
            </Amount>
            <FORM>
              <div className="input_div">
                <SelectInput
                  SelectLabel="Bank name"
                  defaultName="Select Bank"
                  valueItems={nigerianBanks}
                />
                <InputField
                  label="Account Number"
                  type="tel"
                  placeholder="Enter account number"
                  className="input"
                  // value = {props.salary}
                  // onChange={(event) => props.setSalary(event.target.value)}
                />
                <InputField
                  label="Account Name"
                  type="text"
                  className="input"
                  disabled = {true}
                  placeholder="Account Name"
                  // value = {props.salary}
                  // onChange={(event) => props.setSalary(event.target.value)}
                />
              </div>
            </FORM>
          </FinanceContent>
          <SaveButtonComponent className="btn" name="Generate mandate" />
        </Finance>
      </FinanceContainer>
    </>
  );
}

const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 1.8em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile} {
    margin-top: 0;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;

const Finance = styled.div`
  width: 80%;
  margin-inline: auto;
  ${mediaQuery.tablet} {
    width: 100%;
    margin: auto;
  }
  .btn {
    margin-top: 2em;
    padding: 1em !important;
  }
`;

const FinanceContent = styled.div`
  width: 100%;
`;

const FinanceHeader = styled.div``;

const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  ${mediaQuery.mobile} {
    font-size: 18px;
    line-height: 22px;
  }
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 20px;
  height: 53px;
  color: #797474;
  ${mediaQuery.mobile} {
    font-size: 0.875rem;
    line-height: 19px;
    width: 100%;
  }
`;

const Amount = styled.div`
  text-align: center;
  margin-top: 2em;
`;
const H2 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 65px;
  color: #000000;
  margin-bottom: 0.5em;
  margin-top: 0.2em;
  ${mediaQuery.tablet} {
    /* font-size: 2.5rem; */
  }
`;

const Sup = styled.sup`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  ${mediaQuery.tablet} {
    font-size: 0.9rem;
  }
`;

const FORM = styled.div`
  margin-top: 1em;
  background: #e5f3ff;
  width: 100%;
  padding: 1em;
  .input{
    width: 100%;
  }
`;
