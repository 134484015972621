import { styled } from "styled-components";
import { TextArea } from "../../../../../../utils/textArea";
import { SaveButtonComponent } from "../../../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../../../utils/ChartBtn";
import { useNavigate } from "react-router-dom";
import { progressPlanLink } from "../../../../../../links";
import { mediaQuery } from "../../../../../../breakPoint";
import { FormEvent, useEffect, useState } from "react";
import apiFetchClient from "../../../../../../apiProvider/axiosInstance";
import { useNotificationToast } from "../../../../../../hooks/useToast";
import { useGetExistingExclusiveSpeicifaction } from "../../../../../../hooks/buildSpecificationState/exclusive/useExisting";
import SimpleBackdrop from "../../../../../../utils/backdrop";
import { RequestCustomPlanProgress } from "../planDevelopmentProgress";

export function RequestCustomPlan() {
  const navigate = useNavigate();

  const { status, setLoadingBackDrop, loadingBackDrop, handleBackDropClose, handleBackDropOpen } = useGetExistingExclusiveSpeicifaction();

  const hasNoStatus = status === "NONE" || undefined || !status;

  const hasStatus = status === "PENDING" || status === "APPROVED" || status === "REJECTED";

  useEffect(() => {
    if (hasStatus) {
      navigate(progressPlanLink, { replace: true });
    }
  }, [hasStatus, navigate]);

  const [requestPlanDescription, setRequestPlanDescription] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  // const options  = [
  //   "Build on Existing Land",
  //   "Acquire New Land to Build on"
  // ]

  const { errorToast, successToast } = useNotificationToast();

  const submitRequest = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const formData = new FormData();

      setLoading(true);

      formData.append("custom_plan_description", JSON.stringify(requestPlanDescription));

      const { data } = await apiFetchClient.post("/construction/build-now/building-specification", formData);

      setLoading(false);

      navigate(progressPlanLink, { replace: true });

      successToast(data?.message || "Successful!");

    } catch (error: string | any) {
      const errorMessage = error?.response?.data?.message;

      const err = Array.isArray(errorMessage);

      err ? errorToast(errorMessage[0]) : errorToast(errorMessage || "An error occurred");

      setLoading(false);
    }
  };

  
  return (
    <>
      {loadingBackDrop ? (
        <SimpleBackdrop
          setOpen={setLoadingBackDrop}
          loadingBackDrop={loadingBackDrop}
          handleClose={handleBackDropClose}
          handleOpen={handleBackDropOpen}
        />
      ) : hasNoStatus ? (
        <FinanceContainer>
          <Finance onSubmit={submitRequest}>
            <FinanceContent>
              <FinanceHeader>
                <H1>REQUEST CUSTOM PLAN</H1>
                <P>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                  magna aliqua.
                </P>
              </FinanceHeader>
              <SelfEmplyedMain>
                <FieldContainer>
                  <TextArea
                    onchange={(event) => setRequestPlanDescription(event.target.value)}
                    value={requestPlanDescription}
                    label="Description"
                    placeholder="Please enter Description and custom message"
                  />
                </FieldContainer>
              </SelfEmplyedMain>
            </FinanceContent>
            <SaveButtonComponent type="submit" className="btn" loading={loading} name="I want a custom plan" />
          </Finance>
          <ChartConponentDiv>
            <ChartIconComponent />
          </ChartConponentDiv>
        </FinanceContainer>
      ) : (
        hasStatus && <RequestCustomPlanProgress />
      )}
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  display: flex;
  gap: 5em;
  z-index: 1;
  margin-top: 3em;
  position: relative;
  /* margin-left: 2em; */
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
    margin: 1em auto auto;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.form`
  ${mediaQuery.tablet} {
    margin: auto;
  }
  .btn {
    padding: 1em !important;
    ${mediaQuery.tablet} {
      width: 100%;
    }
  }
`;

const FinanceContent = styled.div`
  margin-bottom: 2.375em;
`;
const FinanceHeader = styled.div``;
const H1 = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 29px;
  color: #000000;
  margin-bottom: 0.8em;
  ${mediaQuery.mobile} {
    font-size: 18.5px;
    line-height: 22px;
  }
`;
const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 19px;
  width: 330px;
  height: 53px;
  color: #797474;
  width: 100%;
  ${mediaQuery.mobile} {
    font-size: 0.8rem;
  }
`;
const SelfEmplyedMain = styled.div``;

const FieldContainer = styled.div``;
// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  ${mediaQuery.tablet} {
    right: 2em;
    bottom: 1.408125em;
  }
  ${mediaQuery.mobile} {
    right: 1em;
    bottom: 1em;
  }
`;
