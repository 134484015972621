import { styled } from "styled-components";
import { SaveButtonComponent } from "../../../../utils/buttons/saveButton";
import { ChartIconComponent } from "../../../../utils/ChartBtn";
import {  EditIcon, EyeIcon } from "../../../../utils/svg";
import { Button } from "@mui/material";
import { FileUpload } from "../../../../components/imageUploads/fileUpload";
import { DownloadBtnComponent } from "../../../../utils/buttons/downloadBtn";
import { mediaQuery } from "../../../../breakPoint";
import { useNavigate } from "react-router-dom";

export function TransferOfOwnerShip() {
  const navigate = useNavigate();
  return (
    <>
      <FinanceContainer>
        <Finance>
          <FinanceContent>
            <FinanceHeader>
              <H1>TRANSFER OF OWNERSHIP</H1>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </FinanceHeader>
            <DetailsHeader>
              <AgreementDiv>
                <EditIcon />
                <P2>Unsigned Agreement</P2>
              </AgreementDiv>
              <BtnDiv>
                <Preview variant="outlined" endIcon={<EyeIcon />}>
                  Preview
                </Preview>
                <DownloadBtnComponent variant="outlined" />
              </BtnDiv>
            </DetailsHeader>
            <Line></Line>
            <Text>
              <P>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </P>
            </Text>
            <FileUpload
              name="Upload signed agreement"
              label="Signed Agreement"
            />
          </FinanceContent>
          <SaveButtonComponent onClick={() => navigate("/initialize-construction/payment")} className="btn" name="Accept and proceed" />
        </Finance>
        <ChartConponentDiv>
          <ChartIconComponent />
        </ChartConponentDiv>
      </FinanceContainer>
    </>
  );
}

export const FinanceContainer = styled.div`
  margin-bottom: 5em;
  margin-top: 4em;
  display: flex;
  gap: 5em;
  /* z-index: 1; */
  margin-left: 2em;
  width: 100%;
  ${mediaQuery.tablet} {
    margin-inline: auto;
  }
  ${mediaQuery.mobile}{
    margin-top: 0;
  }
  .input {
    background: #ffffff;
    border: 0.5px solid #999999;
    /* border-radius: 8px; */
    min-width: 100%;
    width: 433px;
    height: 52px;
  }
`;
export const Finance = styled.div`
margin-inline: auto;
width: 80%;
${mediaQuery.tablet} {
  width: 100%;
  margin: auto;
}

.btn{
    padding: 1em !important;
  }
`;

export const FinanceContent = styled.div`
width: 100%;
`;
export const FinanceHeader = styled.div`
`;
export const H1 = styled.h1`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;
color: #000000;
${mediaQuery.tablet}{
  font-size: 22px;
}
`;
export const P = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: .9rem;
  line-height: 20px;
  width: 330px;
  height: 53px;
  color: #797474;
  margin-top: 0.8em;
  width: 100%;
`;

// const SelectInputDiv = styled.div``;
const ChartConponentDiv = styled.div`
  position: fixed;
  right: 6em;
  @media (max-width: 682px) {
    right: 2em;
    bottom: 1.408125em;
  }
  @media (max-width: 500px) {
    right: 1em;
    bottom: 1em;
  }
`;

const DetailsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4em;
  width: 100%;
  margin-top: 1.5em;
  ${mediaQuery.tablet} {
  gap: 1em;
}
`;
const Preview = styled(Button)`
  && {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 17px;
    color: #0084fe;
    text-transform: none;
    background: #ffffff;
    border: 1.2px solid #0084fe;
    border-radius: 29px;
    ${mediaQuery.tablet} {
  width: 100%;
}
  }

  svg {
    fill: #0084fe;
  }
`;



const AgreementDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7em;
  ${mediaQuery.tablet} {
  width: 100%;
}
  svg {
  }
`;

const P2 = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 17px;
  color: #000000;
  width: 100%;
  ${mediaQuery.mobile}{
    font-size: .875rem;
  }
`;

const BtnDiv = styled.div`
display: flex;
align-items: center;
gap: 1em;
${mediaQuery.tablet} {
  width: 100%;
  gap: 0;
}
${mediaQuery.mobile} {
  flex-direction: column;
  gap: .5em;
}
`;

const Line = styled.div`
  border: 0.5px solid #a6a6a6;
  width: 100%;
  margin-top: 1em;
`;

const Text = styled.div`
  margin-top: 1.2em;
`;
