import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ValidationProps {
    firstNameError: string;
    lastNameError: string;
    emailError: string;
    passwordError: string;
    confirmPasswordError: string;
    phoneError: string;
    addressError: string;
    countryError: {
        countryCodeError: string;
        countryValueError: string;
    }
    dropDownError: string;
    imageUploadError: {
        front: string;
        back: string;
        normal: string;
    };
}

const initialState: ValidationProps = {
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
    phoneError: "",
    addressError: "",
    countryError: {
        countryCodeError: "",
        countryValueError: ""
    },
    dropDownError: "",
    imageUploadError: {
        front: "",
        back: "",
        normal: "",
    },
};

const validationSlice = createSlice({
    name: "validation",
    initialState,
    reducers: {
        setFirstNameError: (state, action: PayloadAction<string>) => {
            state.firstNameError = action.payload;
        },
        setLastNameError: (state, action: PayloadAction<string>) => {
            state.lastNameError = action.payload;
        },
        setEmailError: (state, action: PayloadAction<string>) => {
            state.emailError = action.payload;
        },
        setPasswordError: (state, action: PayloadAction<string>) => {
            state.passwordError = action.payload;
        },
        setConfirmPasswordError: (state, action: PayloadAction<string>) => {
            state.confirmPasswordError = action.payload;
        },
        setPhoneError: (state, action: PayloadAction<string>) => {
            state.phoneError = action.payload;
        },
        setAddressError: (state, action: PayloadAction<string>) => {
            state.addressError = action.payload;
        },
        setCountryError: (state, action: PayloadAction<string>) => {
            state.countryError.countryCodeError = action.payload;
            state.countryError.countryValueError = action.payload;
        },
        setDropdownError: (state, action: PayloadAction<string>) => {
            state.dropDownError = action.payload
        },
        // image 
        setbackIDImageUploadError: (state, action: PayloadAction<string>) => {
            state.imageUploadError.back = action.payload
        },
        setFrontIDImageUploadError: (state, action: PayloadAction<string>) => {
            state.imageUploadError.back = action.payload
        },
        setImageError:(state, action: PayloadAction<string>) => {
            state.imageUploadError.normal = action.payload
        }
    },
});

export const { setFirstNameError, setLastNameError, setEmailError, setPasswordError, setConfirmPasswordError, setPhoneError, setAddressError,setCountryError,setDropdownError,setbackIDImageUploadError,setFrontIDImageUploadError,setImageError } =
    validationSlice.actions;

export default validationSlice.reducer;
