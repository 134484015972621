// imageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PhoneOtpProps {
    value: boolean;
    phone: string
    phone_status: string
    createdon: string
}

const initialState: PhoneOtpProps = {
    value: false,
    phone: "",
    phone_status: "",
    createdon:"",
};

const phoneOtpSubmitSuccess = createSlice({
    name: 'otp',
    initialState,
    reducers: {
        // setPhoneOtpSubmit: (state, action: PayloadAction<boolean >) => {
        // state.value = action.payload;
        // },
        setOtpPostResponse: (state, action: PayloadAction<PhoneOtpProps >) => {
            state.phone = action.payload.phone;
            state.phone_status = action.payload.phone_status;
            state.createdon = action.payload.createdon;
        }
    },
});

export const {setOtpPostResponse } = phoneOtpSubmitSuccess.actions;

export default phoneOtpSubmitSuccess.reducer;


