import { createSlice,PayloadAction } from "@reduxjs/toolkit";

interface PlanProps {
    plan: string;
}

const initialState: PlanProps = {
    plan: ""
}



const PlanSlice = createSlice({
    name: "plan",
    initialState,
    reducers: {
        setPlan: (state, action: PayloadAction<string>) =>{
            state.plan = action.payload
            
        }
    }
})

export const {setPlan} = PlanSlice.actions;

export default PlanSlice.reducer