import { LocalizationProvider } from "@mui/x-date-pickers";
import { styled } from "styled-components";

export const MainDashboard = styled.div`
  margin-bottom: 2rem;
  width: 90%;
  .kyc_section {
    margin-top: 2rem;
    text-align: center;
    @media (min-width: 1020px) {
      display: flex;
      align-items: center;
      width: 55%;
      background: #ffffff;
      border-radius: 8px;
      border: 0.5px solid #e1e1e1;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
      justify-content: space-between;
      padding: 0rem 2rem;
      margin: 2rem 1rem;
      text-align: left;
    }
    p {
      font-size: 0.85rem;
      color: rgba(239, 0, 0, 1);
    }
    button {
      width: 70%;
      @media (min-width: 1020px) {
        width: 180px;
        font-size: 0.8rem !important;
        margin: 1rem;
      }
    }
  }
  @media (min-width: 1020px) {
    margin-left: 22em;
    width: 100%;
  }
`;

export const MainContainer = styled.div`
  width: 95%;
  margin-top: 3em;
  margin-inline: auto;
  @media (min-width: 700px) {
    width: 85%;
  }
  @media (min-width: 1020px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 4em;
    margin-top: 2em;
  }
`;

export const TopChart = styled.div`
  width: 100%;
  display: grid;
  gap: 1.5em;
  grid-auto-columns: 1fr;
  .head {
    display: flex;
    gap: 1.5em;
    flex-direction: column;
    @media (min-width: 1140px) {
      flex-direction: row;
    }
  }
  @media (min-width: 1140px) {
    margin-inline: auto;
    width: 55%;
  }
`;

export const Aside = styled.div`
  margin-top: 1.5em;
  @media (min-width: 1020px) {
    margin: 0 auto auto;
    width: 40%;
  }
`;

export const Gallary = styled.div`
  @media (min-width: 1020px) {
    width: 70%;
    margin-inline: auto;
  }
  h3 {
    text-align: left !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #3f3f3f;
    padding: 0.8em;
  }
  .gallary {
    margin-inline: auto;
    padding: 1em;
    background: #ffffff;
    border: 0.5px solid #e1e1e1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    text-align: center;

    .icon {
      padding: 1em;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: rgba(0, 49, 122, 0.51);
      width: 80%;
      text-align: center;
      margin: auto;
      margin-bottom: 2em;
    }
  }
`;

// const Contact = styled.div`
//   padding: 1.5em;
// `;

export const LocalizationProviders = styled(LocalizationProvider)`
  && {
    border: 2px solid #404040;
  }
`;
