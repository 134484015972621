import { Alert, Box, Button, Modal, Snackbar } from "@mui/material";
import { styled } from "styled-components";
import { IDselectProps } from "../../../types";
import { SelectInput } from "../../../utils/select";
import { FileUpload } from "../../imageUploads/fileUpload";
import { FormEvent, useState } from "react";
import apiFetchClient from "../../../apiProvider/axiosInstance";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { setIDcardVerificationSuccess } from "../../../slice/idSuccess";
import { useNavigate } from "react-router-dom";
import { verificationStatusLink } from "../../../links";
import { RootState } from "../../../store";
import { setDropdownError, setFrontIDImageUploadError, setbackIDImageUploadError } from "../../../slice/Validation";
import { mediaQuery } from "../../../breakPoint";
import { useNotificationToast } from "../../../hooks/useToast";


const style = {
  boxShadow: 24,
};

export function SelectIDcardModal(props: IDselectProps) {
  const { successToast } = useNotificationToast()

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { dropDownError, imageUploadError } = useSelector((state: RootState) => state.validationReducer);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<any>();

  const [imageFront, setImageFront] = useState<File[]>([]);
  const [imageBack, setImageBack] = useState<File[]>([]);

  async function HandleSubmit(event: FormEvent) {
    event.preventDefault();

    if (props?.items === "") {
      dispatch(setDropdownError("Required *"))
      return
    }

    // image 
    if (imageFront.length === 0) {
      dispatch(setFrontIDImageUploadError("Please provide an image"))
      return
    }

    if (imageBack.length === 0) {
      dispatch(setbackIDImageUploadError("Please provide an image"));
      return
    }

    try {
      setIsLoading(true)
      const formData = new FormData();

      imageFront.forEach(file => {
        formData.append("files", file);
      });

      imageBack.forEach(file => {
        formData.append("files", file);
      });

      formData.append("card_type", props.items);

      const response = await apiFetchClient.post("profile/verification/id-card/", formData);

      setIsLoading(false);

      navigate(verificationStatusLink, { replace: true });

      dispatch(setIDcardVerificationSuccess(response?.data?.result));

      successToast(response?.data?.message ?? "Successful!")

    }
    catch (err) {
      setIsLoading(false);
      setError(err);

    }
  }


  return (
    <>
      <Main>
        <ModaL
          open={true}
          onClose={props.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BoxDiv sx={style}>
            {error && (
              <Snack
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={error ? true : false}
                autoHideDuration={6000}
              >
                <Alert sx={{ textTransform: "capitalize" }} severity="error">{error?.message === "invalid grant" ? "Something went wrong try again" : error?.response?.data?.message}</Alert>
              </Snack>
            )}
            <Content>
              <Text>
                <h2>{props?.name}</h2>
                <p>{props?.description}</p>
              </Text>
              <div className="input_container">
                <SelectInput
                  SelectLabel={props?.SelectLabel}
                  items={props?.items}
                  valueItems={props?.valueItems}
                  onChange={props?.onChange}
                  setItems={props?.setItems}
                  defaultName="Select"
                  error={dropDownError ? dropDownError : ""}
                />
              </div>
              <section className="images">
                <FileUpload error={imageUploadError.front ? imageUploadError.front : ""} src={setImageFront} name="Front Photo" />
                <FileUpload error={imageUploadError.back ? imageUploadError.back : ""} src={setImageBack} name="Back Photo" />
              </section>
              <SaveButton
                // onClick={() => navigate(verificationStatusLink)}
                onClick={HandleSubmit}
                type="submit"
                loading={isLoading}
              >Save</SaveButton>
              <StyledButton onClick={() => navigate(-1)} variant="text">Cancel</StyledButton>
            </Content>
          </BoxDiv>
        </ModaL>
      </Main>
    </>
  );
}

export const Snack = styled(Snackbar)`

 &&{
  @media (min-width: 1020px) {
  margin-left: 1em;
 }

 ${mediaQuery.mobile}{
  margin-top: 5em;
 }
 }
`

const SaveButton = styled(LoadingButton)`
  && {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: .85rem;
    line-height: 19px;
    padding: 14px 129px;
    background: #0084fe;
    border-radius: 4px;
    color: #ffffff;
    width: 100%;
  }
`

const Main = styled.form``;

const ModaL = styled(Modal)`
  width: 60%;
  margin: auto;
  ${mediaQuery.mobile}{

    &&{
      width: 90%;
      overflow: scroll;
    }
  }
`;

const BoxDiv = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background: #ffffff;
  border-radius: 4px;
  padding: 1.5em;

  &&{
    ${mediaQuery.mobile}{
      margin-top: 2em;
      position: relative;
      width: 400;
    }
  }
`;

const Content = styled.div`
  .input {
    padding: 1.2em;
  }
  .input_container {
    margin-bottom: 2.5em;
  }
`;
const Text = styled.div`
${mediaQuery.mobile}{
  margin-top: 2em;
}
  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.1275rem;
    line-height: 22px;
    color: #00317a;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 19px;
    margin-top: 1em;
    color: #797474;
  }
`;

const StyledButton = styled(Button)`
  && {
    background: #ffffff;
    border: 1px solid #00317a;
    border-radius: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: #00317a;
    width: 100%;
    margin-top: 1em;
    padding: 0.7em;
  }
`;
