import { useSnackbar } from "notistack";


export const useNotificationToast = () => {
    const { enqueueSnackbar } = useSnackbar();
    const errorToast = (message: any) => enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

    const successToast = (message: any) => enqueueSnackbar(message, { variant: "success", anchorOrigin: {vertical: 'top', horizontal: 'right' } });

    const normal = (message: any) => enqueueSnackbar(message, { anchorOrigin: {vertical: 'bottom', horizontal: 'right'},  autoHideDuration: 2000  });
    return {errorToast,successToast, normal}
}