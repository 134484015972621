import { styled } from "styled-components";
import { mediaQuery } from "../../../../../../breakPoint";
import { Button } from "@mui/material";
import { useGetLandDetails } from "../../../../../../hooks/uselandDetails";


export function LandDetailsItems() {

  const { 
    status, 
    land_title, 
    land_description, 
    land_location,
    //  land_document ,
     land_photos, 
     land_floor_area, 
     land_plot_area} = useGetLandDetails()


  return (
    <>
      <ContactInfoDiv>
        <Content>

          <DetailsContainer>
            <DETAILS>
              <H3>STATUS</H3>
              <H2 status={status as string} >{status}</H2>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Title</H3>
              <p>{land_title}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Land description</H3>
              <p>{land_description}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Total Plot Area</H3>
              <p>{land_plot_area}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Land Floor Area</H3>
              <p>{land_floor_area}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          <DetailsContainer>
            <DETAILS>
              <H3>Land Location</H3>
              <p>{land_location}</p>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
          {/* <DetailsContainer>
            <DETAILS>
              <H3>Land Document</H3>
              <Button href={land_document?.src as string} variant="text" size="small" download>Land Document</Button>
            </DETAILS>
            <Line></Line>
          </DetailsContainer> */}
          <DetailsContainer>
            <DETAILS>
              <H3>Land Photos</H3>
              <div className="btn">
                {land_photos && land_photos.map(photo => (

                  <Button href={photo} variant="text" size="small" download>Land Photo</Button>

                ))}
              </div>
            </DETAILS>
            <Line></Line>
          </DetailsContainer>
        </Content>
      </ContactInfoDiv>
    </>
  );
}

const ContactInfoDiv = styled.div`
  margin-top: 2.375em;
  margin-bottom: 3.5em;
  ${mediaQuery.tablet} {
    width: 100%;
  }
`;

const Content = styled.div``;

const DetailsContainer = styled.div``;

const Line = styled.div`
  border: 0.5px solid #919191;
  margin-top: 1.1875em;
`;
const DETAILS = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1em;
  input,
  textarea {
    border: none;
    outline: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    background: #fff;
    line-height: 19px;
    width: 100%;
    padding: 0.5em;
    color: #0e0e0e;
  }
  p{
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #2c2c2c;
    text-transform: capitalize;
  }
  .btn{

  }
`;

const H3 = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  width: 180px;
  line-height: 17px;
  text-transform: uppercase;

  color: #838383;
`;


export const H2 = styled.h2<{ status: string }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 22px;
  color: ${({ status }) =>
    status === "APPROVED"
      ? "#33B95D;"
      : status === "PENDING"
        ? "#DFAE00;"
        : status === "REJECTED"
          ? "#CE2B2B;"
          : "#00317a"};
`


