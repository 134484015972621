import { configureStore } from '@reduxjs/toolkit'
import imageReducer from "..//slice/uploadImageSlice";
import imageBackReducer from "../slice/uploadIdBackSlice"
import phoneSubmitSuccessReducer from '../slice/phoneSubmit';
import phoneOtpSubmitSuccessReducer from '../slice/phoneOtpSuccess';
import IdVerifiactionSuccessReducer from '../slice/idSuccess';
import residentialsSliceReducer from '../slice/residentialsSlice';
import otpRefReducer from '../slice/otpRef';
import validationReducer from '../slice/Validation';
import selectedPlanReducer from '../slice/selectedPlanSlice';

export const store = configureStore({
    reducer: {
        image: imageReducer,
        imageBack: imageBackReducer,
        phoneSubmitSuccess: phoneSubmitSuccessReducer,
        phoneOtpSubmitSuccess: phoneOtpSubmitSuccessReducer,
        IdVerifiactionSuccess: IdVerifiactionSuccessReducer,
        residentialSlice: residentialsSliceReducer,
        otpRefReducer: otpRefReducer,
        validationReducer: validationReducer,
        selectedPlanReducer: selectedPlanReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>; 