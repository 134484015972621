import { useState } from 'react';
import { useNotificationToast } from './useToast';

export const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const {normal} = useNotificationToast()

  const copyToClipboard = (textToCopy: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setIsCopied(true);
        // Reset the copied state after a short delay
        normal("copied to clipboard")
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      });
    }
  };

  return { isCopied, copyToClipboard };
};

