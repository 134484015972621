import { styled } from "styled-components";
import { FileUpload } from "../../../../../components/imageUploads/fileUpload";
import { InputField } from "../../../../../utils/input";
// import { useState } from "react";
// import { SelectChangeEvent } from "@mui/material";
// import { SelectInput } from "../../../../../../utils/select";
import { mediaQuery } from "../../../../../breakPoint";
import { Dispatch, SetStateAction } from "react";

interface SelftEmployedProps {
  jobDescription: string
  setJobDescription: Dispatch<SetStateAction<string>>
  averageEarning: string;
  setAverageEarning: Dispatch<SetStateAction<string>>;
  companyReg: string;
  setCompanyReg: Dispatch<SetStateAction<string>>;
  // image upload
  setPersonalBankStatements: Dispatch<SetStateAction<File[]>>;
  setCompanyBankStatements: Dispatch<SetStateAction<File[]>>;
  setFormCo2: Dispatch<SetStateAction<File[]>>;
  setCertificationRegistration: Dispatch<SetStateAction<File[]>>;
}

export function SelfEmployed(props: SelftEmployedProps) {
  // const [salaryRange, setSalaryRange] = useState<string>('');

  // const amounts = ["N500", "N2000", "N5000", "N1000000", "N100", "N10000"];
  // const selectAmountHandleChange = (event: SelectChangeEvent) => {
  //   setSalaryRange(event.target.value as string);
  // };

  return (
    <>
      <SelfEmplyedMain>
        <ImageUpLoadDiv>
          <FileUpload
            src={props.setPersonalBankStatements}
            name="Upload Personal Bank Statement (1 year) 1.5mb(Max)"
            label="Personal Bank Statement (1 year)"
          />
          <FileUpload
            src={props.setCompanyBankStatements}
            label="Company Bank Statement (1 year)"
            name="Company Bank Statement (1 year)  1.5mb(Max)"
          />
          {/* <FileUpload
            label="Cashflow Projections "
            name="Upload Cashflow Projections "
          /> */}
        </ImageUpLoadDiv>
        <FieldContainer>
          <InputField
            label="Job Description"
            type="text"
            placeholder="Enter Here"
            className="input"
            value={props.jobDescription}
            onChange={(event) => props.setJobDescription(event.target.value)}
          />
          {/* <SelectInput
            SelectLabel="Average Monthly Earning"
            defaultName="N500k - N1Million"
            valueItems={amounts}
            onChange = {selectAmountHandleChange}
            items = {salaryRange} setItems = {setSalaryRange}
          /> */}
          <InputField
            label="Average Monthly Earning"
            type="tel"
            placeholder="Enter Here"
            className="input"
            value={props.averageEarning}
            onChange={(event) => props.setAverageEarning(event.target.value)}
          />
          <InputField
            label="Company registration No."
            type="tel"
            placeholder="Enter Here"
            className="input"
            value={props.companyReg}
            onChange={(event) => props.setCompanyReg(event.target.value)}
          />
        </FieldContainer>
        <ImageUpLoadDiv>
          <FileUpload
            src={props.setFormCo2}
            label="Form CO2"
            name="Form CO2 or CO7 (Particulars of Directors)  1.5mb(Max)"
          />
          <FileUpload
            src={props.setCertificationRegistration}
            label="Certificate of Registration"
            name="Upload certificate of registration  1.5mb(Max)"
          />
          {/* <FileUpload
            label="Memorandum of Articles of Association"
            name="Upload Memorandum of Articles of Association"
          /> */}
        </ImageUpLoadDiv>
      </SelfEmplyedMain>
    </>
  );
}

const SelfEmplyedMain = styled.div``;

const ImageUpLoadDiv = styled.div``;

const FieldContainer = styled.div`
.input{
    ${mediaQuery.tablet} {
      width: 100% !important;
    }
}
`;
