import { gql } from "@apollo/client";

export const EXCLUSIVE_LAND_TYPE = gql`
  query CurrentBuildNowPhase {
    currentBuildNowPhase {
      land_type
    }
  }
`;

export const EXCLUSIVE_BUILDING_SPECIFICATION_DETAILS_EXISTING = gql`
  query CurrentBuildNowPhase {
    currentBuildNowPhase {
      building_specification {
        ... on ExclusiveBuildingSpecification {
          custom_plan_description
          status
        }
      }
    }
  }
`;
